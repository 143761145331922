import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DetailsPro_img from "../../Assets/Images/detailspro_pic.png";
import Slider from "react-slick";
import { Link, NavLink } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RatingChanged from "../../Component/RatingChanged";
import { reactLocalStorage } from "reactjs-localstorage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../utils/HttpClient";
import ReactStars from "react-rating-stars-component";
import Favourite from "../../Assets/Images/favourite.png";
import Insta from "../../Assets/Images/insta.png";
import FB from "../../Assets/Images/Facebook_icon.png";
import WhatsApp_icon from "../../Assets/Images/WhatsApp_icon.png";
import P_icon from "../../Assets/Images/p icon.png";
import Youtube from "../../Assets/Images/youtube.png";
import Magnifier from "react-magnifier";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Loader from "../../Component/loader";
import { confirmAlert } from "react-confirm-alert";
import { onValue } from "firebase/database";

const DetailsPro = (props) => {
  console.log("product----", props.details);
  const userData = reactLocalStorage.getObject("userData");
  const { _id } = useParams();
  const [counter, setCounter] = useState(1);
  const [defaultBaseImage, setdefaultBaseImage] = useState(0);
  const image = props.details.image ? props.details.image : [];
  const [bgImage, setBgImage] = useState("");
  const propsDetails = props.details;
  let tax_percent = props.details.tax.replace("%", "");
  let b = Number(props.details.mrp) * (Number(tax_percent) / 100);
  let selling_priceWithTax = (props.details.mrp + b).toFixed(2);
  // const [reviewlist, setreviewlist] = useState([]);
  // const [avg, setavg] = useState(0)
  const loginstatus = reactLocalStorage.getObject("loginstatus");
console.log(loginstatus,"46");
  const [isWishlisted, setisWishlisted] = useState(false);
  const [cartBtn, setCartBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productid, setproductid] = useState("");

  // console.log(props.details);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    console.log("be");
    // if (reactLocalStorage.hasOwnProperty("loginstatus") && loginstatus) {
    //   console.log("af");
    //   getcart();
    // }
    getcart();
    getWishlistProduct();
  }, []);

  const showReviewSection = () => {
    props.showReviewData(true);
  };

  const getcart = async () => {
    let data = {};
    setLoading(true);
    let result = await HttpClient.requestData(
      "get-cart/" + userData._id,
      "GET",
      data
    );
    console.log(result,"107");
    let _id = propsDetails._id;
    if (result && result.status) {
      let newData = result.data.filter((item) => item.prod_id == _id);
      console.log("FALSE", newData);
      if (newData.length > 0) {
        setCartBtn(true);
        setproductid(newData[0]._id);
      }
      setLoading(false);
      console.log("newData", newData);
    } else {
    }
  };

  const getWishlistProduct = async () => {
    let user_id = userData._id;
    let result = await HttpClient.requestData(
      "product-wishlist/" + user_id,
      "GET"
    );
    let _id = propsDetails._id;
    // console.log(_id);
    if (result && result.status) {
      console.log("wishlisted data product", result.data);
      let newData = result.data.filter((item) => item.prod_id == _id);
      console.log("filter----", newData);
      if (newData.length > 0) {
        setisWishlisted(true);
        setproductid(newData[0]._id);
      }
    } else {
      console.log("error data", result.data);
    }
  };

  const loop = () => {
    let iterator = propsDetails.image.keys();
    let emptyArray = [];
    for (const key of iterator) {
      emptyArray.push(key);
    }
    return emptyArray;
  };

  const desccounter = () => {
    if (counter < 0) {
      setCounter(0);
    } else {
      setCounter(counter - 1);
    }
  };

  const addcart = async () => {
    if (cartBtn) {
      window.location.href = "/checkout";
    } else {
      let data = {
        prod_id: propsDetails._id,
        user_id: userData._id,
        productname: propsDetails.name,
        qty: counter,
        price: propsDetails.selling_price,
        image: propsDetails.image[0],
        orderFrom: "web",
      };

      let result = await HttpClient.requestData("add-to-cart", "POST", data);
      // console.log("result", result);
      if (result && result.status) {
        window.location.href = "/checkout";
      } else {
      }
    }
  };
  const addTocart = async () => {
    let data = {
      prod_id: propsDetails._id,
      user_id: userData._id,
      currency: userData.currency,
      productname: propsDetails.name,
      qty: counter,
      price: propsDetails.selling_price,
      image: propsDetails.image[0],
      orderFrom: "web",
    };
    // console.log("data", data);
    let result = await HttpClient.requestData("add-to-cart", "POST", data);
    // console.log("result", result);
    if (result && result.status) {
      if (isWishlisted) {
        confirmAlert({
          title: "",
          message: "After adding to cart product will be removed from wishlist",
          buttons: [
            {
              label: "Ok",
              onClick: () => {},
            },
          ],
        });
      }
      setCartBtn(true);
      setisWishlisted(false);
      toast.success(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
      reactLocalStorage.setObject("cartitem", data);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const changeImage = (image) => {
    setdefaultBaseImage(image);
  };

  const alert = () => {
    toast.error("You have to login first", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });
  };

  const wishlist = async () => {
    // console.log("propsDetails.image", propsDetails.image);
    let userId = userData._id;
    let _id = propsDetails._id;
    let sending = {
      user_id: userId,
      prod_id: _id,
      productname: propsDetails.name,
      qty: "1",
      price: propsDetails.selling_price,
      image: propsDetails.image,
    };
    let result = await HttpClient.requestData(
      "product-wishlist",
      "POST",
      sending
    );
    if (result && result.status) {
      if (cartBtn) {
        confirmAlert({
          title: "",
          message: "After wishlisting product will be removed from your cart",
          buttons: [
            {
              label: "Ok",
              onClick: () => {},
            },
          ],
        });
      }
      localStorage.setItem("wishlisted", result.data);
      setisWishlisted(true);
      setCartBtn(false);
      toast.success("Added to Wishlist", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    } else {
      toast.error("Server error, try again later!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const facebookShare = () => {
    const facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=";
    const currentUrl = window.location.href;
    return facebookUrl + currentUrl;
  };

  const downloadPdf = () => {
    window.print();
  };
  function curURL() {
    // console.log("url", window.location.href);
    return window.location.href;
    //return ("https://www.geeksforgeeks.org/");
  }

  const confirmyes = async (val) => {
    console.log("iddddddd", val);
    let data = {};
    let result = await HttpClient.requestData(
      "product-wishlist/" + val,
      "DELETE",
      data
    );
    // console.log("result", result);
    if (result && result.status) {
      toast.success("Remove from wishlist", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setisWishlisted(false);
      props.callbackResult(result.status);
      setCartBtn(false);
      // window.location.href = "/home";
    } else {
    }
  };

  const confirmyeswish = async (val) => {
    console.log("del", val);
    let data = {};
    let result = await HttpClient.requestData(
      "product-wishlist/" + val,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      setisWishlisted(false);
    } else {
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="row Details">
            {/* <ToastContainer /> */}
            {/* {console.log(userData, "userData")} */}
            <div className="col-md-1 col-sm-2">
              <Slider {...settings}>
                {image.map((item2, index2) => {
                  return (
                    <div
                      className="my-3 mr-2 border"
                      onClick={() => setBgImage(item2)}
                    >
                      <img
                        className="p-2"
                        src={HttpClient.IMG_URL + item2}
                        alt="img1"
                        style={{
                          width: "100%",
                          MaxWidth: "120px",
                          height: "80px",
                        }}
                        key={index2}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="col-md-5 col-sm-10">
              <Magnifier
                // src={
                //   HttpClient.IMG_URL +
                //   propsDetails.image[defaultBaseImage]
                // }
                src={`https://admin.astrophy.com/${
                  bgImage ? bgImage : image[0]
                }`}
                // width={500}
                className="detail_pic"
              />

              {/* <div>
          <img
            src={HttpClient.IMG_URL + propsDetails.image}
            alt="img"
            className="detail_pic"
          />
        </div> */}
              {loginstatus ? (
                // <div className="absolute_bg" onClick={wishlist}>
                //   <img src={Favourite} alt="img" className="absolute m-2" />
                // </div>
                <div>
                  {isWishlisted ? (
                    <div
                      className="absolute_bg"
                      onClick={() => {
                        confirmyes(productid);
                      }}
                    >
                      <i className="fas fa-heart text-danger"></i>
                    </div>
                  ) : (
                    <div
                      className="absolute_bg"
                      onClick={() => {
                        wishlist(props.details);
                      }}
                    >
                      <i className="far fa-heart text-danger"></i>
                    </div>
                  )}
                </div>
              ) : (
                // <div className="absolute_bg" onClick={alert}>
                //   <img src={Favourite} alt="img" className="absolute m-2" />
                // </div>
                <div className="absolute_bg" onClick={alert}>
                  <i className="far fa-heart text-danger"></i>
                </div>
              )}
              {/* {propsDetails.image.length > 1 ? (
                <Slider {...settings}>
                  {loop().map((item2, index2) => {
                    return (
                      <div className="d-flex  flex-column my-5 " key={index2}>
                        <button
                          onClick={() => changeImage(item2)}
                          style={{ border: "none" }}
                          className="px-3"
                        >
                          <img
                            src={
                              HttpClient.IMG_URL +
                              propsDetails.image[item2]
                            }
                            alt="img1"
                            style={{
                              width: "100%",
                              MaxWidth: "120px",
                              height: "110px",
                            }}
                          />
                        </button>
                      </div>
                    );
                  })}
                </Slider>
              ) : null} */}
            </div>
            <div className="col-md-6 px-md-4 col-sm-12 details_text">
              <div className="rating_sec">
                <i className="fas fa-star my-1 mr-2"></i>
                {}
                {props.avg ? props.avg.toFixed(1) : "0"}/5{" "}
                <span className="mx-3">{propsDetails.salesCount} Sales</span>
              </div>
              <div className="heading">
                <h3 className="my-3">
                  {propsDetails.name ? propsDetails.name : ""}
                </h3>
              </div>
              {selling_priceWithTax < propsDetails.selling_price ? (
                <div className="d-md-flex flex-wrap rupee_sec">
                  {/* <del className="mr-2 text-muted">
                   {userData.currency == "INR"
                     ? "₹"
                     : userData.currency == "CAD"
                     ? "$"
                     : userData.currency == "USD"
                     ? "$"
                     : userData.currency == "	GBP"
                     ? "£"
                     : "€"}{" "}
                   {propsDetails.mrp ? propsDetails.mrp.toFixed(2) : ""}
                 </del> */}
                  <h5>
                    {userData.currency == "INR"
                      ? "₹"
                      : userData.currency == "CAD"
                      ? "$"
                      : userData.currency == "USD"
                      ? "$"
                      : userData.currency == "GBP"
                      ? "£"
                      : userData.currency == "EUR"
                      ? "€"
                      : "₹"}{" "}
                    {props.details.selling_price
                      ? props.details.selling_price
                      : ""}
                  </h5>
                  <h5 className="ml-2 text-muted">
                    {propsDetails.offer ? propsDetails.offer + "% Off+" : null}
                    {propsDetails.tax}Tax
                  </h5>

                  <p className="mx-md-4">
                    <i className="fas fa-check-circle mr-2"></i>
                    In Stock
                  </p>
                </div>
              ) : (
                <div className="d-md-flex flex-wrap rupee_sec">
                  <del className="mr-2 text-muted">
                    {userData.currency == "INR"
                      ? "₹"
                      : userData.currency == "CAD"
                      ? "$"
                      : userData.currency == "USD"
                      ? "$"
                      : userData.currency == "GBP"
                      ? "£"
                      : userData.currency == "EUR"
                      ? "€"
                      : "₹"}{" "}
                    {selling_priceWithTax}
                  </del>
                  <h5>
                    {userData.currency == "INR"
                      ? "₹"
                      : userData.currency == "CAD"
                      ? "$"
                      : userData.currency == "USD"
                      ? "$"
                      : userData.currency == "GBP"
                      ? "£"
                      : userData.currency == "EUR"
                      ? "€"
                      : "₹"}{" "}
                    {propsDetails.selling_price
                      ? propsDetails.selling_price
                      : ""}
                  </h5>
                  <h5 className="ml-2 text-muted">
                    {propsDetails.offer ? propsDetails.offer + "% Off+" : null}
                    {propsDetails.tax}Tax
                  </h5>

                  <p className="mx-md-4">
                    <i className="fas fa-check-circle mr-2"></i>
                    In Stock
                  </p>
                </div>
              )}

              {/* <div className="d-md-flex flex-wrap rupee_sec">
                <h6>(Offer Limit:10th Oct-20th Oct)</h6>

              </div> */}

              {/* <div className=" my-3 ">
          <ul className="color_varient">
            <li className="yellow mx-2"></li>
            <li className="pink mx-2"></li>
            <li className="blue mx-2"></li>
          </ul>
        </div> */}
              <div>
                {" "}
                <span className="text-muted">
                  Local taxes included (where applicable)
                </span>
              </div>
              <p style={{ color: "black" }}>
                Delivery Charge:&nbsp;
                {userData.currency == "INR" &&
                propsDetails.delivery_amount != null &&
                propsDetails.delivery_amount != ""
                  ? "₹"
                  : userData.currency == "CAD" &&
                    propsDetails.delivery_amount != null &&
                    propsDetails.delivery_amount != ""
                  ? "$"
                  : userData.currency == "USD" &&
                    propsDetails.delivery_amount != null &&
                    propsDetails.delivery_amount != ""
                  ? "$"
                  : userData.currency == "GBP" &&
                    propsDetails.delivery_amount != null &&
                    propsDetails.delivery_amount != ""
                  ? "£"
                  : userData.currency == "EUR" &&
                    propsDetails.delivery_amount != null &&
                    propsDetails.delivery_amount != ""
                  ? "€"
                  : ""}{" "}
                {propsDetails.delivery_amount != null &&
                propsDetails.delivery_amount != ""
                  ? propsDetails.delivery_amount
                  : "Free Delivery"}
              </p>
              <div>
                {" "}
                <div className="form-group my-3">
                  {/* <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    placeholder="Add your personalisation"
                  /> */}
                </div>
              </div>
              <div className="bottom_text">
                <p>
                  Please send me your names & dates of birth (if known). Then i
                  will cast your Spell & send you an etsy message within 24
                  hours,{" "}
                </p>
              </div>
              {/* <div>
                <button
                  className="btn pl-0"
                  type="button"
                  onClick={downloadPdf}
                >
                  <i className="fas fa-download mr-2"></i>Download PDF
                </button>
              </div> */}
              {loginstatus ? (
                <div className="d-flex basket_btn my-3">
                   {cartBtn ? (
                <button
                  className="btn btn-success mr-3"
                  type="button"
                  style={{ background: "green" }}
                  onClick={() => {
                    confirmAlert({
                      title: "Already in service cart",
                      message: "Do you want to delete this?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => confirmyes(productid),
                        },
                        {
                          label: "No",
                          // onClick: () => confirmno(),
                        },
                      ],
                    });
                  }}
                >
                  <i className="fas fa-check mr-2"></i>
                  Added to cart
                </button>
              ) : (
                <button
                  className="btn btn-dark mr-3"
                  type="button"
                  style={{ background: "#343a40" }}
                  onClick={addTocart}
                >
                  <i className="fas fa-shopping-cart mr-2"></i>
                  Add to cart
                </button>
              )}
                  <button className="btn" onClick={addcart}>
                    Buy It Now
                  </button>
                </div>
              ) : (
                <div className="d-flex basket_btn my-3 justify-content-end">
                  <button
                    className="btn btn-dark mr-3"
                    type="button"
                    style={{ background: "#343a40" }}
                    onClick={alert}
                  >
                    <i className="fas fa-shopping-cart mr-2"></i>
                    Add to cart
                  </button>
                  <button className="btn" onClick={alert}>
                    Buy It Now
                  </button>
                </div>
              )}
              <div className="Highlights mb-4">
                {/* <h5>Highlights</h5> */}
                {propsDetails.hasOwnProperty("highlights") &&
                propsDetails.highlights != null &&
                propsDetails.highlights.length ? (
                  <div>
                    <h5>Highlights</h5>
                    {propsDetails.highlights.map((item, index) => {
                      return (
                        <p>
                          {" "}
                          <i className="fas fa-check-circle mr-2"></i>
                          {item.name}
                          <br></br>
                          {/* <i className="fas fa-check-circle mr-2"></i>
                        Materials: Crystal */}
                        </p>
                      );
                    })}
                  </div>
                ) : null}
                {/* <div>
                  <h5>Highlights</h5>
                  <p>
                    {" "}
                    <i className="fas fa-check-circle mr-2"></i>Handmade
                    <br></br>
                    <i className="fas fa-check-circle mr-2"></i>
                    Materials: Crystal
                  </p>
                </div> */}
                <div className="d-flex align-items-center">
                  <p className="mr-2 mb-0">Share by:</p>
                  <div>
                    <div className="d-flex align-items-center">
                      {/* <p className="text-dark mb-0 mr-2">Share by:</p> */}
                      <div>
                        {/* <a
                href={facebookShare()}
                className="btn bg-transparent p-0"
                target="_blank"
              >
                <img src={Facebook_icon} alt="img" className="app_img" />
              </a> */}
                        <FacebookShareButton
                          url={curURL()}
                          // quote={fetchTitle()}
                        >
                          <FacebookIcon size={20} round={false} />
                        </FacebookShareButton>
                        &nbsp;&nbsp;
                        {/* <a
                type="button"
                className="btn bg-transparent p-0"
                href="https://wa.me/whatsappphonenumber/?text=urlencodedtext"
              // action="share/whatsapp/share"
              // target="_blank"
              >
                {" "}
                <img src={WhatsApp_icon} alt="img" className="app_img" />
              </a> */}
                        <WhatsappShareButton
                          url={curURL()}
                          // title={fetchTitle()}
                        >
                          <WhatsappIcon size={20} round={false} />
                        </WhatsappShareButton>
                        {/* <button type="button" className="btn bg-dark mx-3">
              {" "}
              <img
                src={P_icon}
                alt="img"
                className="mx-2"
                className="app_img"
              />
            </button> */}
                        {/* <button type="button" className="btn bg-danger ">
              {" "}
              <img
                src={Youtube}
                alt="img"
                className="mx-3"
                className="app_img"
              />
            </button> */}
                      </div>
                    </div>
                    {/* <a
                type="button"
                className="btn bg-danger mx-3"
                // href="https://www.instagram.com/"
              >
                {" "}
                <img
                  src={Insta}
                  alt="img"
                  className="mr-2"
                  className="app_img"
                />
              </a> */}
                    {/* <a
                  type="button"
                  className="btn bg-transparent p-0 border-0"
                  href={facebookShare()}
                >
                  <img
                    src={FB}
                    alt="img"
                    className="mx-2"
                    className="app_img"
                  />
                </a>{" "} */}
                    {/* &nbsp;
                <a
                  type="button"
                  className="btn bg-transparent p-0 border-0"
                  href="https://wa.me/whatsappphonenumber/?text=urlencodedtext"
                // action="share/whatsapp/share"
                // target="_blank"
                >
                  {" "}
                  <img
                    src={WhatsApp_icon}
                    alt="img"
                    className="mx-2"
                    className="app_img"
                  />
                </a> */}
                    {/* <a
                type="button"
                className="btn bg-dark mx-3"
                // href="https://in.pinterest.com/"
              >
                {" "}
                <img
                  src={P_icon}
                  alt="img"
                  className="mx-2"
                  className="app_img"
                />
              </a> */}
                    {/* <a
                type="button"
                className="btn bg-danger "
                // href="https://www.youtube.com/"
              >
                {" "}
                <img
                  src={Youtube}
                  alt="img"
                  className="mx-3"
                  className="app_img"
                />
              </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row  px-md-4 mb-n5 pb-3">
            <div className="col-md-12 rating_sec ">
              <div
                className="d-flex align-items-center justify-content-start mb-3"
                onClick={showReviewSection}
                style={{ cursor: "pointer" }}
              >
                <h6 className="mr-3 mb-0">
                  {props.reviewcount ? props.reviewcount : "0"} Rating
                </h6>
                {/* {props.avg} */}
                <ReactStars
                  count={5}
                  value={Number(props.avg) ? Number(props.avg.toFixed(1)) : 0}
                  // onChange={ratingChanged}
                  size={24}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#ffd700"
                  edit={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DetailsPro;
