import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import HttpClient from "../../utils/HttpClient";
const OtherService = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [service, setService] = useState([]);
  useEffect(() => {
    fetchService();
  }, []);

  const fetchService = async () => {
    let result = await HttpClient.requestData("service", "GET");
    // console.log("Service", result);
    if (result && result.status) {
      // let data = result.data;
      let data = result.data.filter(
        (item) => item._id === "6204f7256dcb6904acb6a897"
      );
      // console.log("data", data);
      setService(data);
      // console.log("Servicedata", service);
    } else {
    }
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }

  return (
    <div className=" other_area">
      <Slider {...settings}>
        {service.length > 0
          ? service[0].service_data.map((item, index) => {
              return (
                <div className="px-3" key={index}>
                  <div className="card text-center service p-2 my-3">
                    <div className="card-body service_part text-center">
                      <img
                        src={HttpClient.IMG_URL + item.image}
                        alt="img"
                        className="m-auto"
                      />
                      <h5 className="card-title my-3">{item.name}</h5>
                      <p className="card-text">
                        {item.description.substring(0, 85)}
                      </p>
                      {/* <Link to={"/services/" + item.id}> */}
                      <button
                        className="btn my-3 "
                        onClick={() =>
                          (window.location.href = "/services/" + nameToSlug(item.name) + "/" + item._id)
                        }
                      >
                        View More
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </Slider>
    </div>
  );
};
export default OtherService;
