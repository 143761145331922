import React, { useEffect, useState } from "react";
import shop1 from "../../Assets/Images/service_list 9.png";
import shop2 from "../../Assets/Images/service_list 8.png";
import shop3 from "../../Assets/Images/service_list 10.png";
import shop4 from "../../Assets/Images/service_list 14.png";
import Provider_name from "../../Assets/Images/provider_name.png";
import blankImg from "../../Assets/Images/blank.jpg";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import EmptyWishlist from "../EmptyWishlist/EmptyWishlist";

const Provider = () => {
  useEffect(() => {
    getServiceProvider();
  }, []);
  const [loading, setLoading] = useState(false);
  const [loopp, setloopp] = useState([]);
  const [providerWishlist, setproviderWishlist] = useState([]);
  const userdata = reactLocalStorage.getObject("userData");

  const getServiceProvider = async () => {
    setLoading(true);
    let result = await HttpClient.requestData(
      "providergetWishlist/" + userdata._id,
      "GET"
    );
    console.log("provider-wishlist", result);
    if (result && result.status) {
      setproviderWishlist(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 10);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 10);
    }
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }

  const loop = [
    {
      img: shop1,
    },
    {
      img: shop2,
    },
    {
      img: shop3,
    },
    // {
    //   img: shop4,
    // },
  ];
  return (
    <div className="row px-md-4">
      {providerWishlist != null && providerWishlist.length > 0 ? (
        providerWishlist.map((item, index) => {
          return (
            <div
              className="provider_sec col-md-4 col-lg-3 my-4"
              key={index}
              onClick={() => {
                window.location.href = "/service-provider/" + nameToSlug(item.providername) + "/" + item.seller_id;
              }}
            >
              <div className="px-2">
                <div className="bg_lineCover">
                  <div className="card slider2_part px-0 pt-0 pb-2">
                    <div className="card-body slider2_body text-center p-0">
                      <img
                        src={
                          item.provider_data[0].image
                            ? HttpClient.IMG_URL + item.provider_data[0].image
                            : blankImg
                        }
                        alt="img"
                      />
                      <h5 className="card-title my-3">{item.providername}</h5>
                      <p className="card-text">
                        {item.provider_data.length
                          ? item.provider_data[0].about
                          : ""}
                      </p>
                      {/* <button className="btn my-3 ">View More</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyWishlist type="services_provider" />
      )}
      {/* {loop.map((item, index) => {
        return (
          <div className="col-lg-4 col-md-6  my-3" key={index}>
            <div className="card shop">
              <img src={item.img} className="card-img-top" alt="img" />
              <div className="card-body mt-3">
                <div className="d-flex px-2 ">
                  <div>
                    <img
                      src={Provider_name}
                      alt="img"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="mx-2">
                    <Link to="/astrophy-mall">
                      <h5 className="card-title">SeraphDragonaara</h5>
                    </Link>
                    <p>
                      <i className="fas fa-star my-1 mr-1"></i> 4.6/5{" "}
                      <span>(15 Reviews)</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })} */}
    </div>
  );
};
export default Provider;
