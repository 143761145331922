import React, { useEffect, useState } from "react";
// import ServiceList from "../Services/ServiceList";
import ServiceList from "./ServiceListProvider";
import ProviderBanner from "./ProviderBanner";
import ServiceList1 from "../../Assets/Images/service_list 1.png";
import ServiceList2 from "../../Assets/Images/service_list 2.png";
import ServiceList3 from "../../Assets/Images/service_list 3.png";
import ServiceList4 from "../../Assets/Images/service_list 4.png";
import ServiceList5 from "../../Assets/Images/service_list 5.png";
import ServiceList6 from "../../Assets/Images/service_list 6.png";
import ServiceList7 from "../../Assets/Images/service_list 7.png";
import ServiceList8 from "../../Assets/Images/service_list 8.png";
// import Pagination from "../Components/Pagination";
import Pagination from "./ProviderPagination";
import ReviewList from "../ServiceDetails/ReviewList";
import { reactLocalStorage } from "reactjs-localstorage";
import Footer from "../../Defaults/Footer";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Index = (props) => {
  // const id = props.match.params.id;
  const { id } = useParams();
  console.log(useParams(),"useparam");
  const [details, setdetails] = useState({});
  const [service, setService] = useState([]);
  const [shopDetaild, setshopDetaild] = useState({});
  const [reviewData, setreviewData] = useState([]);
  // const id = props.match.params.id;
  const [loading, setLoading] = useState(false);
  const [catname, setcatname] = useState("");
  const [pagevalue, setpagevalue] = useState(1);
  const [itemcount, setitemcount] = useState(0);
  const [productpaginator, setproductpaginator] = useState({});
  const userData = reactLocalStorage.getObject("userData");
  useEffect(() => {
   
    getproviderdetails();
    getproviderServiceList();
    // fetchService();
    window.scrollTo(0, 0);
  }, []);
  const [tab, setTab] = useState("all");
  const items = [
    {
      img: ServiceList1,
      para: "Psychic Love Reading,Same day Response..",
    },
    {
      img: ServiceList2,
      para: "Quickly Three month reading low price!",
    },
    {
      img: ServiceList3,
      para: "Tarot Reading Same Hour Same Day Fast..",
    },
    {
      img: ServiceList4,
      para: "Fast Tarot Reading-Message From The..",
    },
    {
      img: ServiceList5,
      para: "Psychic Love Reading,Same day Response..",
    },
    {
      img: ServiceList6,
      para: "Quickly Three month reading low price!",
    },
    {
      img: ServiceList7,
      para: "Tarot Reading Same Hour Same Day Fast..",
    },
    {
      img: ServiceList8,
      para: "Fast Tarot Reading-Message From The..",
    },
  ];
  const item2 = [1, 2, 3, 4, 5];

  const getproviderdetails = async () => {
    // setLoading(true);
    let sendData = {};
    console.log(id,"iddd");
    console.log("providerdetails", sendData);
    let result = await HttpClient.requestData(
      "singleProviders/" + id,
      "GET",
      sendData
    );
    console.log("singleProviders", result);
    if (result && result.status) {
      setdetails(result.data[0]);
      // setService(result.data[0].service_data);
      setshopDetaild(result.data[0].shop_data);
      setreviewData(result.data[0].rev_data);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };
  const getproviderServiceList = async (next) => {
    // setLoading(true);
    // console.log("providerdetails", sendData);
    let sendData = next ? next : "1";
    if (Object.keys(userData).length > 0) {
      let result = await HttpClient.requestData(
        "shop/providerServiceList/" +
          id +
          "/" +
          sendData +
          "?currency=" +
          userData.currency,
        "GET",
        sendData
      );
      console.log("getproviderServiceList", result);
      if (result && result.status) {
        setService(result.data.itemsList);
        setproductpaginator(result.data.paginator);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1);
      } else {
      }
    } else {
      let result = await HttpClient.requestData(
        "shop/providerServiceList/" +
          id +
          "/" +
          sendData +
          "?currency=" +
          "INR",
        "GET",
        sendData
      );
      console.log("getproviderServiceList", result);
      if (result && result.status) {
        setService(result.data.itemsList);
        setproductpaginator(result.data.paginator);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1);
      } else {
      }
    }
  };
  const nextpage = (val) => {
    console.log("nextmin", val);
    getproviderServiceList(val);
  };
  // const fetchService = async (next) => {
  //   console.log("idd", id);
  //   setLoading(true);
  //   let sendData = next ? next : "1";
  //   if (Object.keys(userData).length > 0) {
  //     let result = await HttpClient.requestData(
  //       "service/shop-services/" + userData._id + "/" +
  //       id +
  //       "/" +
  //       sendData +
  //       "?currency=" +
  //       userData.currency,
  //       "GET"
  //     );
  //     console.log("servicefetchbycategory", result);
  //     if (result && result.status) {
  //       let data = [];

  //       if (result.data.itemsList.length > 0) {
  //         data = result.data.itemsList.filter(
  //           (item) => item.shop_details.length > 0 && item.shop_details[0].userid != userData._id
  //         );
  //       } else {
  //         data = [];
  //       }

  //       // console.log("data", data);
  //       setproductpaginator(result.data.paginator);
  //       setitemcount(result.data.paginator ? result.data.paginator.itemCount : 0)
  //       setcatname(result.category_data.name);
  //       setService(data);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1);
  //       // console.log("Servicedata", service);
  //     } else {
  //     }
  //   } else {
  //     let result = await HttpClient.requestData(
  //       "service/shop-services/" + "91a953b1effa7e26d08fff8a" + "/" +
  //       id + "/" + sendData + "?currency=" +
  //       "INR",
  //       "GET"
  //     );
  //     console.log("servicefetchbycategory", result);
  //     if (result && result.status) {
  //       let data = [];

  //       if (result.data.itemsList.length > 0) {
  //         data = result.data.itemsList.filter(
  //           (item) => item.shop_details[0].userid != userData._id
  //         );
  //       } else {
  //         data = [];
  //       }

  //       console.log("servicefetchbycategory data", data);
  //       setproductpaginator(result.data.paginator);
  //       setitemcount(result.data.paginator ? result.data.paginator.itemCount : 0)
  //       setcatname(result.category_data.name);
  //       setService(data);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1);
  //       // console.log("Servicedata", service);
  //     } else {
  //     }
  //   }
  // };
  return (
    <>
      <Helmet>
        <title>{shopDetaild.title}</title>
        <meta property="og:title" content={shopDetaild.title} />
        <meta property="og:description" content={shopDetaild.description} />
      </Helmet>
      <div>
        <div className="wrapper">
          <ReactTitle title={shopDetaild.title + " | Astrophy"} />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <section>
              <ProviderBanner details={details} />
            </section>
            <section className=" Provider_service_list">
              <div className="container">
                <div className="row p-4">
                  <div className="col-md-12">
                    <div>
                      {" "}
                      <h4>Shop Services</h4>
                      {/* <div className="form-group  ">
                      <div className="d-flex Search_bar">
                        <span className="my-auto mr-0 text-muted">
                          <button className="btn" type="button">
                            <i className="fas fa-search mx-3"></i>
                          </button>
                        </span>
                        <input
                          type="text"
                          className="form-control border-0  px-2 my-auto "
                          id="exampleFormControlInput1"
                          placeholder="Seach here "
                        />
                      </div>
                    </div> */}
                    </div>
                    {/* 
                  <div className="d-md-flex  my-4">
                    <div>
                      <button
                        className={
                          tab === "all"
                            ? "provide_tab active btn mr-2 my-md-0 my-2"
                            : "provide_tab btn mr-2 my-md-0 my-2"
                        }
                        onClick={() => {
                          setTab("all");
                        }}
                      >
                        All (42)
                      </button>
                      <button
                        className={
                          tab === "psychic"
                            ? "provide_tab active btn mr-2 my-md-0 my-2"
                            : "provide_tab btn mr-2 my-md-0 my-2"
                        }
                        onClick={() => {
                          setTab("psychic");
                        }}
                      >
                        Psychic Reading (39)
                      </button>
                      <button
                        className={
                          tab === "reiki"
                            ? "provide_tab active btn mr-2 my-md-0 my-2"
                            : "provide_tab btn mr-2 my-md-0 my-2"
                        }
                        onClick={() => {
                          setTab("reiki");
                        }}
                      >
                        Reiki (03)
                      </button>
                      <button
                        className={
                          tab === "preg"
                            ? "provide_tab active btn mr-2 my-md-0 my-2"
                            : "provide_tab btn mr-2 my-md-0 my-2"
                        }
                        onClick={() => {
                          setTab("preg");
                        }}
                      >
                        Pregnance (06)
                      </button>
                    </div>
                    <div className="form-group d-flex ml-auto review_sort">
                      <h6 className="mr-3 my-2">SortBy:</h6>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>Newly Added</option>
                        

                        <option>1 Star</option>
                        <option>2 Star</option>
                        <option>3 Star</option>
                        <option>4 Star</option>
                        <option>5 Star</option>
                      </select>
                    </div>
                  </div> */}
                  </div>
                </div>
                {/* <div>{tab === "all" ? <ServiceList data={items} /> : ""}</div> */}
                <div>
                  <ServiceList
                    data={service}
                    // callbackserv={callserv}
                  />
                </div>
              </div>
            </section>
            {service.length ? (
              <section className="container">
                <div className="row">
                  <div className="col-md-12 ">
                    <Pagination
                      productpaginator={productpaginator}
                      nextpage={nextpage}
                    />
                  </div>
                </div>
              </section>
            ) : null}

            {reviewData.length ? (
              <section className="mb-4" style={{ marginTop: "5rem" }}>
                <div className="container-fluid" style={{ maxWidth: "1200px" }}>
                  <div className="row p-4">
                    <div className="col-md-12">
                      <div className="d-md-flex border-bottom">
                        <div className="text-muted my-md-0 my-2">
                          Reviews for this Shop ({reviewData.length})
                        </div>
                        {/* <div className="form-group d-flex ml-auto review_sort">
                      <h6 className="mr-3 my-2">SortBy:</h6>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>Newly Added</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div> */}
                      </div>
                      <ReviewList data={reviewData} type="provider_review" />
                    </div>
                  </div>
                </div>
              </section>
            ) : null}

            {/* <section className="container">
            <div className="row">
              <div className="col-md-12 ">
                <Pagination
                  productpaginator={productpaginator}
                  nextpage={nextpage}
                />
              </div>
            </div>
          </section> */}
            <section>
              <Footer />
            </section>
          </>
        )}
      </div>
    </>
  );
};
export default Index;
