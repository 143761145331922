import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import EmptySearch from "../Views/EmptySearch/EmptySearch";
// import ClipLoader from "react-spinners/ClipLoader";
// let [loading, setLoading] = useState(true);
const SearchModal = (props) => {
  const setSearchOptions = (item) => {
    props.callBackSearch(false);
    window.location.href = "/search-page/" + item.item.name + "/" + item.type;
  };
  const [cursor, setcursor] = useState(0);
  const [result, setresult] = useState([]);

  // useEffect(() => {
  //   console.log("searchresult", props);
  // }, [props]);

  return props.isLoading ? (
    <div className="modal-dialog my-3">
      <div
        className="d-flex justify-content-center spinner-border center-loader"
        role="status"
      ></div>
    </div>
  ) :  (
    <div className="modal-dialog my-3">
      <div className="modal-content search_modal_content border-0">
        <div className="search_modal-body px-4">
          {/* {console.log("props.searchresult", props.searchresult)} */}
          {props.searchresult.length > 0 ? (
            <>
              {props.searchresult.map((item, index) => {
                if (item.type != "show") {
                  return (
                    <h6
                      key={index}
                      className="border-0 bg-transparent w-100 text-left search-text fw-normal"
                      onClick={() => setSearchOptions(item)}
                    >
                      <span>{item.item.name} </span>
                    </h6>
                  );
                } else {
                  return (
                    <h6
                      key={index}
                      className="border-0 bg-transparent w-100 text-left search-text fw-normal"
                    >
                      <span>{item.val} </span>
                    </h6>
                  );
                }
              })}
              <h6
                onClick={() => {
                  let item = {
                    item: {
                      name: props.searchstring,
                    },
                    type: "service",
                  };
                  setSearchOptions(item);
                }}
                className="border-0 bg-transparent w-100 text-left search-text fw-normal"
              >
                <span>
                  Find Service Providers containing {props.searchstring}{" "}
                </span>
              </h6>
              <h6
                onClick={() => {
                  let item = {
                    item: {
                      name: props.searchstring,
                    },
                    type: "product",
                  };
                  setSearchOptions(item);
                }}
                className="border-0 bg-transparent w-100 text-left search-text fw-normal"
              >
                <span>Find Shops containing {props.searchstring} </span>
              </h6>
            </>
          ) : (
            <EmptySearch />
          )}
        </div>
      </div>
    </div>
  );
};
export default SearchModal;
