import React, { useState, useEffect } from "react";
import Footer from "../../Defaults/Footer";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { ReactTitle } from "react-meta-tags";
import ReCAPTCHA from "react-google-recaptcha";

const ReportProb = () => {
  const [desc, setdesc] = useState("");
  const userId = reactLocalStorage.getObject("userData");
  const [isVerifiedRecaptcha, setisVerifiedRecaptcha] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Submit = async () => {
    if (desc != "") {
      let sendData = {
        userid: userId._id,
        desc: desc,
      };
      console.log("object", sendData);
      let result = await HttpClient.requestData(
        "problem-report",
        "POST",
        sendData
      );
      console.log("result", result);
      if (result && result.status) {
        toast.success("Data saved successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          window.location.href = "/home";
        }, 3000);
      } else {
        toast.error(result.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning("Please enter your problem", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  function onChange(value) {
    console.log("Captcha value:", value);
    setisVerifiedRecaptcha(true);
  }

  return (
    <div className="feedback">
       <ToastContainer />
      <ReactTitle title="Astrophy | Report a Problem" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center my-4">
            <h2>Report a Problem</h2>
          </div>
          <div className="col-md-6 m-auto">
            <div className="form-group mt-3">
              <label htmlFor="exampleFormControlTextarea1">
                Write Your Problem Here
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={5}
                placeholder="Write Here..."
                onChange={(val) => {
                  setdesc(val.target.value);
                }}
              />
            </div><br />
            <div className="form-group">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={onChange}
              />
            </div>
            <div className="text-center my-4">
              <button
                className="btn submit_btn"
                onClick={Submit}
                disabled={!isVerifiedRecaptcha}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="mt-5">
        <Footer />
      </section>
    </div>
  );
};

export default ReportProb;
