import React from "react";
import OrderList from "../../Views/Orders/OrderList";
import CompleteOrderList from "./CompleteOrderList";

const CompleteOrder = () => {
  return (
    <div className="My-orders">
      <div className="row my-3">
        <div className="col-md-12 text-center">
          <h1>Complete Orders </h1>
        </div>
      </div>
      <CompleteOrderList />
    </div>
  );
};
export default CompleteOrder;
