import React from "react";
import msgImg from "../../../Assets/Images/service_list 12.png";

const ImageMsg = (props) => {
  return (
    <div className="card Img-card  ">
      <img src={props.url} alt="img" className="MsgImg" />
    </div>
  );
};
export default ImageMsg;
