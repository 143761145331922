import React, { useEffect, useState } from "react";
import { ReactTitle } from "react-meta-tags";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import Footer from "../../Defaults/Footer";
import ServiceCartList from "./ServiceCartList";
import Loader from "../../Component/loader";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Cart = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getcart();
  }, []);
  const [loading, setLoading] = useState(false);
  const [coupon, setcoupon] = useState("");
  const [couponstring, setcouponstring] = useState("");
  const [discount, setdiscount] = useState(0);
  const [discounttype, setdiscounttype] = useState("");
  const [cartDataStatus, setcartDataStatus] = useState("");
  const userData = reactLocalStorage.getObject("userData");
  const [sum, setsum] = useState(0);
  const [loop, setloop] = useState([]);
  const [count, setcount] = useState(0);
  const [minPrice, setminPrice] = useState("");

  const [valid, setvalid] = useState(false);

  const [invalid, setinvalid] = useState(false);

  const [coupondata, setcoupondata] = useState([]);

  const setarr = (value,index) => {
    console.log("val", value);
    let arr = loop;
    arr[index].addn_note = value;
    // setloop(arr);
    // setloop([]);
    // console.log("loop", arr);
    console.log("loop", arr);
    setloop([]);
    for (let i = 0; i < arr.length; i++) {
      setloop((prevState) => [...prevState, arr[i]]);
    }
  };
  const getcart = async () => {
    setLoading(true);
    let data = {};
    // console.log("data", data);
    let result = await HttpClient.requestData(
      "servicecart/" + userData._id,
      "GET",
      data
    );
    console.log("result servicecart---", result);
    if (result && result.status) {
      let filteredItem = result.data.filter((item) => item.service_data[0].status === true)
      setloop(filteredItem);
      setcount(filteredItem.length);
      let sum1 = 0;
      filteredItem.forEach((element) => {
        sum1 = sum1 + element.price;
      });
      console.log("localsum", sum1);
      setsum(sum1);
      console.log("sum", sum);
      setTimeout(() => {
        setLoading(false);
      }, 1);
      if (result.data.length > 0) {
        if (result.data[0].coupon_data.length > 0) {
          setcoupondata(result.data[0].coupon_data[0]);
          setcoupon(result.data[0].coupon_data[0].name);
          let expdate = result.data[0].coupon_data[0].expdate;
          let expdate1 = moment(expdate).format("YYYY-MM-DD");
          let exp_date = expdate1;
          console.log("exp", exp_date);
          let expdate2 = moment(exp_date);
          let curdate1 = moment().format("YYYY-MM-DD");
          console.log("exp1", curdate1);
          let curdate2 = moment(curdate1);

          if (result.data[0].coupon_data[0].minprice < sum1) {
            if (
              moment(result.data[0].coupon_data[0].expdate).isAfter(
                curdate1,
                "day"
              )
            ) {
              console.log("hello");
              if (
                result.data[0].coupon_data[0].discount_type == "Flat discount"
              ) {
                setdiscounttype(result.data[0].coupon_data[0].discount_type);
                if (result.data[0].coupon_data[0].discount_value >= sum1) {
                  setcouponstring("invalid");
                  setdiscount(0);
                } else {
                  setcouponstring("success");
                  setdiscount(result.data[0].coupon_data[0].discount_value);
                }
              } else {
                setdiscounttype(result.data[0].coupon_data[0].discount_type);

                let amount_dis =
                  (result.data[0].coupon_data[0].discount_value * sum1) / 100;
                if (amount_dis >= sum1) {
                  setcouponstring("invalid");
                  setdiscount(0);
                } else {
                  setcouponstring("success");
                  setdiscount(result.data[0].coupon_data[0].discount_value);
                }
              }
            } else {
              setcouponstring("Date Invalid");
              setdiscount(0);
            }
          } else {
            setcouponstring("invalid");
            setdiscount(0);
          }
        } else {
        }
      }
    }
    else {
    }
  };

  const success = (val) => {
    // console.log("object", val);
    // if (val) {
    setloop(val);
    getcart();
    // }
  };

  const checkout = async () => {
    window.location.href = "/checkoutservice";
  };

  const apply = async () => {
    if (coupon != "") {
      let data = {
        user_id: userData._id,
        coup_name: coupon,
        // {
        // user_id: "62318134740c75144674bfac",
        // coup_name: "Test coupon 1"
      };
      console.log("coupon", data);

      let result = await HttpClient.requestData("applyCoupon", "POST", data);
      console.log("checkCoupon", result);
      if (result && result.status) {
        setcoupondata(result.data);
        let expdate = result.data.expdate;
        let expdate1 = moment(expdate).format("YYYY-MM-DD");
        let exp_date = expdate1;
        console.log("exp", exp_date);
        let expdate2 = moment(exp_date);
        let curdate1 = moment().format("YYYY-MM-DD");
        console.log("exp1", curdate1);
        let curdate2 = moment(curdate1);

        if (result.data.minprice < sum) {
          if (moment(result.data.expdate).isAfter(curdate1, "day")) {
            console.log("hello");
            if (result.data.discount_type == "Flat discount") {
              setdiscounttype(result.data.discount_type);
              if (result.data.discount_value >= sum) {
                setcouponstring("invalid");
                setdiscount(0);
                setTimeout(() => {
                  setcoupon("");
                  setcouponstring("");
                }, 3000);
              } else {
                setcouponstring("success");
                setdiscount(result.data.discount_value);
              }
            } else {
              setdiscounttype(result.data.discount_type);

              let amount_dis = (result.data.discount_value * sum) / 100;
              if (amount_dis >= sum) {
                setcouponstring("invalid");
                setdiscount(0);
                setTimeout(() => {
                  setcoupon("");
                  setcouponstring("");
                }, 3000);
              } else {
                setcouponstring("success");
                setdiscount(result.data.discount_value);
              }
            }
          } else {
            setcouponstring("Date Invalid");
            setdiscount(0);
            setTimeout(() => {
              setcoupon("");
              setcouponstring("");
            }, 3000);
          }
        } else {
          setcouponstring("invalid");
          setminPrice(result.data.minprice);
          setdiscount(0);
          setTimeout(() => {
            setcoupon("");
            setcouponstring("");
          }, 3000);
          // toast.warning("Cart Amount Not Matching with the Coupon Min Price", {
          //   position: "bottom-right",
          //   autoClose: 3000,
          // });
        }
      } else {
        setcouponstring("cantapply");
        setdiscount(0);

        setTimeout(() => {
          setcoupon("");
          setcouponstring("");
        }, 3000);
      }
    } else {
      setcouponstring("empty");
    }
  };

  const remove = async() => {
    let data = {
      user_id: userData._id,
      name: coupon,
    }
    let result = await HttpClient.requestData("removeServiceCoupon", "POST", data);
    console.log("remove Coupon----", result);
    if (result && result.status) {
      getcart();
      setdiscount(0);
      setcoupon("");
      setvalid(false);
      setcouponstring("remove")
      setTimeout(() => {
        setcoupon("");
        setcouponstring("");
      }, 1000);
    } else {

    }
  };

  return (
    <div>
      <div className="wrapper">
        <ReactTitle title="Astrophy - Add to Cart" />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <section>
            <div className="container-fluid" style={{ maxWidth: "1200px" }}>
              <div className="row px-4 pt-4">
                <div className="col-md-12 cart_head">
                  <h3>{count} Items in your Basket</h3>
                </div>
              </div>
              <div className="row">
                <ServiceCartList
                  data={loop}
                  success={success}
                  setarr={setarr}
                />
                <div className="col-md-4 ">
                  <div className="cart_box_right p-4 my-3">
                    <div className="border-bottom my-4">
                      <h4>Your Cart</h4>
                      <div className="d-flex justify-content-between">
                        <p>Item(s) Total</p>
                        <p>
                          {/* ₹  */}
                          {userData.currency == "INR"
                        ? "₹"
                        : userData.currency == "CAD"
                        ? "$"
                        : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "	GBP"
                        ? "£"
                        : userData.currency == "EUR"
                        ? "€"
                        : "₹"}
                          {(sum).toFixed(2)}
                          </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>Discount</p>
                        <p>
                          {/* -₹{" "} */}
                          {userData.currency == "INR"
                        ? "-₹"
                        : userData.currency == "CAD"
                        ? "-$"
                        : userData.currency == "USD"
                        ? "-$"
                        : userData.currency == "	GBP"
                        ? "-£"
                        : userData.currency == "EUR"
                        ? "-€"
                        : "-₹"}
                          {discounttype == "Flat discount"
                            ? discount.toFixed(2)
                            : (sum * (discount / 100)).toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <div className="border-bottom my-4">
                      <div className="d-flex justify-content-between">
                        <h5>Sub Total</h5>
                        <h5>
                          {/* ₹ */}
                          {userData.currency == "INR"
                        ? "₹"
                        : userData.currency == "CAD"
                        ? "$"
                        : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "	GBP"
                        ? "£"
                        : userData.currency == "EUR"
                        ? "€"
                        : "₹"}
                          {discounttype == "Flat discount"
                            ? (sum - discount).toFixed(2)
                            : sum - (sum * (discount / 100)).toFixed(2)}
                        </h5>
                      </div>
                    </div>
                    {/* <div className="border-bottom my-4">
                      <h6>Apply Coupon Code</h6>
                      <div className="form-group">
                        <div className="d-flex border_here">
                          <input
                            type="text"
                            className="form-control border-0 bg-white px-2 my-auto w-100"
                            id="exampleFormControlInput1"
                            placeholder="Enter Coupon Code"
                            value={coupon}
                            onChange={(val) => {
                              console.log("val", val.target.value);
                              setcoupon(val.target.value);
                            }}
                          />

                          {count == 0 ? (
                            <button
                              className="my-auto mr-0 btn apply_coup_btn "
                              onClick={apply}
                              disabled
                            >
                              Apply
                            </button>
                          ) : (
                            <button
                              className="my-auto mr-0 btn apply_coup_btn "
                              onClick={apply}
                            >
                              Apply
                            </button>
                          )}
                          {count == 0 ? null : (
                            <button
                              className="my-auto mr-0 btn apply_coup_btn "
                              onClick={remove}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      </div>
                      {couponstring == "success" ? (
                        <div className="py-3">
                          <span className="text-success ">
                            <i className="far fa-check-circle"></i> Coupon
                            Applied Successfully
                          </span>
                        </div>
                      ) : null}
                      {couponstring == "invalid" ? (
                        <div className="py-3">
                          <span className="text-danger">
                            <i className="far fa-times-circle"></i> 
                            This Coupon code is valid above order {minPrice}
                          </span>
                        </div>
                      ) : null}
                      {couponstring == "empty" ? (
                        <div className="py-3">
                          <span className="text-danger">
                            <i className="far fa-times-circle"></i> Please Enter
                            Coupon Code
                          </span>
                        </div>
                      ) : null}
                      {couponstring == "cantapply" ? (
                        <div className="py-3">
                          <span className="text-danger">
                            <i className="far fa-times-circle"></i> Coupon code
                            can't be applied
                          </span>
                        </div>
                      ) : null}
                      {couponstring == "Date Invalid" ? (
                        <div className="py-3">
                          <span className="text-danger">
                            <i className="far fa-times-circle"></i> Coupon code
                            expireds
                          </span>
                        </div>
                      ) : null}
                      {couponstring == "remove" ? (
                        <div className="py-3">
                          <span className="text-danger">
                            <i className="far fa-times-circle"></i>
                             Coupon removed Successfully
                          </span>
                        </div>
                      ) : null}
                    </div> */}

                    <div>
                      {/* <div className="d-flex justify-content-between">
                        <h5>Total Amount</h5>
                        <h5>₹ {sum}</h5>
                      </div>{" "} */}
                      
                      <div className="text-center my-4">
                        <button className="btn proceed_btn" onClick={checkout}>
                          Proceed to Checkout
                        </button>
                      </div>
                      <p className="text-center">
                        Local taxes included (where applicable) * Additional
                        duties and taxes{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Cart;
