import React, { useEffect, useState } from "react";
import Chat2 from "../../../Assets/Images/chat_img2.png";
import Dots from "../../../Assets/Images/dots.png";
import Profile from "../../../Assets/Images/user.jpg";
import ChatServiceModal from "../components/ChatServiceModal";
import { reactLocalStorage } from "reactjs-localstorage";
import AudioModal from "./AudioModal";
import VideoModal from "./VideoModal";
import { Link } from "react-router-dom";
import ServiceListing from "../components/ServiceListing";
import HttpClient from "../../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";

const ChatHead = (props) => {
  // const { sender } = props;
  console.log("props.props", props.sender);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const [servicemodal, setServicemodal] = useState(false);
  const [listingModal, setListingModal] = useState(false);
  const [activemodal, setactivemodal] = useState("");
  const [sender, setsender] = useState("");
  const [call, setCall] = useState(false);
  const [videoModalshow, setVideoModalshow] = useState(false);
  const [enableCall, setEnableCall] = useState(false);

  const userData = reactLocalStorage.getObject("userData");
  // console.log("userData", userData);

  useEffect(() => {
    getServicesUser();
  }, [])

  const getServicesUser = async () => {
    let data = {
      user_id: userData._id,
      seller_id: props.sender.userId,
    }
    let result = await HttpClient.requestData('buy-history-from-seller', 'POST', data)
    console.log("result", result)
    if (result && result.status) {
      if (result.purchase_data.length > 0) {
        setEnableCall(true);
      } else {
        // setEnableCall(false);
        let data1 = {
          self_id: userData._id,
          buyer_id: props.sender.userId
        }
        let res = await HttpClient.requestData('buy-history-of-buyer', 'POST', data1);
        // console.log("res", res)
        if (res && res.status) {
          if (res.purchase_data.length > 0) {
            setEnableCall(true);
          }
        }
      }
    } else {
      setEnableCall(false);

    }
  }

  const callBackService = (val) => {
    setServicemodal(val);
    // setactivemodal({});
  };

  const callBackcall = (val) => {
    setCall(false);
    console.log("hi");
    // setactivemodal({});
  };

  const callBackcall1 = () => {
    setVideoModalshow(false);
  };
  const servicelistcall = (val) => {
    setListingModal(val);
  };
  return (
    <div className="w-100">
      <ToastContainer />
      <div className="chat_head px-3 py-2">
        <div className="d-flex">
          <div className="my-auto">
            <img src={props.sender.image ? `https://admin.astrophy.com/${props.sender.image}` : Profile} alt="img" className="user_img" />
          </div>
          <div className="mx-3 my-auto">
            <h5>{props.sender.name}</h5>
            {/* <p>Away | Local Time May 05, 8:00 PM</p> */}
          </div>

          <div className="nav-item relative ml-auto d-flex">
            <div
              className="call-icon align-self-center mr-4"
              onClick={() => {
                if (enableCall) {
                  setCall(true)
                } else {
                  if(props.type==="sellerToUser"){
                    toast.error('Call is only available if he buys from you.', {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                    });
                  }else{
                    toast.error('Call is only available when you buy service from this seller.', {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                    });
                  }
                }

              }}
            >
              <i className="fas fa-phone-alt"></i>
            </div>
            <a
              // to="/video-call"
              className="call-icon align-self-center mr-4"
              onClick={() => {
                if (enableCall) {
                  setVideoModalshow(true)
                } else {
                  if(props.type==="sellerToUser"){
                    toast.error('Call is only available if he buys from you.', {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                    });
                  }else{
                    toast.error('Call is only available when you buy service from this seller.', {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                    });
                  }
                  }
                

              }}
            >
              <i className="fas fa-video"></i>
            </a>
            {userData.type == "Seller" ? (
              <div
                className="align-self-center"
                // onBlur={() => setDropdown(!dropdown)}
                // onFocus={() => setDropdown(!dropdown)}
                // tabIndex="0"
                onClick={() => setDropdown(!dropdown)}
                role="button"
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
            ) : userData.type == "User" ? (
              <div
                className="align-self-center"
                // onBlur={() => setDropdown(!dropdown)}
                // onFocus={() => setDropdown(!dropdown)}
                // tabIndex="0"
                onClick={() => setDropdown1(!dropdown1)}
                role="button"
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
            ) : null}
            {dropdown ? (
              <div className="dropdown-menu d-block chat-dropdown">
                <div
                  className="dropdown-item"
                  role="button"
                  onClick={() => {
                    setactivemodal(props.roomId);
                    setsender(props.sender);
                    setTimeout(() => {
                      setServicemodal(true);
                    }, 100);
                    setDropdown(false);
                  }}
                >
                  Create Service
                </div>
                <div
                  className="dropdown-item"
                  role="button"
                  onClick={() => {
                    // setTimeout(() => {
                    setListingModal(true);
                    console.log("200", listingModal);
                    // }, 100);
                    setDropdown(false);
                  }}
                >
                  Service Listing
                </div>
              </div>
            ) : dropdown1 ? (
              <div className="dropdown-menu d-block chat-dropdown">
                <div
                  className="dropdown-item"
                  role="button"
                  onClick={() => {
                    // setTimeout(() => {
                    setListingModal(true);
                    console.log("200", listingModal);
                    // }, 100);
                    setDropdown1(false);
                  }}
                >
                  Service Listing
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* service listing modal */}
      {listingModal ? (
        <div
          className={listingModal ? "modal fade loginmodal show" : "modal fade"}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: listingModal ? "block" : "none" }}
        >
          <ServiceListing
            // callBackModal={(val) => setListingModal(val)}
            servicelistcallback={servicelistcall}
            sellerdata={props.sender}
          />
        </div>
      ) : null}

      <div
        className={servicemodal ? "modal fade loginmodal show" : "modal fade"}
        // className={"modal fade loginmodal show"}
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: servicemodal ? "block" : "none" }}
      >
        {/* {Object.keys(activemodal).length == 0 ? null : ( */}
        <ChatServiceModal
          callBackService={callBackService}
          // roomid={props.roomId}
          item={activemodal}
          sender={sender}
        // success={success}
        />
      </div>

      {call ? (
        <div
          className={"modal fade loginmodal show"}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          // style={{ display: call === "audio" ? "block" : "none" }}
          style={{ display: "block" }}
        >
          {/* {Object.keys(activemodal).length == 0 ? null : ( */}
          <AudioModal
            remoteData={props.sender}
            roomId={props.sender.roomId}
            type="Outgoing"
            callBackcall1={callBackcall}
            userImage={props.sender.image}
          />
        </div>
      ) : null}

      {videoModalshow ? (
        <div
          className="modal fade single_modal show videoModal"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <VideoModal
       
            chatRoomId={props.sender.roomId}
            remoteData={props.sender}
            callBackcall1={callBackcall1}
            userImage={props.sender.image}
          />
        </div>
      ) : null}

      <div
      // className={
      //   call === "video" ? "modal fade loginmodal show" : "modal fade"
      // }
      // id="exampleModal1"
      // tabIndex="-1"
      // aria-labelledby="exampleModalLabel"
      // aria-hidden="true"
      // style={{ display: call === "video" ? "block" : "none" }}
      >
        {/* {Object.keys(activemodal).length == 0 ? null : ( */}
        {/* <VideoModal callBackcall2={callBackcall} /> */}
      </div>
    </div>
  );
};
export default ChatHead;
