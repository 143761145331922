import React from "react";
import HttpClient from "../../utils/HttpClient";
const OrderDLT = (props) => {
  const deletee = async () => {
    let data = {};

    let result = await HttpClient.requestData(
      "refundProduct/" + props.orderid,
      "PUT",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      props.success(result.status);
    } else {
    }
  };
  return (
    <div>
      <div className="modal-dialog  my-3 ">
        <div className="modal-content dlt_modal">
          <div className="login-body my-3 px-5 py-3 text-center ">
            <h3>Want to delete Order?</h3>
            <div className="d-flex justify-content-around mt-4">
              <button
                type="button"
                className="btn dltmodal_btn"
                onClick={deletee}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn dltmodal_btn"
                onClick={() => props.callBackOrder(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderDLT;
