import React, { useState, useEffect } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import axios from "axios";
// import { StateDropdown} from 'react-indian-state-region-selector';
// import CreditCardInput from "react-credit-card-kit";

const stateoptions = [
  {
    key: "AN",
    name: "Andaman and Nicobar Islands",
  },
  {
    key: "AP",
    name: "Andhra Pradesh",
  },
  {
    key: "AR",
    name: "Arunachal Pradesh",
  },
  {
    key: "AS",
    name: "Assam",
  },
  {
    key: "BR",
    name: "Bihar",
  },
  {
    key: "CG",
    name: "Chandigarh",
  },
  {
    key: "CH",
    name: "Chhattisgarh",
  },
  {
    key: "DH",
    name: "Dadra and Nagar Haveli",
  },
  {
    key: "DD",
    name: "Daman and Diu",
  },
  {
    key: "DL",
    name: "Delhi",
  },
  {
    key: "GA",
    name: "Goa",
  },
  {
    key: "GJ",
    name: "Gujarat",
  },
  {
    key: "HR",
    name: "Haryana",
  },
  {
    key: "HP",
    name: "Himachal Pradesh",
  },
  {
    key: "JK",
    name: "Jammu and Kashmir",
  },
  {
    key: "JH",
    name: "Jharkhand",
  },
  {
    key: "KA",
    name: "Karnataka",
  },
  {
    key: "KL",
    name: "Kerala",
  },
  {
    key: "LD",
    name: "Lakshadweep",
  },
  {
    key: "MP",
    name: "Madhya Pradesh",
  },
  {
    key: "MH",
    name: "Maharashtra",
  },
  {
    key: "MN",
    name: "Manipur",
  },
  {
    key: "ML",
    name: "Meghalaya",
  },
  {
    key: "MZ",
    name: "Mizoram",
  },
  {
    key: "NL",
    name: "Nagaland",
  },
  {
    key: "OR",
    name: "Odisha",
  },
  {
    key: "PY",
    name: "Puducherry",
  },
  {
    key: "PB",
    name: "Punjab",
  },
  {
    key: "RJ",
    name: "Rajasthan",
  },
  {
    key: "SK",
    name: "Sikkim",
  },
  {
    key: "TN",
    name: "Tamil Nadu",
  },
  {
    key: "TS",
    name: "Telangana",
  },
  {
    key: "TR",
    name: "Tripura",
  },
  {
    key: "UK",
    name: "Uttar Pradesh",
  },
  {
    key: "UP",
    name: "Uttarakhand",
  },
  {
    key: "WB",
    name: "West Bengal",
  },
];

const countryData = [
  { name: "Afghanistan", code: "AF" },
  { name: "land Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
const SubscriptionForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getCountry();
  }, []);
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const [countryData, setcountryData] = useState([]);
  const [selectcountry, setselectcountry] = useState("");
  const [stateData, setstateData] = useState([]);
  const [selectstate, setselectstate] = useState("");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [address2, setaddress2] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [zip, setzip] = useState("");
  const [phone, setphone] = useState("");
  const [city, setcity] = useState("");
  const [blank, setblank] = useState(null);

  const [blanklast, setblanklast] = useState(null);

  const [blankemail, setblankemail] = useState(null);

  const [blankaddress, setblankaddress] = useState(null);

  const [blankcountry, setblankcountry] = useState(null);

  const [blankstate, setblankstate] = useState(null);

  const [blankzip, setblankzip] = useState(null);

  const [blankphone, setblankphone] = useState(null);
  const [blankcity, setblankcity] = useState(null);

  const [card, setcard] = useState("");

  const [blankcard, setblankcard] = useState(null);

  const [cvc, setcvc] = useState("");

  const [blankcvc, setblankcvc] = useState(null);

  const [error, seterror] = useState(null);

  const [expirydate, setexpirydate] = useState("");

  const [month, setmonth] = useState("");

  const [year, setyear] = useState("");

  const [blankexp, setblankexp] = useState(null);

  const getCountry = () => {
    console.log("hii");
    let response = axios
      .get("https://countriesnow.space/api/v0.1/countries/capital")
      .then((response) => {
        console.log("response", response);
        if (response.data.data.length > 0) {
          setcountryData(response.data.data);
          console.log("country", country);
        }
      });
  };

  const getState = (name) => {
    let arr = [];
    let data = {
      country: name,
    };
    console.log("data", data);
    let response = axios
      .get("https://countriesnow.space/api/v0.1/countries/states")
      .then((response) => {
        console.log("response", response);
        if (response.data.data.length > 0) {
          let cur_data = response.data.data.filter((item) => item.iso2 == name);
          console.log("curdata", cur_data);
          if (cur_data.length > 0) {
            setstateData(cur_data[0].states);
          }
        }
      });
  };

  const pay = async () => {
    let data = {
      card: {
        number: card,
        exp_month: month,
        exp_year: year,
        cvc: cvc,
      },
      billing_details: {
        address: {
          city: city,
          country: country,
          line1: address,
          line2: address2,
          postal_code: zip,
          state: state,
        },
        email: email,
        name: fname,
        phone: lname,
      },
    };
    console.log("request", data);
    let resultData = await HttpClient.requestData(
      "stripe/create-payment",
      "POST",
      data
    );

    let id = resultData.id;

    newsub(id);
  };

  const newsub = async (id) => {
    let subdetails = reactLocalStorage.getObject("subdetails");
    console.log("subdetatils", subdetails);
    let data = {
      email: email,
      payment_method: id,
      plan_id: subdetails.plan_id,
    };
    console.log("data", data);
    let result = await HttpClient.requestData("stripe/subs-new", "POST", data);
    console.log("stripe/subs-new", result);
    if (result && result.status) {
      buysub(result.paymentData.id);
    } else {
    }
  };

  const buysub = async (idd) => {
    let userdata = reactLocalStorage.getObject("userData");
    let subdetails = reactLocalStorage.getObject("subdetails");

    let sendData = {
      userid: userdata._id,
      subscr_id: subdetails._id,
      seller_comission: subdetails.seller_commission_value,
      price: subdetails.price,
      no_of_listing: subdetails.no_of_listing,
      subs_id: idd,
      // comission_type: subdetails.seller_comission_type,
    };
    console.log("object", sendData);

    let result = await HttpClient.requestData(
      "subscription-purchase",
      "POST",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      toast.success("Subscription Bought Successfully!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        window.location.href = "/seller/subscription";
      }, 1000);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const blurfname = (e) => {
    if (e.target.value == "") {
      setblank(false);
    } else {
      setblank(true);
    }
  };

  const blurlname = (e) => {
    if (e.target.value == "") {
      setblanklast(false);
    } else {
      setblanklast(true);
    }
  };

  const blurphone = (e) => {
    if (e.target.value == "") {
      setblankphone(false);
    } else {
      setblankphone(true);
    }
  };

  const bluremail = (e) => {
    if (e.target.value == "") {
      setblankemail(false);
    } else {
      setblankemail(true);
    }
  };

  const blurcity = (e) => {
    if (e.target.value == "") {
      setblankcity(false);
    } else {
      setblankcity(true);
    }
  };
  const blurcountry = (e) => {
    if (e.target.value == "") {
      setblankcountry(false);
    } else {
      setblankcountry(true);
    }
  };

  const bluraddress = (e) => {
    if (e.target.value == "") {
      setblankaddress(false);
    } else {
      setblankaddress(true);
    }
  };

  const blurstate = (e) => {
    if (e.target.value == "") {
      setblankstate(false);
    } else {
      setblankstate(true);
    }
  };

  const blurzip = (e) => {
    if (e.target.value == "") {
      setblankzip(false);
    } else {
      setblankzip(true);
    }
  };

  const blurcard = (e) => {
    if (e.target.value == "") {
      setblankcard(false);
    } else {
      setblankcard(true);
    }
  };

  const blurcvc = (e) => {
    if (e.target.value == "") {
      setblankcvc(false);
    } else {
      setblankcvc(true);
    }
  };

  const handleChangeCardNumber = (val) => {
    setcard(val.target.value);
    if (val.target.value == "") {
      setblankcard(false);
    } else {
      setblankcard(true);
    }

    // console.log("cvarde",card)
  };

  const handleChangeCVC = (val) => {
    setcvc(val.target.value);

    if (val.target.value == "") {
      setblankcvc(false);
    } else {
      setblankcvc(true);
    }

    // console.log("cvc", cvc);
  };

  const handleChangeExpiryDate = (e) => {
    setexpirydate(e.target.value);
    if (e.target.value == "") {
      setblankexp(false);
    } else {
      setblankexp(true);
    }
    // console.log("expirty", e.target.value);

    const myArr = e.target.value.split(" ");

    // console.log("objj", myArr);

    setmonth(myArr[0]);
    setyear(myArr[2]);
  };

  return (
    <div>
      {/* <ToastContainer /> */}
      <section className="container subs-form my-5 ">
        <div className="row">
          <div className="col-md-12 text-center    ">
            <div className="">
              <button className="btn pay-btn" type="button">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRO92hCtTO9B8J3AJ5XPFezV5lkFViOTOB8skOV2ZLL0s06zO_RmxnlCLlgAPKDhIP7hCU&usqp=CAU"
                  alt="img"
                />
              </button>
              <button className="btn pay-btn" type="button">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/1200px-MasterCard_Logo.svg.png"
                  alt="img"
                />
              </button>
              <button className="btn pay-btn " type="button">
                <img
                  src="https://p.kindpng.com/picc/s/12-123352_dawn-ellmore-employment-paypal-logo-png-transparent-png.png"
                  alt="img"
                />
              </button>
              <button className="btn pay-btn" type="button">
                <img
                  src="https://1000logos.net/wp-content/uploads/2021/05/Discover-logo.png"
                  alt="img"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="row px-4">
          <div className="col-md-12">
            <h5>Billing Details</h5>
          </div>
          <div className="col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="First name"
              value={fname}
              style={{
                border:
                  blank == false ? "1px solid #ff0000" : "1px solid #ced4da ",
              }}
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  setfname(val.target.value);
                  if (val.target.value == "") {
                    setblank(false);
                  } else {
                    setblank(true);
                  }
                }
              }}
              onBlur={blurfname}
            />
          </div>
          <div className="col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Last name"
              value={lname}
              style={{
                border:
                  blanklast == false
                    ? "1px solid #ff0000"
                    : "1px solid #ced4da ",
              }}
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  setlname(val.target.value);
                  if (val.target.value == "") {
                    setblanklast(false);
                  } else {
                    setblanklast(true);
                  }
                }
              }}
              onBlur={blurlname}
            />
          </div>
          <div className="col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Phone"
              value={phone}
              style={{
                border:
                  blankphone == false
                    ? "1px solid #ff0000"
                    : "1px solid #ced4da ",
              }}
              onChange={(val) => {
                if (val.target.value.match("^[0-9]*$") != null) {
                  setphone(val.target.value);
                  if (val.target.value == "") {
                    setblankphone(false);
                  } else {
                    setblankphone(true);
                  }
                }
              }}
              onBlur={blurphone}
            />
          </div>
          <div className="col-md-6 mt-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              value={email}
              style={{
                border:
                  blankemail == false
                    ? "1px solid #ff0000"
                    : "1px solid #ced4da ",
              }}
              onChange={(val) => {
                setemail(val.target.value);
                if (val.target.value == "") {
                  setblankemail(false);
                } else {
                  setblankemail(true);
                }
              }}
              onBlur={bluremail}
            />
          </div>
          <div className="col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="City"
              value={city}
              style={{
                border:
                  blankcity == false
                    ? "1px solid #ff0000"
                    : "1px solid #ced4da ",
              }}
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  setcity(val.target.value);
                  if (val.target.value == "") {
                    setblankcity(false);
                  } else {
                    setblankcity(true);
                  }
                }
              }}
              onBlur={blurcity}
            />
          </div>
          <div className="col-md-6 mt-3">
            {/* <input
              type="text"
              className="form-control"
              placeholder="Country"
              value="INDIA"
              readOnly
              style={{
                border:
                  blankcountry == false
                    ? "1px solid #ff0000"
                    : "1px solid #ced4da ",
              }}
            onChange={(val) => {
              if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                setcountry(val.target.value);
                if (val.target.value == "") {
                  setblankcountry(false);
                } else {
                  setblankcountry(true);
                }
              }
            }}
            onBlur={blurcountry}
            /> */}
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={(val) => {
                console.log("country", val.target.value);
                setcountry(val.target.value);
                getState(val.target.value);
                if (val.target.value == "") {
                  setblankcountry(true);
                } else {
                  setblankcountry(false);
                }
              }}
            >
              <option>Select Country</option>
              {countryData.length > 0 &&
                countryData.map((item, index) => {
                  return (
                    <option value={item.iso2} key={index}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Line1"
              value={address}
              // onChange={(val) => setaddress(val.target.value)}
              style={{
                border:
                  blankaddress == false
                    ? "1px solid #ff0000"
                    : "1px solid #ced4da ",
              }}
              onChange={(val) => {
                setaddress(val.target.value);
                if (val.target.value == "") {
                  setblankaddress(false);
                } else {
                  setblankaddress(true);
                }
              }}
              onBlur={bluraddress}
            />
          </div>{" "}
          <div className="col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Line2"
              value={address2}
              onChange={(val) => setaddress2(val.target.value)}
            />
          </div>
          <div className="col-md-6 my-3">
            <div className="form-group">
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                onChange={(val) => {
                  setstate(val.target.value);
                }}
                style={{
                  border:
                    blankstate == false
                      ? "1px solid #ff0000"
                      : "1px solid #ced4da ",
                }}
                onBlur={blurstate}
              >
                <option>Select State</option>
                {stateData.map((item, index) => {
                  return <option value={item.name}>{item.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-md-6 my-3">
            <input
              type="text"
              className="form-control"
              placeholder="Postal Code"
              value={zip}
              style={{
                border:
                  blankzip == false
                    ? "1px solid #ff0000"
                    : "1px solid #ced4da ",
              }}
              onChange={(val) => {
                if (val.target.value.match("^[0-9]*$") != null) {
                  setzip(val.target.value);
                  if (val.target.value == "") {
                    setblankzip(false);
                  } else {
                    setblankzip(true);
                  }
                }
              }}
              onBlur={blurzip}
            />
          </div>
        </div>
        <div className="row px-4 mt-4">
          <div className="col-md-12">
            <h5>Card Details</h5>
          </div>
          <div className="col-md-4">
            <PaymentInputsWrapper {...wrapperProps}>
              <svg {...getCardImageProps({ images })} />
              <input
                {...getCardNumberProps({
                  onChange: handleChangeCardNumber,
                  onBlur: blurcard,
                })}
                value={card}
              />
              <input
                {...getExpiryDateProps({ onChange: handleChangeExpiryDate })}
                value={expirydate}
              />
              <input
                {...getCVCProps({ onChange: handleChangeCVC })}
                value={cvc}
              />
            </PaymentInputsWrapper>
          </div>
          {/* <div className="col-md-12">
            <h5>Card Details</h5>
          </div>
          <div className="col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Card Number"
              style={{
                border:
                  blankcard == false
                    ? "1px solid #ff0000"
                    : "1px solid #ced4da ",
              }}
              onChange={(val) => {
                if (val.target.value.match("^[0-9]*$") != null) {
                  setcard(val.target.value);
                  if (val.target.value == "") {
                    setblankcard(false);
                  } else {
                    setblankcard(true);
                    if (val.target.value > 8) {
                      seterror(true);
                    }
                  }
                }
              }}
              value={card}
              onBlur={blurcard}
            />
          </div>
          <div className="col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="CVC"
              onChange={(val) => {
                if (val.target.value.match("^[0-9]*$") != null) {
                  setcvc(val.target.value);
                  if (val.target.value == "") {
                    setblankcvc(false);
                  } else {
                    setblankcvc(true);
                  }
                }
              }}
              style={{
                border:
                  blankcvc == false
                    ? "1px solid #ff0000"
                    : "1px solid #ced4da ",
              }}
              value={cvc}
              onBlur={blurcvc}
            />
          </div>
          <div className="col-md-12 my-3">
            <label>Expiry Date</label>
            <input
              type="month"
              className="form-control"
              //   placeholder="Expeiry Month"
            />
          </div> */}
        </div>

        <div className="row px-4 ">
          <div className="col-md-12 my-3">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                defaultValue="option1"
              />
              <label className="form-check-label" htmlFor="inlineCheckbox1">
                I'd like to register for a monthly subscription plan
              </label>
            </div>
          </div>
          {/* <div className="col-md-12 text-center pay-text py-2">
            <p>
              Total Amount to Pay:<span>$100</span>
            </p>
          </div> */}
        </div>

        <div className="row my-4 px-4">
          <div className="col-md-12 text-center">
            <button
              className="btn payNow-btn"
              onClick={pay}
              disabled={
                !blank ||
                !blanklast ||
                !blankemail ||
                !blankaddress ||
                !blankstate ||
                !blankzip ||
                !blankcity ||
                !blankphone ||
                !blankcard ||
                !blankcvc
              }
            >
              Pay Now
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubscriptionForm;
