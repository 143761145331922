import React, { useRef, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
export default function Paypal(props) {
  const paypal = useRef();

  const initialOptions = {
    "client-id":
      "ARqzRnPyToy4PkQLCLzh2LSujG13HZSkSvvUIEzQ42kPeY1JDqGuLYgAEQBPpZJncE2_nuoU_EBK6L1D",
    currency: "USD",
    intent: "capture",
  };

  
  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: props.total,
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(function (details) {
            // This function shows a transaction success message to your buyer.
            // console.log("paypalresult", details);
            props.senddetails(true,details);
            // alert("Transaction completed by " + details.payer.name.given_name);
          });
        }}
      />
    </PayPalScriptProvider>
  );
}
