import React from "react";
import { Link } from "react-router-dom";
import so2 from "../../Assets/Images/so2.png";

export default function AvailArea() {
  return (
    <div>
      {/* desktop */}
      <section className="contact_div_index shadow d-none d-xxl-block d-xl-block d-lg-block d-md-block">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
              <div className="contact_div_index_text_div">
                <p>
                  You can also avail one free session with Astrology from here
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 text-right">
              <div className="contact_div_index_btn_div">
                <Link to="/contact-us" className="btn contact_div_index_btn">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* mobile  */}
      <section className="container d-block d-xxl-none d-xl-none d-lg-none d-md-none">
        <div className="offer_bg">
          <div className="offer_banner">new</div>
          <div className="row">
            <div className="col-4">
              <div>
                <div
                  className="offer_img"
                  style={{ backgroundImage: `url('${so2}')` }}
                />
              </div>
            </div>
            <div className="col-8">
              <div>
                <div className="offer_title">
                  <h5>
                    Got any questions? <br />
                    Talk to our astrologers <br />
                    <span>FOR FREE</span>
                  </h5>
                </div>
                <div className="offer_btn">
                  <Link to="/contact-us" className="btn">
                    Talk now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
