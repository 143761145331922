import Home from "./Views/Home";
import Services from "./Views/Services";
import ServiceDetails from "./Views/ServiceDetails/index";
import ServiceProvider from "./Views/ServiceProvider/index";
import OurProducts from "./Views/OurProducts/index";
import ProductDetails from "./Views/ProductDetails/index";
import Blog from "./Views/BlogPage/index";
import BlogDetails from "./Views/BlogDetails/index";
import Faq from "./Views/Faq/index";
import SellerFaq from "./Views/SellerFaq/index";
import AboutUs from "./Views/AboutUs/index";
import SiteMap from "./Views/SiteMap/Site";
import ContactUs from "./Views/ContactUs/Contact";
import AddCart from "./Views/AddCart/Cart";
import ServiceCart from "./Views/ServiceCart/ServiceCart";
import MyProfile from "./Views/MyProfile/index";
import ChangePassword from "./Auth/ChangePassword";
import EditProfile from "./Views/EditProfile/Edit";
import Messages from "./Views/Messages/Chat";
import Orders from "./Views/Orders/index";
import Seller from "./Seller/index";
import MyServices from "./Seller/MyServices/Index";
import Checkout from "./Views/Checkout/Checkout";
import CheckoutService from "./Views/Checkout/CheckoutService";
import Wishlist from "./Views/Wishlist/Wishlist";
import WishlistProvider from "./Views/WishlistProvider/WishlistProvider";
import EmptyProduct from "./Views/EmptyProduct/EmptyProduct";
import EmptyCart from "./Views/EmptyCart/EmptyCart";
import EmptyWishlist from "./Views/EmptyWishlist/EmptyWishlist";
import BlankOrder from "./Views/Orders/BlankOrder";
import ServiceOrder from "./Views/ServiceOrder/ServiceOrder";
import SearchPage from "./Views/SearchPage/Index";
import ServiceWishlist from "./Views/ServiceWishlist/Index";
import Privacy from "./Views/Privacy/Privacy";
import Cookies from "./Views/Cookies/Cookies";
import Return from "./Views/ReturnPolicy/Return";
import Condition from "./Views/Condition/Condition";
import CopyRight from "./Views/CopyRight/CopyRight";
import AfterVerify from "./Views/AfterVerify/AfterVerify";
import SubscriptionForm from "./Seller/Subscription/SubscriptionForm";
import VideoModal from "./Views/Messages/ChatBody/VideoModal";
import Feedback from "./Views/Feedback/Feedback";
import CustomerService from "./Views/CustomerService/CustomerService";
import ReportProb from "./Views/ReportProblem/ReportProb";
import LegalNotice from "./Views/LegalNotice/LegalNotice";
import Grievance from "./Views/Grievance/Grievance";
import SafetyGuide from "./Views/SafetyGuide/SafetyGuide";
import OurAssociate from "./Views/OurAssociate/Associate";
import MoreProvider from "./Views/MoreProvider/MoreProvider";
import MoreServices from "./Views/MoreServices/MoreServices";
import MoreSpellCasting from "./Views/MoreSpellCasting/MoreSpell";
import CastingSingle from "./Views/CastingSingle/CastingSingle";
import MoreBlog from "./Views/MoreBlogs/MoreBlog";
import ViewOrderDetails from "./Views/Seller/ViewOrderDetails";
// import MyOrders from "./Seller/MyOrders/Index";
import Achivements from "./Views/Achivements/index";
import ServiceRequest from "./Views/ServiceRequest/Index";

const PrivateRoute = [
  {
    path: "/add-cart",
    name: "AddCart",
    component: AddCart,
  },
  {
    path: "/service-cart",
    name: "ServiceCart",
    component: ServiceCart,
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    component: MyProfile,
  },
  {
    path: "/change-password/:id",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/edit-profile",
    name: "EditProfile",
    component: EditProfile,
  },
  {
    path: "/messages/:alreadySelectedRoomId",
    name: "Messages",
    component: Messages,
  },
  {
    path: "/messages",
    name: "Messages",
    component: Messages,
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
  },
  {
    path: "/seller/:page",
    name: "Seller",
    component: Seller,
  },
  { path: "/", exact: true, name: "Home", component: Home },
  { path: "/home", name: "Home", component: Home },
  { path: "/services/:name?/:id?", name: "Services", component: Services },
  {
    path: "/service-details/:name?/:id?",
    name: "ServiceDetails",
    component: ServiceDetails,
  },
  {
    path: "/service-provider/:name?/:id?",
    name: "ServiceProvider",
    component: ServiceProvider,
  },
  {
    path: "/astrophy-mall",
    name: "OurProducts",
    component: OurProducts,
  },
  {
    path: "/product-details/:name?/:id?",
    name: "ProductDetails",
    component: ProductDetails,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/blog-details/:name?/:id?",
    name: "BlogDetails",
    component: BlogDetails,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/seller-faq",
    name: "SellerFaq",
    component: SellerFaq,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/site-map",
    name: "SiteMap",
    component: SiteMap,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  // {
  //   path: "/seller-service",
  //   name: "MyServices",
  //   component: MyServices,
  // },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/checkoutservice",
    name: "CheckoutService",
    component: CheckoutService,
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    component: Wishlist,
  },
  {
    path: "/wishlist-provider",
    name: "WishlistProvider",
    component: WishlistProvider,
  },
  {
    path: "/empty-product",
    name: "EmptyProduct",
    component: EmptyProduct,
  },
  {
    path: "/empty-cart",
    name: "EmptyCart",
    component: EmptyCart,
  },
  {
    path: "/empty-wishlist",
    name: "EmptyWishlist",
    component: EmptyWishlist,
  },
  {
    path: "/blank-order",
    name: "BlankOrder",
    component: BlankOrder,
  },
  {
    path: "/service-booking",
    name: "ServiceOrder",
    component: ServiceOrder,
  },
  {
    path: "/search-page/:p1?/:p2?",
    name: "SearchPage",
    component: SearchPage,
  },
  {
    path: "/service-wishlist",
    name: "ServiceWishlist",
    component: ServiceWishlist,
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/cookies-policy",
    name: "Cookies",
    component: Cookies,
  },
  {
    path: "/return-policy",
    name: "Return",
    component: Return,
  },
  {
    path: "/condition-policy",
    name: "Condition",
    component: Condition,
  },
  {
    path: "/copy-right",
    name: "CopyRight",
    component: CopyRight,
  },
  {
    path: "/subscription-form",
    name: "SubscriptionForm",
    component: SubscriptionForm,
  },
  {
    path: "/video-call",
    name: "VideoCall",
    component: VideoModal,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
  },
  {
    path: "/customer-service",
    name: "CustomerService",
    component: CustomerService,
  },
  {
    path: "/report-problem",
    name: "ReportProblem",
    component: ReportProb,
  },
  {
    path: "/legal-notice",
    name: "LegalNotice",
    component: LegalNotice,
  },
  {
    path: "/grievance",
    name: "Grievance",
    component: Grievance,
  },
  {
    path: "/safety-guide",
    name: "SafetyGuide",
    component: SafetyGuide,
  },
  {
    path: "/our-associates",
    name: "OurAssociate",
    component: OurAssociate,
  },
  {
    path: "/more-provider",
    name: "MoreProvider",
    component: MoreProvider,
  },
  {
    path: "/more-services",
    name: "MoreServices",
    component: MoreServices,
  },
  {
    path: "/more-spell",
    name: "MoreSpellCasting",
    component: MoreSpellCasting,
  },
  {
    path: "/casting-single/:id",
    name: "CastingSingle",
    component: CastingSingle,
  },
  {
    path: "/more-blog",
    name: "MoreBlogs",
    component: MoreBlog,
  },
  {
    path: "/view-order-details/:id",
    name: "viewOrderDetails",
    component: ViewOrderDetails,
  },
  {
    path: "/achievements",
    name: "Achievements",
    component: Achivements,
  },
  {
    path: "/service-request",
    name: "ServiceRequest",
    component: ServiceRequest,
  },
];

const PublicRoute = [
  { path: "/", exact: true, name: "Home", component: Home },
  { path: "/home", name: "Home", component: Home },
  { path: "/services/:name?/:id?", name: "Services", component: Services },
  {
    path: "/service-details/:name?/:id?",
    name: "ServiceDetails",
    component: ServiceDetails,
  },

  {
    path: "/service-provider/:name?/:id?",
    name: "ServiceProvider",
    component: ServiceProvider,
  },

  {
    path: "/astrophy-mall",
    name: "OurProducts",
    component: OurProducts,
  },
  {
    path: "/product-details/:name?/:id?",
    name: "ProductDetails",
    component: ProductDetails,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/change-password/:id",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/blog-details/:name?/:id?",
    name: "BlogDetails",
    component: BlogDetails,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/seller-faq",
    name: "SellerFaq",
    component: SellerFaq,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/site-map",
    name: "SiteMap",
    component: SiteMap,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/more-blog",
    name: "MoreBlogs",
    component: MoreBlog,
  },
  {
    path: "/more-provider",
    name: "MoreProvider",
    component: MoreProvider,
  },
  {
    path: "/more-services",
    name: "MoreServices",
    component: MoreServices,
  },
  {
    path: "/more-spell",
    name: "MoreSpellCasting",
    component: MoreSpellCasting,
  },
  {
    path: "/search-page/:p1?/:p2?",
    name: "SearchPage",
    component: SearchPage,
  },
  {
    path: "/service-wishlist",
    name: "ServiceWishlist",
    component: ServiceWishlist,
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/cookies-policy",
    name: "Cookies",
    component: Cookies,
  },
  {
    path: "/return-policy",
    name: "Return",
    component: Return,
  },
  {
    path: "/condition-policy",
    name: "Condition",
    component: Condition,
  },
  {
    path: "/copy-right",
    name: "CopyRight",
    component: CopyRight,
  },
  {
    path: "/after-verify/:email",
    name: "AfterVerify",
    component: AfterVerify,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
  },
  {
    path: "/customer-service",
    name: "CustomerService",
    component: CustomerService,
  },
  {
    path: "/report-problem",
    name: "ReportProblem",
    component: ReportProb,
  },
  {
    path: "/legal-notice",
    name: "LegalNotice",
    component: LegalNotice,
  },
  {
    path: "/grievance",
    name: "Grievance",
    component: Grievance,
  },
  {
    path: "/safety-guide",
    name: "SafetyGuide",
    component: SafetyGuide,
  },
  {
    path: "/our-associate",
    name: "OurAssociate",
    component: OurAssociate,
  },
  {
    path: "/achivements",
    name: "Achivements",
    component: Achivements,
  },
];

// const routes = [
//   { path: "/", exact: true, name: "Home", component: Home },
//   { path: "/home", name: "Home", component: Home },
//   { path: "/services", name: "Services", component: Services },
//   {
//     path: "/service-details",
//     name: "ServiceDetails",
//     component: ServiceDetails,
//   },
//   {
//     path: "/service-provider",
//     name: "ServiceProvider",
//     component: ServiceProvider,
//   },
//   {
//     path: "/astrophy-mall",
//     name: "OurProducts",
//     component: OurProducts,
//   },
//   {
//     path: "/product-details/:id",
//     name: "ProductDetails",
//     component: ProductDetails,
//   },
//   {
//     path: "/blog",
//     name: "Blog",
//     component: Blog,
//   },
//   {
//     path: "/blog-details",
//     name: "BlogDetails",
//     component: BlogDetails,
//   },
//   {
//     path: "/faq",
//     name: "Faq",
//     component: Faq,
//   },
//   {
//     path: "/about-us",
//     name: "AboutUs",
//     component: AboutUs,
//   },
//   {
//     path: "/site-map",
//     name: "SiteMap",
//     component: SiteMap,
//   },
//   {
//     path: "/contact-us",
//     name: "ContactUs",
//     component: ContactUs,
//   },
//   {
//     path: "/add-cart",
//     name: "AddCart",
//     component: AddCart,
//   },
//   {
//     path: "/my-profile",
//     name: "MyProfile",
//     component: MyProfile,
//   },
//   {
//     path: "/edit-profile",
//     name: "EditProfile",
//     component: EditProfile,
//   },
//   {
//     path: "/messages",
//     name: "Messages",
//     component: Messages,
//   },
//   {
//     path: "/orders",
//     name: "Orders",
//     component: Orders,
//   },
//   {
//     path: "/seller",
//     name: "Seller",
//     component: Seller,
//   },
//   // {
//   //   path: "/my-orders",
//   //   name: "MyOrders",
//   //   component: MyOrders,
//   // },
// ];

export { PublicRoute, PrivateRoute };
