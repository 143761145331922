import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import castingimg1 from "../../Assets/Images/cast1.png";
import Insta from "../../Assets/Images/insta.png";
import FB from "../../Assets/Images/fb.png";
import P_icon from "../../Assets/Images/p icon.png";
import Youtube from "../../Assets/Images/youtube.png";
import Footer from "../../Defaults/Footer";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
const CastingSingle = (props) => {
  // console.log("props id----",props.match.params.id);
  const [spellSingleData, setspellSingleData] = useState({});
  const [loading, setLoading] = useState(false);
  let singleSpellId = props.match.params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    getSingleSpellCastingData();
  }, []);

  const getSingleSpellCastingData = async () => {
    setLoading(true);
    let result = await HttpClient.requestData(
      "singlespellCasting/" + singleSpellId,
      "GET",
    );
    console.log("single spellCasting data--", result);
    if (result && result.status) {
      let data = result.data;
      setspellSingleData(data); 
      setTimeout(() => {
        setLoading(false);
      }, 1); 
    } 
    console.log("spellSingleData",spellSingleData);
  };

  return (
    <div className="">
      {loading ? (
        <Loader />
      ) : (
        <>
       
      <div className="container-fluid Details my-4">
        <div className="row p-4">
          <div className="col-md-12 back_to">
            <Link to="/home">
              <p>
                {" "}
                <i className="fas fa-chevron-left mr-2"></i>Back to spells
              </p>
            </Link>
          </div>
        </div>
        <div className="row px-4">
          <div className="col-md-6 my-auto ">
            <div className="text-center">
              <img
                src={HttpClient.IMG_URL + spellSingleData.image}
                alt="img"
                className="detail_pic relative"
                style={{ maxWidth: "180px", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-md-6 px-5 details_text">
            {/* <div className="rating_sec">
              <i className="fas fa-star my-1 mr-2"></i>0/5
              <span className="mx-3"> Sales</span>
            </div> */}
            <div className="heading">
              <h3 className="my-3">{spellSingleData.name}</h3>
            </div>
            <div className="d-md-flex flex-wrap rupee_sec">
              {/* <h5>$ 120</h5> */}
              {/* <p className="mx-md-4">
            <i className="fas fa-check-circle mr-2"></i>
            In Stock
          </p> */}

              {/* <div className="ml-md-auto my-md-0 my-3 mr-5 counter_sec">
            <button
              className="btn mx-3"
              onClick={() => setCounter(counter + 1)}
            >
              <i className="fas fa-minus"></i>
            </button>
            <span>{counter}</span>
            <button
              className="btn mx-3"
              onClick={() => setCounter(counter - 1)}
            >
              <i className="fas fa-plus"></i>
            </button>
          </div> */}
            </div>
            {/* <div>
              {" "}
              <span className="text-muted">
                Local taxes included (where applicable)
              </span>
            </div> */}
            {/* <div>
              {" "}
              <div className="form-group my-3">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={3}
                  placeholder="Add your personalisation"
                />
              </div>
            </div> */}
            {/* <div className="bottom_text">
              <p>
                Please send me your names & dates of birth (if known). Then i
                will cast your Spell & send you an etsy message within 24 hours,{" "}
              </p>
            </div> */}
            {/* <div className="text-right basket_btn mr-md-0 mr-5">
              <button className="btn">Book Now</button>
            </div> */}
            {/* <div className="d-flex">
              <p className="text-dark">Share by:</p>
              <div>
                <button type="button" className="btn bg-danger mx-3">
                  {" "}
                  <img
                    src={Insta}
                    alt="img"
                    className="mr-2"
                    className="app_img"
                  />
                </button>
                <button type="button" className="btn bg-primary ">
                  <img
                    src={FB}
                    alt="img"
                    className="mx-2"
                    className="app_img"
                  />
                </button>
                <button type="button" className="btn bg-dark mx-3">
                  {" "}
                  <img
                    src={P_icon}
                    alt="img"
                    className="mx-2"
                    className="app_img"
                  />
                </button>
                <button type="button" className="btn bg-danger ">
                  {" "}
                  <img
                    src={Youtube}
                    alt="img"
                    className="mx-3"
                    className="app_img"
                  />
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row px-4">
          <div className="col-md-12">
            <div className="Highlights">
              <div>
                <h5>Descriptions</h5>
                <p>
                {spellSingleData.description}
                  {/* Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. */}
                </p>
              </div>
              <Link to="/">
                {" "}
                <div className="text-right">
                  <h6>Read More</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      </>
      )}
      <section>
          <Footer/>
      </section>
    </div>
  );
};

export default CastingSingle;
