import React from "react";
import RatingChanged from "../../Component/RatingChanged";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";

const List = (props) => {
  console.log(props.servicesDetails);
  const userData = reactLocalStorage.getObject("userData");
  let servicesDetails = props.servicesDetails;

  const item = props.data;
  const deletee = async () => {
    let data = {};

    console.log("data", item._id);
    let result = await HttpClient.requestData(
      "servicedeleteWishlist/" + item._id,
      "DELETE",
      data
    );
    console.log("result", result);
    console.log("result", result);
    if (result && result.status) {
      props.success(result.status);
    } else {
    }
  };

  const addtocart = async () => {
    let data = {
      user_id: item.user_id,
      serv_id: item.serv_id,
      currency: userData.currency,
      servicename: item.service_data.name,
      price: item.price,
      image: item.image,
      seller_id: item.seller_id,
      stype: item.stype,
      orderFrom: "web"
    };

    console.log("data", data);
    let result = await HttpClient.requestData("servicecart", "POST", data);
    console.log("result", result);
    if (result && result.status) {
      // window.history.pushState(null, "", "/checkoutservice");
      // window.location.href = "/checkoutservice";
      toast.success("Service has been added  the cart", {
        position: "bottom-right",
        autoClose: 3000,
      });
      setTimeout(() => {
        window.location.href = "/service-details/" + servicesDetails._id;
      }, 4000);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };
  const book = async () => {
   
      let data = {
        user_id: item.user_id,
        serv_id: item.serv_id,
        currency: userData.currency,
        servicename: item.service_data.name,
        price: item.price,
        image: item.image,
        seller_id: item.seller_id,
        stype: item.stype,
        orderFrom: "web"
      };
      console.log("crt data---",data);
      let result = await HttpClient.requestData("servicecart", "POST", data);
      console.log("result", result);
      if (result && result.status) {
        // window.history.pushState(null, "", "/checkoutservice");
        window.location.href = "/checkoutservice";
      } else {
        toast.error(result.message, {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }
  return (
    <div className="bottomSection mt-4" key={props.key}>
      <ToastContainer />
      <div className="card wish_card">
        <div className="card-body"style={{padding: 0}}>
          <div
            className="web_img"
            onClick={() => {
              window.location.href =  "/service-details/" + nameToSlug(item.service_data.name) + "/" + servicesDetails._id;
            }}
          >
            <img
              src={HttpClient.IMG_URL + item.image}
              alt="img"
              className="img-fluid"
            />
          </div>

          <div className="web_box my-lg-0 my-3 w-100" style={{padding: "1.25rem"}}>
            <div className="row  ">
              <div className="col-md-12"  onClick={() => {
              window.location.href = "/service-details/" + nameToSlug(item.service_data.name) + "/" + servicesDetails._id;
            }}>
                <h4>
                  {item.service_data 
                    ? item.service_data.name
                    : ""}
                </h4>
                
                <p className="service-wishlist-para">
                  {item.service_data
                    ? item.service_data.details.replace(/<[^>]+>/g, "")
                    : ""}
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  elementum ac diam at convallis. Nulla diam libero, maximus sed
                  justo et, convallis tempus dolor. Etiam non magna vel felis. */}
                </p>
              </div>
              <div class="col-md-12">
              <div className="d-flex"  onClick={() => {
              window.location.href = "/service-details/" + servicesDetails._id;
            }}>
              <h5>
                {/* ₹ */}
                {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "	GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}
                {item.service_data.price}
              </h5>
              {/* <p className="mx-md-3 mt-1 text-success">
                <i className="fas fa-check-circle mr-2" />
                In Stock
              </p> */}
            </div>
              </div>
              <div className="col-md-12 d-flex">
                <img
                    className="mr-2 rounded-circle"
                    src={item.service_data ? (HttpClient.IMG_URL + item.service_data.shop_data.shop_img) : ""}
                    alt="service_img"
                    style={{
                      width: "100%",
                      height: "25px",
                      maxWidth: "25px",
                    }}
                  />
                <h5>
                  {item.service_data 
                    ? item.service_data.shop_data.name
                    : ""}
                </h5>
                </div>
              <div className="col-md-12">
                {/* <p className=" text-right">Item Added 28 Oct </p> */}
                <div className="d-flex justify-content-between " style={{margin: "10px 0"}}>
                <button
                    className="btn btn-danger"
                    type="button"
                    onClick={addtocart}
                  >
                    Add to Cart
                  </button>
                  <button
                    className="btn btn-danger ml-3"
                    type="button"
                    onClick={book}
                  >
                    Buy Now
                  </button>

                  <button
                    className="btn btn-secondary register_btn ml-3"
                    type="button"
                    onClick={deletee}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div>{/* <RatingChanged /> */}</div>
            {/* <div className="d-flex"  onClick={() => {
              window.location.href = "/service-details/" + servicesDetails._id;
            }}>
              <h5>
                {/* ₹ */}
                {/* {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "	GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}
                {item.service_data.price}
              </h5> */}
              {/* <p className="mx-md-3 mt-1 text-success">
                <i className="fas fa-check-circle mr-2" />
                In Stock
             </p>
             </div> */} 
          </div>
        </div>
      </div>
    </div>
  );
};
export default List;