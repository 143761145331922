import React from "react";

const Success = (props) => {
  return (
    <div className="text-center">
      <p className="text-success">
      <i className="fas fa-exclamation-circle mr-2"></i>{props.success}
      </p>
    </div>
  );
};
export default Success;
