import React, { useEffect, useState } from "react";
import Footer from "../../Defaults/Footer";
import HttpClient from "../../utils/HttpClient";
import { ReactTitle } from "react-meta-tags";
import SellerFaq from "./sellerFaq";

const Index = () => {
  const [sellerFaqData, setsellerFaqData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    sellerFaq();
  }, []);

  const sellerFaq = async () => {
    let result = await HttpClient.requestData("sellerFaq", "GET");
    console.log("sellerFaq --", result);
    if (result && result.status) {
      setsellerFaqData(result.data);
    }
  };

  //   function strToHtml(str) {
  //     var parser = new DOMParser();
  //     var doc = parser.parseFromString(str, "text/html");
  //     // console.log('doc 1', doc.body.innerHTML);
  //     document.getElementById("contentDiv").innerHTML = doc.body.innerHTML;
  //   }
  return (
    <div className="">
      <ReactTitle title="Astrophy | Seller Faq" />
      <section className="container-fluid privacy my-4">
        <div className="row px-3">
          <div className="col-md-12 text-center mb-5 seller-faq-title">
            <h3>Seller Faq</h3>
          </div>
          <div className="col-md-12">
            {sellerFaqData.length ? (
              sellerFaqData.map((item, index) => {
                return (
                  // <MoreInfo dataFaq={item}/>
                  <SellerFaq dataFaqSeller={item} type="aboutFaq" />
                );
              })
            ) : (
              <div className="pl-lg-3">No More Information Found</div>
            )}
            {/* <SellerFaq /> */}
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Index;
