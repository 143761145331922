import React, { useEffect, useState, useRef } from "react";
import Chat2 from "../../../Assets/Images/chat_img2.png";
import MessageBody from "./messageBody";
import db from "../../../Firebase/fbconfig";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../Component/loader";
import {
  ref,
  get,
  child,
  limitToLast,
  onChildAdded,
  query,
  orderByKey,
  endAt,
  onValue,
  update,
  off,
  onChildChanged,
} from "firebase/database";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import ImageMsg from "./ImageMsg";
const data = [];
const MessageList = (props) => {
  // console.log("messagelistcomponent");
  const messageEl = useRef(null);
  const [msgarr, setmsgarr] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [hasMore, sethasMore] = useState(true);
  const [chatRoomId, setChatRoomId] = useState(null);
  const userData = reactLocalStorage.getObject("userData");
  const chatRef = useRef(null);
  const chatUpdateRef = useRef(null);
  console.log("props.sender", props.sender);
  useEffect(() => {
    // if(chatRef.current != null){
    // console.log("called")
    off(ref(db, `/Chat/${chatRoomId}/messages/`), undefined, chatRef.current);
    off(
      ref(db, `/Chat/${chatRoomId}/messages/`),
      undefined,
      chatUpdateRef.current
    );
    // }

    getchat();
    setChatRoomId(props.roomId);
  }, [props.roomId]);

  useEffect(() => {
    updateSeenStatus();
  }, [msgarr.length]);
  useEffect(() => {
    console.log(msgarr,"msgarr");
  }, [msgarr]);

  const updateSeenStatus = () => {
    update(ref(db, `/chatList/${userData._id}/${props.sender.userId}`), {
      unseen: false,
    });
    // database()
    //   .ref(`/chatList/${userData._id}/${remoteId}`)
    //   .update({
    //     unseen: false
    //   })
  };

  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, []);

  const getchat = async () => {
    const dbRef = ref(db);
    // console.log("/Chat/" + props.roomId + "/messages/");
    // child(dbRef, "/Chat/" + props.roomId + "/messages/", limitToLast(10))
    get(query(ref(db, `/Chat/${props.roomId}/messages/`)))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log("message----", snapshot.val());
          setisloading(false);
          sethasMore(true);
          setmsgarr(Object.values(snapshot.val()));
          getFirebaseListener(Object.values(snapshot.val()));
        } else {
          sethasMore(false);
          setmsgarr([]);
          getFirebaseListener([]);
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getFirebaseListener = (oldVal) => {
    let mssg = oldVal;
    const dbRef = ref(db, "/Chat/" + props.roomId + "/messages/");

    const dbquery = query(dbRef, limitToLast(1));

    chatRef.current = onChildAdded(dbquery, (snapshot) => {
      if (snapshot.exists()) {
        let fbData = snapshot.val();
        let msgIndex = mssg.findIndex((it) => it.msgId == fbData.msgId);
        if (msgIndex == -1) {
          setmsgarr((msgarr) => [...msgarr, fbData]);
          mssg = [...mssg, fbData];
          const updates = {};
          updates[
            `/chatList/${userData._id}/${props.sender.userId}/unseen`
          ] = false;
          update(ref(db), updates);
        }
      }
    });

    chatUpdateRef.current = onChildChanged(
      ref(db, `/Chat/${props.roomId}/messages/`),
      (snapshot) => {
        if (snapshot.exists()) {
          let fbData = snapshot.val();
          if (!fbData.uploading) {
            let index = mssg.findIndex((it) => it.msgId == fbData.msgId);
            if (index >= 0) {
              mssg[index] = fbData;
              setmsgarr(mssg);
              //console.log("arrayyyyyyyyyyyyyyyyyyy", mssg)
            }
          }
        }
      }
    );
  };

  const sorted = () => {
    let a;
    a = msgarr.sort(function (a, b) {
      return b.send_time < a.send_time ? -1 : b.send_time > a.send_time ? 1 : 0;
    });
    console.log(a);
    return a;
  };

  const fetchMoreData = () => {
    alert("hgfy");
    // if (hasMore) {
    //   // setisloading(true);
    //   let arrLength = sorted().length;
    //   let lastKeyRef = sorted()[arrLength - 1].nodeId;
    //   // console.log("lastKeyRef", lastKeyRef);
    //   const dbRef = ref(db, "/Chat/" + props.roomId + "/messages/");

    //   const dbquery = query(
    //     dbRef,
    //     orderByKey(),
    //     limitToLast(11),
    //     endAt(lastKeyRef)
    //   );
    //   onValue(dbquery, (snapshot) => {
    //     // console.log("snapshot.val()",snapshot.val());
    //     if (snapshot.exists()) {
    //       let fbData = Object.values(snapshot.val());
    //       let finalArr = fbData.filter((it) => it.nodeId != lastKeyRef);
    //       setmsgarr((msgarr) => [...msgarr, ...finalArr]);
    //       // setLoadMore(false);
    //       // setisloading(false);
    //       console.log("msgarr", msgarr);
    //       if (finalArr.length == 0) {
    //         sethasMore(false);
    //       }
    //     } else {
    //       // setLoadMore(false);
    //       // setisloading(false);
    //       sethasMore(false);
    //       console.log("No data available");
    //     }
    //   });
    // }
  };
  let URL_REGEX =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  return (
    <React.Fragment>
      <div className="message_list px-3" ref={messageEl}>
        {/* <div className="row my-3">
        <div className="col-md-12 text-center">Today</div>
      </div> */}
        <InfiniteScroll
          loadMore={fetchMoreData}
          hasMore={true}
          loader={""}
          threshold={150}
          useWindow={false}
          dataLength="10"
          inverse={true}
        >
          {msgarr.length > 0
            ? msgarr.map((item, index) => {
              let mssageUrl = item.message
              // let match = /n/.test(mssageUrl); 
              // console.log("match---",match);
              // let newMassageUrl = match ? mssageUrl.split('\n') : item.message;
              // console.log("newMassageUrl---",newMassageUrl);
                let regexUrl =
                new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	    '(\\#[-a-z\\d_]*)?$','i');
                  let message = regexUrl.test(mssageUrl);
                  // console.log("check message -----",message);
                // console.log("item----", item);
                return (
                  <> 
                    {console.log("itemcall", item)}
                    {item?.type == "call" ? (
                      <li className="log_time">

                        {moment(item.callTime).format("hh:mm A")}
                        <br />
                        <span>
                          {item.callStatus == "Recieved"
                            ? `Call ${item.callDuration}`
                            : item.sender_id == userData._id
                            ? "Not Connected"
                            : "Missed Call"}
                        </span>
                        <br />
                        <span>
                          {moment(item.callTime).format("DD MMM, YYYY")}
                        </span>
                      </li>
                    ) : (
                      <div
                        className={
                          item.sender_id == userData._id
                            ? "userSended"
                            : "userRecieved"
                        }
                        key={index}
                      >
                        <div className="chat_body my-3" key={item.msgId}>
                          <div
                            className={
                              item.offer
                                ? "SendText w-100"
                                : "d-flex SendText w-100"
                            }
                          >
                            {/* <div className="mb-auto">
                  <img src={item.img} alt="img" className="avatar_50" />
                </div> */}
                            <div className="mx-3 my-auto   ">
                              <div className="message_text">
                                {/* <span>{moment(item.send_time).format("LT")}</span> */}
                                <span>{moment(item.send_time).fromNow()}</span>

                                {/* {item.image ? (
                          <ImageMsg url={item.message} />
                        ) : (
                          <p className="mb-0">{item.message}</p>
                        )} */}
                                {item.image ? (
                                  <ImageMsg url={item.message} />
                                ) : item.offer ? (
                                  <MessageBody
                                    offername={item.offername}
                                    price={item.price}
                                    details={item.details}
                                    image={item.imagepath}
                                    serviceid={item.service_id}
                                    tosendwhom={props.sender.userId}
                                    accepted={item.accepted}
                                    rejected={item.rejected}
                                    nodeId={item.nodeId}
                                    sender={item.sender_id == userData._id}
                                    roomId={props.roomId}
                                  />
                                ) : (
                                  // <p className="mb-0">{URL_REGEX.test(part){item.message}}</p>
                                  <p className="mb-0">
                                    {message ? (
                                      <a href={item.message} target="_blank" rel="noreferrer">{item.message}</a>
                                    ) : (
                                      item.message
                                    )}
                                  </p>
                                )}
                                {/* <MessageBody/> */}
                                {/* <ImageMsg /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })
            : null}
        </InfiniteScroll>
      </div>
    </React.Fragment>
  );
};
export default MessageList;
