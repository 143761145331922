import React from "react";
import { Link } from "react-router-dom";
import avatar from "../../Assets/Images/avatar.png";
import { useEffect, useState } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import Shop from "../../Views/MyProfile/Shop";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Component/loader";

import "react-toastify/dist/ReactToastify.css";
const userdata = reactLocalStorage.getObject("userData");
const EditSellerProfile = () => {
  const [user, setuser] = useState({});
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [about, setabout] = useState("");
  const [city, setcity] = useState("");
  const [profileimg, setprofileimg] = useState("");
  const [shop, setshop] = useState("");
  const [favourite_item, setfavourite_item] = useState("");
  const [favourite_shop, setfavourite_shop] = useState("");
  const [password, setpassword] = useState("");
  const [arr, setarr] = useState([]);
  const [checkArr, setcheckArr] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const onImageId = async (event) => {
    setprofileimg(event.target.files[0]);
    setarr([
      {
        avatar: URL.createObjectURL(event.target.files[0]),
      },
    ]);
    // console.log("arr", arr);
  };

  const fetchData = async () => {
    setLoading(true);
    let userdata = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData("seller/" + userdata._id, "GET");
    // console.log("Data", result);
    if (result && result.status) {
      setuser(result.data);
      setfname(result.data.firstName);
      setlname(result.data.lastName);
      setemail(result.data.email);
      setpassword(result.data.password);
      setcity(result.data.city ? result.data.city : "");
      setabout(result.data.about ? result.data.about : "");
      result.data.image
        ? setarr([
            {
              avatar: HttpClient.IMG_URL + result.data.image,
            },
          ])
        : setarr([]);
      setTimeout(() => {
        setLoading(false);
      }, 1);
      // console.log("setuser", user);
    } else {
    }
  };

  const save = async () => {
    // let data=formdata=();
    // console.log("checkArr", JSON.stringify(checkArr));
    const res = checkArr.map((data, index) => {
      return {
        position: data,
      };
    });
    console.log("checkArr", JSON.stringify(res));
    // return;
    let data = new FormData();

    if (fname != "") {
      data.append("firstName", fname);
    }
    if (lname != "") {
      data.append("lastName", lname);
    }
    if (profileimg != "") {
      data.append("image", profileimg);
    }
    data.append("email", email);
    data.append("password", password);

    if (city != "") {
      data.append("city", city);
    }

    if (about != "") {
      data.append("about", about);
    }

    if (checkArr.length > 0) {
      data.append("include", checkArr);
    }

    let result = await HttpClient.fileUplode(
      "update-profile/" + userdata._id,
      "PUT",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      // console.log("result", result);
      toast.success("Profile Updated Successfully", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // fetchData();
      window.location.href = "/seller";
    } else {
    }
  };
  return (
    <div className="row edit_head">
      {/* <ToastContainer /> */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <div className="col-md-6 mx-auto">
            <div className="">
              <div className="d-lg-flex  justify-content-between">
                {/* <div>
            <img src={avatar} alt="img" />
          </div> */}
                <div>
                  {arr.length > 0 ? (
                    arr.map((item, index) => {
                      // console.log("object", item);
                      return (
                        <img
                          key={index}
                          alt="img"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                          }}
                          src={item.avatar}
                        />
                      );
                    })
                  ) : (
                    <img
                      src={avatar}
                      // key={index}
                      alt="img"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </div>

                <div className="choose_file my-4">
                  <input
                    type="file"
                    className="input_file"
                    onChange={onImageId}
                  />
                  Choose File
                </div>
              </div>
              <p>
                Must be a .jpg, .gif or .png file smaller than 10MB and at least
                400px by 400px.
              </p>
            </div>
            <div>
              <div className=" edit_form_left">
                <div className="form-group row">
                  <div className="col-md-6">
                    {" "}
                    <label htmlFor="fname">First Name</label>
                    <input
                      type="text"
                      id="fname"
                      className="form-control"
                      onChange={(val) => {
                        if (val.target.value.match("^[a-zA-Z0-9]*$") != null) {
                          setfname(val.target.value);
                        }
                      }}
                      value={fname}
                    />
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <label htmlFor="lname">Last Name</label>
                    <input
                      type="text"
                      id="lname"
                      className="form-control"
                      onChange={(val) => {
                        if (val.target.value.match("^[a-zA-Z0-9]*$") != null) {
                          setlname(val.target.value);
                        }
                      }}
                      value={lname}
                    />
                  </div>
                </div>
                {/* <div className="form-group my-2 ml-3 " style={{ width: "100%" }}>
            <label htmlFor="formGroupExampleInput">Last Name</label>
            <input
              type="text"
              className="form-control"
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z0-9]*$") != null) {
                  setlname(val.target.value);
                }
              }}
              value={lname}
            />
          </div> */}
                <div className="form-group mt-3">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    // placeholder="email@gmail.com"

                    value={email}
                    readOnly
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="inputCity">City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCity"
                    onChange={(val) => {
                      if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                        setcity(val.target.value);
                      }
                    }}
                    value={city}
                  />
                </div>
                <div className="form-group my-3">
                  <label htmlFor="exampleFormControlTextarea1">About</label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    defaultValue={""}
                    onChange={(val) => {
                      if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                        setabout(val.target.value);
                      }
                    }}
                    value={about}
                  />
                </div>
                {/* <h6>Include on Your Profile</h6> */}
                {/* <div>
            <div className="form-check form-check-inline my-2 mr-4">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                name="check_shop"
                value="shop"
                onChange={handleCheck}
                
              />
              <label className="form-check-label" htmlFor="inlineCheckbox1">
                Shop
              </label>
            </div>
            <div className="form-check form-check-inline my-2 mr-4">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox2"
                name="check_favourite_item"
                value="favourite_item"
                onChange={handleCheck}
                
              />
              <label className="form-check-label" htmlFor="inlineCheckbox2">
                Favourite items
              </label>
            </div>
            <div className="form-check form-check-inline my-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox3"
                name="check_favourite_shop"
                value="favourite_shop"
                onChange={handleCheck}
               
              />
              <label className="form-check-label" htmlFor="inlineCheckbox3">
                Favourite shops
              </label>
            </div>
          </div> */}
                <div className="text-center my-5">
                  <button type="button" className="btn save_btn" onClick={save}>
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default EditSellerProfile;
