import React, { useEffect, useState, useCallback } from "react";
import Filter from "./Filter";
import ServiceList from "./ServiceList";
import ServicePagination from "../Components/ServicePagination";
import Spell from "../Components/Spell";
import OtherService from "../Components/OtherService";
import Footer from "../../Defaults/Footer";
import { ReactTitle } from "react-meta-tags";
import Loader from "../../Component/loader";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import bgBlog from "../../Assets/Images/blogBg.jpg";

const Index = (props) => {
  // console.log("index props",props)
  const [service, setService] = useState([]);
  const id = props.match.params.id;
  const [loading, setLoading] = useState(false);
  const [catname, setcatname] = useState("");
  const [pagevalue, setpagevalue] = useState(1);
  const [itemcount, setitemcount] = useState(0);
  const [minum, setminum] = useState("");
  const [maxnum, setmaxnum] = useState("");
  const [sortBy, setsortBy] = useState("");
  const [rat, setrat] = useState("");
  const [ratee, setratee] = useState("");
  const [short, setshort] = useState("");
  const [priceby, setpriceby] = useState("");

  const userData = reactLocalStorage.getObject("userData");

  useEffect(() => {
    // fetchShopId();
    fetchService(1, "", "", "", "");
    window.scrollTo(0, 0);
  }, []);
  // const items = [];

  const [productpaginator, setproductpaginator] = useState({});

  const fetchService = async (next, max, min, sortBy, rateby) => {
    console.log("fect dsta ---", next, max, min, sortBy);
    // console.log("idd", id);
    setLoading(true);
    let sendData = next ? next : "1";
    if (Object.keys(userData).length > 0) {
      let result = await HttpClient.requestData(
        "service/shop-services/" +
          userData._id +
          "/" +
          id +
          "/" +
          sendData +
          "?currency=" +
          userData.currency +
          "&min=" +
          min +
          "&max=" +
          max +
          "&shortby=" +
          sortBy +
          `&rateby=` +
          rateby,
        "GET"
      );
      console.log(
        "servicefetchbycategory",
        "service/shop-services/" +
          userData._id +
          "/" +
          id +
          "/" +
          sendData +
          "?currency=" +
          userData.currency +
          "&min=" +
          min +
          "&max=" +
          max +
          "&shortby=" +
          sortBy +
          `&rateby=` +
          rateby
      );
      if (result && result.status) {
        let data = [];

        if (result.data.itemsList.length > 0) {
          data = result.data.itemsList.filter(
            (item) =>
              item.shop_details.length > 0 &&
              item.shop_details[0].userid != userData._id
          );
        } else {
          data = [];
        }

        // console.log("data", data);
        setproductpaginator(result.data.paginator);
        setitemcount(
          result.data.paginator ? result.data.paginator.itemCount : 0
        );
        setcatname(result.category_data.name);
        setService(data);
        setshort(sortBy);
        setrat(rateby);

        setpriceby(min == "151" ? "above151" : min + "-" + max);
        setTimeout(() => {
          setLoading(false);
        }, 1);
        // console.log("Servicedata", service);
      } else {
      }
    } else {
      let result = await HttpClient.requestData(
        "service/shop-services/" +
          "91a953b1effa7e26d08fff8a" +
          "/" +
          id +
          "/" +
          sendData +
          "?currency=" +
          "INR" +
          "&min=" +
          min +
          "&max=" +
          max +
          "&shortby=" +
          sortBy +
          `&rateby=` +
          rateby,
        "GET"
      );
      console.log("servicefetchbycategory", result);
      if (result && result.status) {
        let data = [];

        if (result.data.itemsList.length > 0) {
          data = result.data.itemsList.filter(
            (item) => item.shop_details[0].userid != userData._id
          );
        } else {
          data = [];
        }

        console.log("servicefetchbycategory data", data);
        setproductpaginator(result.data.paginator);
        setitemcount(
          result.data.paginator ? result.data.paginator.itemCount : 0
        );
        setcatname(result.category_data.name);
        setService(data);
        setshort(sortBy);
        setrat(rateby);

        setpriceby(min == "151" ? "above151" : min + "-" + max);
        setTimeout(() => {
          setLoading(false);
        }, 1);
        // console.log("Servicedata", service);
      } else {
      }
    }
  };

  const serviceFilter = (data, paginator) => {
    console.log("filter data---", data);
    setService(data);
    setproductpaginator(paginator);
  };

  const nextpage = (val, min, max, sortBy, rateby) => {
    console.log("nextmin", val, max, min, sortBy);
    setpagevalue(val);
    fetchService(val, max, min, sortBy, rateby);
    setshort(sortBy);
    setrat(rateby);
    setpriceby(min == "151" ? "above151" : min + "-" + max);
  };

  const min = (val) => {
    console.log("index prop", val.max, val.min);
    setminum(val.min);
    setmaxnum(val.max);
  };
  const sortby = (val) => {
    console.log("props sort", val.sort);
    setsortBy(val.sort);
  };

  const rate = (val) => {
    console.log("props rate", val.rate);
    setratee(val.rate);
  };

  // const callserv = () => {
  //   fetchService();
  // };
  const callserv = useCallback(
    (event) => {
      fetchService(1, "", "", "");
    },
    [service]
  );
  return (
    <div>
      <div className="wrapper">
        <ReactTitle title={"Services Of " + catname + " | " + " Astrophy"} />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <section className="Spell">
            {/* <div className="container" style={{ maxWidth: "1200px" }}>
              <Spell />
            </div> */}
          </section>
          <section
            className="Services"
            style={{
              background: `url(${bgBlog})`,
            }}
          >
            <div className="container" style={{ maxWidth: "1200px" }}>
              <div class="service_head">
                <h6>Services</h6>
              </div>

              <h3 className="my-4 px-1">
                Services Of <span style={{ color: "#E84A56" }}>{catname}</span>
              </h3>
              <Filter
                page="services"
                serviceFilter={serviceFilter}
                pagevalue={pagevalue ? pagevalue : "1"}
                catName={(val) => setcatname(val)}
                catid={id}
                count={itemcount}
                parentmin={min}
                sortby={sortby}
                rate={rate}
                rat={rat}
                short={short}
                priceby={priceby}
              />
            </div>
          </section>
          <section>
            <div className="container" style={{ maxWidth: "1200px" }}>
              <ServiceList data={service} callbackserv={callserv} />
            </div>
          </section>
          <section className="container">
            <div className="row">
              <div className="col-md-12 ">
                <ServicePagination
                  productpaginator={productpaginator}
                  nextpage={nextpage}
                  min={minum}
                  max={maxnum}
                  sortBy={sortBy}
                  ratee={ratee}
                />
              </div>
            </div>
          </section>
          <section className="mb-3">
            <div className="container" style={{ maxWidth: "1200px" }}>
              <div className="row">
                <div className="col-md-12 my-4">
                  <div className="service_head">
                    <h6>Reading</h6>
                    <h3 className="mt-4">Other Services</h3>
                  </div>
                </div>
              </div>
              <OtherService />
            </div>
          </section>
          <section>
            <Footer />
          </section>
        </>
      )}
    </div>
  );
};
export default Index;
