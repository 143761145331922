import React,{ useState, useEffect } from 'react'

const ShowMore = ({ data }) => {
    const [show, setShow] = useState(true);
  
    useEffect(() => {
      console.log(show);
    }, [show]);
    return (
      <>
        {data.length > 700 ? (
          <div>
            {show == true ? (
              <>
              <div dangerouslySetInnerHTML={{__html: data.slice(0, 100)}}></div>
                {/* {data.slice(0, 100)}{" "} */}
                <button
                  onClick={() => setShow(false)}
                  className="text-right"
                  style={{float: "right",
                    border: "none",
                    background: "#fff",
                    color: "gray"}}
                >
                  Read More
                </button>
              </>
            ) : (
              <div dangerouslySetInnerHTML={{__html: data}}></div>
            )}
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{__html: data}}></div>
        )}
      </>
    );
  };

export default ShowMore
