import React from "react";

const BlankOrder = () => {
  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-md-12 text-center">
          <img
            src="https://cdn4.iconfinder.com/data/icons/ecommerce-flat-8/64/avatar_man_think_shopping_ecommerce-512.png"
            alt="img"
            style={{ width: "200px", height: "200px", borderRadius: "50%" }}
          />
          <h2 className="pt-3">You Don't Have Any Orders</h2>
        </div>
      </div>
    </div>
  );
};
export default BlankOrder;
