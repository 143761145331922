import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Editor } from "@tinymce/tinymce-react";
import HttpClient from "../../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import db from "../../../Firebase/fbconfig";
import { v4 as uuidv4 } from "uuid";
import { ref, push, set, child, update } from "firebase/database";
import moment from "moment";


const ChatServiceModal = (props) => {
  // let userdata = reactLocalStorage.getObject("userData");
  const [name, setname] = useState("");
  const [price, setprice] = useState("");
  const [details, setdetails] = useState("");
  const [personalisation, setpersonalisation] = useState("");
  const [hashtag, sethashtag] = useState("");
  const [serviceimg, setserviceimg] = useState("");

  const [arr, setarr] = useState([]);

  const [category, setcategory] = useState("");
  const [subcategory, setSubcategory] = useState("");

  const [categoryName, setcategoryName] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");

  const [cat, setcat] = useState([]);
  const [subcat, setsubcat] = useState([]);
  const [shopid, setshopid] = useState("");
  const userData = reactLocalStorage.getObject("userData");
  const [imageurl, setimageurl] = useState("");

  const [imagepath, setimagepath] = useState("");
  const [currency, setcurrency] = useState("");
  const [currencyTyp, setcurrencyTyp] = useState([
    {
      name: "INR",
    },
    {
      name: "USD",
    },
    {
      name: "GBP",
    },
    {
      name: "EUR",
    },
    {
      name: "CAD",
    },
    // {
    //   name: "cdd",
    // },
  ]);

  const sender = props.sender;

  useEffect(() => {
    // console.log("props.sender",props.sender);
    fetchCat();
    fetchShopId();
  }, []);

  const onImageId = async (event) => {
    setserviceimg(event.target.files[0]);
    setarr([
      {
        preview: URL.createObjectURL(event.target.files[0]),
      },
    ]);
    let data = new FormData();
    data.append("image", event.target.files[0]);
    let result = await HttpClient.fileUplode("image-uploadurl", "POST", data);
    console.log("result", result);
    if (result && result.status) {
      let imageurl = HttpClient.IMG_URL + result.data;
      setimageurl(imageurl);

      setimagepath(result.data);
    } else {
    }
  };

  const handlecategory = (event) => {
    const selectedcategory = event.target.value;
    let filterCategory = cat.filter((item) => item._id === selectedcategory);
    setcategoryName(filterCategory[0].name);
    setcategory(selectedcategory);
    fetchSubCat(selectedcategory);
  };

  const handleSubcategory = (event) => {
    const selectedSubcategory = event.target.value;
    let filterSubCategory = subcat.filter(
      (item) => item._id === selectedSubcategory
    );
    setSubcategoryName(filterSubCategory[0].name);
    setSubcategory(selectedSubcategory);
  };

  const fetchCat = async () => {
    let result = await HttpClient.requestData("service", "GET");
    // console.log("Cat", result);
    if (result && result.status) {
      let data = result.data.filter((item) => item.status == true);
      // console.log("data", data);
      setcat(data);
      // console.log("setcat", cat);
    } else {
    }
  };

  const fetchSubCat = async (id) => {
    // let result = await HttpClient.requestData(
    //   "service/subcategory/" + id,
    //   "GET"
    // );
    // // console.log("SubCat", result);
    // if (result && result.status) {
    //   let data = result.data;
    //   // console.log("subdata", data);
    //   setsubcat(data);
    //   // console.log("SubCatdata", subcat);
    // } else {
    // }
    let result = cat.filter((item) => item._id === id);
    console.log("SubCat", result);
    if (result && result.length > 0) {
      let data = result[0].service_data;
      console.log("SubCat data", data);
      setsubcat(data);
    } else {
    }
  };

  const fetchShopId = async () => {
    let userdata = reactLocalStorage.getObject("userData");
    // console.log("userdata", userdata);
    let result = await HttpClient.requestData("seller/" + userdata._id, "GET");
    // console.log("Shop", result.shop_id);
    if (result && result.status) {
      setshopid(result.shop_id);
      // console.log("shopid", shopid);
    }
  };
  const update = async () => {
    if (
      name == "" ||
      price == "" ||
      details == "" ||
      personalisation == "" ||
      hashtag == ""
    ) {
      toast.error("Please Enter All Fields", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (arr.length == 0) {
      toast.error("Please Enter Images", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let data = new FormData();
    data.append("name", name);
    data.append("price", price);
    data.append("details", details);
    // data.append("category_id", category);
    // data.append("subcategory_id", subcategory);
    data.append("image", serviceimg);
    data.append("shop_id", shopid);
    data.append("personalization", personalisation);
    data.append("hashtags", hashtag);
    // data.append("cat_name", categoryName);
    // data.append("subcat_name", subcategoryName);
    data.append("currency", userData.currency);
    data.append("seller_id", userData._id);
    // data.append("service_type", "custom");
    let result = await HttpClient.fileUplode(
      "shop/web-chatservices",
      "POST",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      let _id = result.data._id;
      props.callBackService(false);
      const postListRef = ref(db, "/Chat/" + props.item + "/messages/");
      const newPostRef = push(postListRef);
      set(newPostRef, {
        roomId: props.item,
        msgId: uuidv4(),
        sender_id: userData._id,
        offername: name,
        price: price,
        details: details,
        imageurl: imageurl,
        imagepath: result.data.image,
        service_id: _id,
        send_time: moment().format(),
        name: userData.firstName + " " + userData.lastName,
        offer: true,
        nodeId: newPostRef.key
      });
      setname("");
      setprice("");
      setdetails("");
      setpersonalisation("");
      sethashtag("");
      setserviceimg("");
      setcategory("");
      setSubcategory("");
      setimageurl("");
      setarr([]);
      setcurrency("");
      // props.callBackService(false);
    } else {
    }
    // const postListRef = ref(db, "/Chat/" + props.item + "/messages/");
    // const newPostRef = push(postListRef);
    // set(newPostRef, {
    //   roomId: props.item,
    //   msgId: uuidv4(),
    //   sender_id: userData._id,
    //   offername: name,
    //   price: price,
    //   details: details,
    //   imageurl: imageurl,
    //   send_time: moment().format(),
    //   name: userData.firstName + " " + userData.lastName,
    //   offer: true,
    // });
    // let postData = {
    //   lastMsgTime: moment().format(),
    //   name: sender.displayName,
    //   displayName: sender.displayName,
    //   userId: sender.userId,
    //   roomId: sender.roomId,
    //   lastMsgtype: "offer",
    // };

    // let postDataa = {
    //   lastMsgTime: moment().format(),
    //   name: userData.firstName,
    //   displayName: userData.firstName + " " + userData.lastName,
    //   userId: userData._id,
    //   roomId: props.item,
    //   lastMsgtype: "offer",
    // };
    // const updates = {};
    // updates[`/chatList/${userData._id}/${sender.userId}`] = postData;
    // updates[`/chatList/${sender.userId}/${userData._id}`] = postDataa;
    // update(ref(db), updates);
    // setname("");
    // setprice("");
    // setdetails("");
    // setimageurl("");
    // setpersonalisation("");
    // sethashtag("");
    // setarr([]);
    // setserviceimg("");
    // setcategory("");
    // setSubcategory("");

    // props.callBackService(false);
  };
  return (
    <div>
      <div className="modal-dialog  my-3 ">
        <ToastContainer />
        <div className="modal-content add-service_modal">
          <button
            type="button"
            className="cross close ml-auto px-4 py-2"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.callBackService(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="login-body my-3 px-5 ">
            <div className="Add_service ">
              <div className="Toastify" />
              <div className="text-center">
                <h1>Create New Services</h1>
              </div>

              <div className="form-group my-4">
                <label htmlFor="name">
                  Service Name{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Service Name"
                  onChange={(val) => {
                    setname(val.target.value);
                  }}
                  value={name}
                />
              </div>
              <div className="form-group my-4">
                <label htmlFor="currency">
                  Currency{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <select
                  id="currency"
                  className="form-control"
                  onChange={(val) => {
                    setcurrency(val.target.value);

                    // getTax(val.target.value);
                  }}
                  value={currency}
                >
                  <option value="">Select Currency</option>
                  {currencyTyp.map((item, index) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
                </select>
              </div>
              <div className="form-group my-4">
                <label htmlFor="price">
                  Service Price{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  aria-describedby="emailHelp"
                  placeholder="Service Price"
                  onChange={(val) => {
                    if (
                      val.target.value.match("^[+]?([0-9]*[.])?[0-9]*$") != null
                    ) {
                      setprice(val.target.value);
                    }
                  }}
                  value={price}
                />
              </div>
              <div className="form-group my-4">
                <label htmlFor="details">
                  Service Details{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                {/* <textarea
                  className="form-control"
                  id="details"
                  rows={3}
                  placeholder="Please Add Your Service Details"
                  onChange={(val) => {
                    setdetails(val.target.value);
                  }}
                  value={details}
                /> */}
                <Editor
                  apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                  value={details}
                  onEditorChange={(newValue, editor) => {
                    setdetails(newValue);
                  }}
                />
              </div>
              <div className="form-group my-4">
                <label htmlFor="personalisation">
                  Add Your Personalization{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                {/* <textarea
                  className="form-control"
                  id="personalisation"
                  rows={3}
                  placeholder=" Please Add Your Personalization"
                  onChange={(val) => {
                    setpersonalisation(val.target.value);
                  }}
                  value={personalisation}
                /> */}
                <Editor
                  apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                  value={personalisation}
                  onEditorChange={(newValue, editor) => {
                    setpersonalisation(newValue);
                  }}
                />
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={handlecategory}
                      value={category}
                    >
                      <option value>Select Catagory</option>
                      {cat.map((item, index) => {
                        return (
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={handleSubcategory}
                      value={subcategory}
                    
                    >
                      <option>Select Sub Catagory</option>
                      {subcat.map((item, index) => {
                        return (
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="form-group my-4">
                <label htmlFor="hashtag">
                  Hashtag for Services{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <textarea
                  className="form-control"
                  id="hashtag"
                  rows={3}
                  placeholder="Please Add Hashtag for Service"
                  onChange={(val) => {
                    sethashtag(val.target.value);
                  }}
                  value={hashtag}
                />
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <h5>
                    Service Image{" "}
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </h5>
                  {arr.map((item, index) => {
                    return (
                      <img
                        className="avatar ml-3 img-fluid img-thumbnail"
                        key={index}
                        alt="img"
                        style={{ width: "54px", height: "54px" }}
                        src={item.preview}
                      />
                    );
                  })}
                  {/* {} */}
                </div>
                <div className="choose_file my-4">
                  <input
                    type="file"
                    className="input_file"
                    onChange={onImageId}
                  />
                  Choose File
                </div>
              </div>
              <div className="d-flex justify-content-between my-5">
                <button
                  type="button"
                  className="btn publish_btn"
                  onClick={update}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatServiceModal;
