const initialState = {
  cartRDX: [],
  cartServiceRDX: null,
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CART":
      return {
        ...state,
        cartRDX: action.payload,
      };
    case "SET_CARTSERVICE":
      return {
        ...state,
        cartServiceRDX: action.payload,
      };
    default:
      return state;
  }
}

export default rootReducer;
