import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
// import ViewOrderDetails from "./ViewOrderDetails";

const ServiceList = (props) => {
  const userData = reactLocalStorage.getObject("userData");
  useEffect(() => {
    window.scrollTo(0, 0);
    getorderlist();
  }, []);
  // console.log("props",props.data);
  const [show, setShow] = useState(true);
  const [hide, sethide] = useState(true);
  const [acceptstatus, setacceptstatus] = useState("");
  const [order, setorder] = useState([]);
  const [loading, setLoading] = useState(false);

  const getorderlist = async () => {
    setLoading(true);
    let data = {};
    let result = await HttpClient.requestData(
      "sellerbookhistory/" + userData._id,
      "GET",
      data
    );
    console.log("order details", result);
    if (result && result.status) {
      let newArray = result.data.reverse();
      setorder(newArray);
      // setorder(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };

  const handleAccept = async (id) => {
    let sendData = {
      id: id,
      acceptstatus: "accept",
      currencyy: userData.currency,
    };
    console.log("data", sendData);
    // return false;
    let result = await HttpClient.requestData(
      "accept_status",
      "POST",
      sendData
    );
    console.log("accept_status", result);
    if (result && result.status) {
      toast.success("Accept successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getorderlist();
    //   props.update(result.status);
    } else {
      toast.error("Error", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleReject = async (id) => {
    let sendData = {
      id: id,
      acceptstatus: "reject",
    };
    console.log("data", sendData);
    // return false;
    let result = await HttpClient.requestData(
      "accept_status",
      "POST",
      sendData
    );
    console.log("accept_status", result);
    if (result && result.status) {
      toast.success("Rejected", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getorderlist();
    //   props.update(result.status);
    } else {
      toast.error("Error", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const viewOrderDetail = (id) => {
    setTimeout(() => {
      window.location.href = "/view-order-details/" + id;
    }, 10);
  };

  return (
    <div className="row">
      {order.map((item, index) => {
        // console.log("index", item)
        return (
          <div className="col-md-6" key={index}>
            <div className="card sell_order my-3">
              <div className="card-body">
                <div className="d-flex justify-content-around">
                  <img src={HttpClient.IMG_URL + item.image} alt="img" />
                  <div
                    className="ml-3"
                    onClick={() => viewOrderDetail(item._id)}
                  >
                    <h6>{item.order_id}</h6>
                    <h5>{item.servicename}</h5>
                    {/* <p>{item.para}</p> */}
                  </div>
                  {item.cancelstatus == "" ? (
                    item.acceptstatus == "pending" ? (
                      <div className="ml-4 w-25">
                        <button
                          type="button"
                          className="btn acc_btn"
                          onClick={() => handleAccept(item._id)}
                        >
                          Accept
                        </button>

                        <button
                          type="button"
                          className="btn reject_btn my-3"
                          onClick={() => handleReject(item._id)}
                        >
                          Reject
                        </button>
                      </div>
                    ) : item.acceptstatus == "accept" ? (
                      <div className="ml-4 w-25">
                        <button type="button" className="btn acc_btn">
                          Accepted
                        </button>
                      </div>
                    ) : (
                      <div className="ml-4 w-25">
                        <button type="button" className="btn reject_btn my-3">
                          Rejected
                        </button>
                      </div>
                    )
                  ) : (
                    <div className="ml-4 w-25">
                      <button type="button" className="btn reject_btn my-3">
                        Order Cancelled
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ServiceList;
