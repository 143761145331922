import React, { useState } from "react";
// import Table from "./Table";
import { MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import Details from "./Details";
import moment from "moment";
const OrderReport = () => {
  const [table, setTable] = useState(false);
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [disbale, setdisbale] = useState(false);
  const userData = reactLocalStorage.getObject("userData");
  const [dataa, setdata] = useState([]);
  const [activeid, setactiveid] = useState("");
  const [ismodalopen, setismodalopen] = useState(false);

  const onCloseCallBack = (val) => {
    console.log("object", val);
    setismodalopen(val);
  };
  const submit = async () => {
    let data = {
      datefrom: fromdate,
      dateto: todate,
      seller_id: userData._id,
    };
    let result = await HttpClient.requestData(
      "sellerbookhistoryrepo/",
      "POST",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      result.data.forEach((element, index) => {
        let fname = element.user_data.length > 0 && element.user_data[0].firstName;
        let lname = element.user_data.length > 0 && element.user_data[0].lastName;
        let rows = {
          sl: i,

          name: fname + " " + lname,
          orderid: element.order_id,
          email: element.user_data[0].email,
          amount: element.total,
          price: element.price,
          // catname: element.service_data[0].cat_name,
          // subcatname: element.service_data[0].subcat_name,
          servicename: element.servicename,
          paydate_on: moment(element.booking_date).format("MMMM Do YYYY"),
          // details: element.service_data[0].details,
          image: <img
            src={HttpClient.IMG_URL + element.image[0]}
            alt="img"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "6px",
            }}
          />,
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
            >
              {element.status ? "Confirmed" : "Pending"}
            </button>
          ),
          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                setactiveid(element);
                setTimeout(() => {
                  setismodalopen(true);
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });

      setdata(data);
      setTable(true);
    } else {
      setdata([]);
    }
  };

  const data = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        width: 150,
      },
      {
        label: "OrderId",
        field: "orderid",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      // {
      //   label: "Category Name",
      //   field: "catname",
      //   width: 150,
      // },
      // {
      //   label: "Subcategory Name",
      //   field: "subcatname",
      //   width: 150,
      // },
      {
        label: "Servicename",
        field: "servicename",
        width: 270,
      },
      {
        label: "Image",
        field: "image",
        width: 270,
      },
      {
        label: "Paid On",
        field: "paydate_on",
        width: 270,
      },
      // {
      //   label: "Payment Type",
      //   field: "payment_type",
      //   width: 270,
      // },
      {
        label: "Price",
        field: "price",
        width: 270,
      },
      // {
      //   label: "Details",
      //   field: "details",
      //   width: 270,
      // },
      // {
      //   label: "Total",
      //   field: "amount",
      //   width: 270,
      // },
      // {
      //     label: "Pay Status",
      //     field: "paystatus",
      //     width: 270,
      // },
      // {
      //   label: "Tip",
      //   field: "tip",
      //   width: 270,
      // },
      // {
      //   label: "Order Reciept",
      //   field: "download",
      //   width: 270,
      // },
    ],
    rows: dataa,
  };
  return (
    <div className="order-report">
      <div className="row my-4">
        <div className="col-md-12 text-center">
          <h1>Order Report </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 mx-auto">
          <form>
            <div className="row">
              <div className="col-12">
                <label htmlFor="inputFromDate">From Date</label>
                <input
                  type="date"
                  className="form-control _borderRed"
                  placeholder="From Date"
                  value={fromdate}
                  onChange={(val) => {
                    setfromdate(val.target.value);
                  }}
                />
              </div>
              <div className="col-12 my-4">
                <label htmlFor="inputToDate">To Date</label>
                <input
                  type="date"
                  className="form-control _borderRed"
                  placeholder="To Date"
                  value={todate}
                  onChange={(val) => {
                    settodate(val.target.value);
                    setdisbale(true);
                  }}
                />
              </div>
            </div>
          </form>
          <div className="text-center">
            <button
              type="button"
              className="btn report-btn"
              onClick={submit}
              disabled={!disbale}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {table ? (
        <div className="row mt-5">
          <div className="col-md-10 mx-auto table-flow">
            {ismodalopen ? (
              <Details
                status={true}
                onCloseCallBack={onCloseCallBack}
                data={activeid}
              />
            ) : null}
            <MDBDataTableV5
            
              hover
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              data={data}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default OrderReport;
