import React from "react";

const TopIntro = (props) => {
  return (
    <div>
      <div className="row px-3">
        <div className="col-md-12 my-3">
          <div className="service_head">
            <h6>{props.name}</h6>
            <h3 className="mt-2 mb-0">
              {props.title}
              <span>{props.title1}</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopIntro;
