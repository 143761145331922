import React, { useEffect, useState } from "react";
import SellerBreadcumb from "../Components/SellerBreadcumb";
import Footer from "../../Defaults/Footer";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
import { ReactTitle } from "react-meta-tags";

export default function Index() {
  const [loader, setLoading] = useState(false);
  const [achivmentData, setachivmentData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAchivement();
  }, []);
  const fetchAchivement = async () => {
    setLoading(true);
    let result = await HttpClient.requestData("cms/achievement", "GET");
    console.log("cms/achievement", result);
    if (result && result.status) {
      setachivmentData(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
      setachivmentData([]);
    }
  };
  return (
    <div>
      <SellerBreadcumb title="Achievements" />
      {loader ? (
        <Loader />
      ) : (
        <section className="container-fluid condition my-4">
          <ReactTitle title="Astrophy | Achivements" />
          <div className="row px-5">
            <div className="col-md-12">
              <div className="terms">
                {achivmentData.length ? (
                  achivmentData.map((item, index) => {
                    return (
                      <>
                      <h3>{item.title}</h3>
                        <div className="d-flex py-4">
                          <img
                            src={HttpClient.IMG_URL + item.image}
                            alt="img"
                            style={{ width: "200px", maxWidth: "200px" }}
                          />
                          <p className="px-4">{item.description}</p>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div className="col-md-12 text-center blank_product">
                    <h4>
                      No Achivment found <br></br>
                    </h4>
                  </div>
                )}
                {/* <h3>Condition Of Use &amp; Sell :</h3>
                <p>
                  It seems that only fragments of the original text remain in
                  the Lorem Ipsum texts used today. One may speculate that over
                  the course of time certain letters were added or deleted at
                  various positions within the text.
                </p>
                <p>
                  In the 1960s, the text suddenly became known beyond the
                  professional circle of typesetters and layout designers when
                  it was used for Letraset sheets (adhesive letters on
                  transparent film, popular until the 1980s) Versions of the
                  text were subsequently included in DTP programmes such as
                  PageMaker etc.
                </p>
                <p>
                  There is now an abundance of readable dummy texts. These are
                  usually used when a text is required purely to fill a space.
                  These alternatives to the classic Lorem Ipsum texts are often
                  amusing and tell short, funny or nonsensical stories.
                </p>
                <h3>We use your information to :</h3>
                <ul>
                  <li>
                    <span>
                      <i
                        className="far fa-hand-point-right"
                        aria-hidden="true"
                      />
                    </span>
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li>
                    <span>
                      <i
                        className="far fa-hand-point-right"
                        aria-hidden="true"
                      />
                    </span>
                    Our Talented &amp; Experienced Marketing Agency
                  </li>
                  <li>
                    <span>
                      <i
                        className="far fa-hand-point-right"
                        aria-hidden="true"
                      />
                    </span>
                    Create your own skin to match your brand
                  </li>
                  <li>
                    <span>
                      <i
                        className="far fa-hand-point-right"
                        aria-hidden="true"
                      />
                    </span>
                    Our Talented &amp; Experienced Marketing Agency
                  </li>
                  <li>
                    <span>
                      <i
                        className="far fa-hand-point-right"
                        aria-hidden="true"
                      />
                    </span>
                    Create your own skin to match your brand
                  </li>
                </ul>
                <h3>Information Provided Voluntarily :</h3>
                <p>
                  In the 1960s, the text suddenly became known beyond the
                  professional circle of typesetters and layout designers when
                  it was used for Letraset sheets (adhesive letters on
                  transparent film, popular until the 1980s) Versions of the
                  text were subsequently included in DTP programmes such as
                  PageMaker etc. Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Nemo ipsam repudiandae fugit nulla doloribus
                  dolore temporibus officia, tempora minus, dicta expedita
                  voluptatem enim eaque corrupti velit illum nihil explicabo.
                  Error numquam cumque aliquid modi veniam culpa aut totam
                  provident dolorem voluptates est adipisci nulla, corrupti ipsa
                  a sed deleniti delectus. Quisquam voluptatibus rerum molestias
                  quia officiis commodi, eaque quos repellendus alias temporibus
                  nobis quis odit, et quod consequatur, odio totam accusamus.
                  Autem officiis sed placeat. Vel maxime pariatur porro ullam ut
                  numquam exercitationem est tempora aliquid ratione, commodi
                  dolor cupiditate nobis nostrum doloremque expedita repellendus
                  beatae sed laboriosam magni sint!
                </p>
                <h3>Information Provided Voluntarily :</h3>
                <p>
                  In the 1960s, the text suddenly became known beyond the
                  professional circle of typesetters and layout designers when
                  it was used for Letraset sheets (adhesive letters on
                  transparent film, popular until the 1980s) Versions of the
                  text were subsequently included in DTP programmes such as
                  PageMaker etc. Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Nemo ipsam repudiandae fugit nulla doloribus
                  dolore temporibus officia, tempora minus, dicta expedita
                  voluptatem enim eaque corrupti velit illum nihil explicabo.
                  Error numquam cumque aliquid modi veniam culpa aut totam
                  provident dolorem voluptates est adipisci nulla, corrupti ipsa
                  a sed deleniti delectus. Quisquam voluptatibus rerum molestias
                  quia officiis commodi, eaque quos repellendus alias temporibus
                  nobis quis odit, et quod consequatur, odio totam accusamus.
                  Autem officiis sed placeat. Vel maxime pariatur porro ullam ut
                  numquam exercitationem est tempora aliquid ratione, commodi
                  dolor cupiditate nobis nostrum doloremque expedita repellendus
                  beatae sed laboriosam magni sint!
                </p> */}
              </div>
            </div>
          </div>
        </section>
      )}

      <section>
        <Footer />
      </section>
    </div>
  );
}
