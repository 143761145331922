import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import Footer from "../../Defaults/Footer";
import { ToastContainer, toast } from "react-toastify";
import HttpClient from "../../utils/HttpClient";
import blankImg from "../../Assets/Images/blank.jpg";
import provider_bg from "../../Assets/Images/provider_bg.svg";
import bgBlog from "../../Assets/Images/blogBg.jpg";

const MoreProvider = () => {
  const loginstatus = reactLocalStorage.getObject("loginstatus");
  const userData = reactLocalStorage.getObject("userData");
  const [providerdata, setproviderdata] = useState([]);
  const [shortedProvider, setshortedProvider] = useState([]);
  const [searchByName, setsearchByName] = useState("");
  const [bestSale, setbestSale] = useState("");
  const [rating, setrating] = useState("");
  const [sortBy, setsortBy] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getprovider("", "", "");
  }, []);

  const handleBest = (val) => {
    console.log(val.target.value);
    setbestSale(val.target.value);
    getprovider("", "", val.target.value);
  };
  const handleRating = (val) => {
    console.log(val.target.value);
    setrating(val.target.value);
    getprovider("", val.target.value, "");
  };
  const handleSortBy = (val) => {
    console.log(val.target.value);
    setsortBy(val.target.value);
    getprovider("", "", val.target.value);
    getprovider(val.target.value, "", "");
  };

  const sortByPriorityy = (data) => {
    let sortByPriority = data.sort((a, b) =>
      a.priority > b.priority ? 1 : -1
    );
    let sortData = sortByPriority;
    setshortedProvider(sortData);
  };
  const getprovider = async (sortBy, rating, best) => {
    // let sendData = {
    //   best: best,
    //   rating: rating,
    //   shortby: sortBy,
    // };
    // console.log("object", sendData);
    let result = await HttpClient.requestData(
      "topProviders" +
        "?shortby=" +
        best +
        "&rating=" +
        rating +
        "&best=" +
        sortBy,
      "GET"
    );
    console.log("topProviders", result);
    if (result && result.status) {
      setproviderdata(result.data);
      sortByPriorityy(result.data);
    } else {
    }
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchProvider(searchByName);
    }
  };

  const searchProvider = async (val) => {
    let data = {
      searchname: val,
    };
    console.log(data);
    let result = await HttpClient.requestData(
      "topProvidersByname",
      "POST",
      data
    );
    console.log("service provider search", result);
    if (result && result.status) {
      setshortedProvider(result.data);
    } else {
      setshortedProvider(result.data);
    }
  };
  const alertt = () => {
    toast.error("You have to login first", {
      position: "bottom-right",
      autoClose: 3000,
    });
  };
  const wishlist = async (item) => {
    // console.log("provider wishlist", item);
    let sending = {
      user_id: userData._id,
      seller_id: item._id,
      providername: item.firstName + " " + item.lastName,
    };
    console.log(sending);
    let result = await HttpClient.requestData(
      "providerwishlist",
      "POST",
      sending
    );
    console.log("wish provider---", result);
    if (result && result.status) {
      toast.success("Added to Wishlist", {
        position: "bottom-right",
        autoClose: 3000,
      });
      getprovider();
    } else {
      toast.error("Server error, try again later!", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const deleteWishlist = async (val) => {
    console.log("del", val);
    let data = {};
    let result = await HttpClient.requestData(
      "providerdeleteWishlist/" + val.providerwishlists_data[0]._id,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      toast.success("Remove from Wishlist", {
        position: "bottom-right",
        autoClose: 3000,
      });
      getprovider();
    } else {
      toast.error("Server error, try again later!", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(" ", "-");
    return strReplace;
  };
  const loop = [1, 2, 3, 4, 5, 6];
  return (
    <div>
      <div className="provider_sec">
        <div className="container-fluid" style={{ padding: 0 }}>
          {/* <div className="row my-4">
            <div className="col-md-12 text-center service_head">
              <h3>
                Service <span>Providers</span>
              </h3>
              <p className="text-dark">Lorem Ipsum is simply dummy text of the printing and typesetting industry<br/>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

              
            </div>
 
          </div> */}
          <section
            className="page_top_section"
            style={{
              background: `url(${bgBlog})`,
            }}
          >
            <div className="page_top">
              <div className="page_top_title">
                <h3>Service Providers</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Aliquam, asperiores?
                  <br /> Lorem ipsum dolor sit amet.
                </p>
              </div>
            </div>
          </section>

          <section className="suggested_section">
            <div className="d-xxl-block d-xl-block d-lg-block d-md-block">
              <div class="container">
                <ul class="serviceFilter">
                  <li>
                    <div className="suggested_btn_div">
                      <select onChange={handleBest} value={bestSale}>
                        <option>Best Available</option>
                        <option value="bestavailable">Best Available</option>
                      </select>
                      <select onChange={handleRating} value={rating}>
                        <option>Rating</option>
                        <option value="high">Hight to low</option>
                        <option value="low">Low to high</option>
                      </select>
                      {/* <select>
                        <option>Select Price</option>
                        <option>$100-$500</option>
                        <option>$100-$500</option>
                      </select> */}
                    </div>
                  </li>
                  <li>
                    <div className="suggested_btn_div">
                      <label style={{ color: "#fff" }}>Sort By:</label>
                      <select onChange={handleSortBy} value={sortBy}>
                        <option>Providers Sorting</option>
                        <option value="ascending">Newest A-Z</option>
                        <option value="decending">Newest Z-A</option>
                      </select>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* <div className="px-4 container">
            <form className="row d-flex align-items-center service-search my-3 px-3 my-md-0">
              <input
                className="form-control h-100 col col-lg-4 border-top-0 border-right-0 border-left-0"
                type="search"
                placeholder="Search Service Provider"
                aria-label="Search"
                value={searchByName}
                onChange={(val) => {
                  setsearchByName(val.target.value);
                  searchProvider(val.target.value);
                }}
                onKeyPress={(e) => handleKey(e)}
                style={{ minWidth: "172px" }}
              />
              <span>
                <button
                  className="btn pr-1 pl-0 col col-lg-2"
                  type="button"
                  onClick={searchProvider}
                >
                  <i className="fas fa-search  align-self-center "></i>
                </button>
              </span>
            </form>
          </div> */}
          <div className="container">
            <div className="row px-4">
              {providerdata.length ? (
                providerdata.map((item, index) => {
                  return (
                    <div className="col-md-4 col-lg-3 my-4">
                      <div className="px-2">
                        <div className="bg_lineCover">
                          <div className="card slider2_part px-0 pt-0 pb-2">
                            <div className="card-body slider2_body text-center p-0">
                              <img
                                src={
                                  item.image
                                    ? HttpClient.IMG_URL + item.image
                                    : blankImg
                                }
                                alt="img1"
                                className="m-auto"
                              />
                              {loginstatus ? (
                                <>
                                  {item.providerwishlists_data.length > 0 ? (
                                    <button
                                      className="_wislist_icon_465"
                                      onClick={() => {
                                        deleteWishlist(item);
                                      }}
                                    >
                                      <i className="fas fa-heart text-danger"></i>
                                    </button>
                                  ) : (
                                    <button
                                      className="_wislist_icon_465"
                                      onClick={() => {
                                        wishlist(item);
                                      }}
                                    >
                                      <i className="far fa-heart text-danger"></i>
                                    </button>
                                  )}
                                </>
                              ) : (
                                <button
                                  className="_wislist_icon_465"
                                  onClick={alertt}
                                >
                                  <i className="fas fa-heart text-light"></i>
                                </button>
                              )}

                              <div className="px-3">
                                <h5 className="card-title my-2 ">
                                  {item.firstName + " " + item.lastName}
                                </h5>
                                <p className="card-text">
                                  {item.shop_data ? item.shop_data.name : ""}
                                </p>

                                <div className="d-flex justify-content-between mt-2 mb-2 align-items-center">
                                  <span className="d-flex justify-content-center align-items-center">
                                    <i className="fas fa-star mr-2"></i>
                                    <p>
                                      {item.avgRating
                                        ? item.avgRating.toFixed(1)
                                        : "0"}
                                    </p>
                                  </span>
                                  <span className="mb-0">
                                    <b>Sales Value: {item.salesCount}</b>
                                  </span>
                                </div>
                                <Link
                                  to={
                                    "/service-provider/" +
                                    nameToSlug(item.shop_data.name) +
                                    "/" +
                                    item._id
                                  }
                                >
                                  <button className="btn">View More</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="col-md-12 text-center blank_product mb-5">
                  <h4>
                    No service providers found <br></br>
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default MoreProvider;
