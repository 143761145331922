import React, { useState } from "react";
// import EditBudgetModal from "../Services/EditBudgetModal";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

const ProductFilter = (props) => {
  console.log("filterm", props.deli);
  const [userdata, setuserdata] = useState(
    reactLocalStorage.getObject("userData")
  );
  const [rate, setRate] = useState(props.rat);
  const [price, setPrice] = useState(props.priceby);
  const [delivery, setDelivery] = useState(props.deli);
  const [sortBy, setSortBy] = useState(props.short);
  const [sortData, setSortData] = useState("");
  const [minn, setminn] = useState("");
  const [maxx, setmaxx] = useState("");

  const [sortOptions, setSortOptions] = useState([
    { label: "New Arrivals", value: "newarrivals" },
    { label: "Best Selling", value: "bestselling" },
    { label: "Total Sales : Low to High", value: "lowhighsell" },
    { label: "Total Sales : High to Low", value: "highlowsell" },
    { label: "Price : High to Low", value: "highlow" },
    { label: "Price : Low to High", value: "lowhigh" },
    { label: "Top Customer Reviews", value: "highlowrev" },
  ]);

  const [rating, setrating] = useState([
    { label: "Hight to low", value: "highlowrev" },
    { label: "Low to high", value: "lowhighrev" },
  ]);

  const handleDelivery = (event) => {
    console.log(event.target.value);
    // let val = event.target.value;
    // setDelivery(val);
    // // setTimeout(() => {
    setDelivery(event.target.value);
    props.delivery({ deli: event.target.value });
    searchByFilter(event.target.value, minn, maxx, sortBy, rate);
    // }, 100);
  };

  const handleRate = (event) => {
    console.log("rate", event.target.value);
    // let val = event.target.value;
    // setSortBy(val);
    // setRate(val);
    // setTimeout(() => {
    setRate(event.target.value);
    props.rate({ rate: event.target.value });
    searchByFilter(delivery, minn, maxx, sortBy, event.target.value);
    // }, 100);
  };

  const handleSortBy = (event) => {
    let val = event.target.value;
    console.log("ghghgh", event.target.value);
    setSortBy(val);
    // setSortData(val);
    // setTimeout(() => {
    props.sortby({ sort: event.target.value });
    searchByFilter(delivery, minn, maxx, event.target.value, rate);
    // }, 100);
  };

  const handlePrice = async (event) => {
    let val = event.target.value;
    setPrice(val);
    let _min = 0;
    let _max = 0;
    if (val == "0-50") {
      _min = 0;
      _max = 50;
    } else if (val == "51-100") {
      _min = 51;
      _max = 100;
    } else if (val == "101-150") {
      _min = 101;
      _max = 150;
    } else if (val == "above151") {
      _min = 151;
      _max = 10000;
    } else {
      _min = 0;
      _max = 10000;
    }
    setminn(_min);
    setmaxx(_max);

    props.parentmin({ min: _min, max: _max });
    let data = {
      delivery: delivery ? delivery : "Free Delivery",
      min: _min,
      max: _max,
      shortby: sortBy ? sortBy : "",
    };
    console.log("data", props.pagevalue);
    console.log("min", _max);
    console.log("min", _min);
    console.log("min", sortBy);
    // if (Object.keys(userdata).length > 0) {
    //   let result = await HttpClient.requestData(
    //     "listProducts/" +
    //       userdata._id +
    //       "/" +
    //       props.pagevalue +
    //       "?currency=" +
    //       userdata.currency +
    //       "&delivery=" +
    //       "Free Delivery" +
    //       "&min=" +
    //       _min +
    //       "&max=" +
    //       _max +
    //       "&shortby=" +
    //       val,
    //     "GET"
    //   );
    //   console.log("resultfilter", result);
    //   if (result && result.status) {
    //     props.filterProduct(result.data.itemsList, result.data.paginator);
    //   } else {
    //     props.filterProduct(result.data.itemsList, result.data.paginator);
    //   }
    // } else {
    //   let result = await HttpClient.requestData(
    //     "listProducts/" +
    //       "91a953b1effa7e26d08fff8a/" +
    //       props.pagevalue +
    //       "?currency=" +
    //       "INR" +
    //       // eslint-disable-next-line no-useless-concat
    //       "&delivery=" +
    //       "Free Delivery" +
    //       "&min=" +
    //       _min +
    //       "&max=" +
    //       _max +
    //       "&shortby=" +
    //       val,
    //     "GET"
    //   );
    //   console.log("resultfilter", result);
    //   if (result && result.status) {
    //     props.filterProduct(result.data.itemsList, result.data.paginator);
    //   } else {
    //     props.filterProduct(result.data.itemsList, result.data.paginator);
    //   }
    // }

    // let result = await HttpClient.requestData("listProducts/" + userdata._id + "/" + props.pagevalue + "?currency=" + userdata.currency +
    //   "&delivery=" + "Free Delivery" + "&min=" + _min + "&max=" + _max + "&shortby=" + sortBy,
    //   "GET");
    // console.log("service", result);
    // if (result && result.status) {
    //   props.filterProduct(result.data.itemsList);
    // } else {
    //   props.filterProduct(result.data.itemsList);
    // }
    searchByFilter(delivery, _min, _max, sortBy, rate);
  };

  const searchByFilter = async (deli, min, max, shortby, rateby) => {
    console.log(min + "ff" + max);
    // let data = {
    //   currency: userdata.currency,
    //   delivery: deli,
    //   min: "",
    //   max: "",
    //   shortby: shortby,
    // };
    // console.log("data", data);

    if (Object.keys(userdata).length > 0) {
      let result = await HttpClient.requestData(
        "listProducts/" +
          userdata._id +
          "/" +
          props.pagevalue +
          `?currency=` +
          userdata.currency +
          `&delivery=` +
          deli +
          `&min=` +
          min +
          `&max=` +
          max +
          `&shortby=` +
          shortby +
          `&rateby=` +
          rateby,
        "GET"
      );
      console.log(
        "resultfilter",
        "listProducts/" +
          userdata._id +
          "/" +
          props.pagevalue +
          `?currency=` +
          userdata.currency +
          `&delivery=` +
          deli +
          `&min=` +
          min +
          `&max=` +
          max +
          `&shortby=` +
          shortby +
          `&rateby=` +
          rateby
      );
      if (result && result.status) {
        props.filterProduct(result.data.itemsList, result.data.paginator);
      } else {
        props.filterProduct(result.data.itemsList, result.data.paginator);
      }
    } else {
      // eslint-disable-next-line no-useless-concat
      let result = await HttpClient.requestData(
        "listProducts/" +
          "91a953b1effa7e26d08fff8a/" +
          props.pagevalue +
          `?currency=INR&delivery=` +
          deli +
          `&min=` +
          min +
          `&max=` +
          max +
          `&shortby=` +
          shortby,
        "GET"
      );
      console.log("resultfilter", result);
      if (result && result.status) {
        props.filterProduct(result.data.itemsList, result.data.paginator);
      } else {
        props.filterProduct(result.data.itemsList, result.data.paginator);
      }
    }
  };

  return (
    <div className="d-md-flex justify-content-between py-4 filter_part">
      <div className="d-md-flex justify-content-between">
        <div className="mr-lg-3 mr-0">
          <div className="form-group my-1">
            <select
              className="form-control"
              id="delivery"
              onChange={handleDelivery}
              value={delivery}
            >
              <option value=""> Delivery</option>
              <option value="Free Delivery"> Free Delivery </option>
              <option value="Paid Delivery"> Paid Delivery </option>
              {/* <option value="Ready to Dispatch">Ready to dispatch</option>
              <option value="1 business day"> 1 business day </option>
              <option value="1-4 Business days">1-4 Business days</option> */}
            </select>
          </div>
        </div>
        <div className="mr-lg-3 mr-0">
          <div className="form-group my-1">
            <select
              className="form-control"
              id="rate"
              onChange={handleRate}
              value={rate}
            >
              <option value=""> Rating </option>
              {/* <option value="lowhighrev"> Hight to low</option>
              <option value="highlowrev">Low to high</option> */}
              {rating.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="mr-lg-3 mr-0">
          <div className="form-group my-1">
            <select
              className="form-control"
              id="price"
              onChange={handlePrice}
              value={price}
            >
              <option value="">Select Price</option>
              <option value="0-50">$ 0 - $ 50</option>
              <option value="51-100">$ 51 - $ 100 </option>
              <option value="101-150"> $ 101 - $ 150</option>
              <option value="above151">more then $ 151</option>
            </select>
          </div>
        </div>
        <div className="mr-3">
          {" "}
          {/* <button
            type="button"
            className="btn budget_btn"
            onClick={() => setBudget(true)}
          >
            Edit Price
          </button> */}
        </div>
      </div>
      <div>
        <div className="mr-lg-3 mr-0">
          <div className="form-group my-1">
            {/* <h6 className="my-2">SortBy:</h6> */}
            <select
              className="form-control"
              id="sortOptions"
              onChange={handleSortBy}
              value={sortBy}
              // multiple={false}
            >
              <option value="">Products Sorting</option>
              {sortOptions.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      {/* <div
        className={budget ? "modal fade loginmodal show" : "modal fade"}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: budget ? "block" : "none" }}
      >
        <EditBudgetModal callBackBudget={callBackBudget} />
      </div> */}
    </div>
  );
};
export default ProductFilter;
