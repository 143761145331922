import React, { useState, useEffect } from "react";
import Detailsproduct from "../../Assets/Images/detailspro_pic.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "@tinymce/tinymce-react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../../Component/loader";
const CreateShop = () => {
  useEffect(() => {
    getshop();
  }, []);
  const [suspendstatus, setsuspendstatus] = useState("");
  const [name, setname] = useState("");

  const userdata = reactLocalStorage.getObject("userData");

  const [title, settitle] = useState("");

  const [tags, settags] = useState("");
  const [desc, setdesc] = useState("");
  const [personalization, setpersonalization] = useState("");

  const [banner, setbanner] = useState("");

  const [arr, setarr] = useState([]);

  const [shop, setshop] = useState("");

  const [shoparr, setshoparr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageValid, setimageValid] = useState(false);
  const [imageValid1, setimageValid1] = useState(false);

  // const [nameerror, setnameerror] = useState(false);

  // const [titleerror, settitleerror] = useState(false);

  // const [tagserror, settagserror] = useState(false);

  // const [descerror, setdescerror] = useState(false);

  // const [setperserror, setsetperserror] = useState(false);

  const onImageId = async (event) => {
    console.log("image size", event.target.files);
    // console.log("arr", arr);
    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = (event) => {
        const height = event.target.height;
        const width = event.target.width;
        console.log("height and width", height, width);
        if (parseInt(height) != 600 || parseInt(width) != 1500) {
          // alert("Height and Width must not exceed 100px. ");
          setbanner("");
          setarr([]);
          setimageValid(true);
          setTimeout(() => {
            setimageValid(false);
          }, 2000);
          return false;
        }
        // alert("Uploaded image has valid Height and Width.");
      };
    };
    setbanner(event.target.files[0]);
    setarr([
      {
        preview: URL.createObjectURL(event.target.files[0]),
      },
    ]);

    return true;
  };

  const onShopId = async (event) => {
    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = (event) => {
        const height = event.target.height;
        const width = event.target.width;
        console.log("height and width", height, width);
        if (parseInt(height) != 500 || parseInt(width) != 500) {
          // alert("Height and Width must not exceed 100px. ");
          setshop("");
          setshoparr([]);
          setimageValid1(true);
          setTimeout(() => {
            setimageValid1(false);
          }, 2000);
          return false;
        }
        // alert("Uploaded image has valid Height and Width.");
      };
    };
    setshop(event.target.files[0]);
    setshoparr([
      {
        preview: URL.createObjectURL(event.target.files[0]),
      },
    ]);
    return true;
  };

  const getshop = async () => {
    let data = {};
    console.log("object", data);
    let result = await HttpClient.requestData(
      "shop/" + userdata._id,
      "GET",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      if (result.data.status == false) {
        setsuspendstatus("suspend");
      } else {
        setsuspendstatus("");
        setname(result.data ? result.data.name : "");
        settitle(result.data ? result.data.title : "");
        settags(result.data ? result.data.tags : "");
        setdesc(result.data ? result.data.description : "");
        setpersonalization(result.data ? result.data.personalization : "");
        result.data
          ? setarr([
              {
                preview: HttpClient.IMG_URL + result.data.banner_img,
              },
            ])
          : setarr([]);
        result.data
          ? setshoparr([
              {
                preview: HttpClient.IMG_URL + result.data.shop_img,
              },
            ])
          : setshoparr([]);
      }
    } else {
    }
  };
  const save = async () => {
    if (
      name == "" ||
      title == "" ||
      tags == "" ||
      desc == "" ||
      personalization == ""
    ) {
      toast.error("Please Enter All Fields", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (arr.length == 0 || shoparr.length == 0) {
      toast.error("Please Enter Images", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // console.log("object")
      let data = new FormData();
      if (banner != "") {
        data.append("file1", banner);
      }

      if (shop != "") {
        data.append("file2", shop);
      }

      data.append("name", name);
      data.append("title", title);
      data.append("description", desc);
      data.append("userid", userdata._id);
      data.append("tags", tags);
      data.append("personalization", personalization);
      setLoading(true);
      let result = await HttpClient.fileUplode("shop", "POST", data);
      console.log("result", result);
      if (result && result.status) {
        setLoading(false);
        toast.success(result.message, {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getshop();
        setTimeout(() => {
          window.location.href = "/seller/shop";
        }, 2000);
      } else {
        toast.error(result.error[0].msg, {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="row create_shop mt-3">
          {/* <ToastContainer /> */}
          <div className="col-md-6 mx-auto">
            <div className="d-flex justify-content-between">
              <div>
                <h5>
                  Banner Image{" "}
                  <span
                    className="required"
                    style={{ color: "red", fontSize: "13px" }}
                  >
                    [1500 * 600]*
                  </span>
                </h5>
                {/* <img src={Detailsproduct} alt="img" /> */}
                {arr.map((item, index) => {
                  console.log("object", item);
                  return (
                    <>
                      <div className="img-wrap">
                        {/* <span ></span> */}
                        {/* <button className="close">&times;</button> */}
                        <img
                          className="avatar ml-3 img-fluid img-thumbnail"
                          key={index}
                          alt="img"
                          style={{ width: "54px", height: "54px" }}
                          src={item.preview}
                        />
                      </div>
                    </>
                  );
                })}
                {imageValid ? (
                  <p style={{ color: "red", fontSize: "13px" }}>
                    Height and Width must not exceed the given dimension
                  </p>
                ) : null}
              </div>

              <div className="choose_file my-4">
                <input
                  type="file"
                  className="input_file"
                  onChange={onImageId}
                />
                Choose File
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <h5>
                  Shop Image{" "}
                  <span
                    className="required"
                    style={{ color: "red", fontSize: "13px" }}
                  >
                    [500 * 500]*
                  </span>
                </h5>
                {/* <img src={Detailsproduct} alt="img" /> */}
                {shoparr.map((item, index) => {
                  console.log("object", item);
                  return (
                    <img
                      className="avatar ml-3 img-fluid img-thumbnail"
                      key={index}
                      alt="img"
                      style={{ width: "54px", height: "54px" }}
                      src={item.preview}
                    />
                  );
                })}
                {imageValid1 ? (
                  <p style={{ color: "red", fontSize: "13px" }}>
                    Height and Width must not exceed the given dimension
                  </p>
                ) : null}
              </div>

              <div className="choose_file my-4">
                <input type="file" className="input_file" onChange={onShopId} />
                Choose File
              </div>
            </div>
            <div>
              <div className="form-group mt-3">
                <label htmlFor="shop_name">
                  Shop Name{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shop_name"
                  placeholder="Please Enter Shop Name"
                  onChange={(val) => {
                    setname(val.target.value);
                    // if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                    //   setname(val.target.value);
                    // }
                  }}
                  value={name}
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="shop_title">
                  Shop Title{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shop_title"
                  placeholder="Please Enter Shop Title"
                  onChange={(val) => {
                    settitle(val.target.value);
                    // if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                    //   settitle(val.target.value);
                    // }
                  }}
                  value={title}
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="tags">
                  Tags{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="tags"
                  placeholder="Please Enter Tags"
                  onChange={(val) => {
                    settags(val.target.value);
                    // if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                    //   settags(val.target.value);
                    // }
                  }}
                  value={tags}
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="exampleFormControlTextarea1">
                  Description{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                {/* <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Please Enter Description"
              onChange={(val) => {
                setdesc(val.target.value);
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  setdesc(val.target.value);
                }
              }}
              value={desc}
            ></textarea> */}
                <Editor
                  apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                  value={desc}
                  onEditorChange={(newValue, editor) => {
                    setdesc(newValue);
                  }}
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="exampleFormControlTextarea1">
                  About Shop{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                {/* <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Please Enter Personalization"
              onChange={(val) => {
                setpersonalization(val.target.value);
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  setpersonalization(val.target.value);
                }
              }}
              value={personalization}
            ></textarea> */}
                <Editor
                  apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                  value={personalization}
                  onEditorChange={(newValue, editor) => {
                    setpersonalization(newValue);
                  }}
                />
              </div>
              {suspendstatus == "suspend" ? null : (
                <div className="text-center my-3">
                  <button
                    type="button"
                    className="btn changes_btn"
                    onClick={save}
                  >
                    Save Changes
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CreateShop;
