import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactStars from "react-rating-stars-component";

export default function RecentAct(props) {
  const review = props.data;
  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        {/* <img
          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"
          alt="img"
          className="rounded-circle"
          style={{ width: "46px", height: "46px" }}
        /> */}
        <div className="ml-3">
          <p className="text-dark mb-0">
            <Link to="/#"> Service Name: {review.service_name}</Link>
            
          </p>
          <p className="mb-0">
          <span className="text-dark">{review.comment}</span>
            {/* <i className="fa fa-heart mr-2 text-dark" aria-hidden="true"></i> */}
            <ReactStars
              count={5}
              // value={rating}
              value={review.rating}
              // onChange={ratingChanged}
              size={24}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
            <span className="text-muted">
              {moment(review.rev_date).format("ll")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
