import React from "react";

const DashboardActivity = (props) => {
  return (
    <div className="row seller_sec_card">
      {props.data.map((item, index) => {
        return (
          <div className="col-lg-4 col-md-6" key={index}>
            <div className="card service my-4">
              <div className="card-body">
                <div className="d-xl-flex justify-content-around">
                  <img src={item.img} alt="img" />
                  <div className="ml-lg-3">
                    <h5>{item.name}</h5>
                    <h6>{item.para}</h6>
                  </div>
                  <p className="ml-xl-3 text-dark">{item.status}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default DashboardActivity;
