import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Defaults/Footer";
import HttpClient from "../../utils/HttpClient";
import { ReactTitle } from "react-meta-tags";

const MoreProvider = () => {
  const [service, setService] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchService();
  }, []);
  const fetchService = async () => {
    console.log("hii");
    let result = await HttpClient.requestData("service", "GET");
    console.log("Service data", result);
    if (result && result.status) {
      let data = result.data.filter(
        (item) => item.name === "Astrology & Reading"
      );
      console.log("data", data);
      setService(data);
      console.log("Servicedata", service);
    } else {
    }
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }
  const loop = [1, 2, 3, 4, 5, 6];
  return (
    <div>
      <div className="provider_sec">
      <ReactTitle title="Astrology & Reading Services | Astrophy" />
        <div className="container-fluid" style={{ maxWidth: "1200px" }}>
          <div className="row my-4">
            <div className="col-md-12 text-center provide-services">
              <h1>We Provide Services</h1>
            </div>
          </div>

          <div className="row px-4">
            {service.length > 0
              ? service[0].service_data.map((item, index) => {
                return (
                  <div className="col-md-3 mb-3" key={index}>
                    <div className="card text-center service p-2 my-3">
                      <div className="card-body service_part">
                        <img
                          src={HttpClient.IMG_URL + item.image}
                          alt="img"
                        />
                        <h5 className="card-title my-3">{item.name}</h5>
                        <p className="card-text">
                          {item.description.substring(0, 85)}
                        </p>
                        {/* <Link to={"/services/" + item.id}> */}
                        <button
                          className="btn my-3 "
                          onClick={() =>
                            (window.location.href = "/services/" + nameToSlug(item.name) + "/" + item._id)
                          }
                        >
                          View More
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default MoreProvider;
