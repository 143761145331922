import React, { useState, useEffect } from "react";
import Detailsproduct from "../../Assets/Images/detailspro_pic.png";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
const MyBankDetails = () => {
  useEffect(() => {
    // getKyc();
  }, []);
  const [name, setname] = useState("");
  const [account, setaccount] = useState("");
  const [ifcCode, setifcCode] = useState("");
  const [bankName, setbankName] = useState("");

  const userData = reactLocalStorage.getObject("userData");

  // const getKyc = async () => {
  //   let result = await HttpClient.requestData("Kyc/" + userData._id, "GET");
  //   console.log("get kyc", result);
  //   if (result && result.status) {
  //     setname(result.data.length > 0 ? result.data[0].name : "");
  //     setaccount(result.data.length > 0 ? result.data[0].account : "");
  //     setifcCode(result.data.length > 0 ? result.data[0].iifsc : "");
  //     setbankName(result.data.length > 0 ? result.data[0].bank : "");
  //   } else {
  //   }
  // };

  const save = async () => {
    if (name == "" || account == "" || bankName == "" || ifcCode == "") {
      toast.error("Please Enter All Fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let sendData = {
        name: name,
        seller_id: userData._id,
        account: account,
        iifsc: ifcCode,
        bank: bankName,
      };
      console.log("data", sendData);
      let result = await HttpClient.requestData("Kyc", "POST", sendData);
      console.log("send kyc", result);
      if (result && result.status) {
        toast.success('Bank details are submited successfully', {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setname("");
        setaccount("");
        setifcCode("");
        setbankName("");
        // getKyc();
      } else {
        toast.error(result.error[0].msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <div className="row create_shop mt-3">
      <ToastContainer />
      <div className="col-md-12 mx-auto">
        <div className="col-md-12 text-center">
          <h1>My Bank Details</h1>
        </div>
        <div className="col-md-8 mx-auto">
          <div className="form-group mt-3">
            <label htmlFor="1">
              Name{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id="1"
              placeholder="Please Enter Name"
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  setname(val.target.value);
                }
              }}
              value={name}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="2">
              Account Number{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id="2"
              placeholder="Please Enter Account Number"
              onChange={(val) => {
                if (val.target.value.match("^[0-9]*$") != null) {
                  setaccount(val.target.value);
                }
              }}
              value={account}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="3">
              Bank Name{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id="3"
              placeholder="Please Enter Bank Name"
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  setbankName(val.target.value);
                }
              }}
              value={bankName}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="4">
              IFSC Code{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id="4"
              placeholder="Please Enter IFSC Code"
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z0-9 ]*$") != null) {
                  setifcCode(val.target.value);
                }
              }}
              value={ifcCode}
            />
          </div>

          <div className="text-center my-3">
            <button type="button" className="btn changes_btn" onClick={save}>
              Send Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyBankDetails;
