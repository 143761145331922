import React, { useState } from "react";
import Provider_name from "../../Assets/Images/provider_name.png";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import EmptyCart from "../EmptyCart/EmptyCart";
import { ToastContainer, toast } from "react-toastify";
const CartList = (props) => {
  console.log("props", props.data);
  const remove = async (item) => {
    let data = {};
    let result = await HttpClient.requestData(
      "servicecart/" + item._id,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      props.success(result.data);
    } else {
    }
  };
  const userData = reactLocalStorage.getObject("userData");
  const wishlist = async (item) => {
    // let data = {
    //   user_id: userData._id,
    //   serv_id: item._id,
    //   servicename: item.servicename,
    //   price: item.price,
    //   image: item.image[0],
    //   seller_id: item.user_id,
    //   currency: userData.currency,
    // };
    console.log("data", item._id);
    let result = await HttpClient.requestData(
      "save-for-later/" + item._id,
      "DELETE"
    );
    console.log("result", result);
    if (result && result.status) {
      toast.success("Service added to Wishlist", {
        position: "bottom-right",
        autoClose: 3000,
      });
      props.success(result.data);
    } else {
      toast.error("Item already added please remove or checkout the service", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const Send = async (item, index) => {
    let data = {
      addn_note: props.data[index].addn_note,
    };
    let result = await HttpClient.requestData(
      "note-with-service/" + item._id,
      "PUT",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      toast.success("Note added successfully", {
        position: "bottom-right",
        autoClose: 3000,
      });
      props.setarr(props.data[index].addn_note, index);
    } else {
    }
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }

  const imgStyle = {
    width: "150px",
    height: "150px",
    borderRadius: "6px",
    objectFit: "cover",
    objectPosition: "top",
  };
  return (
    <div className="col-md-8">
      <ToastContainer />
      {props.data.length > 0 ? (
        props.data.map((item, index) => {
          return (
            <div className="card cart_box my-4" key={index}>
              <div className="row card-body p-3 no-gutters">
                <div
                  className="col-md-2"
                  onClick={() => {
                    window.location.href = "/service-details/" + nameToSlug(item.servicename) + "/" + item.serv_id;
                  }}
                >
                  <img
                    src={HttpClient.IMG_URL + item.image}
                    alt="img"
                    style={imgStyle}
                  />
                </div>
                
                <div className="col-md-7 px-3">
                  <h5>{item.servicename}</h5>
                  <div className="d-flex">
                <img
                    className="mr-2 rounded-circle"
                    src={item.shop_data ? (HttpClient.IMG_URL + item.shop_data[0].shop_img) : ""}
                    alt="service_img"
                    style={{
                      width: "100%",
                      height: "25px",
                      maxWidth: "25px",
                    }}
                  />
                <p style={{fontSize : "15px"}}>
                  {item.shop_data 
                    ? item.shop_data[0].name
                    : ""}
                </p>
                </div>
                  <p>{item.service_data[0].details.replace(/<[^>]+>/g, "")}</p>
                  {item.type ? (
                    <div className="mt-4">
                      <div className="d-flex ">
                        <div>
                          <img src={Provider_name} alt="img" />
                        </div>
                        <div>
                          <h4>T Boutique</h4>
                          <p>Owner of TarotBoutique555</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="form-group form-check ">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      This order is a gift
                    </label>
                    <p>Prices will not be shown on packing slip</p>
                  </div> */}
                </div>
                
                <div className="col-md-3">
                  <div className="d-flex justify-content-between">
                    {" "}
                    <button
                      type="button"
                      className="btn side_btn"
                      onClick={() => wishlist(item)}
                    >
                      <p>Save For Later</p>
                    </button>
                    <button
                      type="button"
                      className="btn side_btn"
                      onClick={() => remove(item)}
                    >
                      <p>Remove</p>
                    </button>
                  </div>
                  <h6>
                    {/* ₹  */}
                    {userData.currency == "INR"
                      ? "₹"
                      : userData.currency == "CAD"
                      ? "$"
                      : userData.currency == "USD"
                      ? "$"
                      : userData.currency == "	GBP"
                      ? "£"
                      : userData.currency == "EUR"
                      ? "€"
                      : "₹"}
                    {item.price.toFixed(2)}
                  </h6>
                </div>
              </div>
              <div className="row card-body px-4 ">
                <div className="col-md-2"></div>
                <div className="col-md-10">
                  <div className="form-group ">
                    <label htmlFor="exampleFormControlTextarea1">
                      Add additional notes
                    </label>
                    <div className="d-flex justify-content-start">
                    <textarea
                      className="form-control my-2 w-100"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      // defaultValue={""}
                      style={{ marginTop: "-10px" }}
                      // defaultValue={item.description}
                      onChange={(val) => {
                        // console.log(item.target.value);
                        // props.data[index].notes = item.target.value;
                        props.setarr(val.target.value, index);
                        // props.setarr(index, val.target.value);
                      }}
                      value={item.addn_note}
                    />
                    <button
                        type="button"
                        className="btn side_btn"
                        onClick={() => Send(item, index)}
                        style={{ width: "100px" }}
                      >
                        Save
                      </button>
                      </div>
                  </div>
                  {/* <div className="text-right">
                    <ul>
                      <li>
                        {" "}
                        <i className="fas fa-circle  mr-2"></i>Ready to dispatch
                        in 1–3 business days from United States
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyCart />
      )}
    </div>
  );
};
export default CartList;
