import React, { useEffect, useState } from "react";
import Faq from "../Home/Faq/Faq";
import Footer from "../../Defaults/Footer";
import HttpClient from "../../utils/HttpClient";
import validator from "validator";
import Warning from "../../Component/Warning";
import Success from "../../Component/Success";
import { ReactTitle } from "react-meta-tags";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import bgBlog from "../../Assets/Images/blogBg.jpg";

const CustomerService = () => {
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [question, setquestion] = useState("");
  const [phoneError, setphoneError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [emailvalid, setemailvalid] = useState(false);
  const [isVerifiedRecaptcha, setisVerifiedRecaptcha] = useState(false);
  const [contactUs, setcontactUs] = useState("");
  const [tollfree, settollfree] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchContactInfo();
  }, []);

  const userTyping = async (e) => {
    if (e.keyCode === 13) {
      submitQuestion();
    }
  };
  const fetchContactInfo = async () => {
    let result = await HttpClient.requestData("allinfo", "POST");
    console.log("allinfo -- --", result);
    if (result && result.status) {
      setcontactUs(result.data[0].contactnumber);
      settollfree(result.data[0].tollfree);
    } else {
      setcontactUs("");
      settollfree("");
    }
  };

  const submitQuestion = async () => {
    if (phone == "" && email == "" && question == "") {
      toast.error("Please enter all field", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (phone == "") {
      toast.error("Please enter phone", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (email == "") {
      toast.error("Please enter email", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (question == "") {
      toast.error("Please enter question", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (emailvalid == true) {
      toast.error("Please enter valid email", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let sendData = {
        email: email,
        heading: phone,
        qstn: question,
      };
      let result = await HttpClient.requestData(
        "createCservice",
        "POST",
        sendData
      );
      // console.log("submit question",result);
      if (result && result.status) {
        toast.success(
          "Thank you for contacting us, we will reach back to you in a short time.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setquestion("");
        setemail("");
        setphone("");
      } else {
        toast.error("Error in Adding Question", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const regex = /^[ A-Za-z0-9_!@./*$%()#&+-]*$/;
  // /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
  function onChange(value) {
    console.log("Captcha value:", value);
    setisVerifiedRecaptcha(true);
  }
  return (
    <div className="Customer-service feedback">
      <ToastContainer />
      <ReactTitle title="Astrophy | Customer Service" />
      <div className="container-fluid">
        <div
          className="bGimg"
          style={{
            background: `url(${bgBlog})`,
          }}
        >
          <div className="row">
            <div className="col-md-12 text-center my-4">
              <h2>Customer Service</h2>
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-4" style={{ background: "rgba(79, 25, 99, 1)" }}>
          <div className="col-md-6 m-auto ">
            <div className="row">
              <div className="col-md-6 ">
                <div>
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#fff" }}>
                      Call Back to this Number
                    </h5>
                    <h6
                      className="card-subtitle mb-2"
                      style={{ color: "#fff" }}
                    >
                      {contactUs}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                <div>
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#fff" }}>
                      Toll-Free Number
                    </h5>
                    <h6
                      className="card-subtitle mb-2 "
                      style={{ color: "#fff" }}
                    >
                      {tollfree}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8  my-4 m-auto">
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(val) => {
                    if (val.target.value.match("^[0-9]*$") != null) {
                      setphone(val.target.value);
                      if (val.target.value.length > 10) {
                        setphoneError(true);
                      } else {
                        setphoneError(false);
                      }
                    }
                  }}
                />
              </div>
              <div className="col">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(val) => {
                    setemail(val.target.value);
                    if (val.target.value == "") {
                      setemailError(true);
                    } else {
                      setemailError(false);

                      if (validator.isEmail(email)) {
                        setemailvalid(false);
                      } else {
                        setemailvalid(true);
                      }
                    }
                  }}
                  onKeyUp={(e) => userTyping(e)}
                />
                {emailError ? (
                  <Warning warning="Please Enter Email" />
                ) : emailvalid ? (
                  <Warning warning="Please Enter Valid Email" />
                ) : phoneError ? (
                  <Warning warning="Please Enter Phone Less than 10 Digit" />
                ) : null}
              </div>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="exampleFormControlTextarea1">Any Question</label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={5}
                placeholder="Write Here..."
                value={question}
                onChange={(val) => {
                  if (val.target.value.match(regex) != null) {
                    setquestion(val.target.value);
                  }
                }}
              />
            </div>
            <div className="form-group mt-3">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={onChange}
              />
            </div>
            <div className="text-center my-4">
              <button
                className="btn submit_btn"
                onClick={submitQuestion}
                disabled={!isVerifiedRecaptcha}
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        {/* <div className="row my-5">
          <div className="col-md-12 text-center">
            <h2>FAQ</h2>
          </div>
          <div className="col-md-8 answer my-5 m-auto">
            <Faq />
            <div className="text-center my-5">
              <button href="#" className="btn mb-4  ">
                Load More
              </button>
            </div>
          </div>
        </div> */}
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default CustomerService;
