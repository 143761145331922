import React, { useState, useEffect } from "react";
import HttpClient from "../../../../src/utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

export default function ServiceListing(props) {
  const [Servicelist, setServicelist] = useState([]);
  const userData = reactLocalStorage.getObject("userData");

  useEffect(() => {
    if (reactLocalStorage.getObject("userData").type == "Seller") {
      fetchServiceNew()
    }
    else {
      fetchService();
    }
  }, []);


  const fetchServiceNew = async () => {
    let data = {
      self_id: reactLocalStorage.getObject("userData")._id,
      buyer_id: props.sellerdata.userId,

    };
    console.log("data", data);
    let result = await HttpClient.requestData(
      "buy-history-of-buyer",
      "POST",
      data
    );
    console.log("Service", result);
    if (result && result.status) {
      setServicelist(result.purchase_data);
      console.log("Servicedata", Servicelist);
    } else {
    }
  };
  const fetchService = async () => {
    let data = {
      user_id: reactLocalStorage.getObject("userData")._id,
      seller_id: props.sellerdata.userId,
    };
    console.log("data", data);
    let result = await HttpClient.requestData(
      "buy-history-from-seller",
      "POST",
      data
    );
    console.log("Service", result);
    if (result && result.status) {
      setServicelist(result.purchase_data);
      console.log("Servicedata", Servicelist);
    } else {
    }
  };
  return (
    <div className="modal-dialog mt-5">
      <div
        className="modal-content border-0"
        style={{ boxShadow: "0px 34px 100px rgb(0 0 0 / 20%)" }}
      >
        <div className="listing_body">
          <button
            className="listclose_btn"
            onClick={() => props.servicelistcallback(false)}
          >
            <i className="fa fa-times"></i>
          </button>
          <h3 className="text-center my-3">Service Listing here</h3>
          <ul
            className="list-group"
            style={{ height: "500px", overflowY: "auto" }}
          >
            {/* list group item */}
            {Servicelist.length > 0 ? (
              Servicelist.map((item, index) => {
                // const formatedAbout = new DOMParser().parseFromString(item.service_data.details, 'text/html');

                // document.getElementById("contentDiv").innerHTML = formatedAbout.body.innerHTML;
                return (
                  <li className="list-group-item p-0">
                    <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                      <div className="media-body order-2 order-lg-1">
                        <h5 className="mt-0 font-weight-bold mb-2">
                          {item.service_data.name}
                        </h5>
                        <p className="font-italic text-muted mb-0 small">
                          {item.service_data.details.replace(/<[^>]+>/g, "")}
                        </p>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h6 className="font-weight-bold my-2">
                            {userData.currency == "INR"
                              ? "₹"
                              : userData.currency == "CAD"
                                ? "$"
                                : userData.currency == "USD"
                                  ? "$"
                                  : userData.currency == "	GBP"
                                    ? "£"
                                    : "€"}{" "}
                            {item.service_data.price}
                          </h6>
                        </div>
                      </div>
                      <img
                        src={
                          HttpClient.IMG_URL +
                          item.service_data.image[0]
                        }
                        alt="image"
                        width={100}
                        height={100}
                        style={{ objectFit: "contain" }}
                        className="ml-lg-3 order-1 order-lg-2 border p-1"
                      />
                    </div>
                  </li>
                );
              })
            ) : (
              <li>
                <h3 className="list-group text-danger text-center">
                  No Service Found
                </h3>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
