import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Details from "./Details";
import Highlight from "./Highlight";
import ReviewSec from "./ReviewSec";
import Pagination from "../Components/Pagination";
import Loader from "../../Component/loader";
import OtherService from "../Components/OtherService";
import Footer from "../../Defaults/Footer";
import EmptyService from "../EmptyProduct/EmptyService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { ReactTitle } from "react-meta-tags";
import RelatedService from "./RelatedService";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import bgBlog from "../../Assets/Images/blogBg.jpg";
import { Helmet } from "react-helmet";
const Index = (props) => {
  const userData = reactLocalStorage.getObject("userData");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchService();
  }, []);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { id } = useParams();
  console.log(id);

  const [service, setService] = useState({});
  const [reviewlist, setreviewlist] = useState([]);

  const [reviewcount, setreviewcount] = useState(0);

  const [salescount, setsalescount] = useState(0);
  const [catid, setcatid] = useState("");
  const [subcatId, setsubcatId] = useState("");
  const [subcatName, setsubcatName] = useState("");
  const [showReview, setshowReview] = useState(false);

  const fetchService = async () => {
    console.log("idd", id);
    if (Object.keys(userData).length > 0) {
      setLoading(true);
      let result = await HttpClient.requestData(
        "shop/view-shopservice/" + id + "?currency=" + userData.currency,
        "GET"
      );
      console.log("servicefetchsingle", result);
      if (result && result.status) {
        if (result.data[0].status) {
          getreview(result.data[0]._id);
          getsalescount(result.data[0]._id);
          setService(result.data[0]);
          setcatid(result.data[0].category_id);
          setsubcatId(result.data[0].subcategory_id);
          setsubcatName(result.data[0].subcat_name);
        } else {
          getreview(result.data[0]._id);
          getsalescount(0);
          setService({});
          setcatid("");
          setsubcatId("");
        }

        setTimeout(() => {
          setLoading(false);
        }, 1);
      } else {
      }
    } else {
      setLoading(true);
      let result = await HttpClient.requestData(
        "shop/view-shopservice/" + id + "?currency=" + "INR",
        "GET"
      );
      console.log("servicefetchsingle", result);
      if (result && result.status) {
        if (result.data[0].status) {
          getreview(result.data[0]._id);
          getsalescount(result.data[0]._id);
          setService(result.data[0]);
          setcatid(result.data[0].category_id);
          setsubcatId(result.data[0].subcategory_id);
        } else {
          getreview(result.data[0]._id);
          getsalescount(0);
          setService({});
          setcatid("");
          setsubcatId("");
        }

        setTimeout(() => {
          setLoading(false);
        }, 1);
      } else {
      }
    }
  };

  const getreview = async (id) => {
    // setLoading(true);
    let sendData = {};
    console.log("object", sendData);
    let result = await HttpClient.requestData(
      "servicereview/" + id,
      "GET",
      sendData
    );
    console.log("review", result);
    if (result && result.status) {
      setreviewlist(result.data);
      setreviewcount(result.data.length);
      // let sum = 0;
      // let avg = 0;
      // result.data.map((itemm, index) => {
      //   return (sum = Number(sum) + Number(itemm.rating));
      // });
      // avg = Number(sum) / Number(result.data.length);
      // setavg(avg);
    } else {
    }
  };

  const getsalescount = async (id) => {
    // setLoading(true);
    let sendData = {};
    console.log("object", sendData);
    let result = await HttpClient.requestData(
      "sales-count/" + id,
      "GET",
      sendData
    );
    // console.log("sales-count", result.number);
    if (result && result.status) {
      setsalescount(result.number);
    } else {
    }
  };

  const showReviewData = (val) => {
    console.log("showReviewData", val);
    setshowReview(val);
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    // console.log('name',name)
    let strReplace = lName.replaceAll(" ", "-");
    return strReplace;
  };
  return (
    <>
      <Helmet>
        <title>{service.name}</title>
        <meta property="og:title" content={service.name} />
        <meta property="og:description" content={service.details} />
      </Helmet>
      <div>
        <div className="wrapper">
          <ReactTitle title={service.name + " | " + " Astrophy"} />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div
              className="bGimg_service-details"
              style={{
                background: `url(${bgBlog})`,
              }}
            >
              <div className="container-fluid" style={{ maxWidth: "1200px" }}>
                <div className="row px-md-5 test" style={{ padding: "16px 0" }}>
                  <div className="col-md-12 back_to">
                    {/* <Link to="/services">
                    <p>
                      {" "}
                      <i className="fas fa-chevron-left mr-2"></i>Back to Services
                    </p>
                  </Link> */}
                    <Link
                      to={
                        "/services/" + nameToSlug(subcatName) + "/" + subcatId
                      }
                    >
                      <p>
                        {" "}
                        <i className="fas fa-chevron-left mr-2"></i>Back to
                        Services
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="bGimg_service-details_head">
                  <h2>Service Details</h2>
                </div>
              </div>
            </div>
            <section>
              {Object.keys(service).length ? (
                <div className="container-fluid" style={{ maxWidth: "1200px" }}>
                  <Details
                    service={service}
                    reviewcount={reviewcount}
                    salescount={salescount}
                    showReviewData={showReviewData}
                  />
                </div>
              ) : (
                <EmptyService />
              )}
            </section>
            <section>
              <div className="container-fluid" style={{ maxWidth: "1200px" }}>
                {Object.keys(service).length ? (
                  <div className="row p-md-4">
                    <div className="col-md-12">
                      <Highlight service={service} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <p>No description</p>
                    {/* <p>{props.service.details}</p> */}
                  </div>
                )}
              </div>
            </section>
            {showReview ? (
              <section>
                <div className="container-fluid" style={{ maxWidth: "1200px" }}>
                  <div className="row p-md-4">
                    <div className="col-md-12">
                      <ReviewSec
                        data={reviewlist}
                        reviewcount={reviewcount}
                        serv_id={id}
                      />
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            <section className="container">
              <div className="row">
                <div className="col-md-12 ">{/* <Pagination /> */}</div>
              </div>
            </section>
            {/* <section style={{ position: "relative" }}>
            <div className="container-fluid" style={{ maxWidth: "1200px" }}>
              <div className="row px-md-4 pb-md-4">
                <div className="col-md-12 Related">
                  <h4>Related Services</h4>
                </div>
              </div>
              <RelatedService service_id={id} catid={catid} />
            </div>
          </section> */}
            <section>
              <div className="container-fluid" style={{ maxWidth: "1200px" }}>
                <div className="row">
                  <div className="col-md-12 mt-5">
                    <div className="service_head">
                      <h6>Reading</h6>
                      <h3 className="mt-4">Other Services</h3>
                    </div>
                  </div>
                </div>
                <OtherService />
              </div>
            </section>{" "}
            <section>
              <Footer />
            </section>
          </>
        )}
      </div>
    </>
  );
};
export default Index;
