import React, { useState, useEffect } from "react";
import Avatar from "../../Assets/Images/avatar.png";
import Activity from "../../Views/Seller/Activity";
import shop1 from "../../Assets/Images/service_list 1.png";
import Cat4 from "../../Assets/Images/cast4.png";
import { Link } from "react-router-dom";
import ReviewList from "../../Views/ServiceDetails/ReviewList";
import EditSellerProfile from "./EditSellerProfile";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../../Component/loader";
import { MDBDataTableV5 } from "mdbreact";
import { Table } from "reactstrap";

const Index = () => {
  const [dataa, setdata] = useState([]);
  const [edit, setEdit] = useState(false);
  const [user, setuser] = useState({});
  const [loading, setLoading] = useState(false);

  const [totalEarning, settotalEarning] = useState(0);
  const [totalSettle, settotalSettle] = useState(0);
  const [pending, setpending] = useState(0);
  const [wallet, setwallet] = useState(0);
  const [yearlytotal, setyearlytotal] = useState(0);

  const [monthlytotal, setmonthlytotal] = useState(0);
  const [weeklytotal, setweeklytotal] = useState(0);
  const [yesterdaytotal, setyesterdaytotal] = useState(0);
  const [todaytotal, settodaytotal] = useState(0);
  const [refund, setrefund] = useState(0);

  const userdata = reactLocalStorage.getObject("userData");
  useEffect(() => {
    fetchData();
    fetchcount();
    getEarningshistory();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    let result = await HttpClient.requestData("seller/" + userdata._id, "GET");
    console.log("Data seller", result);
    if (result && result.status) {
      setuser(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 1);
      // console.log("setuser", user);
    } else {
    }
  };

  const fetchcount = async () => {
    let result = await HttpClient.requestData(
      "getSellersettlement/" + userdata._id,
      "GET"
    );
    console.log("Count", result);
    // if (result && result.status) {
    settotalEarning(result.total_earnings ? result.total_earnings : 0);

    // settotalSettle(result.earning_settled);
    settotalSettle(result.earning_settled ? result.earning_settled : 0);
    setpending(result.pending_settlement ? result.pending_settlement : 0);
    setwallet(result.claimable_earnings ? result.claimable_earnings : 0);
    setrefund(result.service_refund_amt ? result.service_refund_amt : 0);
    // setuser(result.data);

    console.log("setuser", result.total_earnings);
    // } else {
    // }
  };
  const getEarningshistory = async () => {
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "getGraphcomission/" + userdata._id,
      "GET"
    );
    console.log("withdraw-history", result);
    if (result && result.status) {
      setyearlytotal(result.yearTotal);
      setweeklytotal(result.sevendaysTotal);
      setmonthlytotal(result.monthTotal);
      setyesterdaytotal(result.yestardayTotal);
      settodaytotal(result.todaysTotal);
    } else {
    }
  };

  return (
    <div className="Seller_Profile">
      {edit ? (
        <div>
          <div className="row my-2">
            <div className="col-md-12">
              <div onClick={() => setEdit(false)}>
                <a href="#" className="text-dark">
                  <i className="fas fa-chevron-left mr-2"></i>
                  Back
                </a>
              </div>
            </div>
          </div>
          <EditSellerProfile />
        </div>
      ) : loading ? (
        <Loader />
      ) : (
        <div>
          <div className="row my-3">
            <div className="col-md-8 sell_profile_head p-3 mx-auto">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <img src={Avatar} alt="img" />
                  <div className="px-3 py-2 ">
                    <h5>{user.firstName + " " + user.lastName}</h5>

                    <p>{user.email}</p>
                  </div>
                </div>
                <div className="" onClick={() => setEdit(true)}>
                  <button className="btn my-3" type="button">
                    <i className="fas fa-pencil-alt text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="row">
                <div className="col-md-6 my-2">
                  <div className="balance_card">
                    <div className="card-body text-center">
                      <h6>Total Earning</h6>
                      <span>{totalEarning}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="balance_card">
                    <div className="card-body text-center">
                      <h6>Earning Settled </h6>
                      <span>{totalSettle}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="balance_card">
                    <div className="card-body text-center">
                      <h6>Pending </h6>
                      <span>{pending}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="balance_card">
                    <div className="card-body text-center">
                      <h6>Claimable Amount</h6>
                      <span>{wallet}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="balance_card">
                    <div className="card-body text-center">
                      <h6>Refund Amount</h6>
                      <span>{refund}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12 text-center">
                  <h1>Total Income History</h1>
                </div>
                <br />
                <br />
                <div className="col-md-10 mx-auto">
                  <Table border>
                    <thead>
                      <tr>
                        <th>Yearly Total</th>
                        <th>Monthly Total</th>
                        <th>Weekly Total</th>
                        <th>Yesterday Total</th>
                        <th>Today Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{yearlytotal}</td>
                        <td>{monthlytotal}</td>
                        <td>{weeklytotal}</td>
                        <td>{yesterdaytotal}</td>
                        <td>{todaytotal}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row my-4">
            <div className="col-md-8 offset-md-2">
              <Activity data={Item} />
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="d-flex justify-content-between">
                <h4>User Review</h4>
                <div className="">
                  <Link to="/..">View More</Link>
                </div>
              </div>
              <ReviewList data={loop} />
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};
export default Index;
