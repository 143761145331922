import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import validator from "validator";
import HttpClient from "../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import Warning from "../Component/Warning";
import Success from "../Component/Success";
import VerifiedModal from "./VerifiedModal";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin } from 'react-google-login';
import { useLocation } from 'react-router-dom';

const AuthModal = (props) => {
  const location = useLocation();
  const currentUrl = location;
  const customId = "custom-id-yes";
  const [signup, setSignup] = useState(false);
  const [signin, setSignin] = useState(false);
  const [forgot, SetForgot] = useState(false);
  const [emailsend, setEmailsend] = useState(false);
  const [email, setemail] = useState("");
  const [forgetEmail, setforgetEmail] = useState("");
  const [password, setpassword] = useState("");
  const [newEmail, setnewEmail] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [validConfirmPassword, setvalidConfirmPassword] = useState(false);
  const [fname, setfname] = useState("");

  const [lname, setlname] = useState("");

  const [phone, setphone] = useState("");

  const [emailerror, setemailerror] = useState(false);

  const [emailvalid, setemailvalid] = useState(false);
  const [passvalid, setpassvalid] = useState(false);
  const [passerror, setpasserror] = useState(false);

  const [fnameerror, setfnameerror] = useState(false);

  const [phoneerror, setphoneerror] = useState(false);

  const [lnameerror, setlnameerror] = useState(false);
  const [warning, setwarning] = useState(false);
  const [warningg, setwarningg] = useState(false);
  const [verify, setVerify] = useState(false);
  const [success, setsuccess] = useState(false);

  const [successs, setsuccesss] = useState(false);
  const [hidden, sethidden] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectcountry, setselectcountry] = useState("");
  const [country, setcountry] = useState([]);
  const [currency, setcurrency] = useState("");
  const [currencyTyp, setcurrencyTyp] = useState([
    {
      name: "INR",
    },
    {
      name: "USD",
    },
    {
      name: "GBP",
    },
    {
      name: "EUR",
    },
    {
      name: "CAD",
    },
    // {
    //   name: "cdd",
    // },
  ]);
  const [currencyvalid, setcurrencyvalid] = useState("");
  const [countryvalid, setcountryvalid] = useState("");

  useEffect(() => {}, []);

  // const usePathname = () => {
  //   const location = useLocation();
  //   return location.pathname;
  // }

  const getCountry = () => {
    let response = axios
      .get("https://countriesnow.space/api/v0.1/countries/capital")
      .then((response) => {
        console.log("response country", response);
        if (response.data.data.length > 0) {
          let arr = [];
          response.data.data.forEach((item) => {
            let obj = {
              value: item.name,
              label: item.name,
            };
            arr.push(obj);
          });
          console.log(arr);
          setcountry(arr);
          console.log("country", country);
        }
      });
  };

  const closeverify = (val) => {
    setVerify(val);
  };
  const userTyping = async (e) => {
    if (e.keyCode === 13) {
      handleSignup();
    }
  };

  const signinTyping = async (e) => {
    if (e.keyCode === 13) {
      handleSignin();
    }
  };

  const toggleShow = async () => {
    sethidden(!hidden);
    // console.log("object",hidden)
  };
  const handleSignin = async () => {
    if (email != "" && password != "") {
      let sendData = {
        email: email,
        password: password,
      };
      console.log("object", sendData);
      let result = await HttpClient.requestData("login", "POST", sendData);
      console.log("result", result);
      if (result && result.status) {
        console.log("result", result);
        setwarning(false);
        setwarningg(false);

        reactLocalStorage.setObject("userData", result.data);
        reactLocalStorage.setObject("loginstatus", true);
        reactLocalStorage.setObject("loginToken", result.data.token);
        setsuccess(true);
        props.callBackAuth(false);

        setemail("");
        setpassword("");

        setTimeout(function () {
          // window.location.href = "/dashboard";
          window.location.href = currentUrl.pathname;
        }, 1);
      } else {
        setwarning(true);
      }
    } else {
      setwarningg(true);
    }
  };
  const handleSignup = async () => {
    let patternn =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;

    let passresult = patternn.test(password);
    // && phone=="" && cpassword=="" && password=="" && email==""
    if (fname == "") {
      setfnameerror(true);
    } else if (lname == "") {
      setlnameerror(true);
    }
    //  else if (phone == "") {
    //   setphoneerror(true);
    // }
    else if (email == "") {
      setemailerror(true);
    } else if (validator.isEmail(email) == false) {
      console.log("validate", validator.isEmail(email));
      setemailvalid(true);
    } else if (password == "") {
      setpasserror(true);
    } else if (passresult !== true) {
      setpassvalid(true);
    } else if (selectcountry == "") {
      setcountryvalid(true);
    } else if (currency == "") {
      setcurrencyvalid(true);
    } else {
      let sendData = {
        firstName: fname,
        lastName: lname,
        // phone: phone,
        email: email,
        password: password,
        country: { label: selectcountry, value: selectcountry },
        currency: currency,
        device_type: "Web",
      };
      console.log("object", sendData);

      let result = await HttpClient.requestData("register", "POST", sendData);
      console.log("result", result);
      if (result && result.status) {
        setwarning(false);

        setsuccesss(true);
        setfname("");
        setlname("");

        setpassword("");
        setemail("");
        setSignin(true);
        setSignup(false);
        setVerify(true);
      } else {
        setwarning(true);
      }
    }
  };

  const handleChangeCountry = (selectedOption) => {
    console.log(selectedOption);
    let selectCountry = selectedOption.value
    console.log(selectCountry);
    setSelectedOption(selectedOption)
    setselectcountry(selectCountry)
  }

  const closemodal = () => {
    props.callBackAuth(false);

    setfname("");
    setlname("");
    setemail("");
    setpassword("");
    setemailerror(false);

    setemailvalid(false);
    setpassvalid(false);
    setpasserror(false);

    setfnameerror(false);

    setphoneerror(false);

    setlnameerror(false);

    setwarning(false);
    setwarningg(false);

    setsuccess(false);

    setsuccesss(false);
    SetForgot(false);
  };

  const sendEmail = async() => {
    let data = {
      email: forgetEmail
    }
    console.log("data",data);
    let result = await HttpClient.requestData("checkEmail", "POST", data);
    console.log("checkEmail",result);
    if (result && result.status) {
      toast.success("Please check you mail to change password", {
        position: "bottom-right",
        autoClose: 3000,
        toastId: customId,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      sendLink(result.data[0])
      reactLocalStorage.setObject("checkEmail",result.data[0].email);
      // setEmailsend(true); 
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const sendLink = async(dataa) => {
    let data = {
      email: dataa.email,
      links: "https://astrophy.com/change-password/" + dataa._id
      // links: "http://localhost:3000/change-password/" + dataa._id
    }
    console.log("link data",data);
    let result = await HttpClient.requestData("linkSend", "POST", data);
    console.log("linkSend",result);
    if (result && result.status) {
      props.callBackAuth(false);
      // setEmailsend(true); 
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 1000,
        toastId: customId,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const changePassword = async() => {
    let data = {
      email: newEmail,
      password: newPassword,
    }
    let result = await HttpClient.requestData("passwordChange", "POST", data);
    console.log("passwordChange",result);
    if (result && result.status) {
      toast.success("Password changed successfully", {
          position: "bottom-right",
          autoClose: 2000,
          toastId: customId,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }); 
        reactLocalStorage.setObject("userData", result.data);
        reactLocalStorage.setObject("loginstatus", true);
        reactLocalStorage.setObject("loginToken", result.data.token);
        setTimeout(function () {
          window.location.href = "/dashboard";
        }, 2000);
    } else {
      toast.error("Error in server", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  const onLoginSuccess = (res) => {
    console.log('Login Success:', res.profileObj);
};

const onLoginFailure = (res) => {
    console.log('Login Failed:', res);
};
  return (
    <div>
      <div className="modal-dialog  my-3 ">
      <ToastContainer />
        <div className="modal-content LogIn_modal">
          <button
            type="button"
            className="cross close ml-auto px-4 py-2"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closemodal}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="login-body my-3 px-5 ">
            {signup ? (
              <div className="login_text">
                <div className="text-center mt-0 mb-3">
                  <h4>Create your Account</h4>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={fname}
                    onChange={(val) => {
                      if (val.target.value.match("^[a-zA-Z0-9]*$") != null) {
                        setfname(val.target.value);
                        if (val.target.value == "") {
                          setfnameerror(true);
                        } else {
                          setfnameerror(false);
                        }
                      }
                    }}
                    onKeyUp={(e) => userTyping(e)}
                  ></input>
                </div>
                <div className="form-group mt-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={lname}
                    onChange={(val) => {
                      if (val.target.value.match("^[a-zA-Z0-9]*$") != null) {
                        setlname(val.target.value);
                        if (val.target.value == "") {
                          setlnameerror(true);
                        } else {
                          setlnameerror(false);
                        }
                      }
                    }}
                    onKeyUp={(e) => userTyping(e)}
                  ></input>
                </div>
                <div className="form-group mt-2">
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Your Email"
                    value={email}
                    onChange={(val) => {
                      setemail(val.target.value);
                      if (val.target.value == "") {
                        setemailerror(true);
                      } else {
                        setemailerror(false);

                        if (validator.isEmail(email)) {
                          setemailvalid(false);
                        } else {
                          setemailvalid(true);
                        }
                      }
                    }}
                    onKeyUp={(e) => userTyping(e)}
                  />
                </div>
                {/* <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    maxLength="10"
                    value={phone}
                    onChange={(val) => {
                      if (val.target.value.match("^[0-9]*$") != null) {
                        setphone(val.target.value);
                        if (val.target.value == "") {
                          setphoneerror(true);
                        } else {
                          setphoneerror(false);
                        }
                      }
                    }}
                    onKeyUp={(e) => userTyping(e)}
                  ></input>
                </div> */}
                <div className="form-group mt-2">
                  <div className="d-flex form ">
                    <input
                      type={hidden ? "password" : "text"}
                      className="form-control border-0"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      value={password}
                      onChange={(val) => {
                        setpassword(val.target.value);
                        if (val.target.value == "") {
                          setpasserror(true);
                        } else {
                          setpasserror(false);
                          var pattern = new RegExp(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/
                          );

                          console.log("pass", val.target.value);
                          if (pattern.test(val.target.value)) {
                            console.log("object");
                            setpassvalid(false);
                          } else {
                            console.log("invalid");
                            setpassvalid(true);
                          }
                        }
                      }}
                      // onChange={passvalid}
                      onKeyUp={(e) => userTyping(e)}
                    />
                    <span className="my-auto mr-2 text-muted">
                      {/* <i className="far fa-eye"></i> */}
                      <button
                        className="my-auto mr-0 btn"
                        type="button"
                        onClick={toggleShow}
                      >
                        <i
                          className={hidden ? "fa fa-eye-slash" : "far fa-eye"}
                          aria-hidden="true"
                        ></i>
                      </button>
                    </span>
                  </div>
                </div>

                <div className="form-group mt-2">
                  {/* <select
                    className="form-control contact_form_text"
                    id="exampleFormControlSelect1"
                    onChange={(val) => {
                      console.log("hjhj", val.target.value);
                      setselectcountry(val.target.value);
                      if (val.target.value == "") {
                        setcountryvalid(true);
                      } else {
                        setcountryvalid(false);
                      }
                    }}
                  >
                    <option>Select Country</option>
                    {country.length > 0 &&
                      country.map((item, index) => {
                        return (
                          <option value={item.name} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select> */}
                  <Select
                    value={selectedOption}
                    onChange={handleChangeCountry}
                    options={country}
                    placeholder="Select Country"
                    isSearchable
                  />
                </div>
                <div className="form-group mt-2">
                  <select
                    className="form-control"
                    onChange={(val) => {
                      setcurrency(val.target.value);

                      if (val.target.value == "") {
                        setcurrencyvalid(true);
                      } else {
                        setcurrencyvalid(false);
                      }
                    }}
                    value={currency}
                  >
                    <option value="">Select Currency</option>
                    {currencyTyp.map((item, index) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </select>
                </div>

                <div className="text-center my-2">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      handleSignup();
                    }}
                  >
                    Register
                  </button>
                </div>
                <div className="text-center my-3">
                  <p>
                    Already have an account ?
                    <span
                      onClick={() => {
                        setSignin(true);
                        setSignup(false);
                      }}
                    >
                      Sign in
                    </span>{" "}
                  </p>
                </div>
                {warning ? (
                  <Warning warning="The email entered already exists" />
                ) : null}
                {fnameerror ? (
                  <Warning warning="Please Enter First Name" />
                ) : lnameerror ? (
                  <Warning warning="Please Enter Last Name" />
                ) : emailerror ? (
                  <Warning warning="Please Enter Email" />
                ) : emailvalid ? (
                  <Warning warning="Please Enter Valid Email" />
                ) : passerror ? (
                  <Warning warning="Please Enter Password " />
                ) : passvalid ? (
                  <Warning warning="Password must be at least 7 characters, 1 lower case, 1 upper case, and 1 number" />
                ) : countryvalid ? (
                  <Warning warning="Please Enter country" />
                ) : currencyvalid ? (
                  <Warning warning="Please Enter currency" />
                ) : null}

                {successs ? <Success success="Sign up successful" /> : null}
                <div className="text-center app_details">
                  <p className="text-muted text-center">- Or -</p>
                  {/* <Link to="/.."> */}
                    <div className="google text-center my-3">
                      <i className="fab fa-google mx-2"></i>Continue with Google
                    </div>
                  {/* </Link>{" "} */}
                  {/* <Link to="/.."> */}
                    <div className="fb text-center my-3">
                      <i className="fab fa-facebook-square mx-2"></i>Continue
                      with Facebook
                    </div>
                  {/* </Link>{" "} */}
                  {/* <Link to="/..">
                    <div className="apple text-center my-3">
                      <i className="fab fa-apple mx-2"></i>Continue with Apple
                    </div>
                  </Link> */}
                  <p className="mt-4">
                    By clicking Sign in or Continue with Google, Facebook, or
                    Apple, you agree to Astophy's<span onClick={() => {
                      window.location.href = "/condition-policy"
                    }}>Terms of Use</span> and<span onClick={() => {
                      window.location.href = "/privacy-policy"
                    }}>Privacy Policy.</span>
                  </p>
                </div>
              </div>
            ) : emailsend ? (
              <div className="login_text">
                <div className="text-center mt-0 mb-3">
                  <h4>New Password</h4>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter Email"
                    value={newEmail}
                    onKeyUp={(e) => signinTyping(e)}
                    onChange={(val) => {
                      setnewEmail(val.target.value);
                      if (val.target.value != "") {
                        setemailerror(false)
                      } else {
                        setemailerror(true)
                      }
                      if (validator.isEmail(newEmail)) {
                        setemailvalid(false);
                      } else {
                        setemailvalid(true);
                      }
                    }}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="New Password"
                    value={newPassword}
                    onKeyUp={(e) => signinTyping(e)}
                    onChange={(val) => {
                      setnewPassword(val.target.value);
                      var pattern = new RegExp(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/
                      );
                      if (pattern.test(val.target.value)) {
                        setpassvalid(false);
                      } else {
                        setpassvalid(true);
                      }
                    }}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onKeyUp={(e) => signinTyping(e)}
                    onChange={(val) => {
                      setconfirmPassword(val.target.value);
                      if (val.target.value == newPassword) { 
                        setvalidConfirmPassword(false);
                      } else {
                        setvalidConfirmPassword(true);
                      }
                      
                    }}
                  />
                </div>
                {emailerror ? (
                  <Warning warning="Please Enter Email" />
                ) : emailvalid ? (
                  <Warning warning="Please Enter Valid Email" />
                ) : passerror ? (
                  <Warning warning="Please Enter Password " />
                ) : passvalid ? (
                  <Warning warning="Password must be at least 7 characters, 1 lower case, 1 upper case, and 1 number" />
                ) : validConfirmPassword ? (
                  <Warning warning="Confirm Password must be equal to New Password" />
                ) : null}
                <div className="text-center mt-5">
                  <button type="button" className="btn" onClick={changePassword}>
                    Submit
                  </button>
                </div>
              </div>
            ) : forgot ? (
              <div className="login_text">
                <div className="text-center mt-0 mb-3">
                  <h4>Forgot Password</h4>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail2"
                    aria-describedby="emailHelp"
                    placeholder="Your Email"
                    value={forgetEmail}
                    onKeyUp={(e) => signinTyping(e)}
                    onChange={(val) => {
                      setforgetEmail(val.target.value);
                      if (val.target.value != "") {
                        setemailerror(false)
                      } else {
                        setemailerror(true)
                      }
                      if (validator.isEmail(forgetEmail)) {
                        setemailvalid(false);
                      } else {
                        setemailvalid(true);
                      }
                    }}
                  />
                </div>
                {emailerror ? (
                  <Warning warning="Please Enter Email" />
                ) : emailvalid ? (
                  <Warning warning="Please Enter Valid Email" />
                ) : null}
                <div
                  className="text-center mt-5"
                  onClick={() => {
                    sendEmail()
                  }}
                >
                  <button type="button" className="btn">
                    Send Email
                  </button>
                </div>
              </div>
            ) : (
              <div className="login_text">
                <div className="text-center mt-0 mb-3">
                  <h4>Sign in</h4>
                </div>
                {/* <div className="form-group my-3">
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option>User</option>
                    <option>Seller</option>
                  </select>
                </div> */}
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail3"
                    aria-describedby="emailHelp"
                    placeholder="Your Email"
                    value={email}
                    onKeyUp={(e) => signinTyping(e)}
                    onChange={(val) => {
                      setemail(val.target.value);
                    }}
                  />
                </div>
                <div className="form-group my-3">
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    // value={password}
                    onKeyUp={(e) => signinTyping(e)}
                    onChange={(val) => {
                      setpassword(val.target.value);
                    }}
                  />
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                  {" "}
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label
                      className="form-check-label mx-3 my-2"
                      htmlFor="exampleCheck1"
                    >
                      Remember Me
                    </label>
                  </div>
                  <div className="my-2" onClick={() => SetForgot(true)}>
                    <h6>Forgot Password ?</h6>
                  </div>
                </div>
                <div className="text-center">
                  <button type="button" className="btn" onClick={handleSignin}>
                    Continue
                  </button>
                </div>
                <div className="text-center my-3">
                  <p>
                    New User ?
                    <span
                      onClick={() => {
                        setSignup(true);
                        setSignin(false);
                        getCountry();
                      }}
                    >
                      Sign up
                    </span>{" "}
                  </p>
                </div>
                {success ? (
                  <div className="text-center">
                    <p className="text-success">
                      <i className="fas fa-exclamation-circle mr-2"></i>
                      "Sign in successful"
                    </p>
                  </div>
                ) : null}
                {warning ? (
                  <div className="text-center">
                    <p className="text-danger">
                      <i className="fas fa-exclamation-circle mr-2"></i>
                      "You have entered incorrect email id or password"
                    </p>
                  </div>
                ) : null}
                {warningg ? (
                  <div className="text-center">
                    <p className="text-warning">
                      <i className="fas fa-exclamation-circle mr-2"></i>
                      "Please enter all fields"
                    </p>
                  </div>
                ) : null}
                <div className="text-center app_details">
                  <p className="text-muted text-center">- Or -</p>
                  {/* <Link to="/.."> */}
                    <div className="google text-center my-3">
                      <i className="fab fa-google mx-2"></i>Continue with Google
                      {/* <GoogleLogin
                        clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                        // onSuccess={onLoginSuccess}
                        // onFailure={onLoginFailure}
                        cookiePolicy={'single_host_origin'}
                      /> */}
                      
                    </div>
                  {/* </Link>{" "} */}
                  {/* <Link to="/.."> */}
                    <div className="fb text-center my-3">
                      <i className="fab fa-facebook-square mx-2"></i>Continue
                      with Facebook
                    </div>
                  {/* </Link>{" "} */}
                  {/* <Link to="/..">
                    <div className="apple text-center my-3">
                      <i className="fab fa-apple mx-2"></i>Continue with Apple
                    </div>
                  </Link> */}
                  <p className="mt-4">
                    By clicking Sign in or Continue with Google, Facebook, or
                    Apple, you agree to Astophy's<span onClick={() => {
                      window.location.href = "/condition-policy"
                    }}>Terms of Use</span> and
                    <span onClick={() => {
                      window.location.href = "/privacy-policy"
                    }}>Privacy Policy.</span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={verify ? "modal fade loginmodal show" : "modal fade"}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: verify ? "block" : "none" }}
      >
        <VerifiedModal closeverify={closeverify} />
      </div>
    </div>
  );
};
export default AuthModal;
