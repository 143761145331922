import React, { useEffect, useState } from "react";
import Footer from "../../Defaults/Footer";
import HttpClient from "../../utils/HttpClient";
import { ReactTitle } from "react-meta-tags";

const Condition = () => {
  const [content1, setcontent1] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    getCookie();
  }, []);

  const getCookie = async () => {
    let result = await HttpClient.requestData(
      "condition",
      "GET"
    );
    console.log("result data--", result);
    if (result && result.status) {

      strToHtml(result.data[0].content1);
    }
  };
  function strToHtml(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    console.log('doc 1', doc.body.innerHTML);
    document.getElementById("contentDiv").innerHTML = doc.body.innerHTML;

  }
  return (
    <div>
      <section className="container-fluid condition my-4">
      <ReactTitle title="Astrophy | Condition of Use & Sell" />
        <div className="row px-3">
          <div className="col-md-12 text-center mb-4">
            <h3>Condition of Use & Sell</h3>
          </div>
          <div id="contentDiv" className="col-md-12">


          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Condition;
