import React from "react";
import { Link } from "react-router-dom";
// import ShowMore from 'react-show-more-button';
import Readmore from "./Readmore";

const DescriptionPro = (props) => {
  return (
    <div className="Highlights">
      <div>
        <h5>Descriptions</h5>
        {/* <ShowMore maxHeight={100}>
        <p>
          {props.description?props.description:""}
        </p>
        </ShowMore> */}
        <Readmore data={props.description?props.description:""} />
      </div>
      {/* <Link >
        {" "}
        <div className="text-right">
          <h6>Read More</h6>
        </div>
      </Link> */}
    </div>

    
  );
};
export default DescriptionPro;
