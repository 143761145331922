import React from "react";

const CompleteOrderList = () => {
  const loop = [1, 2, 3, 4, 5];
  return (
    <div className="order_list">
      <div className="row">
        {loop.map((item, index) => {
          return (
            <div className="col-md-12 " key={index}>
              <div className="border-bottom p-3 highlight">
                <h5>Order Number : 4855698</h5>
                <div className="d-flex justify-content-between">
                  <p>
                    <span>Order On:</span>
                    <br></br>
                    14 May 2021
                  </p>
                  <p>
                    <span>Total Amount:</span>
                    <br></br>₹ 220.00
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CompleteOrderList;
