import React from "react";

const NoChat = () => {
  return (
    <div className="col-md-6 text-center no-chat my-5">
      <img
        src="https://images-eu.ssl-images-amazon.com/images/I/51Dop0XlFmL.png"
        alt="img"
      />
      <h3>Start Connection with us</h3>
    </div>
  );
};
export default NoChat;
