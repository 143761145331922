import React from 'react'
import SellOrder from "../../Views/Seller/SellOrder";

function Index() {
  return (
    <div class="dashboard_over seller">
        <div>
            <SellOrder  />
      </div>
    </div>
  )
}

export default Index