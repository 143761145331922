import React, { useState, useEffect } from "react";
import Casting from "./Casting/Casting";
import Provider from "./Provider/Provider";
import Service from "./Service/Service";
import Banner from "./Banner/Banner";
import Product from "./Products/Product";
import ProductUpdated from "./Products/ProductUpdated";
import Blog from "./Blog/Blog";
import BlogUpdated from "./Blog/BlogUpdated";
import Faq from "./Faq/Faq";
import Footer from "../../Defaults/Footer";
import { ReactTitle } from "react-meta-tags";
import TopIntro from "../Components/topIntro";
import HttpClient from "../../utils/HttpClient";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import AvailArea from "./AvailArea";
// var id = "";
const loginstatus = reactLocalStorage.getObject("loginstatus");
const Index = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getproduct();
    getprovider();
    getSpellCastingData();
    getBlogData();
    getAboutUsFaq();
    // sortByPriorityy();
    // if (loginstatus && Object.keys(userData).length > 0) {
    //   getcart();
    // }
  }, []);

  const [providerdata, setproviderdata] = useState([]);
  const [shortedProvider, setshortedProvider] = useState([]);
  const [spellCastingData, setspellCastingData] = useState([]);
  const [blogData, setblogData] = useState([]);
  const userData = reactLocalStorage.getObject("userData");
  const [allproducts, setAllproducts] = useState([]);
  const [aboutFaq, setaboutFaq] = useState([]);
  const [loading, setLoading] = useState(false);

  const getproduct = async (next) => {
    // console.log("next", next);
    setLoading(true);
    // console.log("userdata", userdata);
    let datass = "/k";
    if (Object.keys(userData).length > 0) {
      // if (typeof userdata._id !== "undefined") {
      datass = "/" + userData._id;
    } else {
      datass = "/91a953b1effa7e26d08fff8a";
    }
    // }
    let sendData = next ? next : "1";
    // console.log("data......", datass);
    if (Object.keys(userData).length > 0) {
      let result = await HttpClient.requestData(
        "listProducts/" +
          userData._id +
          "/" +
          sendData +
          "?currency=" +
          userData.currency,
        "GET"
      );
      console.log("result", result);
      if (result && result.status) {
        setAllproducts(result.data.itemsList);
        // setproductpaginator(result.data.paginator);
        setTimeout(() => {
          setLoading(false);
        }, 1);
      } else {
      }
    } else {
      let result = await HttpClient.requestData(
        "listProducts/" +
          "91a953b1effa7e26d08fff8a/" +
          sendData +
          "?currency=" +
          "INR",
        "GET"
      );
      // console.log("result", result);
      if (result && result.status) {
        setAllproducts(result.data.itemsList);
        // setproductpaginator(result.data.paginator);
        setTimeout(() => {
          setLoading(false);
        }, 1);
      } else {
      }
    }
  };

  const getSpellCastingData = async () => {
    // let result = await HttpClient.requestData("spellCasting", "GET");
    // // console.log("spellCasting data--", result);
    // if (result && result.status) {
    //   setspellCastingData(result.data);
    // }
    let result = await HttpClient.requestData("service", "GET");
    if (result && result.status) {
      let data = result.data.filter(
        (item) => item._id === "6204f6f56dcb6904acb6a894"
      );
      // let datas = data[0].service_data;
      setspellCastingData(data[0].service_data);
      console.log();
    } else {
    }
  };

  const getBlogData = async () => {
    let result = await HttpClient.requestData("Blog", "GET");
    console.log("result blog data--", result);
    if (result && result.status) {
      setblogData(result.data);
    }
  };
  const backprovider = (val) => {
    if (val) {
      getprovider();
    }
  };
  const sortByPriorityy = (data) => {
    let sortByPriority = data.sort((a, b) =>
      a.priority > b.priority ? 1 : -1
    );
    let sortData = sortByPriority;
    setshortedProvider(sortData);
  };

  const getprovider = async () => {
    let sendData = {};
    // console.log("object", sendData);
    let result = await HttpClient.requestData("topProviders", "GET", sendData);
    // console.log("topProviders", result);
    if (result && result.status) {
      setproviderdata(result.data);
      sortByPriorityy(result.data);
    } else {
    }
  };

  const getcart = async () => {
    let data = {};
    // console.log("data", data);
    let result = await HttpClient.requestData(
      "servicecart/" + userData._id,
      "GET",
      data
    );
    // console.log("result", result);
    if (result && result.status) {
      let count = result.data.length;

      // if (count > 0) {
      //   id = result.data[0]._id;
      //   confirmAlert({
      //     title: "There is item in service cart",
      //     message: "Do you want to delete this?",
      //     buttons: [
      //       {
      //         label: "Yes",
      //         onClick: () => confirmyes(id),
      //       },
      //       {
      //         label: "No",
      //         onClick: () => confirmno(),
      //       },
      //     ],
      //   });
      // } else {
      // }
    } else {
    }
  };

  // const confirmyes = async (id) => {
  //   let data = {};
  //   let result = await HttpClient.requestData(
  //     "servicecart/" + id,
  //     "DELETE",
  //     data
  //   );
  //   // console.log("result", result);
  //   if (result && result.status) {
  //     toast.success("Cart Deleted!", {
  //       position: "bottom-right",
  //       autoClose: 1000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     // window.location.href = "/home";
  //   } else {
  //   }
  // };

  // const confirmno = () => {
  //   // alert("csshygdy")
  //   window.history.pushState(null, "", "/checkoutservice");
  //   window.location.href = "/checkoutservice";
  // };

  const refresh = (val) => {
    if (val) {
      getproduct();
    }
  };

  const getAboutUsFaq = async () => {
    let result = await HttpClient.requestData(
      "allFaqabout",
      "POST"
    );
    console.log("allFaqabout --", result);
    if (result && result.status) {
      setaboutFaq(result.data);
    } else {
      setaboutFaq([]);
    }
  };
  const addcart = async (val) => {
    let data = {
      prod_id: val._id,
      user_id: userData._id,
      productname: val.name,
      qty: 1,
      currency: userData.currency,
      price: val.selling_price,
      image: val.image[0],
    };
    let result = await HttpClient.requestData("add-to-cart", "POST", data);
    if (result && result.status) {
      getproduct();
      toast.success("Item Added Successfully", {
        position: "bottom-right",
      });
    } else {
      toast.error(result.message, {
        position: "bottom-right",
      });
    }
  };

  const wishlistCallback = (val) => {
    if (val) {
      getproduct();
    }
  };

  return (
    <div>
      <div className="wrapper ">
        <ToastContainer />
        <ReactTitle title="Home | Astrophy" />
      </div>
      <section>
        <Banner />
      </section>
      <AvailArea />
      <section>
        <div
          className="container my-md-5 my-3 home-service"
          style={{ maxWidth: "1200px" }}
        >
          <TopIntro name="Services" title="We Provide " title1="Services" />
          <div className="pt-1">
            <Service />
          </div>
          <div className="row">
            <div className="text-center col-md-12">
              <Link to="/more-services" className="btn my-4 view-btn ">
                View More
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="Provider mt-1">
        <div className="container p-md-0 p-lg-0" style={{ maxWidth: "1200px" }}>
          <TopIntro name="Providers" title="Top Service Provider" />
          <Provider data={shortedProvider} backprovider={backprovider} />
        </div>
        <div className="text-center">
          <Link to="/more-provider" className="btn my-4 view-btn ">
            View More
          </Link>
        </div>
      </section>
      <section>
        <div className="container home-service" style={{ maxWidth: "1200px" }}>
          <TopIntro name="Spell" title="We offer " title1="Spell Casting" />
          <div className="pt-1">
            <Casting spellCastingData={spellCastingData} />
          </div>
          <div className="text-center">
            <Link to="/more-spell" className="btn my-4 view-btn ">
              View More
            </Link>
          </div>
        </div>
      </section>
      {/* <section className="Provider mt-5">
        <div className="container-fluid px-lg-5">
          <TopIntro name="Providers" title="Top Service Provider" />
          <Provider data={providerdata} />
        </div>
      </section> */}
      <section className="">
        <div
          className="container px-lg-0 mb-5 Products"
          style={{ maxWidth: "1200px" }}
        >
          <TopIntro name="Product" title="Product for " title1="Sell" />
          <ProductUpdated
            data={allproducts}
            addTocart={addcart}
            wishlistCallback={wishlistCallback}
          />
          <div className="text-center">
            <Link to="/astrophy-mall">
              <button href="#" className="btn my-4 ">
                View More
              </button>
            </Link>
          </div>
        </div>
      </section>
      <section className="Blog-- my-md-5 my-3">
        <div className="container " style={{ maxWidth: "1200px" }}>
          <TopIntro name="Blog" title="Blogs" />
          <BlogUpdated blogData={blogData} />
          <div className="text-center mb-3">
            <Link to="/blog" className="btn blog_btn ">
              Read More
            </Link>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container" style={{ maxWidth: "1200px" }}>
          <TopIntro name="FAQ" title="Frequently Asked " title1="Questions" />
          {/* <div className="row px-3">
            <div className="col-md-12 faq_head_home">
              <h6>FAQ</h6>
              <h3>
                Frequently Asked <span>Questions</span>{" "}
              </h3>
            </div>
          </div> */}
          <div className="row mb-5 answer px-3">
            <div className="col-md-12">
            {aboutFaq.length ? (
                aboutFaq.map((item,index) => {
                  return (
                    // <MoreInfo dataFaq={item}/>
                    <Faq dataFaq={item} type="aboutFaq"/>
                  ) 
                })
              ) : (
                <div className="pl-lg-3">No More Information Found</div>
              )}
              {/* <Faq /> */}
              </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row px-3">
            <div className="col-md-12">
              <Link to="/faq" className="btn mb-4 ans_btn ">
                Find More, Click here...
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Index;
