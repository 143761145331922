import React, { Component,} from "react";
import Chart from "react-apexcharts";
import HttpClient from "../../utils/HttpClient";
export default class ProCharts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Number of Service Created",
          data: [10, 41, 35, 51, 67, 49, 69, 90, 72, 34, 45, 23],
        },
        {
          name: "Number of Service Booked",
          data: [3, 34, 56, 60, 72, 82, 89, 90, 79, 67, 89, 45],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f9f9f9", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
        },
      },
      seriesData: [],
    };
  }

  componentDidMount = async () => {
    this.fetchGraphData();
  };

  fetchGraphData = async () => {
    let result = await HttpClient.requestData("serviceGraph", "GET");
    console.log("Graph Data---- ", result);
    if (result && result.status) {
      this.setState({ seriesData: result.data });
    }
  };
  render() {
    return (
      <div>
        <div id="chart">
          <Chart
            options={this.state.options}
            series={this.state.seriesData}
            type="line"
            height={350}
          />
        </div>
      </div>
    );
  }
}
