import React, { useState, useEffect } from "react";
import ChatHead from "../../Views/Messages/ChatBody/chatHead";
import WriteChat from "../../Views/Messages/ChatBody/writeChat";
import { DemoData } from "../../Views/Messages/components/demodata";
import MessageList from "../../Views/Messages/MessageList/messageList";
import UserList from "../../Views/Messages/userList";
import SellerList from "../../Views/Messages/sellerList"
import NoChat from "../../Views/Messages/ChatBody/NoChat";
import { v4 as uuidv4 } from "uuid";
import { reactLocalStorage } from "reactjs-localstorage";
import db from "../../Firebase/fbconfig";
import { ref, child, get, set, update } from "firebase/database";
const Index = () => {
  const [newchat, setnewchat] = useState({});
  const [nochatopen, setnochatopen] = useState(false);
  const [send, setsend] = useState(true);
  const userData = reactLocalStorage.getObject("userData");
  const [roomId, setroomId] = useState("");
  const [chatlist, setchatlist] = useState([]);
  const [backupChatlist, setBackupChatlist] = useState([]);
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getchatlist();
    getSellerchatlist();
  }, []);
  const getchatlist = async () => {
    const dbRef = ref(db);
    get(child(dbRef, `/chatList/${userData._id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let chatlist = Object.values(snapshot.val())
          let filterChatlist = chatlist.filter((item) => item.user_type == "User")
          console.log("sellerchat---",filterChatlist);
          // console.log("chatList",Object.values(snapshot.val()));
          setchatlist(filterChatlist);
          setBackupChatlist(filterChatlist);
          // console.log(Object.values(snapshot.val()));
        } else {
          console.log("No Chat data data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getSellerchatlist = async () => {
    const dbRef = ref(db);
    get(child(dbRef, `/chatList/${userData._id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let chatlist = Object.values(snapshot.val())
          let filterChatlist = chatlist.filter((item) => item.user_type == "Seller")
          console.log("sellerchat---",filterChatlist);
          setchatlist(filterChatlist);
          setBackupChatlist(filterChatlist);
          // console.log(Object.values(snapshot.val()));
        } else {
          console.log("No Chat data data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSearch = (event) => {
    let searchdata = event.target.value;
    setSearchText(searchdata)
    // userFind(searchdata);
  };

  const userFind = (keyword) => {
    let searchString = keyword.trim().toLowerCase();
    let result = [];
    result = backupChatlist.filter((it) => it.name.toLowerCase().match(searchString));
    setchatlist(result)
  };

  const openchat = (val, item) => {
    // console.log("item",item);
    if (val) {
      setnewchat(item);
      setnochatopen(true);
      setroomId(item.roomId);
      // console.log(`/chatList/${userData._id}/${item.userId}/unseen`);
      // const updates = {};
      // updates[`/chatList/${userData._id}/${item.userId}/unseen`] = false;
      // update(ref(db), updates);
    }
  };
  const openchat1 = (val, item) => {
    // console.log("item",item);
    if (val) {
      setnewchat(item);
      setnochatopen(true);
      setroomId(item.roomId);
      // console.log(`/chatList/${userData._id}/${item.userId}/unseen`);
      // const updates = {};
      // updates[`/chatList/${userData._id}/${item.userId}/unseen`] = false;
      // update(ref(db), updates);
    }
  };
  return (
    <div className="row my-3 px-3">
      <div className="col-md-6 ">
        <div className="sellCust">
          <button type="btn my-md-3 mb-md-0">Seller</button>
          <button type="btn my-md-3 mb-md-0">Customer</button>
        </div>
        <div class="row">
        <div className="col-md-6">
        <div className="chat_list test">
          {/* search user */}
          <div className="form-group py-3 px-3 mb-0">
            <div className="d-flex Search_bar">
              <span className="my-auto mr-0 text-muted">
                <i className="fas fa-search mx-3"></i>
              </span>
              <input
                type="text"
                className="form-control border-0  px-2 my-auto w-100"
                id="searchChat"
                placeholder="Search by Name "
                onChange={handleSearch}
              />
            </div>
          </div>
          {/* {data1.length > 0 ? ( <div>
            <UserList userlist={data1} openchat={openchat} />
          </div>) : (
             <div>
             <UserList userlist={chatlist} openchat={openchat} />
           </div>
          )} */}

          <div>
            <SellerList
              // userlist={chatlist} 
              openchat1={openchat1}
              searchText = {searchText}
            />
          </div>

        </div>
        </div>
        <div className="col-md-6">
        <div className="chat_list test">
          {/* search user */}
          <div className="form-group py-3 px-3 mb-0">
            <div className="d-flex Search_bar">
              <span className="my-auto mr-0 text-muted">
                <i className="fas fa-search mx-3"></i>
              </span>
              <input
                type="text"
                className="form-control border-0  px-2 my-auto w-100"
                id="searchChat"
                placeholder="Search by Name "
                onChange={handleSearch}
              />
            </div>
          </div>
          {/* {data1.length > 0 ? ( <div>
            <UserList userlist={data1} openchat={openchat} />
          </div>) : (
             <div>
             <UserList userlist={chatlist} openchat={openchat} />
           </div>
          )} */}

          <div>
            <UserList
              // userlist={chatlist} 
              openchat={openchat}
              searchText = {searchText}
            />
          </div>

        </div>
        </div>
        </div>
       
        {/* <div className="sellerChat">

        </div> */}
       
      </div>
      {nochatopen ? (
        <div className="col-md-6 chat_details my-md-0 my-3">
          <div>
            <ChatHead type="sellerToUser" sender={newchat} roomId={roomId} />
          </div>
          <div className="message-area">
            <MessageList sender={newchat} roomId={roomId} />
          </div>
          <div>
            <WriteChat sender={newchat} roomId={roomId} />
          </div>
        </div>
      ) : (
        <NoChat />
      )}
    </div>
  );
};
export default Index;
