import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Peer from "peerjs";
import * as uuid from "uuid";
import {
  ref,
  get,
  set,
  child,
  limitToLast,
  onChildAdded,
  query,
  orderByKey,
  endAt,
  onValue,
  update,
  push,
} from "firebase/database";
import db from "../../../Firebase/fbconfig";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";

const configuration = {
  iceServers: [
    { url: "stun:stun.l.google.com:19302" },
    { url: "stun:stun1.l.google.com:19302" },
    { url: "stun:stun2.l.google.com:19302" },
    { url: "stun:stun3.l.google.com:19302" },
    { url: "stun:stun4.l.google.com:19302" },
    {
      url: "turn:numb.viagenie.ca",
      credential: "Bokachoda@2020",
      username: "soumya.webhibe@gmail.com",
    },
  ],
};

var peerServer = null;
var myStream = null;
var remoteUserId = "";
var remoteUserStream = null;
var peerCall = null;
var callNodeId = null;
var callIsRecieved = false
var hour = 0;
var min = 0;
var sec = 0;
var timerRef = null;


const AudioModal = (props) => {
  console.log(props,"proppp");
  const { remoteData, roomId, type } = props;
  const [iconoff, setIconoff] = useState("");
  const [recieved, setRecieved] = useState(false);
  const [remoteUserToken, setRemoteUserToken] = useState("");
  const [callState, setCallState] = useState("Connecting");
  const [callTime, setCallTime] = useState("");
  const [audioMute, setAudioMute] = useState(false);
  const [remoteUserMute, setRemoteUserMute] = useState(false);
  const [refress, setRefress] = useState(false);

  console.log("remoteData", remoteData)

  const myUserData = reactLocalStorage.getObject("userData");

  useEffect(() => {

    getUserMedia();
  }, []);

  useEffect(() => {
    if (callState == 'Recieved') {
      // console.log("call start")
      startTimer();
    }
  }, [callState]);

  const startTimer = () => {
    let re = false;
    timerRef = setInterval(() => {
      if (sec < 59) {
        sec = sec + 1;
      } else {
        min = min + 1;
        sec = 0;
      }

      if (min == 60) {
        min = 0;
        hour = hour + 1;
      }
      // console.log("balance", balance)
      setRefress(!re);
      re = !re;
    }, 1000);
  }


  const sendNotificationEnd = async (token) => {
    const FIREBASE_API_KEY =
      "AAAAkKuhlBw:APA91bHuUxvnxQR6VxdLpxkBsgyZjapd4ekX-eFV4EhKGjR0lFoALfCbGsg_U1IW1-jOuLd3vjYTWm2EaCUi64pQNrxirTCsV23IzgcXki3a3yROBY8tFPsWJ_w1sc_zmCNc8L8N5nO2";
    // data.notiType = 'Call';
    const message = {
      registration_ids: [token],
      priority: "high",
      data: { notiType: "Call", callDataType: "end" },
    };

    let headers = new Headers({
      "content-type": "application/json",
      Authorization: "key=" + FIREBASE_API_KEY,
    });
    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers,
      body: JSON.stringify(message),
    });
    response = await response.json();
    console.log("response55555", response, message);
  };

  const sendNotification = async (data, token) => {
    const FIREBASE_API_KEY =
      "AAAAkKuhlBw:APA91bHuUxvnxQR6VxdLpxkBsgyZjapd4ekX-eFV4EhKGjR0lFoALfCbGsg_U1IW1-jOuLd3vjYTWm2EaCUi64pQNrxirTCsV23IzgcXki3a3yROBY8tFPsWJ_w1sc_zmCNc8L8N5nO2";
    // data.notiType = 'Call';
    const message = {
      registration_ids: [token],
      priority: "high",
      data: { ...data, notiType: "Call", callDataType: "start" },
      notification: {
        title: myUserData.firstname + " " + myUserData.lastname,
        body: "Incoming Call",
        vibrate: 1,
        sound: 1,
        show_in_foreground: true,
        priority: "high",
        content_available: true,
      },
    };

    let headers = new Headers({
      "content-type": "application/json",
      Authorization: "key=" + FIREBASE_API_KEY,
    });
    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers,
      body: JSON.stringify(message),
    });
    response = await response.json();
    console.log("response55555", response, message);
  };

  const FBGetValue = (nodeId) => {
    onValue(ref(db, `/call/${myUserData._id}/${nodeId}`), (snapshot) => {
      let data = snapshot.val();
      setCallState(data.status);
      if (data.status == "Recieved") {
        // InCallManager.stopRingtone();
        // InCallManager.stopRingback();
        setCallTime(data.time);
      }

      if (data.muted) {
        setRemoteUserMute(true);
      } else {
        setRemoteUserMute(false);
      }

      if (data.endStatus) {
        get(ref(db, `/user/${remoteData.userId}`)).then((snap) => {
          let remoteToken = snapshot.val().fcmToken;

          sendNotificationEnd(remoteToken);
          endCall(data.status);
        });
      }
    });
  };

  const getUserMedia = () => {
    console.log("issss");
    navigator.getUserMedia(
      { audio: true },
      (stream) => {
        console.log("stream", stream)
        initCall(stream);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const initCall = (stream) => {
    
    myStream = stream;
    peerServer = new Peer(undefined, {
      host: "peer.astrophy.com",
      path: "/",
      secure: true,
      config: configuration,
    });

    peerServer.on("open", (userId) => {
      console.log(type,"tpppp");
      if (type == "Incomming") {
        // InCallManager.startRingtone('_BUNDLE_');
        callNodeId = remoteData.nodeId;
        FBGetValue(remoteData.nodeId);
        updateMyFb({ status: "Ringing" });
        updateClientFb({ status: "Ringing" });
      } else {
        // console.log("remoteData.userId", remoteData.userId)
        const dbRef = ref(db, `/user/${remoteData.userId}`);
        get(dbRef).then((snapshot) => {
          let remoteToken = snapshot.val()?.fcmToken;
          console.log("initcall", remoteToken)
          setRemoteUserToken(remoteToken);
          let callId = uuid.v4();
          callNodeId = callId;
          let myData = {
            userId: remoteData.userId,
            name: remoteData.name,
            nodeId: callId,
            time: moment().format(),
            type: "Outgoing",
            status: "Connecting",
            endStatus: false,
          };

          set(ref(db, `/call/${myUserData._id}/${callId}`), myData).then(() => {
            FBGetValue(callId);
          });

          let clientData = {
            userId: myUserData._id,
            name: myUserData.firstName + " " + myUserData.lastName,
            peerId: userId,
            time: moment().format(),
            type: "Incomming",
            status: "Connecting",
            nodeId: callId,
            endStatus: false,
          };

          set(ref(db, `/call/${remoteData.userId}/${callId}`), clientData).then(
            () => {
              sendNotification(clientData, remoteToken);
            }
          );
        });
      }
    });

    peerServer.on("call", (call) => {
      const conn = peerServer.connect(call.peer);
      call.answer(stream);

      call.on("stream", (userStream) => {
        console.log("userStream peer", userStream);
        peerCall = call;
        remoteUserStream = userStream;
        const videoGreed = document.getElementById("video-view-rtc");
        videoGreed.srcObject = userStream;
        videoGreed.addEventListener("loadedmetadata", () => {
          videoGreed.play();
        });
      });

      call.on("close", () => {
        console.log("streamstream1");
      });
    });
  };

  const connectUser = (userId) => {
    // console.log("called")
    let data = {};
    const conn = peerServer.connect(userId);
    const call = peerServer.call(userId, myStream);

    call.on("stream", (remoteVideoStream) => {
      if (remoteVideoStream) {
        peerCall = call;
        console.log("user stream", remoteVideoStream);
        remoteUserStream = remoteVideoStream;
        const videoGreed = document.getElementById("video-view-rtc");
        videoGreed.srcObject = remoteVideoStream;
        videoGreed.addEventListener("loadedmetadata", () => {
          videoGreed.play();
        });
      }
    });
    call.on("close", () => {
      console.log("streamstream");
      // dispatch({ type: REMOVE_REMOTE_STREAM, payload: peers[userId].stream.id })
      // this.props.dispatch(removeRemoteStream(peers[userId].stream.id))
    });
  };

  const reciveCall = () => {
    connectUser(remoteData.peerId);
    updateMyFb({ status: "Recieved", receiveViaWeb: true });
    updateClientFb({ status: "Recieved" });
    setRecieved(true);
  };

  const endCall = (callStat) => {
    // alert(peerCall)
    remoteUserStream = null;
    clearInterval(timerRef)
    if (peerCall != null) {
      peerCall.close();
    }
      if (type != "Incomming") {
        let callMsgRef = push(ref(db, `/Chat/${roomId}/messages`))

        let msdId = callMsgRef.key;

        set(callMsgRef, {
          sender_id: myUserData._id,
          type: 'call',
          msgId: msdId,
          send_time: moment().format(),
          roomid: roomId,
          name: myUserData.firstName + " " + myUserData.lastName,
          callDuration: `${hour.toString().padStart(2, '0')} : ${min.toString().padStart(2, '0')} : ${sec.toString().padStart(2, '0')}`,
          callTime: moment().format(),
          callStatus: callStat == 'Recieved' ? 'Recieved' : 'Outgoing'
        })
      }





    if (myStream != null) {
      myStream.getTracks().forEach(function (track) {
        track.stop();
      });
    }

    props.callBackcall1(false);
  };

  const updateMyFb = (data) => {
    console.log(myUserData._id,callNodeId);
    console.log(data);
    // console.log("myUserData", myUserData)
    // console.log("updateMy", '/user/' + myUserData._id + '/call/' + callNodeId)
    if (callNodeId) {
      update(ref(db, `/call/${myUserData._id}/${callNodeId}`), data).then((res) => {
        console.log(res,"done");
      });
    }else {
      if (data.endStatus) {
        props.callBackcall1(false);
      }
    }
  
  };

  const updateClientFb = (data) => {
    console.log("iss audio");
    // console.log("updateClient", '/user/' + remoteUserData.userId + '/call/' + callNodeId)
    if (callNodeId) {
      update(ref(db, `/call/${remoteData.userId}/${callNodeId}`), data).then(
        (res) => {console.log(res,"aures"); }
      );
    }else {
      if (data.endStatus) {
        props.callBackcall1(false);
      }
    }
    
  };

  return (
    <div>
      <div className="modal-dialog  my-3 ">
        <div className="modal-content call_modal">
          <div className="call-body my-3 px-5 pt-4">
            <div className="text-center">
              <h5>{remoteData.name} </h5>
              {callState != "Recieved" ? <p>{callState}...</p> : null}
              {callState == "Recieved" ? (
                <p>{`${hour.toString().padStart(2, '0')} : ${min.toString().padStart(2, '0')} : ${sec.toString().padStart(2, '0')}`}</p>
              ) : null}
              <img
                // src="https://png.pngitem.com/pimgs/s/130-1300344_user-symbol-png-transparent-png.png"
                src={props.userImage ? `https://admin.astrophy.com/${props.userImage}` : "https://png.pngitem.com/pimgs/s/130-1300344_user-symbol-png-transparent-png.png"}
                alt="img"
                className="audio-img"
                style={{
                  objectFit: "contain",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          </div>

          <video
            id="video-view-rtc"
            style={{
              display: "none",
            }}
            muted={remoteUserMute}
          />

          <div className=" down-part mt-5">
            <div className="d-flex justify-content-around">
              {!recieved && type == "Incomming" ? (
                <button
                  type="button"
                  className="btn receive-btn"
                  onClick={reciveCall}
                >
                  <i className="fas fa-phone" />
                </button>
              ) : null}

              {callState == "Recieved" ? (
                !audioMute ? (
                  <button
                    type="button"
                    className="btn down-btn"
                    onClick={() => {
                      setAudioMute(true);
                      updateClientFb({ muted: true });
                    }}
                  >
                    <i className="fas fa-microphone"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn down-btn"
                    onClick={() => {
                      setAudioMute(false);
                      updateClientFb({ muted: false });
                    }}
                    style={{
                      borderRadius: 30,
                      background: "#ffffff33",
                    }}
                  >
                    <i
                      className="fas fa-microphone-slash"
                      aria-hidden="true"
                    ></i>
                  </button>
                )
              ) : null}
              <button
                type="button"
                className="btn decline-btn"
                onClick={() => {
                  updateMyFb({ endStatus: true });
                  updateClientFb({ endStatus: true });
                }}
              >
                <i className="fas fa-phone"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioModal;
