import React from "react";

const Warning = (props) => {
  return (
    <div className="text-center">
      <p className="text-danger">
        <i className="fas fa-exclamation-circle mr-2"></i>
        {props.warning}
      </p>
    </div>
  );
};
export default Warning;
