import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Defaults/Footer";
import { ReactTitle } from "react-meta-tags";
import SiteLinks from "./SiteLinks";
import { useParams } from "react-router";

const Site = (props) => {
  const noSite = props.location.pathname;
  console.log("props.location.pathname", props.location.pathname);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="wrapper">
        <ReactTitle title="Astrophy | Site Map" />
      </div>
      <div className="container Site_map">
        <div className="row mt-4">
          <div className="col-md-12 text-center">
            <h3>Site Map</h3>
          </div>
        </div>
        <SiteLinks page={noSite} />
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Site;
