import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import EmptyProduct from "../EmptyProduct/EmptyProduct";

const RelatedProduct = () => {
  const [allproducts, setAllproducts] = useState([]);
  const userData = reactLocalStorage.getObject("userData");
  const loginstatus = reactLocalStorage.getObject("loginstatus");

  useEffect(() => {
    getproduct();
  }, []);

  const getproduct = async () => {
    let sendData = "1";
    let _id = userData._id ? userData._id : "91a953b1effa7e26d08fff8a";
    if (Object.keys(userData).length > 0) {
      let result = await HttpClient.requestData(
        "listProducts/" +
          userData._id +
          "/" +
          sendData +
          "?currency=" +
          userData.currency,
        "GET"
      );
      console.log("listProducts item", result);
      if (result && result.status) {
        setAllproducts(result.data.itemsList);
      } else {
        // console.log("error", result.error);
      }
    } else {
      let result = await HttpClient.requestData(
        "listProducts/" +
          "91a953b1effa7e26d08fff8a/" +
          sendData +
          "?currency=" +
          "INR",
        "GET"
      );
      // console.log("listProducts item", result);
      if (result && result.status) {
        setAllproducts(result.data.itemsList);
      } else {
        // console.log("error", result.error);
      }
    }
  };

  const addTocart = async (val) => {

console.log("carrrrrr");
  
    let data = {
      prod_id: val._id,
      user_id: userData._id,
      productname: val.name,
      qty: 1,
      currency: userData.currency,
      price: val.selling_price,
      image: val.image[0],
    };
    console.log(data);
    // return false
    let result = await HttpClient.requestData("add-to-cart", "POST", data);
    console.log(result);
    if (result && result.status) {
     
      getproduct();
      toast.success(result.message, {
        position: "bottom-right",
      });
    } else {
      toast.error(result.message, {
        position: "bottom-right",
      });
    }
  };

  const alert = () => {
    toast.error("You have to login first", {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const buyNow = async (val) => {
    if (val.cart_details.length > 0) {
      window.location.href = "/checkout";
    } else {
      let data = {
        prod_id: val._id,
        user_id: userData._id,
        productname: val.name,
        qty: 1,
        currency: userData.currency,
        price: val.selling_price,
        image: val.image[0],
      };

      let result = await HttpClient.requestData("add-to-cart", "POST", data);
      // console.log("result", result);
      if (result && result.status) {
        window.location.href = "/checkout";
      } else {
      }
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const styles = {
    color: "green",
    // cursor: "default",
    cursor : "pointer"
  };
  return (
    <div className="related_products  ">
      <ToastContainer />
      <Slider {...settings}>
        {allproducts.length > 0 ? (
          allproducts.map((item, index) => {
            let sum = 0;
            let avg = 0;
            item.review_data.map((itemm, index) => {
              return (sum = Number(sum) + Number(itemm.rating));
            });
            avg = Number(sum) / Number(item.review_data.length);
            return (
              <div className="py-4 px-2" key={index}>
                <div className="card service_list">
                  <Link to={"/product-details/" + item.name + "/" + item._id} key={index}>
                    <div>
                      <img
                        src={HttpClient.IMG_URL + item.image[0]}
                        alt="img"
                        className="relative service_list_img"
                      />
                    </div>
                  </Link>
                  <div className="card-body product_list_text px-3 pt-2">
                    <Link to={"/product-details/" + item.name + "/" + item._id}>
                      <h6 style={{color:"#a024b7"}}>{item.name}</h6>
                    </Link>
                    <div className="d-flex justify-content-between py-3">
                    <div className="pricing">
                      <p className="font-weight-bold text-dark mb-0">
                        {userData.currency == "INR"
                          ? "₹"
                          : userData.currency == "CAD"
                          ? "$"
                          : userData.currency == "USD"
                          ? "$"
                          : userData.currency == "GBP"
                          ? "£"
                          : userData.currency == "EUR"
                          ? "€"
                          : "₹"}{" "}
                        {item.mrp.toFixed(2)}
                        <del className="text-muted font-italic ml-2 small">
                          {userData.currency == "INR"
                            ? "₹"
                            : userData.currency == "CAD"
                            ? "$"
                            : userData.currency == "USD"
                            ? "$"
                            : userData.currency == "GBP"
                            ? "£"
                            : userData.currency == "EUR"
                            ? "€"
                            : "₹"}{" "}
                          {item.selling_price.toFixed(2)}
                        </del>
                      </p>
                      <h5 className="small mb-0 font-weight-bold">
                        {item.offer ? item.offer + "% offer+" : null}
                        {item.tax}tax
                      </h5>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star my-1 mr-2" style={{color:"#a024b7"}}></i>
                      {item.avgRating ? item.avgRating.toFixed(1) : "0"}/5
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-center">
                      {loginstatus ? (
                        <button
                          className="btn my-3 red_btn"
                          onClick={() => buyNow(item)}
                        >
                          Buy Now
                        </button>
                      ) : (
                        <button className="btn my-3 red_btn" onClick={alert}>
                          Buy Now
                        </button>
                      )}
                    </div>
                    {/* <Link to="/add-cart"> */}
                    {loginstatus ? (
                      item.cart_details.length > 0 ? (
                        <button
                          className="cart btn bg-transparent"
                          style={styles}
                        >
                          <i className="fas fa-shopping-cart text-success"></i>{" "}
                          Already added
                        </button>
                      ) : (
                        <button
                          className="cart btn bg-transparent text-dark"
                          onClick={() => addTocart(item)}
                          style={styles}
                        >
                          <i className="fas fa-cart-plus" style={{color:"#a024b7"}}></i> Add to
                          Cart 
                        </button>
                      )
                    ) : (
                      <button
                        className="cart btn bg-transparent text-dark"
                        onClick={alert}
                        style={styles}
                      >
                        <i className="fas fa-cart-plus text-dark"></i> Add to
                        Cart
                      </button>
                    )}
                    {/* </Link> */}
                  </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <EmptyProduct />
        )}
      </Slider>
    </div>
  );
};
export default RelatedProduct;
