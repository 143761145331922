import React, { useState, useEffect } from "react";
import Footer from "../../Defaults/Footer";
import Loader from "../../Component/loader";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { ReactTitle } from "react-meta-tags";
import "react-toastify/dist/ReactToastify.css";
import StripeCheckout from "react-stripe-checkout";
import PayPal from "./PayPal";
import axios from "axios";
import moment from "moment";
// import {NotificationContainer, NotificationManager} from 'react-notifications';
// import Warning from "../../Component/Warning";
const Checkout = () => {
  const [coupondata, setcoupondata] = useState([]);
  const [coupondataApplied, setcoupondataApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryy, setcountryy] = useState([]);
  const [coupon, setcoupon] = useState("");
  const [minPrice, setminPrice] = useState("");
  const [couponName, setcouponName] = useState("");
  const [couponstring, setcouponstring] = useState("");
  const [discount, setdiscount] = useState(0);
  const [discounttype, setdiscounttype] = useState("");
  const userData = reactLocalStorage.getObject("userData");

  const coupondetails = reactLocalStorage.getObject("coupondetails");
  // console.log("coupondetails", coupondetails);
  // const discount = reactLocalStorage.getObject("discount");
  // console.log("pageload", discount);

  const discountt = Object.keys(discount).length == 0 ? 0 : discount;

  // console.log("discount", discount);
  const [sum, setsum] = useState(0);
  const [delevaryAmount, setdelevaryAmount] = useState("");
  const [loop, setloop] = useState([]);
  const [count, setcount] = useState(0);
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [address2, setaddress2] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState([]);
  const [selectstate, setselectstate] = useState("");
  const [zip, setzip] = useState("");
  const [phone, setphone] = useState("");
  const [payment, setpayment] = useState("");
  const [check1, setcheck1] = useState(false);
  const [check2, setcheck2] = useState(false);
  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);

  const [blank, setblank] = useState(false);
  const [valid, setvalid] = useState(false);

  const [blanklast, setblanklast] = useState(false);

  const [blankemail, setblankemail] = useState(false);

  const [blankaddress, setblankaddress] = useState(false);

  const [blankcountry, setblankcountry] = useState(false);

  const [blankstate, setblankstate] = useState(false);

  const [blankzip, setblankzip] = useState(false);
  const [saveInfo, setSaveInfo] = useState(false);
  const [shipAddress, setShipAddress] = useState(false);
  const [loader, setloader] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    getFutureAddress();
    getcart();
    getCountry();
  }, []);

  const getcart = async () => {
    setLoading(true);
    let data = {};
    // console.log("data", data);
    let result = await HttpClient.requestData(
      "get-cart/" + userData._id,
      "GET",
      data
    );
    console.log("result get-cart", result);
    if (result && result.status) {
      setloop(result.data);
      setcount(result.data.length);
      let arr = [];
      let newdata = result.data.map((item) => {
        console.log("cart item", item.product_data);
        let discountData = 0;
        let deliamount = item.product_data.length
          ? item.product_data[0].delivery_amount != null
            ? item.product_data[0].delivery_amount
            : 0
          : 0;
        return (discountData = discountData + deliamount);
      });
      arr.push(newdata);
      console.log("arr----------", arr);
      const deliverySum = arr[0].reduce((partialSum, a) => partialSum + a, 0);
      console.log(sum);
      setdelevaryAmount(deliverySum);
      // console.log("delevaryAmount",delevaryAmount)
      // if (result.data[0].product_data[0].delivery_amount != null) {
      // let sum1 = 0;
      // result.data.forEach((element) => {
      //   sum1 = sum1 + (element.price * element.qty) + element.product_data[0].delivery_amount;

      // });
      // console.log("sum", sum);
      // console.log("discunt", discountt);
      // setsum(sum1);
      // } else {
      //   let sum1 = 0;
      //   result.data.forEach((element) => {
      //     sum1 = sum1 + element.price * element.qty;
      //   });
      //   console.log("sum", sum);
      //   console.log("discunt", discountt);
      //   setsum(sum1);
      // }
      let sum1 = 0;
      result.data.forEach((element) => {
        sum1 = sum1 + element.price * element.qty;
      });
      console.log("sum", sum);
      console.log("discunt", discountt);
      setsum(sum1);
      // let totall = sum - discountt.discount;
      // console.log("total value", totall);
      setTimeout(() => {
        setLoading(false);
      }, 1);

      if (result.data.length > 0) {
        if (result.data[0].coupon_data.length > 0) {
          setcoupondataApplied(true);
          setcoupondata(result.data[0].coupon_data[0]);
          setcoupon(result.data[0].coupon_data[0].name);
          let expdate = result.data[0].coupon_data[0].expdate;
          let expdate1 = moment(expdate).format("YYYY-MM-DD");
          let exp_date = expdate1;
          console.log("exp", exp_date);
          let expdate2 = moment(exp_date);
          let curdate1 = moment().format("YYYY-MM-DD");
          console.log("exp1", curdate1);
          let curdate2 = moment(curdate1);

          if (result.data[0].coupon_data[0].minprice < sum1) {
            if (
              moment(result.data[0].coupon_data[0].expdate).isAfter(
                curdate1,
                "day"
              )
            ) {
              console.log("hello");
              if (
                result.data[0].coupon_data[0].discount_type == "Flat discount"
              ) {
                setdiscounttype(result.data[0].coupon_data[0].discount_type);
                if (result.data[0].coupon_data[0].discount_value >= sum1) {
                  setcouponstring("invalid");
                  setdiscount(0);
                } else {
                  setcouponstring("success");
                  setdiscount(result.data[0].coupon_data[0].discount_value);
                }
              } else {
                setdiscounttype(result.data[0].coupon_data[0].discount_type);

                let amount_dis =
                  (result.data[0].coupon_data[0].discount_value * sum1) / 100;
                console.log("amount_dis", amount_dis);
                if (amount_dis >= sum1) {
                  setcouponstring("invalid");
                  setdiscount(0);
                } else {
                  setcouponstring("success");
                  setdiscount(amount_dis);
                }
              }
            } else {
              setcouponstring("Date Invalid");
              setdiscount(0);
            }
          } else {
            setcouponstring("invalid");
            setdiscount(0);
          }
        } else {
          setcoupondataApplied(false);
        }
      }
    } else {
    }
  };

  const apply = async (e) => {
    e.preventDefault();
    if (coupon != "") {
      let data = {
        user_id: userData._id,
        name: coupon,
      };
      console.log("coupon", data);

      let result = await HttpClient.requestData("checkCoupon", "POST", data);
      console.log("checkCoupon", result);
      if (result && result.status) {
        setcoupondata(result.data);
        setminPrice(result.data.minprice);
        let expdate = result.data.expdate;
        let expdate1 = moment(expdate).format("YYYY-MM-DD");
        let exp_date = expdate1;
        console.log("exp", exp_date);
        let expdate2 = moment(exp_date);
        let curdate1 = moment().format("YYYY-MM-DD");
        console.log("exp1", curdate1);
        let curdate2 = moment(curdate1);
        let date = moment(result.data.expdate).isAfter(curdate1, "day");
        console.log("date", date);

        if (result.data.minprice <= sum) {
          if (moment(result.data.expdate).isSameOrAfter(curdate1, "day")) {
            console.log("hello");
            if (result.data.discount_type == "Flat discount") {
              setdiscounttype(result.data.discount_type);
              if (result.data.discount_value >= sum) {
                setcouponstring("discountvalueexceed");
                setdiscount(0);
                setTimeout(() => {
                  setcoupon("");
                  setcouponstring("");
                }, 4000);
              } else {
                setcouponstring("success");
                setdiscount(result.data.discount_value);
              }
            } else {
              setdiscounttype(result.data.discount_type);

              let amount_dis = (result.data.discount_value * sum) / 100;
              if (amount_dis >= sum) {
                setcouponstring("invalid");
                setdiscount(0);
                setTimeout(() => {
                  setcoupon("");
                  setcouponstring("");
                }, 4000);
              } else {
                setcouponstring("success");
                setdiscount(amount_dis);
              }
            }
          } else {
            setcouponstring("Date Invalid");
            setdiscount(0);
            setTimeout(() => {
              setcoupon("");
              setcouponstring("");
            }, 4000);
          }
        } else {
          setcouponstring("invalid");
          setdiscount(0);
          setTimeout(() => {
            setcoupon("");
            setcouponstring("");
          }, 4000);
          // toast.warning("Cart Amount Not Matching with the Coupon Min Price", {
          //   position: "bottom-right",
          //   autoClose: 3000,
          // });
        }
      } else {
        setcouponstring(result.error);
        setdiscount(0);

        setTimeout(() => {
          setcoupon("");
          setcouponstring("");
        }, 4000);
      }
    } else {
      setcouponstring("empty");
    }
  };

  const remove = async (e) => {
    e.preventDefault();
    let data = {
      user_id: userData._id,
      name: coupon,
    };
    let result = await HttpClient.requestData("removeCoupon", "POST", data);
    console.log("remove Coupon----", result);
    if (result && result.status) {
      getcart();
      setdiscount(0);
      setcoupon("");
      setvalid(false);
      setcouponstring("remove");
      setTimeout(() => {
        setcoupon("");
        setcouponstring("");
      }, 1000);
    } else {
    }
  };

  const getCountry = () => {
    console.log("hii");
    let response = axios
      .get("https://countriesnow.space/api/v0.1/countries/capital")
      .then((response) => {
        console.log("response", response);
        if (response.data.data.length > 0) {
          setcountryy(response.data.data);
          console.log("country", countryy);
        }
      });
  };
  const getState = (name) => {
    let arr = [];
    let data = {
      country: name,
    };
    console.log("data", data);
    let response = axios
      .get("https://countriesnow.space/api/v0.1/countries/states", data)
      .then((response) => {
        console.log("response", response);
        if (response.data.data.length > 0) {
          let cur_data = response.data.data.filter((item) => item.name == name);
          console.log("curdata", cur_data);
          if (cur_data.length > 0) {
            setstate(cur_data[0].states);
          }
        }
      });
  };
  const getFutureAddress = async () => {
    setLoading(true);
    let data = {
      userid: userData._id,
    };
    // console.log("data", data);
    let result = await HttpClient.requestData(
      "future_use_address",
      "POST",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      let data = result.data;
      if (Array.isArray(data)) {
        getState(data.length > 0 ? data[0].country : "");
        setfname(data.length > 0 ? data[0].firstname : "");
        setlname(data.length > 0 ? data[0].lastname : "");
        setaddress(data.length > 0 ? data[0].address1 : "");
        setaddress2(data.length > 0 ? data[0].address2 : "");
        setcountry(data.length > 0 ? data[0].country : "");
        setselectstate(data.length > 0 ? data[0].state : "");
        setzip(data.length > 0 ? data[0].zip : "");
        setemail(data.length > 0 ? data[0].email : "");
        setphone(data.length > 0 ? data[0].phone : "");
        console.log("fname", fname);
      } else {
        getState(data && data.country ? data.country : "");
        setfname(data && data.firstname ? data.firstname : "");
        setlname(data && data.lastname ? data.lastname : "");
        setaddress(data && data.address1 ? data.address1 : "");
        setaddress2(data && data.address2 ? data.address2 : "");
        setcountry(data && data.country ? data.country : "");
        setselectstate(data && data.state ? data.state : "");
        setzip(data && data.zip ? data.zip : "");
        setemail(data && data.email ? data.email : "");
        setphone(data && data.phone ? data.phone : "");
        console.log("fname", fname);
      }
    } else {
    }
  };
  const senddetails = (val, details) => {
    if (val) {
      console.log("details", details);
      order(details.id);
    }
  };
  const handlepayment = (e) => {
    setpayment(e.target.value);
  };
  const onToken = (token, addresses) => {
    console.log("token", token);
    console.log("addresses", addresses);

    stripeapicall(token.id);
    // fetch('/save-stripe-token', {
    //   method: 'POST',
    //   body: JSON.stringify(token),
    // }).then(response => {
    //   response.json().then(data => {
    //     alert(`We are in business, ${data.email}`);
    //   });
    // });
  };
  const stripeapicall = async (token) => {
    console.log(token);
    const card = {
      amount: (sum - discount + delevaryAmount) * 100,
      currency: userData.currency,
      source: token,
      description: "Astrophy Product Payment",
    };

    let result = await fetch("https://api.stripe.com/v1/charges", {
      headers: {
        // Use the correct MIME type for your server
        Accept: "application/json",
        // Use the correct Content Type to send data to Stripe
        "Content-Type": "application/x-www-form-urlencoded",
        // Use the Stripe publishable key as Bearer
        Authorization: `Bearer sk_test_51JavJLSB5KAxkRypR3jypiPJk4I9nQ7L9uGMqDwQSPXCEhwFhYRMGVLpp5UvQTXFYJ6GQ4MqXfAW1cVFxbqEvpR900Bc3ynSKr`,
      },
      // Use a proper HTTP method
      method: "post",
      // Format the credit card data to a string of key-value pairs
      // divided by &
      body: Object.keys(card)
        .map((key) => key + "=" + card[key])
        .join("&"),
    }).then((response) => response.json());

    console.log("resultt", result);

    order(result.balance_transaction);
  };

  const order = async (transaction_id) => {
    // e.preventDefault();
    // if (
    //   fname == "" ||
    //   lname == "" ||
    //   address == "" ||
    //   country == "" ||
    //   state == "" ||
    //   zip == ""
    // ) {
    //   toast.warning("Enter Required Fields", {
    //     position: "bottom-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else {
    if (isEmailFromatValid) {
      toast.warning("Please enter a valid email", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (check1 == false) {
      toast.warning("Please Select Shipping Address", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let a = discountt.discount ? discountt.discount : 0;

      let data = {
        user_id: userData._id,
        subtotal: delevaryAmount
          ? sum - discount + delevaryAmount
          : sum - discount,
        total: sum,
        coupon: discount > 0 ? coupondata : null,
        delevaryAmount: delevaryAmount,
        discount: discount,
        firstname: fname,
        lastname: lname,
        email: email,
        currency: userData.currency,
        address1: address,
        address2: address2,
        country: country,
        state: selectstate,
        zip: zip,
        phone: phone,
        paymenttype: payment,
        // tokenid: transaction_id,
        shipping_address: check1,
        address_future_use: check2,
        orderFrom: "web",
        tokenid: transaction_id,
        // coupon_type: coupondetails.discount_type,
      };
      console.log("object", data);
      setLoading(true);
      let result = await HttpClient.requestData("checkout", "POST", data);
      console.log("result order ", result);
      if (result && result.status) {
        setLoading(false);
        toast.success("Order placed successfully!", {
          position: "bottom-right",
          autoClose: 3000,
        });
        reactLocalStorage.setObject("coupondetails", {});
        reactLocalStorage.setObject("discount", {});
        setTimeout(() => {
          window.location.href = "/orders";
        }, 3000);
      } else {
        toast.error("Something Went Wrong!", {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    }
    // }
  };

  const deletecart = async (item) => {
    let data = {};
    let result = await HttpClient.requestData(
      "cartDelete/" + item,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      getcart();
      // window.location.href = "/home";
    } else {
    }
  };

  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    console.log(valid);
    setisEmailFromatValid(valid ? false : true);
  }

  return (
    <div>
      <div className="wrapper">
        <ReactTitle title="Astrophy-Checkout" />
      </div>
      <div className="container my-5">
        <div className="row">
          <ToastContainer />
          <div className="col-md-12 check_out mb-3">
            <h3>Checkout</h3>
          </div>
          <div className="col-md-4 order-md-2 mb-4">
            {loading ? (
              <Loader />
            ) : (
              <div className="cart_box_right">
                <h4 className="d-flex justify-content-between align-items-center mb-3 p-2">
                  <span className="text-muted">Your Cart</span>
                  <span className="badge badge-danger badge-pill">{count}</span>
                </h4>
                <ul className="list-group mb-3">
                  {loop.map((item, index) => {
                    return (
                      // <li className="list-group-item d-flex justify-content-between lh-condensed border_here">
                      //   <div>
                      //     <h6 className="my-0">{item.productname}</h6>
                      //     <small className="text-muted">
                      //       {/* Brief description */}
                      //       {item.product_data[0].description}
                      //     </small>
                      //   </div>
                      //   <div className="d-flex">
                      //     <p className="text-muted">₹{item.price}</p>
                      //     <span className="text-muted">
                      //       <i className="fas fa-times mx-2"></i> {item.qty}
                      //     </span>
                      //   </div>
                      // </li>
                      <div className="list-group-item px-2 py-2">
                        <li className="d-flex justify-content-between lh-condensed">
                          <div className="d-flex">
                            <img
                              src={HttpClient.IMG_URL + item.image}
                              alt="img"
                              style={{ height: "50px", width: "50px" }}
                            />
                            <div className="ml-3">
                              <h6 className="my-0 ">{item.productname}</h6>X
                              {item.qty}
                              {/* <small className="text-muted">
                                {item.product_data[0].description}
                              </small> */}
                            </div>
                          </div>
                          <div className="d-flex">
                            <p className="text-muted">
                              {/* ₹ */}
                              {userData.currency == "INR"
                                ? "₹"
                                : userData.currency == "CAD"
                                ? "$"
                                : userData.currency == "USD"
                                ? "$"
                                : userData.currency == "	GBP"
                                ? "£"
                                : userData.currency == "EUR"
                                ? "€"
                                : "₹"}
                              {item.price.toFixed(2)}
                            </p>

                            {/* <span className="text-muted">
                            <i className="fas fa-times mx-2"></i> {item.qty}
                          </span> */}
                          </div>
                        </li>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ marginTop: 15 }}
                        >
                          <p className="text-muted" style={{ marginBottom: 0 }}>
                            Additonal Note:{" "}
                            {item.addn_note != "" ? item.addn_note : ""}
                          </p>
                          <button
                            type="button"
                            className="btn"
                            onClick={() => deletecart(item._id)}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    );
                  })}

                  <li className="list-group-item d-flex justify-content-between bg-light">
                    {/* <div className="text-success">
                      <h6 className="my-0">Promo code</h6>
                      <small>{coupon}</small>
                    </div> */}
                    <span>Discount Amount</span>
                    <strong>
                      {/* -₹ */}
                      {userData.currency == "INR"
                        ? "₹"
                        : userData.currency == "CAD"
                        ? "$"
                        : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "	GBP"
                        ? "£"
                        : userData.currency == "EUR"
                        ? "€"
                        : "₹"}
                      {discount ? discount.toFixed(2) : 0}
                    </strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span>Delivery Amount</span>

                    <strong>
                      {/* ₹ */}
                      {userData.currency == "INR"
                        ? "₹"
                        : userData.currency == "CAD"
                        ? "$"
                        : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "	GBP"
                        ? "£"
                        : userData.currency == "EUR"
                        ? "€"
                        : "₹"}
                      {delevaryAmount ? delevaryAmount : 0}
                    </strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span>Total</span>

                    <strong>
                      {/* ₹ */}
                      {userData.currency == "INR"
                        ? "₹"
                        : userData.currency == "CAD"
                        ? "$"
                        : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "	GBP"
                        ? "£"
                        : userData.currency == "EUR"
                        ? "€"
                        : "₹"}
                      {delevaryAmount
                        ? (sum - discount + delevaryAmount).toFixed(2)
                        : (sum - discount).toFixed(2)}
                    </strong>
                  </li>
                </ul>
                <form className="card p-2">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Promo code"
                      value={coupon}
                      onChange={(val) => setcoupon(val.target.value)}
                    />
                    <div className="input-group-append">
                      <button className="btn" onClick={apply}>
                        Apply
                      </button>
                      {count == 0 ? null : (
                        <button
                          className="my-auto mr-0 btn apply_coup_btn "
                          style={{background:"#a024b7", marginLeft:"5px"}}
                          onClick={remove}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                  {couponstring == "success" ? (
                    <div className="py-3">
                      <span className="text-success ">
                        <i className="far fa-check-circle"></i> Coupon Applied
                        Successfully
                      </span>
                    </div>
                  ) : null}
                  {couponstring == "invalid" ? (
                    <div className="py-3">
                      <span className="text-danger">
                        <i className="far fa-times-circle"></i>
                        {/* Coupon Code Invalid */}
                        {/* This Coupon code is valid only for the cart value {minPrice} or above order{" "}
                        {minPrice} */}
                        This Coupon code is valid only for the cart value{" "}
                        {minPrice} or above.
                      </span>
                    </div>
                  ) : null}
                  {couponstring == "discountvalueexceed" ? (
                    <div className="py-3">
                      <span className="text-danger">
                        <i className="far fa-times-circle"></i>
                        {/* Coupon Code Invalid */}
                        {/* This Coupon code is valid only for the cart value {minPrice} or above order{" "}
                        {minPrice} */}
                        Coupon not applicable
                      </span>
                    </div>
                  ) : null}
                  {couponstring == "empty" ? (
                    <div className="py-3">
                      <span className="text-danger">
                        <i className="far fa-times-circle"></i> Please Enter
                        Coupon Code
                      </span>
                    </div>
                  ) : null}
                  {couponstring == "Date Invalid" ? (
                    <div className="py-3">
                      <span className="text-danger">
                        <i className="far fa-times-circle"></i> Coupon code
                        expireds
                      </span>
                    </div>
                  ) : null}
                  {/* {couponstring == "cantapply" ? (
                    <div className="py-3">
                      <span className="text-danger">
                        <i className="far fa-times-circle"></i>
                      </span>
                    </div>
                  ) : null} */}
                  {couponstring == "This coupon is not available anymore" ? (
                    <div className="py-3">
                      <span className="text-danger">
                        <i className="far fa-times-circle"></i> This coupon is
                        not available anymore
                      </span>
                    </div>
                  ) : null}
                  {couponstring == "This coupon has already been used" ? (
                    <div className="py-3">
                      <span className="text-danger">
                        <i className="far fa-times-circle"></i> This coupon has
                        already been used
                      </span>
                    </div>
                  ) : null}
                  {couponstring == "This coupon doesnot exist" ? (
                    <div className="py-3">
                      <span className="text-danger">
                        <i className="far fa-times-circle"></i> This coupon
                        doesnot exist
                      </span>
                    </div>
                  ) : null}
                  {couponstring == "remove" ? (
                    <div className="py-3">
                      <span className="text-danger">
                        <i className="far fa-times-circle"></i>
                        Coupon removed Successfully
                      </span>
                    </div>
                  ) : null}
                </form>
              </div>
            )}
          </div>
          <div className="col-md-8 order-md-1">
            <h4 className="mb-3">Billing Address</h4>
            <div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="firstName">
                    First name{" "}
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="First Name"
                    value={fname}
                    onChange={(val) => {
                      setfname(val.target.value);
                      if (val.target.value == "") {
                        setblank(false);
                        setcheck2(false);
                      } else {
                        setblank(true);
                      }
                    }}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">
                    Last name{" "}
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Last Name"
                    value={lname}
                    onChange={(val) => {
                      setlname(val.target.value);
                      if (val.target.value == "") {
                        setblanklast(false);
                        setcheck2(false);
                      } else {
                        setblanklast(true);
                      }
                    }}
                  />
                  <div className="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="email">
                  Email{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(val) => {
                    setemail(val.target.value);
                    if (val.target.value == "") {
                      setblankemail(false);
                      setcheck2(false);
                      validateEmail(val.target.value);
                    } else {
                      setblankemail(true);
                      validateEmail(val.target.value);
                    }
                  }}
                />
                {isEmailFromatValid ? (
                  <small style={{ color: "red" }}>
                    {" "}
                    Please enter a valid email.{" "}
                  </small>
                ) : null}
                {/* <div className="invalid-feedback">
                  Please enter a valid email address for shipping updates.
                </div> */}
              </div>
              <div className="mb-3">
                <label htmlFor="address">
                  Phone{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Enter your phone"
                  value={phone}
                  // onChange={(val) => setaddress(val.target.value)}
                  onChange={(val) => {
                    setphone(val.target.value);
                    if (val.target.value == "") {
                      setblankaddress(false);
                      setcheck2(false);
                    } else {
                      setblankaddress(true);
                    }
                  }}
                />
                <div className="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="address">
                  Address{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Enter your address"
                  value={address}
                  // onChange={(val) => setaddress(val.target.value)}
                  onChange={(val) => {
                    setaddress(val.target.value);
                    if (val.target.value == "") {
                      setblankaddress(false);
                      setcheck2(false);
                    } else {
                      setblankaddress(true);
                    }
                  }}
                />
                <div className="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="address2">
                  Address 2 <span className="text-muted">(Optional)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address2"
                  placeholder="Enter your address2"
                  value={address2}
                  onChange={(val) => setaddress2(val.target.value)}
                />
              </div>
              <div className="row">
                <div className="col-md-5 mb-3">
                  <label htmlFor="country">
                    Country{" "}
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  <div className="form-group mt-2">
                    <select
                      className="form-control contact_form_text"
                      id="exampleFormControlSelect1"
                      value={country}
                      onChange={(val) => {
                        // console.log("hjhj", val.target.value);
                        if (val.target.value == "true") {
                          setcountry("");
                          setselectstate("");
                          setcheck2(false);
                        } else {
                          setcountry(val.target.value);
                          getState(val.target.value);
                          if (val.target.value == "") {
                            setblankcountry(false);
                          } else {
                            setblankcountry(true);
                          }
                        }
                      }}
                    >
                      <option>Select Country</option>
                      {/*  */}
                      {countryy.length > 0 &&
                        countryy.map((item, index) => {
                          return (
                            <option value={item.name} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {/* <input
                    type="text"
                    className="form-control"
                    id="country"
                    placeholder
                    value={country}
                    onChange={(val) => {
                      if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                        setcountry(val.target.value);
                        if (val.target.value == "") {
                          setblankcountry(false);
                        } else {
                          setblankcountry(true);
                        }
                      }
                    }}
                  /> */}

                  <div className="invalid-feedback">
                    Please select a valid country.
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="state">
                    State{" "}
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  {/* <input
                    type="text"
                    className="form-control"
                    id="state"
                    placeholder
                    value={state}
                    onChange={(val) => {
                      if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                        setstate(val.target.value);
                        if (val.target.value == "") {
                          setblankstate(false);
                        } else {
                          setblankstate(true);
                        }
                      }
                    }}
                  /> */}

                  <select
                    className="form-control"
                    id="state"
                    onChange={(val) => {
                      if (val.target.value == "true") {
                        setselectstate("");
                        setcheck2(false);
                      } else {
                        setselectstate(val.target.value);
                        if (val.target.value == "") {
                          setblankstate(false);
                        } else {
                          setblankstate(true);
                        }
                      }
                    }}
                    value={selectstate}
                  >
                    <option value>Choose...</option>
                    {state.map((item, index) => {
                      return (
                        <option value={item.name} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {/* <select
                    className="custom-select d-block w-100"
                    id="state"
                    required
                  >
                    <option value>Choose...</option>
                    <option>California</option>
                  </select> */}
                  <div className="invalid-feedback">
                    Please provide a valid state.
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="zip">
                    Zip{" "}
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    placeholder="Enter Zip"
                    value={zip}
                    onChange={(val) => {
                      setzip(val.target.value);
                      if (val.target.value == "") {
                        setblankzip(false);
                        setcheck2(false);
                      } else {
                        setblankzip(true);
                      }
                    }}
                  />
                  <div className="invalid-feedback">Zip code required.</div>
                </div>
              </div>
              <hr className="mb-4" />
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="same-address"
                  onChange={(val) => {
                    setcheck1(!check1);
                    setpayment("");
                    console.log("payment", payment);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="same-address"
                  // onClick={() => setShipAddress(!shipAddress)}
                >
                  {/* Shipping address is the same as my billing address */}
                  Billing and Shipping address
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  checked={check2 ? true : false}
                  className="custom-control-input"
                  id="save-info"
                  onChange={(val) => {
                    if (
                      fname != "" &&
                      lname != "" &&
                      email != "" &&
                      address != "" &&
                      country != "" &&
                      selectstate != "" &&
                      zip != ""
                    ) {
                      setcheck2(!check2);
                      setpayment("");
                      console.log("payment", payment);
                    } else {
                      toast.warning(
                        "Please fill out all the required fields to proceed to payment",
                        {
                          position: "bottom-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                    }
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="save-info"
                  // onClick={() => setSaveInfo(!saveInfo)}
                >
                  Save this information for next time
                </label>
              </div>

              <hr className="mb-4" />

              {fname != "" &&
              lname != "" &&
              email != "" &&
              address != "" &&
              country != "" &&
              selectstate != "" &&
              zip != "" ? (
                <>
                  {" "}
                  <h4 className="mb-3">
                    Payment{" "}
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </h4>
                  <div className="d-block my-3">
                    <div className="custom-control custom-radio">
                      <input
                        id="stripe"
                        name="paymentMethod"
                        type="radio"
                        className="custom-control-input"
                        value="stripe"
                        checked={payment == "stripe" ? true : false}
                        onChange={handlepayment}
                      />
                      <label className="custom-control-label" htmlFor="stripe">
                        Stripe
                      </label>
                    </div>

                    <div className="custom-control custom-radio">
                      <input
                        id="paypal"
                        name="paymentMethod"
                        type="radio"
                        className="custom-control-input"
                        value="paypal"
                        checked={payment == "paypal" ? true : false}
                        onChange={handlepayment}
                      />
                      <label className="custom-control-label" htmlFor="paypal">
                        Paypal
                      </label>
                    </div>
                  </div>
                </>
              ) : null}

              <hr className="mb-4" />

              {payment == "stripe" && (
                <StripeCheckout
                  token={onToken}
                  stripeKey="pk_test_51JavJLSB5KAxkRypwpyFZVGF7Mxwel9iBFVqCbIaqDQERfj9A6NoeJEaBBZnnGRgSCasukWcLtWICm7SI0CtSDAl00bKUxkGVB"
                  // amount={(sum - discountt.discount) * 100}
                  amount={(sum - discount + delevaryAmount) * 100}
                  name="Astrophy Product"
                  description="Pay & Buy"
                  currency={userData.currency}
                  shippingAddress={true}
                />
              )}
              {payment == "paypal" && (
                <PayPal
                  // total={sum - discountt.discount}
                  total={sum - discount}
                  senddetails={senddetails}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Checkout;
