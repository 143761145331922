import React, { useState } from "react";
import Provider_name from "../../Assets/Images/provider_name.png";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import EmptyCart from "../EmptyCart/EmptyCart";
import { ToastContainer, toast } from "react-toastify";
const CartList = (props) => {
  console.log("cart data props", props.data);
  console.log("productDetails", props.productDetails);
  let productDetails = props.productDetails;
  const userData = reactLocalStorage.getObject("userData");
  const [counter, setCounter] = useState(1);
  const wishlist = async (val) => {
    let user_id = userData._id;
    let data = {
      user_id: user_id,
      prod_id: val.prod_id,
      productname: val.productname,
      qty: "1",
      price: val.price,
      image: val.image,
    };
    console.log("data", data);
    let result = await HttpClient.requestData("product-wishlist", "POST", data);
    console.log("result", result);
    if (result && result.status) {
      props.cartdec(result.status);
      toast.success("Added to Wishlist", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else {
    }
  };
  const remove = async (item) => {
    let data = {};
    let result = await HttpClient.requestData(
      "cartDelete/" + item._id,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      props.success(result.status);
    } else {
    }
  };

  const decrement = async (val, index) => {
    props.data[index].qty = val.qty - 1;
    // setCounter(props.data[index].qty);
    // console.log("counter", props.data[index].qty);
    if (props.data[index].qty == 0) {
      let data = {};
      let result = await HttpClient.requestData(
        "cartDelete/" + val._id,
        "DELETE",
        data
      );
      console.log("result", result);
      if (result && result.status) {
        props.success(result.status);
      } else {
      }
    } else {
      let data = {
        prod_id: val.prod_id,
        user_id: userData._id,
        productname: val.productname,
        qty: props.data[index].qty,
        price: val.price,
        image: val.image[0],
      };
      console.log("data", data);
      let result = await HttpClient.requestData(
        "updateCart/" + val._id,
        "PUT",
        data
      );
      console.log("result", result);
      if (result && result.status) {
        props.cartdec(result.data);
      } else {
      }
    }
  };
  const increment = async (val, index) => {
    console.log("val", val);
    console.log("val", val.qty);
    props.data[index].qty = val.qty + 1;
    // setCounter(props.data[index].qty);
    console.log("counter", props.data[index].qty);

    let data = {
      prod_id: val.prod_id,
      user_id: userData._id,
      productname: val.productname,
      qty: props.data[index].qty,
      price: val.price,
      image: val.image[0],
    };
    console.log("data", data);
    let result = await HttpClient.requestData(
      "updateCart/" + val._id,
      "PUT",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      props.data[index].qty = result.data.qty;
      props.cartdec(result.data);
    } else {
    }
  };
  const Send = async (item, index) => {
    let data = {
      addn_note: props.data[index].addn_note,
    };
    let result = await HttpClient.requestData(
      "note-with-product/" + item._id,
      "PUT",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      toast.success("Note added successfully", {
        position: "bottom-right",
        autoClose: 3000,
      });
      props.setarr(props.data[index].addn_note, index);
    } else {
    }
  };
  const imgStyle = {
    width: "150px",
    height: "150px",
    borderRadius: "6px",
    objectFit: "cover",
    objectPosition: "top",
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }
  return (
    <div className="col-md-8">
    <ToastContainer />
      {props.data.length > 0 ? (
        props.data.map((item, index) => {
          // let tax_percent = item.product_data[0].tax.replace("%", "");      
          // let b = Number(item.product_data[0].selling_price) * (Number(tax_percent) / 100);
          // let selling_priceWithTax = (item.product_data[0].selling_price + b).toFixed(2)
          return (
            <div className="card cart_box my-4" key={index}>
              <div className="row card-body p-3 no-gutters"
              >
                <div className="col-md-2"
                 onClick={() => {
                  window.location.href = "product-details/" + nameToSlug(item.productname) + "/" + item.prod_id
                }}
                >
                  <img
                    src={HttpClient.IMG_URL + item.image}
                    alt="img"
                    style={imgStyle}
                  />
                </div>
                <div className="col-lg-7 px-3"
                 onClick={() => {
                  window.location.href = "product-details/" + nameToSlug(item.productname) + "/" + item.prod_id
                }}
                >
                  <h5>{item.productname}</h5>
                  <p>
                    {item.product_data.length
                      ? item.product_data[0].description
                        ? item.product_data[0].description
                        : ""
                      : null}
                  </p>
                  {/* {item.type ? (
                    <div className="mt-4">
                      <div className="d-flex ">
                        <div>
                          <img src={Provider_name} alt="img" />
                        </div>
                        <div>
                          <h4>T Boutique</h4>
                          <p>Owner of TarotBoutique555</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {/* <div className="form-group form-check ">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      This order is a gift
                    </label>
                    <p>Prices will not be shown on packing slip</p>
                  </div> */}
                </div>
                <div className="col-lg-3 col-8">
                  <div className="d-flex justify-content-between">
                    {" "}
                    <button
                      type="button"
                      className="btn side_btn"
                      onClick={() => wishlist(item)}
                    >
                      <p>Save For Later</p>
                    </button>
                    <button
                      type="button"
                      className="btn side_btn"
                      onClick={() => remove(item)}
                    >
                      <p>Remove</p>
                    </button>
                  </div>
                  <h6>
                    {/* ₹  */}
                    {userData.currency == "INR"
                      ? "₹"
                      : userData.currency == "CAD"
                      ? "$"
                      : userData.currency == "USD"
                      ? "$"
                      : userData.currency == "GBP"
                      ? "£"
                      : userData.currency == "EUR"
                      ? "€"
                      : "₹"}
                    {(item.price * item.qty).toFixed(2)}
                  </h6>
                  <div className=" my-3 d-flex justify-content-around align-items-center">
                    {item.qty > 1 ? (
                      <button
                        className="btn  my-lg-0 my-2"
                        // onClick={() => setCounter(counter - 1)}
                        onClick={() => {
                          if (item.qty > 1) {
                            decrement(item, index);
                          }
                        }}
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    ) : (
                      <button className="btn  my-lg-0 my-2" disabled>
                        <i className="fas fa-minus"></i>
                      </button>
                    )}
                    <span className="my-lg-0 my-2">{item.qty}</span>
                    <button
                      className="btn my-lg-0 my-2"
                      onClick={() => increment(item, index)}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row card-body px-4 ">
                {/* <div className="col-md-2"></div> */}
                <div className="col-md-12">
                  <div className="form-group ">
                    <label htmlFor="exampleFormControlTextarea1">
                      Add additional notes
                    </label>
                    <div className="d-flex justify-content-start">
                      <textarea
                        className="form-control my-2 w-100"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        // defaultValue={""}
                        style={{ marginTop: "-10px" }}
                        // defaultValue={item.description}
                        onChange={(val) => {
                          console.log(val.target.value);

                          props.setarr(val.target.value, index);
                        }}
                        value={item.addn_note}
                      />
                      <button
                        type="button"
                        className="btn side_btn"
                        onClick={() => Send(item, index)}
                        style={{ width: "100px" }}
                      >
                        Save
                      </button>
                    </div>
                  </div>

                  <div className="text-right">
                    <ul>
                      <li>
                        {" "}
                        <i className="fas fa-circle  mr-2"></i>
                        {item.product_data.length
                          ? item.product_data[0].delivery_time
                          : null}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyCart />
      )}
    </div>
  );
};
export default CartList;
