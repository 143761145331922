import React from "react";
import Provider_name from "../../Assets/Images/provider_name.png";
// import RatingChanged from "../../Component/RatingChanged";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import Profile from "../../Assets/Images/user.jpg";

const ReviewList = (props) => {
  return (
    <div>
      {props.reviewlist.length > 0 ? (
        <>
          {props.reviewlist.map((item, index) => {
            return (
              <div className="review_list my-3" key={index}>
                <div className="d-flex">
                  <img
                    src={
                      item.user_data.image
                        ? `https://admin.astrophy.com/${item.user_data.image}`
                        : Profile
                    }
                    alt="img"
                    style={{ width: "100%", height: "30px", maxWidth: "30px" }}
                  />
                  <div className="my-lg-0 my-2 mx-4">
                    <h5>
                      {item.user_data
                        ? item.user_data[0].firstName +
                          " " +
                          item.user_data[0].lastName
                        : null}
                    </h5>
                  </div>
                  <div className="ml-auto">
                    <span> {moment(item.rev_date).format("D-MM-YYYY")}</span>
                  </div>
                </div>
                <div className="mx-5">
                  <ReactStars
                    count={5}
                    value={item.rating}
                    // onChange={ratingChanged}
                    size={24}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                    edit={false}
                  />
                </div>
                <div className="mx-5 my-3">
                  <p>{item.comment}</p>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <h5>Oops! No data found.</h5>
        </>
      )}
    </div>
  );
};
export default ReviewList;
