import React from "react";

const EmptyWishlist = (props) => {
  return (
    <div className="col-md-12 text-center blank_product mb-5">
      <h4>
        {props.type == "service"
          ? "No services found in Your Wishlist" :
          props.type == "services_provider" 
          ? "No service providers found in Your Wishlist"
          : "No products found in Your Wishlist"}{" "}
        <br></br>
      </h4>
    </div>
  );
};
export default EmptyWishlist;
