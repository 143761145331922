import React from "react";
import { Link } from "react-router-dom";
import HttpClient from "../../../utils/HttpClient";
const Casting = (props) => {
  // console.log("spell casting Props data--",props.spellCastingData);
  let spellCastingData = props.spellCastingData;
  // console.log("spell casting",spellCastingData);
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }
  return (
    <div className="row casting_area px-2 px-md-3">
      {spellCastingData.length
        ? spellCastingData.map((item, index) => {
            return (
              <div
                className="col-6 col-xl-3 col-lg-3 col-md-4 px-2 px-md-4"
                key={index}
              >
                <div className="card text-center casting_sec p-md-2 my-md-4 my-3">
                  <div className="card-body casting_text p-md-0">
                    <img
                      className=""
                      src={HttpClient.IMG_URL + item.image}
                      alt="img"
                    />

                    <h4 className="my-3">{item.name}</h4>
                    <p>{item.description}</p>
                    <Link to={"/services/" + nameToSlug(item.name) + "/" + item._id}>
                      <button href="#" className="btn my-3 ">
                        View More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};
export default Casting;
