import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { MDBDataTableV5 } from "mdbreact";
import moment from "moment";

const Index = () => {
  const [service, setService] = useState([]);
  const [data, setdata] = useState([]);
  const [name, setname] = useState("");
  const [price, setprice] = useState("");
  const [details, setdetails] = useState("");
  const [personalisation, setpersonalisation] = useState("");
  const [hashtag, sethashtag] = useState("");
  const [serviceimg, setserviceimg] = useState("");

  const [arr, setarr] = useState([]);

  const [category, setcategory] = useState("");
  const [subcategory, setSubcategory] = useState("");

  const [categoryName, setcategoryName] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");

  const [cat, setcat] = useState([]);
  const [subcat, setsubcat] = useState([]);
  const [shopid, setshopid] = useState("");
  const [preview, setpreview] = useState([]);
  const [multiImages, setmultiImages] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [tax, settax] = useState(0);
  const [currency, setcurrency] = useState("");
  const [suspendstatus, setsuspendstatus] = useState("");

  const [currencyTyp, setcurrencyTyp] = useState([
    {
      name: "INR",
    },
    {
      name: "USD",
    },
    {
      name: "GBP",
    },
    {
      name: "EUR",
    },
    {
      name: "CAD",
    },
    {
      name: "cdd",
    },
  ]);

  useEffect(() => {
    getTicket();
  }, []);

  const publish = async () => {
    if (name != "" && details != "") {
      let userData = reactLocalStorage.getObject("userData");
      let sendData = {
        seller_id: userData._id,
        name: name,
        issue_for: details,
      };
      console.log("object", sendData);
      let result = await HttpClient.requestData("tickets", "POST", sendData);
      console.log("userSubs", result);
      if (result && result.status) {
        toast.success("Ticket Raised Successfully", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setname("");
        setdetails("");
        getTicket();
      } else {
      }
    } else {
      toast.warn("Please fillup all fields", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
   
  };

  const getTicket = async () => {
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData("tickets/" + userData._id, "GET");
    console.log("withdraw-history", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      result.data.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          date: moment(element.on_date).format("MMMM Do YYYY"),
          issue: element.issue_for,
        };
        i++;
        data.push(rows);
      });

      setdata(data);
      // setTable(true);
    } else {
      setdata([]);
    }
  };

  const dataa = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        width: 150,
      },

      {
        label: "Name",
        field: "name",
        width: 270,
      },

      {
        label: "Date",
        field: "date",
        width: 270,
      },
    ],
    rows: data,
  };

  return (
    <div className="Add_service  ">
      {/* <ToastContainer /> */}
      <div className="row mt-3">
        <div className="col-md-12 text-center">
          <h1>Add Ticket</h1>
          {suspendstatus == "suspend"
            ? "(Due to Suspension you can't create service)"
            : null}
        </div>
        <div className="col-md-8 mx-auto">
          <div className="form-group my-4">
            <label htmlFor="name">
              Name{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control _borderRed"
              id="name"
              aria-describedby="nameHelp"
              placeholder="Service Name"
              onChange={(val) => {
                setname(val.target.value);
              }}
              value={name}
            />
          </div>

          <div className="form-group my-4">
            <label htmlFor="details">
              Details{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <textarea
              className="form-control _borderRed"
              id="details"
              rows={3}
              placeholder="Please Add Your Service Details"
              onChange={(val) => {
                setdetails(val.target.value);
              }}
              value={details}
            />
          </div>

          <div className="d-flex justify-content-between my-5">
            {suspendstatus == "suspend" ? null : (
              <button
                type="button"
                className="btn publish_btn"
                onClick={publish}
              >
                Submit
              </button>
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <h1>Ticket Raise History</h1>
          </div>
          <br />
          <br />
          <div className="col-md-10 mx-auto">
            <MDBDataTableV5
              hover
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              data={dataa}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
