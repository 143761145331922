import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./Layout/index";
import VideoCall from "./Views/Messages/ChatBody/VideoModal";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "react-confirm-alert/src/react-confirm-alert.css";
import "./App.css";


const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <Switch>
  
        <Route
          path="/video-call"
          name="VideoCall"
          render={(props) => <VideoCall {...props} />}
        />
        <Route
          path="/"
          name="Layouts"
          render={(props) => <Layout {...props} />}
        />
      </Switch>
    </Router>
  );
};

export default App;
