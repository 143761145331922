import React, { useState, useEffect } from "react";
import Footer from "../../Defaults/Footer";
import Pagination from "../Components/Pagination";
import Filter from "../Services/Filter";
import SerarchListService from "./SerarchListService";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { ReactTitle } from "react-meta-tags";
const Index = (props) => {
  const [userdata, setuserdata] = useState(
    reactLocalStorage.getObject("userData")
  );
  useEffect(() => {
    getproduct();
  }, []);
  const [reviewlist, setreviewlist] = useState([]);
  const [pro, setpro] = useState([]);
  const [searchTitle, setsearchTitle] = useState({});

  const [length, setlength] = useState(0);

  const name = props.match.params.p1;
  const type = props.match.params.p2;
  // console.log(name);
  // console.log(type);
  const getproduct = async () => {
    // setLoading(true);
    let sendData = {
      type: type,
      searchname: name,
    };
    let endPint = "";
    if (Object.keys(userdata).length > 0) {
      endPint = "searchAll" + `?currency=` + userdata.currency;
    } else {
      // eslint-disable-next-line no-useless-concat
      endPint = "searchAll";
    }
    let result = await HttpClient.requestData(endPint, "POST", sendData);
    console.log("singlproresult", result);
    if (result && result.status) {
      setpro(result.data);
      setsearchTitle(result.data[0]);
      setlength(result.data.length);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1);
    } else {
    }
  };

  return (
    <div>
      <section className="Search-page">
        <ReactTitle title={name + " | Astrophy"} />
        {/* <ReactTitle title= " | Astrophy" /> */}
        <div className="container" style={{ maxWidth: "1200px" }}>
          <div className="row py-4">
            <div className="col-md-12">
              <h4>
                {length} search results <span>"{name}"</span>
              </h4>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-12">
              <Filter />
            </div> */}
          </div>
          <SerarchListService pro={pro} type={type} />
          <div className="row">
            <div className="col-md-12">{/* <Pagination /> */}</div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Index;
