import React, { useEffect, useState } from "react";
import Footer from "../../Defaults/Footer";
import Faq from "../Home/Faq/Faq";
import HttpClient from "../../utils/HttpClient";
import { ReactTitle } from "react-meta-tags";
const Category = (props) => {
    console.log("cat props", props.catId, props.subCatId);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllCategory();
    // setTimeout(() => {
    //   fetchSubCategory(props.catId);
    // }, 5000);
    
  }, []);
  const category = props.data;
  const [cat1, setCat1] = useState(false);
  const [allCategory, setallCategory] = useState([]);
  const [subCategory, setsubCategory] = useState([]);
  const [category_id, setcategory_id] = useState("");
  const [subCategory_id, setsubCategory_id] = useState("");
  
  const fetchAllCategory = async () => {
    let result = await HttpClient.requestData("viewallcat", "GET");
    console.log("All Category Data --", result);
    if (result && result.status) {
      setallCategory(result.data);
      fetchSubCategory(result.data[0]._id)
    } else {
      setallCategory([]);
    }
  };
  const handlecategory = (event) => {
    const selectedcategory = event.target.value;
    setcategory_id(selectedcategory);
    fetchSubCategory(selectedcategory);
  };

  const handleSubcategory = (event) => {
    const selectedSubcategory = event.target.value;
    setsubCategory_id(selectedSubcategory);
    let obj = {
      cat_id: category_id,
      subCat_id: selectedSubcategory,
    };
    // console.log("obj", obj);
    props.recieveData(obj);
  };

  const fetchSubCategory = async (id) => {
    console.log("catId",id);
    let result = await HttpClient.requestData("viewallsubcat/" + id, "GET");
    console.log("All Sub Category Data --", result);
    if (result && result.status) {
      setsubCategory(result.data);
    } else {
      setsubCategory([]);
    }
  };

  return (
    <div className=" ">
      <h6>Select Categories to view Faq</h6>
      <div className="faq_catagory mt-3">
        <div className="mb-4">
          <label className="font-weight-bold">Select Category</label>
          <div className="form-group">
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={handlecategory}
              value={category_id ? category_id : props.catId}
            >
              <option value="">Select Catagory</option>
              {allCategory.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="mb-4">
          <label className="font-weight-bold">Select Sub Category</label>
          <div className="form-group">
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={handleSubcategory}
              value={subCategory_id ? subCategory_id : props.subCatId}
              // onBlur={sendData}
            >
              <option>Select Sub Catagory</option>
              {subCategory.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {/* <div>
          <div>
            <div
              onClick={() => {
                setCat1(!cat1);
                setcategory_id(category._id);
                console.log("category_id", category_id);
              }}
              className="catagory_list my-4"
            >
              {category.name}
              {cat1 ? (
                <i className="fas fa-chevron-right"></i>
              ) : (
                <i className="fas fa-chevron-down"></i>
              )}
            </div>

            <div className={cat1 ? "d-block ml-4  faq_sub_catagory" : "d-none"}>
              {category.subcategory_data.map((item2, index2) => {
                return (
                  <li
                    onChange={(val) => {
                      setsubCategory_id(val.target.value);
                      console.log(subCategory_id);
                    }}
                    value={item2._id}
                  >
                    {item2.name}
                  </li>
                );
              })}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default Category;
