import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../Defaults/Footer";
import HttpClient from "../utils/HttpClient";
import Warning from "../Component/Warning";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";
import validator from "validator";

const ChangePaswword = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userData = reactLocalStorage.getObject("userData");
  const checkEmailed = reactLocalStorage.getObject("checkEmail");
  let { id } = useParams();
  const [newEmail, setnewEmail] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [emailerror, setemailerror] = useState(false);
  const [emailCheck, setemailCheck] = useState(false);

  const [emailvalid, setemailvalid] = useState(false);
  const [passvalid, setpassvalid] = useState(false);
  const [passerror, setpasserror] = useState(false);
  const [validConfirmPassword, setvalidConfirmPassword] = useState(false);

  const signinTyping = async (e) => {
    if (e.keyCode === 13) {
      ChangePaswword();
    }
  };

  const changePassword = async () => {
    let patternn =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;

  let passresult = patternn.test(newPassword);
    if (passresult !== true) {
      setpassvalid(true);
    } else if (confirmPassword != newPassword) {
      setvalidConfirmPassword(true);
    } else {
      let data = {
        id: id,
        email: checkEmailed,
        password: newPassword,
      };
      let result = await HttpClient.requestData("passwordChange", "POST", data);
      console.log("passwordChange", result);
      if (result && result.status) {
        toast.success("Password changed successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        reactLocalStorage.setObject("userData", result.data);
        reactLocalStorage.setObject("loginstatus", true);
        reactLocalStorage.setObject("loginToken", result.data.token);
        setTimeout(function () {
          window.location.href = "/dashboard";
        }, 2000);
      } else {
        toast.error("Error in server", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    
  };
  return (
    <div className="feedback">
      {/* <ToastContainer /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center my-4">
            <h2>Change Password</h2>
          </div>
          <div className="col-md-6 m-auto">
            <div className="form-group mb-3">
              {/* <label htmlFor="exampleFormControlTextarea1">Email</label> */}
              <input
                type="email"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Enter Email"
                value={checkEmailed}
                readOnly
                // onKeyUp={(e) => signinTyping(e)}
                // onChange={(val) => {
                //   setnewEmail(val.target.value);
                //   if (val.target.value != "") {
                //     setemailerror(false);
                //   } else {
                //     setemailerror(true);
                //   }
                //   if (validator.isEmail(newEmail)) {
                //     setemailvalid(false);
                //   } else {
                //     setemailvalid(true);
                //   }
                // }}
              />
            </div>
            <div className="form-group mb-3">
              {/* <label htmlFor="exampleFormControlTextarea1">New Password</label> */}
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="New Password"
                value={newPassword}
                onKeyUp={(e) => signinTyping(e)}
                onChange={(val) => {
                  setnewPassword(val.target.value);
                  var pattern = new RegExp(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/
                  );
                  if (pattern.test(val.target.value)) {
                    setpassvalid(false);
                  } else {
                    setpassvalid(true);
                  }
                }}
              />
              {passvalid ? (
                <Warning warning="Password must be at least 7 characters, 1 lower case, 1 upper case, and 1 number" />
              ) : null}
            </div>
            <div className="form-group mb-3">
              {/* <label htmlFor="exampleFormControlTextarea1">
                Confirm Password
              </label> */}
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Confirm Password"
                value={confirmPassword}
                onKeyUp={(e) => signinTyping(e)}
                onChange={(val) => {
                  setconfirmPassword(val.target.value);
                  if (val.target.value == newPassword) {
                    setvalidConfirmPassword(false);
                  } else {
                    setvalidConfirmPassword(true);
                  }
                }}
              />
              {validConfirmPassword ? (
                <Warning warning="Confirm Password must be same as Password" />
              ) : null}
            </div>
            <div className="text-center my-4">
              <button className="btn submit_btn" onClick={changePassword}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="mt-5">
        <Footer />
      </section>
    </div>
  );
};

export default ChangePaswword;
