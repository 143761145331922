import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import blankImg from "../../../Assets/Images/blank.jpg";
import { reactLocalStorage } from "reactjs-localstorage";
import provider_bg from "../../../Assets/Images/provider_bg.svg";
import { Link } from "react-router-dom";
import HttpClient from "../../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";

const Provider = (props) => {
  console.log("top provider props", props.data);
  const loginstatus = reactLocalStorage.getObject("loginstatus");
  const userData = reactLocalStorage.getObject("userData");
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const alertt = () => {
    toast.error("You have to login first", {
      position: "bottom-right",
      autoClose: 3000,
    });
  };
  const wishlist = async (item) => {
    // console.log("provider wishlist", item);
    let sending = {
      user_id: userData._id,
      seller_id: item._id,
      providername: item.firstName + " " + item.lastName,
    };
    console.log(sending);
    let result = await HttpClient.requestData(
      "providerwishlist",
      "POST",
      sending
    );
    console.log("wish provider---", result);
    if (result && result.status) {
      toast.success("Added to Wishlist", {
        position: "bottom-right",
        autoClose: 3000,
      });
      props.backprovider(result.status);
      // getprovider();
    } else {
      toast.error("Server error, try again later!", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const deleteWishlist = async (val) => {
    console.log("del", val);
    let data = {};
    let result = await HttpClient.requestData(
      "providerdeleteWishlist/" + val.providerwishlists_data[0]._id,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      toast.success("Remove from Wishlist", {
        position: "bottom-right",
        autoClose: 3000,
      });
      props.backprovider(result.status);
      // getprovider();
    } else {
      toast.error("Server error, try again later!", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }

  return (
    <div className="provider_sec">
      <div className="px-md-0 mx-0">
        <Slider {...settings}>
          {props.data.map((item, index) => {
            return index < 6 ? (
              <div className="px-0" key={index}>
                <div className="bg_lineCover" key={index}>
                  <div className="card slider2_part px-0 pt-0 pb-2" key={index}>
                    <div className="card-body slider2_body text-center p-0">
                      <img
                        src={
                          item.image
                            ? HttpClient.IMG_URL + item.image
                            : blankImg
                        }
                        alt="img1"
                        className="m-auto"
                      />
                      {loginstatus ? (
                        <>
                          {item.providerwishlists_data.length > 0 ? (
                            <button
                              className="_wislist_icon_465"
                              onClick={() => {
                                deleteWishlist(item);
                              }}
                            >
                              <i className="fas fa-heart text-danger"></i>
                            </button>
                          ) : (
                            <button
                              className="_wislist_icon_465"
                              onClick={() => {
                                wishlist(item);
                              }}
                            >
                              <i className="far fa-heart text-danger"></i>
                            </button>
                          )}
                        </>
                      ) : (
                        <button className="_wislist_icon_465" onClick={alertt}>
                          <i className="fas fa-heart text-light"></i>
                        </button>
                      )}
                      <div className="px-3">
                        <h5 className="card-title my-2 ">
                          {item.firstName + " " + item.lastName}
                        </h5>
                        <p className="card-text">
                          {item.shop_data ? item.shop_data.name : ""}
                        </p>
                        <div className="d-flex justify-content-between mt-2 mb-2 align-items-center">
                          <span className="d-flex justify-content-center align-items-baseline">
                            <i className="fas fa-star mr-2"></i>
                            <p>
                              {item.avgRating ? item.avgRating.toFixed(1) : "0"}
                              /5
                            </p>
                          </span>
                          <span className="mb-0">
                            {/* <b className="d-none d-md-block">Sales:</b>{" "} */}
                            {item.salesCount} Sales
                          </span>
                        </div>
                        <Link to={"/service-provider/" + nameToSlug(item.shop_data.name) + "/" + item._id}>
                          <button href="#" className="btn">
                            View More
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </Slider>
      </div>
    </div>
  );
};
export default Provider;
