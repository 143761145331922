import React, { useEffect, useState } from "react";
import WhatsApp_icon from "../../Assets/Images/WhatsApp_icon.png";
import Facebook_icon from "../../Assets/Images/Facebook_icon.png";
import contact_logo from "../../Assets/Images/Vector (6).png";
import Favourite from "../../Assets/Images/favourite.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import RatingChanged from "../../Component/RatingChanged";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import Profile from "../../Assets/Images/user.jpg";
import ReactStars from "react-rating-stars-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import db from "../../Firebase/fbconfig";
import { ref, child, get, set } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import Insta from "../../Assets/Images/insta.png";
import FB from "../../Assets/Images/fb.png";
import P_icon from "../../Assets/Images/p icon.png";
import Youtube from "../../Assets/Images/youtube.png";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { confirmAlert } from "react-confirm-alert";

const Details = (props) => {
  console.log("propsss", props.service);

  const image = props.service.image ? props.service.image : [];
  const [counter, setCounter] = useState(1);
  const userData = reactLocalStorage.getObject("userData");
  const loginstatus = reactLocalStorage.getObject("loginstatus");
  const [roomId, setroomId] = useState("");
  const [bgImage, setBgImage] = useState("");

  const [isWishlisted, setisWishlisted] = useState(false);
  const [cartBtn, setCartBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [servconfid, setservconfid] = useState("");

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getcart();
    getwishlist();
  }, []);

  const getcart = async () => {
    let data = {};
    setLoading(true);
    let result = await HttpClient.requestData(
      "servicecart/" + userData._id,
      "GET",
      data
    );
    let _id = props.service._id;
    // console.log("cartred data", result.data, _id);

    if (result && result.status) {
      let newData = result.data.filter((item) => item.serv_id == _id);
      console.log("nreData", newData[0]);
      if (newData.length > 0) {
        setCartBtn(true);
        setservconfid(newData[0]._id);
      }
      setLoading(false);
      // console.log("newData", newData);
    } else {
    }
  };

  const getwishlist = async () => {
    setLoading(true);
    let data = {};
    let result = await HttpClient.requestData(
      "servicegetWishlist/" + userData._id,
      "GET",
      data
    );
    let _id = props.service._id;
    if (result && result.status) {
      // console.log("wishlisted data", result.data);
      let newData = result.data.filter((item) => item.serv_id === _id);
      if (newData.length > 0) {
        setisWishlisted(true);
        setservconfid(newData[0]._id);
      }
      setLoading(false);
    } else {
      // console.log("error data", result.data);
    }
  };

  const book = async () => {
    if (cartBtn) {
      // window.history.pushState(null, "", "/checkoutservice");
      window.location.href = "/checkoutservice";
    } else {
      let data = {
        user_id: userData._id,
        serv_id: props.service._id,
        currency: userData.currency,
        servicename: props.service.name,
        price: props.service.price,
        image: props.service.image[0],
        stype: props.stype,
        orderFrom: "web",
        seller_id: props.service.shop_details.user_data._id,
      };
      let result = await HttpClient.requestData("servicecart", "POST", data);
      // console.log("result", result);
      if (result && result.status) {
        // window.history.pushState(null, "", "/checkoutservice");
        window.location.href = "/checkoutservice";
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const addTocart = async () => {
    let data = {
      user_id: userData._id,
      serv_id: props.service._id,
      currency: userData.currency,
      seller_id: props.service.seller_id,
      servicename: props.service.name,
      price: props.service.price,
      image: props.service.image[0],
      stype: props.stype,
      orderFrom: "web",
      // currency: props.service.currency,
      // seller_id: props.service.shop_details.user_data._id,
    };
    // console.log("DATA HERE", data);
    let result = await HttpClient.requestData("servicecart", "POST", data);
    // console.log("result here", result);
    if (result && result.status) {
      setCartBtn(true);

      toast.success(result.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      reactLocalStorage.setObject("cartitem", data);
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const alert = () => {
    toast.error("You have to login first", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });
  };

  const chat = (data) => {
    console.log("data", data.shop_details.user_data);
    // reactLocalStorage.setObject("chatuser", data.shop_details.user_data);
    const dbRef = ref(db);
    get(
      child(
        dbRef,
        `/chatList/${userData._id}/${data.shop_details.user_data._id}`
      )
    )
      .then((snapshot) => {
        if (snapshot.exists()) {
          // setnewchat(item);
          // setnochatopen(true);
          console.log(snapshot.val());
          setroomId(snapshot.val().roomId);
          setTimeout(() => {
            window.location.href = `/messages/${snapshot.val().roomId}`;
          }, 100);
        } else {
          // setnewchat(item);
          // setnochatopen(true);
          let roomId = uuidv4();
          console.log("No data available");
          // console.log(`/chatList/${userData._id}/${item._id}`);
          set(
            ref(
              db,
              `/chatList/${userData._id}/${data.shop_details.user_data._id}`
            ),
            {
              name:
                data.shop_details.user_data.firstName +
                " " +
                data.shop_details.user_data.lastName,
              displayName: data.shop_details.user_data.firstName,
              userId: data.shop_details.user_data._id,
              roomId: roomId,
            }
          );
          set(
            ref(
              db,
              `/SellerToSellerchatList/${userData._id}/${data.shop_details.user_data._id}`
            ),
            {
              name: userData.firstName + " " + userData.lastName,
              displayName: userData.firstName,
              userId: userData._id,
              roomId: roomId,
            }
          );

          setroomId(roomId);
          setTimeout(() => {
            window.location.href = `/messages/${roomId}`;
          }, 100);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    // setTimeout(() => {
    //   window.location.href = "/messages";
    // }, 100);
  };

  const wishlist = async () => {
    let data = {
      user_id: userData._id,
      serv_id: props.service._id,
      servicename: props.service.name,
      price: props.service.price,
      image: props.service.image[0],
      seller_id: props.service.shop_details.user_data._id,
    };
    console.log("data", data);
    let result = await HttpClient.requestData("servicewishlist", "POST", data);
    // console.log("result", result);
    if (result && result.status) {
      localStorage.setItem("wishlisted", result.data);
      setisWishlisted(true);
      toast.success("Service added to Wishlist", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const facebookShare = () => {
    const facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=";
    const currentUrl = window.location.href;
    return facebookUrl + currentUrl;
  };

  function curURL() {
    console.log("url", window.location.href);
    return window.location.href;
    //return ("https://www.geeksforgeeks.org/");
  }

  // function fetchTitle() {
  //   console.log("url", window.location.href);
  //   return ("Service Name:" + "Test" + ", Seller Name:" + "Anisha");
  //   //return ("https://www.geeksforgeeks.org/");
  // }

  const showReviewSection = () => {
    props.showReviewData(true);
  };

  const confirmyes = async (id) => {
    let data = {};
    let result = await HttpClient.requestData(
      "servicecart/" + id,
      "DELETE",
      data
    );
    // console.log("result", result);
    if (result && result.status) {
      toast.success("Cart Deleted!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setCartBtn(false);
      // window.location.href = "/home";
    } else {
    }
  };

  const confirmyeswish = async (val) => {
    console.log("del", val);
    let data = {};
    let result = await HttpClient.requestData(
      "servicedeleteWishlist/" + val,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      toast.success("Remove from wishlist", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setisWishlisted(false);
    } else {
    }
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(" ", "-");
    return strReplace;
  };

  return (
    <div>
      <div className="row  Details">
        {/* <ToastContainer /> */}
        <div className="col-md-1 col-sm-2 col-xs-2">
          <Slider {...settings}>
            {image.map((item2, index2) => {
              return (
                <div
                  className="my-3 mr-2 border"
                  onClick={() => setBgImage(item2)}
                >
                  <img
                    className="p-2"
                    src={HttpClient.IMG_URL + item2}
                    alt="img1"
                    style={{
                      width: "100%",
                      MaxWidth: "120px",
                      height: "80px",
                    }}
                    key={index2}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="col-md-5 col-sm-10 col-xs-10">
          <div>
            <img
              src={`https://admin.astrophy.com/${bgImage ? bgImage : image[0]}`}
              alt="img"
              className="detail_pic relative"
            />
          </div>
          {/* <div className="absolute_bg">
          <img src={Favourite} alt="img" className="absolute m-2" />
        </div> */}
        </div>
        <div className="col-md-6 px-md-4 col-sm-12 col-xs-12 details_text">
          <div className="d-md-flex details_sub_text justify-content-between flex-md-column flex-lg-row">
            <div className="d-flex">
              <img
                src={`https://admin.astrophy.com/${
                  props.service.shop_details
                    ? props.service.shop_details.shop_img
                    : Profile
                }`}
                alt="img"
                style={{ width: "100%", height: "45px", maxWidth: "45px" }}
              />
              <div className="mx-2">
                <Link
                  to={
                    "/service-provider/" +
                    nameToSlug(props.service.shop_details.name) +
                    "/" +
                    props.service.shop_details.userid
                  }
                >
                  <h5>
                    {props.service.shop_details
                      ? props.service.shop_details.name
                      : null}
                  </h5>
                </Link>
                <p>
                  Owner of{" "}
                  {props.service.shop_details
                    ? props.service.shop_details.name
                    : ""}
                </p>
              </div>
            </div>

            {loginstatus ? (
              <div
                className="contact my-2 my-lg-0"
                style={{ cursor: "pointer" }}
                onClick={() => chat(props.service)}
              >
                <img
                  src={contact_logo}
                  alt="img"
                  className="mx-2"
                  style={{ width: "18px" }}
                />
                <span>Contact</span>
              </div>
            ) : (
              <div
                className="contact my-2 my-lg-0"
                style={{ cursor: "pointer" }}
                onClick={alert}
              >
                <img
                  src={contact_logo}
                  alt="img"
                  className="mx-2"
                  style={{ width: "18px" }}
                />
                <span>Contact</span>
              </div>
            )}
            {loginstatus ? (
              <>
                {isWishlisted ? (
                  <div
                    className="contact my-2 my-lg-0"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      confirmyeswish(servconfid);
                      // confirmAlert({
                      //   title: "Already in Wishlist",
                      //   message: "Do you want to remove from wishlist?",
                      //   buttons: [
                      //     {
                      //       label: "Yes",
                      //       onClick: () => confirmyeswish(servconfid),
                      //     },
                      //     {
                      //       label: "No",
                      //       // onClick: () => confirmno(),
                      //     },
                      //   ],
                      // });
                    }}
                  >
                    <i
                      className="fas fa-heart text-danger mx-2 text-center"
                      style={{ width: "18px" }}
                    ></i>
                    <span>Service Wishlisted</span>
                  </div>
                ) : (
                  <div
                    className="contact my-2 my-lg-0 "
                    style={{ cursor: "pointer" }}
                    onClick={wishlist}
                  >
                    <i
                      className="far fa-heart text-danger mx-2 text-center"
                      style={{ width: "18px" }}
                    ></i>
                    <span>Add to Wishlist</span>
                  </div>
                )}
              </>
            ) : (
              <div className="contact my-2 my-lg-0" onClick={alert}>
                <i
                  className="far fa-heart text-danger mx-2 text-center"
                  style={{ width: "18px" }}
                ></i>
                <span>Add to Wishlist</span>
              </div>
            )}
          </div>
          <div className="rating_sec">
            <i
              className="fas fa-star my-1 mx-2 text-center"
              style={{ width: "18px" }}
            ></i>
            {props.service.avgRating ? props.service.avgRating : 0}/5{" "}
            <span className="mx-3">{props.salescount} Sales</span>
          </div>
          <div className="heading">
            <h3 className="my-3">{props.service.name}</h3>
          </div>
          <div className="d-md-flex flex-wrap rupee_sec">
            <h5>
              {userData.currency == "INR"
                ? "₹"
                : userData.currency == "CAD"
                ? "$"
                : userData.currency == "USD"
                ? "$"
                : userData.currency == "	GBP"
                ? "£"
                : userData.currency == "EUR"
                ? "€"
                : "₹"}{" "}
              {props.service.price}
            </h5>
            {/* <p className="mx-md-4">
            <i className="fas fa-check-circle mr-2"></i>
            In Stock
          </p> */}

            {/* <div className="ml-md-auto my-md-0 my-3 mr-5 counter_sec">
            <button
              className="btn mx-3"
              onClick={() => setCounter(counter + 1)}
            >
              <i className="fas fa-minus"></i>
            </button>
            <span>{counter}</span>
            <button
              className="btn mx-3"
              onClick={() => setCounter(counter - 1)}
            >
              <i className="fas fa-plus"></i>
            </button>
          </div> */}
          </div>
          <div>
            {" "}
            <span className="text-muted">
              Local taxes included (where applicable)
            </span>
          </div>
          <div>
            {" "}
            <div className="form-group my-3">
              {/* <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                placeholder="Add your personalisation"
              /> */}
            </div>
          </div>
          <div className="bottom_text">
            <p>
              Please send me your names & dates of birth (if known). Then i will
              cast your Spell & send you an etsy message within 24 hours,{" "}
            </p>
          </div>

          {loginstatus && Object.keys(userData).length ? (
            <div className="d-flex basket_btn mb-2">
              {cartBtn ? (
                <button
                  className="btn btn-success mr-3"
                  type="button"
                  style={{ background: "green" }}
                  onClick={() => {
                    confirmAlert({
                      title: "Already in service cart",
                      message: "Do you want to delete this?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => confirmyes(servconfid),
                        },
                        {
                          label: "No",
                          // onClick: () => confirmno(),
                        },
                      ],
                    });
                  }}
                >
                  <i className="fas fa-check mr-2"></i>
                  Added to cart
                </button>
              ) : (
                <button
                  className="btn btn-dark mr-3"
                  type="button"
                  style={{ background: "#343a40" }}
                  onClick={addTocart}
                >
                  <i className="fas fa-shopping-cart mr-2"></i>
                  Add to cart
                </button>
              )}

              <button className="btn" onClick={book}>
                Buy It Now
              </button>
            </div>
          ) : (
            <div className="d-flex basket_btn my-3">
              <button
                className="btn btn-dark mr-3"
                type="button"
                style={{ background: "#343a40" }}
                onClick={alert}
              >
                <i className="fas fa-shopping-cart mr-2"></i>
                Add to cart
              </button>
              <button className="btn" onClick={alert}>
                Buy It Now
              </button>
            </div>
          )}
          <div className="Highlights mb-4">
            {/* <h5>Highlights</h5> */}
            {props.service.hasOwnProperty("highlights") &&
            props.service.highlights != null &&
            props.service.highlights.length ? (
              <div>
                <h5>Highlights</h5>
                {props.service.highlights.map((item, index) => {
                  return (
                    <p>
                      {" "}
                      <i className="fas fa-check-circle mr-2"></i>
                      {item.highlight}
                      <br></br>
                      {/* <i className="fas fa-check-circle mr-2"></i>
                        Materials: Crystal */}
                    </p>
                  );
                })}
              </div>
            ) : null}
          </div>

          <div className="d-flex align-items-center">
            <p className="text-dark mb-0 mr-2">Share by:</p>
            <div>
              {/* <a
                href={facebookShare()}
                className="btn bg-transparent p-0"
                target="_blank"
              >
                <img src={Facebook_icon} alt="img" className="app_img" />
              </a> */}
              <FacebookShareButton
                url={curURL()}
                // quote={fetchTitle()}
              >
                <FacebookIcon size={20} round={false} />
              </FacebookShareButton>
              &nbsp;&nbsp;
              {/* <a
                type="button"
                className="btn bg-transparent p-0"
                href="https://wa.me/whatsappphonenumber/?text=urlencodedtext"
              // action="share/whatsapp/share"
              // target="_blank"
              >
                {" "}
                <img src={WhatsApp_icon} alt="img" className="app_img" />
              </a> */}
              <WhatsappShareButton
                url={curURL()}
                // title={fetchTitle()}
              >
                <WhatsappIcon size={20} round={false} />
              </WhatsappShareButton>
              {/* <button type="button" className="btn bg-dark mx-3">
              
              <img
                src={P_icon}
                alt="img"
                className="mx-2"
                className="app_img"
              />
            </button> */}
              {/* <button type="button" className="btn bg-danger ">
              
              <img
                src={Youtube}
                alt="img"
                className="mx-3"
                className="app_img"
              />
            </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row px-md-4 mb-n5 pb-3">
        <div className="col-md-12 rating_sec ">
          <div
            className="d-flex align-items-center justify-content-end"
            onClick={showReviewSection}
            style={{ cursor: "pointer" }}
          >
            <h6 className="mr-3 mb-0">{props.reviewcount} Reviews</h6>
            {/* <RatingChanged /> */}
            <ReactStars
              count={5}
              value={
                Number(props.service.avgRating)
                  ? Number(props.service.avgRating)
                  : 0
              }
              // onChange={ratingChanged}
              size={24}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
              edit={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
