import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner1_img from "../../../Assets/Images/bannerImage.jpg";
import banner2_img from "../../../Assets/Images/bannerImage2.jpg";
import banner3_img from "../../../Assets/Images/bannerImage3.jpg";
import { Link } from "react-router-dom";
import HttpClient from "../../../utils/HttpClient";

const Banner = () => {
  const [bannerData, setBannerData] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const bannerArray = [
    {
      img: banner1_img,
      name: "Time To Unfold The Secret Of Your Love Life With Psychic Love  Reading Sessions.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat nibh cursus ",
    },
    {
      img: banner2_img,
      name: "Let Manifestation Of Magic Embrace Your Life!",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat nibh cursus ",
    },
    {
      img: banner3_img,
      name: "A Psychic Reading Brings Clarity To Your Life. ",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat nibh cursus ",
    },
  ];

  const [service, setService] = useState([]);

  useEffect(() => {
    fetchService();
    fetchHomeBannerData();
  }, []);

  const fetchHomeBannerData = async () => {
    let result = await HttpClient.requestData("cms/banner", "GET");
    console.log("cms/banner", result);
    if (result && result.status) {
      // if (result.data.status) {
      //   setBannerData(result.data);
      // }
      let activeBanner = [];
      result.data.map((item) => {
        if (item.status) {
          activeBanner.push(item);
        }
      });
      console.log("banner-------", activeBanner);
      setBannerData(activeBanner);
    } else {
    }
  };

  const fetchService = async () => {
    let result = await HttpClient.requestData("service", "GET");
    console.log("Service", result);
    if (result && result.status) {
      let data = result.data;
      setService(data);
    } else {
    }
  };
  return (
    <div className="">
      <div className="banner  ">
        <Slider {...settings}>
          {bannerData.map((item, index) => {
            return (
              <div className="banner_slider" key={index}>
                <div
                  className="slider_ban"
                  style={{
                    backgroundImage: `url('${
                      item.image ? HttpClient.IMG_URL + item.image : banner1_img
                    }')`,
                  }}
                  key={index}
                >
                  <div className="container h-100">
                    <div className="slider_text">
                      <h1 className="">{item.name}</h1>
                      <p className="lead mt-3">{item.description}</p>
                      {/* <Link to="/astrophy-mall"> */}
                      <button
                        className="btn my-md-3 mb-md-0"
                        role="button"
                        onClick={() => (window.location.href = item.link)}
                      >
                        Shop Now<i className="fas fa-chevron-right ml-2"></i>
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
                {/* <div className="overlay"></div> */}
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
export default Banner;
