import React, { useEffect, useState } from "react";
import Blog_ban from "../../Assets/Images/blog_ban_img.png";
import Footer from "../../Defaults/Footer";
// import Pagination from "../Components/Pagination";
import LatestBlog from "./LatestBlog";
import MorePopular from "./MorePopular";
import SubsBox from "./SubsBox";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "../../utils/HttpClient";
import moment from "moment";
import bgBlog from "../../Assets/Images/blogBg.jpg";

const Index = () => {
  const [blogData, setblogData] = useState([]);
  const [blogCategory, setblogCategory] = useState([]);
  const [randomBlog, setRandomBlog] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogData();
    getBlogCategory();
  }, []);

  const getBlogCategory = async () => {
    let result = await HttpClient.requestData("viewblogcategory", "GET");
    // console.log("result viewblogcategory--", result);
    if (result && result.status) {
      setblogCategory(result.data);
    }
  };

  const getBlogData = async () => {
    let result = await HttpClient.requestData("Blog", "GET");
    // console.log("result blog data--", result);
    if (result && result.status) {
      setblogData(result.data);
    }
    // const itRandom = result.data;
    // const randomIndex = Math.floor(Math.random() * itRandom.length);
    // const randomItem = itRandom.splice(randomIndex, 1)[0];
    // setRandomBlog(randomItem);
  };
  // const [tab, setTab] = useState("all");

  const getFilteredData = async (id) => {
    let result = await HttpClient.requestData("Blog", "GET");
    console.log("result blog data--", result);
    if (result && result.status) {
      let filterData = result.data.filter((item) => item.category_id === id);
      setblogData(filterData);
    } else {
      setblogData(result.data);
    }
  };

  return (
    <div>
      <div className="wrapper">
        <ReactTitle title="Blog | Astrophy" />
      </div>
      {/* <section>
        <div className="container-fluid" style={{ maxWidth: "1200px" }}>
          <div className="row p-4">
            <div className="col-md-12 service_head">
              <h3>Blogs</h3>
              <div className="d-md-flex  my-1"> */}
      {/* <div>
                  <button
                    className={
                      tab === "all"
                        ? "provide_tab active btn mr-2 my-md-0 my-2"
                        : "provide_tab btn mr-2 my-md-0 my-2"
                    }
                    onClick={() => {
                      setTab("all");
                    }}
                  >
                    All (42)
                  </button>
                  <button
                    className={
                      tab === "psychic"
                        ? "provide_tab active btn mr-2 my-md-0 my-2"
                        : "provide_tab btn mr-2 my-md-0 my-2"
                    }
                    onClick={() => {
                      setTab("psychic");
                    }}
                  >
                    Category 1 (39)
                  </button>
                  <button
                    className={
                      tab === "reiki"
                        ? "provide_tab active btn mr-2 my-md-0 my-2"
                        : "provide_tab btn mr-2 my-md-0 my-2"
                    }
                    onClick={() => {
                      setTab("reiki");
                    }}
                  >
                    Category 2 (03)
                  </button>
                  <button
                    className={
                      tab === "preg"
                        ? "provide_tab active btn mr-2 my-md-0 my-2"
                        : "provide_tab btn mr-2 my-md-0 my-2"
                    }
                    onClick={() => {
                      setTab("preg");
                    }}
                  >
                    Category 3 (06)
                  </button>
                </div> */}
      {/* <div className="form-group d-flex ml-auto review_sort">
                  <h6 className="mr-3 my-2">SortBy:</h6>
                  <select 
                    className="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option>Newly Added</option>
                    <option>Most Popular</option>
                  </select>
                </div> */}
      {/* </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section>
        <div className="container-fluid mb-4" style={{ maxWidth: "1200px" }}>
          <div className="row">
            <div className="col-lg-9" onClick={() => {
              window.location.href = "/blog-details/" + randomBlog.heading + "/" + randomBlog._id
            }}>
              <div className="blog_ban_img">
                <img
                  src={HttpClient.IMG_URL + randomBlog.image}
                  alt="img"
                  style={{ borderRadius: "10px" }}
                />
                <div className="blog_ban_overlay d-flex justify-content-center align-items-end">
                  <h6>{randomBlog.heading}</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-lg-0 my-4 ">
              <SubsBox />
            </div>
          </div>
        </div>
      </section> */}
      {/* Blog Banner */}
      <section
        className="page_top_section"
        style={{
          background: `url(${bgBlog})`,
        }}
      >
        <div className="page_top">
          <div className="page_top_title">
            <h3>blogs</h3>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam,
              asperiores?
              <br /> Lorem ipsum dolor sit amet.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 Latest_blog" style={{padding: 0}}>
              {/* <h6>Latest <span>Blogs</span></h6> */}
              {/* <div className="container"> */}
              <section className="suggested_section">
                <div className="d-none d-xxl-block d-xl-block d-lg-block d-md-block">
                  <ul>
                    <li className="suggested_li">Suggested:</li>
                    <li>
                      <div className="suggested_btn_div">
                        <button className="btn" onClick={() => getBlogData()}>
                          All
                        </button>
                      </div>
                    </li>
                    {blogCategory.length
                      ? blogCategory.map((item, index) => {
                          return (
                            <li>
                              <div className="suggested_btn_div">
                                <button
                                  className="btn"
                                  onClick={() => getFilteredData(item._id)}
                                >
                                  {item.category_name}
                                </button>
                              </div>
                            </li>
                          );
                        })
                      : null}
                    {/* <li>
                      <div className="suggested_btn_div">
                        <button className="btn">All (42)</button>
                        <button className="btn">Category 1 (39)</button>
                        <button className="btn">Category 2 (03)</button>
                        <button className="btn">Category 3 (06)</button>
                      </div>
                    </li> */}
                  </ul>
                </div>
                <div className=" ">
                  <div className=" d-block d-xxl-none d-xl-none d-lg-none d-md-none">
                    <div className="suggested_li">Suggested</div>
                    <div className="blog-filter">
                      <div className="blog-filter-stage">
                        <div
                          className=""
                          style={{
                            transform: "translate3d(0px, 0px, 0px)",
                            transition: "all 0s ease 0s",
                          }}
                        >
                          <div className="blog-filter-stage">
                            <div className="item">
                              <div className="suggested_btn_div">
                                <button
                                  className="btn"
                                  onClick={() => getBlogData()}
                                >
                                  All
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {blogCategory.length
                      ? blogCategory.map((item, index) => {
                          return (
                            <div className="blog-filter">
                              <div className="blog-filter-stage">
                                <div
                                  className=""
                                  style={{
                                    transform: "translate3d(0px, 0px, 0px)",
                                    transition: "all 0s ease 0s",
                                  }}
                                >
                                  <div className="blog-filter-stage">
                                    <div className="item">
                                      <div className="suggested_btn_div">
                                        <button
                                          className="btn"
                                          onClick={() =>
                                            getFilteredData(item._id)
                                          }
                                        >
                                          {item.category_name}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}
                    {/* <div className="blog-filter">
                      <div className="blog-filter-stage">
                        <div
                          className=""
                          style={{
                            transform: "translate3d(0px, 0px, 0px)",
                            transition: "all 0s ease 0s",
                          }}
                        >
                          <div className="blog-filter-stage">
                            <div className="item">
                              <div className="suggested_btn_div">
                                <button className="btn">All (42)</button>
                              </div>
                            </div>
                          </div>
                          <div className="blog-filter-stage">
                            <div className="item">
                              <div className="suggested_btn_div">
                                <button className="btn">Category 1 (39)</button>
                              </div>
                            </div>
                          </div>
                          <div className="blog-filter-stage">
                            <div className="item">
                              <div className="suggested_btn_div">
                                <button className="btn">Category 2 (03)</button>
                              </div>
                            </div>
                          </div>
                          <div className="blog-filter-stage">
                            <div className="item">
                              <div className="suggested_btn_div">
                                <button className="btn">Category 3 (06)</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </section>

              {/* </div> */}
              <div className="container">
                <LatestBlog blogData={blogData} />
              </div>
            </div>
            {/* <div className="col-md-3 Latest_blog">
              <h6>More <span>Popular</span></h6>
              <MorePopular blogData={blogData} />
            </div> */}
          </div>
        </div>
      </section>
      {/* <section className="container">
        <div className="row">
          <div className="col-md-12 ">
            <Pagination />
          </div>
        </div>
      </section> */}
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Index;
