import React, { useState } from "react";
import Slider from "react-slick";
import Provider_name from "../../Assets/Images/user.jpg";
import blankImg from "../../Assets/Images/blank.jpg";
import Favourite from "../../Assets/Images/favourite.png";
import { Link } from "react-router-dom";
import ServiceModal from "../../Seller/MyServices/ServiceModal";
import ServiceDlt from "../../Seller/MyServices/ServiceDlt";
import EmptyService from "../EmptyProduct/EmptyService";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const ServiceList = (props) => {
  const userData = reactLocalStorage.getObject("userData");
  console.log("props.data service", props.data);
  const [servicemodal, setServicemodal] = useState(false);
  const [servicedlt, setServicedlt] = useState(false);
  const [activemodal, setactivemodal] = useState({});
  const [delid, setdelid] = useState(false);
  const loginstatus = reactLocalStorage.getObject("loginstatus");
  const styles = {
    color: "green",
    cursor: "default",
  };
  const callBackService = (val, val1) => {
    setServicemodal(val);
    setactivemodal({});
    if (val1 != "cancel") {
      window.location.href = "/seller-service";
    }
  };
  const callBackServiceDlt = (val, val1) => {
    setServicedlt(val);
    if (val1 != "cancel") {
      window.location.href = "/seller-service";
    }
  };

  const success = (val) => {
    // console.log("object", val);
    if (val) {
      props.refresh(true);
    }
  };

  // const handleSubmit =(itm) =>{
  //   setactivemodal(itm);
  //   console.log("activemodal",activemodal);
  //   setTimeout(() => {
  //     setServicemodal(true);
  //   }, 100);
  // };
  // console.log("activeModal", activemodal);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const loop = [1, 2, 3, 4];
  const book = async (item) => {
    if (item.cart_items.length > 0) {
      // window.history.pushState(null, "", "/checkoutservice");
      window.location.href = "/checkoutservice";
    } else if (userData.currency == undefined || userData.currency == "") {
      toast.error("Please add currency to your account", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else {
      let data = {
        user_id: userData._id,
        serv_id: item._id,
        seller_id: item.seller_id,
        servicename: item.name,
        currency: userData.currency,
        price: item.price,
        image: item.image[0],
        stype: item.stype,
        orderFrom: "web"
        // seller_id: item.shop_details[0].userid,
      };
      console.log("crt data---",data);
      let result = await HttpClient.requestData("servicecart", "POST", data);
      console.log("result", result);
      if (result && result.status) {
        // window.history.pushState(null, "", "/checkoutservice");
        window.location.href = "/checkoutservice";
      } else {
        toast.error(result.message, {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    }
  };
  const addTocart = async (item) => {
    if (userData.currency == undefined || userData.currency == "") {
      toast.error("Please add currency to your account", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else {
      let data = {
        user_id: userData._id,
        serv_id: item._id,
        currency: userData.currency,
        seller_id: item.seller_id,
        servicename: item.name,
        price: item.price,
        image: item.image[0],
        stype: item.stype,
        orderFrom: "web"
        // currency: item.currency,
        // seller_id: props.service.shop_details.user_data._id,
      };
      console.log("DATA HERE", data);
      let result = await HttpClient.requestData("servicecart", "POST", data);
      console.log("result here", result);
      if (result && result.status) {
        toast.success(result.message, {
          position: "bottom-right",
          autoClose: 3000,
        });
        props.callbackserv(result.data);
      } else {
        toast.error(result.message, {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    }
   
  };

  const wishlist = async (item, e) => {
    // e.preventDefault();
    // console.log("wishlist item----",item);
    let data = {
      user_id: userData._id,
      serv_id: item._id,
      servicename: item.name,
      price: item.price,
      image: item.image[0],
      seller_id: item.shop_details[0].userid,
    };
    console.log("data", data);
    let result = await HttpClient.requestData("servicewishlist", "POST", data);
    // console.log("result", result);
    if (result && result.status) {
      localStorage.setItem("wishlisted", result.data);
      // setisWishlisted(true);
      toast.success("Service added to Wishlist", {
        position: "bottom-right",
        autoClose: 3000,
      });
      props.callbackserv(result.data);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };
  const confirmyeswish = async (val, e) => {
    // e.preventDefault();
    console.log("del", val);
    let data = {};
    let result = await HttpClient.requestData(
      "servicedeleteWishlist/" + val.wishlist_data[0]._id,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      // setisWishlisted(false)
      props.callbackserv(result.data);
    } else {
    }
  };

  const activeData = async (id) => {
    console.log("clicked");
    let result = await HttpClient.requestData(
      "deactivate-service/" + id,
      "PUT"
    );
    console.log(" deactivate result", result);
    if (result && result.status) {
      // setisWishlisted(false)
      window.location.href = "/seller/seller-service";
    } else {
    }
  };
  const alertt = () => {
    toast.error("You have to login first", {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }

  return (
    <div>
      <ToastContainer />
      <div className="row px-0">
        {props.data.length > 0 ? (
          props.data.map((item, index) => {
            return (
              <div className="col-6 col-md-4 col-lg-3 col-sm-6 my-3 px-2 px-md-3" key={index}>
                <div key={index}>
                  <div className="card service_list _service_Allpage custom-mob-service-list">
                    <Slider {...settings}>
                      {item.image
                        ? item.image.map((item2, index2) => {
                            return (
                              <div key={index2}>
                                {props.type == "edit" ? (
                                  <img
                                    src={
                                      item2
                                        ? HttpClient.IMG_URL + item2
                                        : blankImg
                                    }
                                    alt="img"
                                    className="relative service_list_img"
                                  />
                                ) : (
                                  <Link to={"/service-details/" + nameToSlug(item.name) + "/" + item._id}>
                                    <img
                                      src={
                                        item2
                                          ? HttpClient.IMG_URL + item2
                                          : blankImg
                                      }
                                      alt="img"
                                      className="relative service_list_img"
                                    />
                                  </Link>
                                )}
                                {/* <img
                                  src={Favourite}
                                  alt="img"
                                  className="absolute m-2"
                                /> */}
                              </div>
                            );
                          })
                        : ""}
                    </Slider>

                    <div className="card-body service_list_text service-list-custom px-md-3 pt-2">
                      {props.type === "edit" ? (
                        <p className="card-text">{item.name}</p>
                      ) : (
                        <Link to={"/service-details/" +  nameToSlug(item.name) + "/" + item._id}>
                          <p className="card-text">{item.name}</p>
                        </Link>
                      )}
                      <div className="cutom-service-box">
                          <div className="pricing">
                          {props.type === "edit" ? (
                        <h5 className="my-3">
                        {item.currency == "INR"
                          ? "₹"
                          : item.currency == "CAD"
                          ? "$"
                          : item.currency == "USD"
                          ? "$"
                          : item.currency == "	GBP"
                          ? "£"
                          : item.currency == "EUR"
                          ? "€"
                          : "₹"}{" "}
                        {item.price.toFixed(2)}
                      </h5>
                      ) : (<h5 className="my-3 test">
                      {userData.currency == "INR"
                        ? "₹"
                        : userData.currency == "CAD"
                        ? "$"
                        : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "	GBP"
                        ? "£"
                        : userData.currency == "EUR"
                        ? "€"
                        : "₹"}{" "}
                      {item.price}
                    </h5>)}
                          </div>
                          <div className="rating">
                              <i className="fas fa-star my-1 mr-2"></i>
                              <h6 className="mb-0">{item.avgRating ? item.avgRating : 0}/5</h6>
                          </div>
                        
                        <div>
                          {props.type === "edit" ? (
                            <div className="ml-auto">
                              <button
                                type="button"
                                className="btn "
                                // onClick={() => setServicemodal(true)}
                                onClick={() => {
                                  setactivemodal(item);
                                  setTimeout(() => {
                                    setServicemodal(true);
                                  }, 100);
                                  // setServicemodal(true);
                                }}
                              >
                                <i className="fas fa-pencil-alt text-dark"></i>
                              </button>
                              <button
                                type="button"
                                className="btn "
                                onClick={() => {
                                  setServicedlt(true);
                                  setdelid(item._id);
                                }}
                              >
                                <i className="fas fa-trash-alt text-danger"></i>
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* {loginstatus ? (
                          props.type === "edit" ? null : item.cart_items
                              .length > 0 ? (
                            <button className="cart btn " style={styles}>
                              Already added
                            </button>
                          ) : (
                            <button
                              className="cart btn "
                              onClick={() => addTocart(item)}
                            >
                              + Add to Cart
                            </button>
                          )
                        ) : (
                          <button className="cart btn" onClick={alert}>
                            + Add to Cart
                          </button>
                        )} */}
                      </div>
                      {props.type === "edit" ? null : loginstatus && Object.keys(userData).length ? (
                        <div>
                          {item.wishlist_data.length > 0 ? (
                            <div
                              className="absolute_bg"
                              onClick={() => {
                                confirmyeswish(item);
                                // confirmAlert({
                                //   title: "Already in Wishlist",
                                //   message:
                                //     "Do you want to remove from wishlist?",
                                //   buttons: [
                                //     {
                                //       label: "Yes",
                                //       onClick: () => confirmyeswish(item),
                                //     },
                                //     {
                                //       label: "No",
                                //     },
                                //   ],
                                // });
                              }}
                            >
                              <i className="fas fa-heart text-danger"></i>
                            </div>
                          ) : (
                            <div
                              className="absolute_bg"
                              onClick={() => {
                                wishlist(item);
                              }}
                            >
                              <i className="far fa-heart text-danger"></i>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="absolute_bg" onClick={alertt}>
                          <i className="far fa-heart text-danger"></i>
                        </div>
                      )}

                     
                      {props.type === "edit" ? (
                        <button
                          // type="button"
                          className={
                            item.status ? "active_btn" : "deactive_btn"
                          }
                          onClick={() => activeData(item._id)}
                        >
                          {item.status ? "Active" : "Inactive"}
                          {/* Active */}
                        </button>
                      ) : null}
                      
                      
                      {/* {item.shop_details} */}
                      <div className="d-flex align-items-center">
                        <img
                          src={`https://admin.astrophy.com/${
                            item.shop_details.length > 0
                              ? item.shop_details[0].shop_img
                              : Provider_name
                          }`}
                          alt="img"
                          className="mr-2 rounded-circle"
                          style={{
                            width: "100%",
                            height: "25px",
                            maxWidth: "25px",
                          }}
                        />
                        <h4>
                          {item.shop_details.length > 0
                            ? item.shop_details[0].name
                            : null}
                        </h4>
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                      {loginstatus ? (
                        props.type === "edit" ? null : (
                          <button
                            className="btn my-3 red_btn"
                            onClick={() => book(item)}
                          >
                            Buy Now
                          </button>
                        )
                      ) : (
                        <button className="btn my-3 red_btn" onClick={alertt}>
                          Buy Now
                        </button>
                      )}
                      {loginstatus ? (
                        props.type === "edit" ? null : item.cart_items.length >
                          0 ? (
                          <button className="cart btn " style={styles}>
                            Already added
                          </button>
                        ) : (
                          <button
                            className="cart btn p-0 bg-transparent text-dark"
                            onClick={() => addTocart(item)}
                          >
                            <i class="fas fa-cart-plus text-dark" style={{marginRight: 3}}></i>
                            <span>Add to Cart </span>
                          </button>
                        )
                      ) : (
                        <button
                          className="cart btn"
                          style={{ color: "#e84a56", fontWeight: "600" }}
                          onClick={alertt}
                        >
                          + Add to Cart
                        </button>
                      )}
                    </div>


                    </div>
                    
                  </div>
                </div>
                <div
                  className={
                    servicemodal ? "modal fade loginmodal show" : "modal fade"
                  }
                  id="exampleModal1"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  style={{ display: servicemodal ? "block" : "none" }}
                >
                  {Object.keys(activemodal).length == 0 ? null : (
                    <ServiceModal
                      callBackService={callBackService}
                      item={activemodal}
                      success={success}
                    />
                  )}
                </div>
                <div
                  className={
                    servicedlt ? "modal fade loginmodal show" : "modal fade"
                  }
                  id="exampleModal2"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  style={{ display: servicedlt ? "block" : "none" }}
                >
                  <ServiceDlt
                    callBackServiceDlt={callBackServiceDlt}
                    delid={delid}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <EmptyService />
        )}
      </div>
    </div>
  );
};
export default ServiceList;
