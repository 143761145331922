import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Highlight = (props) => {
  useEffect(() => {
    const formatedDesc = new DOMParser().parseFromString(
      props.service.details,
      "text/html"
    );

    document.getElementById("contentDiv").innerHTML =
      formatedDesc.body.innerHTML;
  }, 2000);

  return (
    <div className="Highlights">
      {/* <div>
        <h5>Highlights</h5>
        <p>
          {" "}
          <i className="fas fa-check-circle mr-2"></i>Handmade
        </p>
        <p>
          <i className="fas fa-check-circle mr-2"></i>
          Materials: Psychic Abilities, Psychic Reading, Clairempathy, Empathic
          Projection, White Magic, Clairvoyance
        </p>
      </div> */}
      <div>
        <h5>Descriptions</h5>
        <p id="contentDiv"></p>
        {/* <p>{props.service.details}</p> */}
      </div>
      {/* <Link to="/">
        {" "} */}
      {/* <div className="text-right">
        <h6>Read More</h6>
      </div> */}
      {/* </Link> */}
    </div>
  );
};
export default Highlight;
