import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Blog_img from "../../Assets/Images/blog.png";
import HttpClient from "../../utils/HttpClient";
import moment from "moment";
const MoreBlog = () => {
  const [blogData, setblogData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogData();
  }, []);

  const getBlogData = async () => {
    let result = await HttpClient.requestData("Blog", "GET");
    console.log("result blog data--", result);
    if (result && result.status) {
      setblogData(result.data);
    }
  };

  const loop = [1, 2, 3, 4, 5, 6];
  return (
    <div className="provider_sec my-4">
      <div className="container-fluid" style={{ maxWidth: "1200px" }}>
        <div className="row">
          <div className="col-md-12 text-center service_head">
            <h3>More Blogs</h3>
          </div>
        </div>
        <div className="row">
          {blogData
            ? blogData.map((item, index) => {
                return (
                  <div className="col-md-4 my-4 px-md-4_5 px-3" key={index}>
                    <div className="card blog_box2 mb-2">
                      <img
                        src={HttpClient.IMG_URL + item.image}
                        className="card-img-top"
                        alt="img"
                      />
                      <div className="card-body blog_text">
                        <Link to={"/blog-details/" + item._id}>
                          <h5>{item.heading}</h5>
                        </Link>
                        <h6>
                          {moment(item.created_on).format("MMMM Do YYYY")}
                        </h6>
                        <p className="card-text">{item.content1}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
};

export default MoreBlog;
