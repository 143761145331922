import React, { useState, useEffect } from "react";
import profile_img from "../../Assets/Images/provider_card_img.png";
import Shop from "./Shop";
import Product from "./Product";
import Provider from "./Provider";
import ServiceList9 from "../../Assets/Images/service_list 9.png";
import ServiceList10 from "../../Assets/Images/service_list 10.png";
import ServiceList13 from "../../Assets/Images/service_list 13.png";
import ServiceList14 from "../../Assets/Images/service_list 14.png";
import ServiceList from "../Services/ServiceList";
// import Product from "../Home/Products/Product";
import Product1_img from "../../Assets/Images/thought-catalog-o0Qqw21-0NI-unsplash 1.png";
import Product2_img from "../../Assets/Images/thought-catalog-o0Qqw21-0NI-unsplash 1 (1).png";
import Product3_img from "../../Assets/Images/thought-catalog-o0Qqw21-0NI-unsplash 1 (2).png";
import Footer from "../../Defaults/Footer";
import { Link } from "react-router-dom";
import { ReactTitle } from "react-meta-tags";
import { reactLocalStorage } from "reactjs-localstorage";
import Profile from "../../Assets/Images/user.jpg";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
import bgBlog from "../../Assets/Images/blogBg.jpg";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  const userdata = reactLocalStorage.getObject("userData");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [arr, setarr] = useState("");

  const [loader, setLoading] = useState(false);
  const items = [
    {
      img: ServiceList9,
      para: "Psychic Love Reading,Same day Response..",
    },
    {
      img: ServiceList13,
      para: "Quickly Three month reading low price!",
    },
    {
      img: ServiceList10,
      para: "Tarot Reading Same Hour Same Day Fast..",
    },
    {
      img: ServiceList14,
      para: "Fast Tarot Reading-Message From The..",
    },
  ];
  const loop3 = [
    {
      img: Product1_img,
      name: "Parad Meru Shri Shree Yantra",
    },
    {
      img: Product2_img,
      name: "Zodiac Sign Bracelet",
    },
    {
      img: Product3_img,
      name: "Yelook 100% Natural",
    },
  ];

  const fetchData = async () => {
    console.log('userdata',userdata)
    setLoading(true);
    let result = await HttpClient.requestData("seller/" + userdata._id, "GET");
    console.log("Data", result);
    if (result && result.status) {
      // setuser(result.data);
      setfname(result.data.firstName);
      setlname(result.data.lastName);
      result.data.image
        ? setarr(HttpClient.IMG_URL + result.data.image)
        : setarr("");
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };
  return (
    <div>
      <div className="wrapper">
        <ReactTitle title="Astrophy-My Profile" />
      </div>
      <section>
        <div
          className="bGimg_My_Profile"
          style={{
            background: `url(${bgBlog})`,
          }}
        >
          <div className="container">
            <div className="bGimg_My_Profile_head">
              <h2>My Profile</h2>
            </div>
          </div>
        </div>
        <div className="container profile pt-4" style={{ maxWidth: "1200px" }}>
          <div className="row my-1">
            <div className="col-md-12">
              <div className="d-md-flex justify-content-start align-items-center">
                {loader ? (
                  <Loader />
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="mr-2">
                      <img
                        src={arr ? arr : Profile}
                        alt="img"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div className="">
                      <h5>{fname + " " + lname}</h5>
                      <p className="mb-0">{userdata ? userdata.email : ""}</p>
                    </div>
                  </div>
                )}
                <Link to="/edit-profile" className="d-block ml-3">
                  <button type="button" className="btn edit_btn">
                    <i className="fas fa-pen mr-2"></i>Edit Profile
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-4" style={{ maxWidth: "1200px" }}>
          <div className="row">
            <div className="col-md-12 shop_head">
              <h5>Favourite Services</h5>
            </div>
          </div>
          <Shop />
          <Link to="/">
            {" "}
            <div className="row mb-3">
              <div className="col-md-12 text-right text-muted">
                <p>View More</p>
              </div>
            </div>
          </Link>
        </div>
      </section>
      <section>
        <div className="container" style={{ maxWidth: "1200px" }}>
          <div className="row px-md-4 ">
            <div className="col-md-12 shop_head">
              <h5>Favourite Service Providers</h5>
            </div>
          </div>
          <Provider />
          <Link to="/">
            {" "}
            <div className="row px-4">
              <div className="col-md-12 text-right text-muted">
                <p>View More</p>
              </div>
            </div>
          </Link>
        </div>
      </section>
      <section>
        <div className="container " style={{ maxWidth: "1200px" }}>
          <div className="row px-md-4 mb-3">
            <div className="col-md-12 shop_head">
              <h5>Favourite Products</h5>
            </div>
          </div>
          <Product data={loop3} />
          <Link to="/">
            {" "}
            <div className="row px-md-4 mb-3">
              <div className="col-md-12 text-right text-muted">
                <p>View More</p>
              </div>
            </div>
          </Link>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Index;
