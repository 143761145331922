import React, { useEffect, useState } from "react";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";
import validator from "validator";
import Warning from "../../Component/Warning";
import Success from "../../Component/Success";

const SubsBox = () => {
  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState(false);
  const [emailvalid, setemailvalid] = useState(false);

  const userTyping = async (e) => {
    if (e.keyCode === 13) {
      subscribeNow();
    }
  };

  const subscribeNow = async () => {
    if (email == "") {
      toast.error("Please enter email", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (emailError == true) {
      toast.error("Please enter valid email", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let sendData = {
        email: email,
      };
      // let result = await HttpClient.requestData("createSubscribe", "POST", sendData);
      let result = await HttpClient.requestData("contactus", "POST", sendData);
      // console.log("submit subscribe", result);
      if (result && result.status) {
        toast.success("Subscribe Successfully", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setemail("");
      } else {
        toast.error("Error in Subscribing", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <div>
      <div className="card pt-4 subs_box  ">
      <ToastContainer />
        <div className="card-body text-center">
          <h5 className="card-title">Subscribe to the Newsletter</h5>

          <p className="card-text py-3">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
            nibh cursus. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. A, volutpat nibh cursus
          </p>
          <div className="form-row mt-4 ">
            <div className="form-group col-md-12 ">
              <input
                type="email"
                className="form-control m-auto"
                id="inputEmail4"
                placeholder="Email Address"
                value={email}
                onChange={(val) => {
                  setemail(val.target.value);
                  if (val.target.value == "") {
                    setemailError(true);
                  } else {
                    setemailError(false);

                    if (validator.isEmail(email)) {
                      setemailvalid(false);
                    } else {
                      setemailvalid(true);
                    }
                  }
                }}
                onKeyUp={(e) => userTyping(e)}
              />
            </div>
          </div>
          {emailError ? (
            <Warning warning="Please Enter Email" />
          ) : emailvalid ? (
            <Warning warning="Please Enter Valid Email" />
          ) : null}
          <button
            type="button"
            className="subs_blog_btn btn mt-2 mb-4"
            onClick={subscribeNow}
          >
            Subscribe Now
          </button>
          <h6>100% Privacy. No spam guaranteed</h6>
        </div>
      </div>
    </div>
  );
};
export default SubsBox;
