import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Favourite from "../../../Assets/Images/favourite.png";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { toast } from "react-toastify";
import HttpClient from "../../../utils/HttpClient";
import EmptyProduct from "../../EmptyProduct/EmptyProduct";
import { confirmAlert } from "react-confirm-alert";

const ProductUpdated = (props) => {
  const [isWishlisted, setisWishlisted] = useState(false);
  const [cartBtn, setCartBtn] = useState(false);
  useEffect(() => {}, [props]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const styles = {
    color: "green",
    cursor: "default",
  };
  // const loop = [1];
  const userData = reactLocalStorage.getObject("userData");
  const loginstatus = reactLocalStorage.getObject("loginstatus");
  const customId = "custom-id-yes";

  const alert = () => {
    toast.error("You have to login first", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      toastId: customId,
    });
  };

  const buyNow = async (val) => {
    if (val.cart_details.length > 0) {
      window.location.href = "/checkout";
    } else {
      let data = {
        prod_id: val._id,
        user_id: userData._id,
        productname: val.name,
        qty: 1,
        currency: userData.currency,
        price: val.selling_price,
        image: val.image[0],
        orderFrom: "web"
      };

      let result = await HttpClient.requestData("add-to-cart", "POST", data);
      // console.log("result", result);
      if (result && result.status) {
        window.location.href = "/checkout";
      } else {
      }
    }
  };

  const wishlist = async (item) => {
    console.log("propsDetails", item);
    let userId = userData._id;
    let _id = item._id;
    let sending = {
      user_id: userId,
      prod_id: _id,
      productname: item.name,
      qty: "1",
      price: item.selling_price,
      image: item.image,
    };
    let result = await HttpClient.requestData(
      "product-wishlist",
      "POST",
      sending
    );
    console.log("wish---", result);
    if (result && result.status) {
      props.wishlistCallback(result.status);
      if (item.cart_details.length > 0) {
        confirmAlert({
          title: "",
          message: "After wishlisting product will be removed from your cart",
          buttons: [
            {
              label: "Ok",
              onClick: () => {},
            },
          ],
        });
      }
      reactLocalStorage.setObject("wishlisted", result.data);
      localStorage.setItem("wishlisted", result.data);
      setisWishlisted(true);
      setCartBtn(false);
      toast.success("Added to Wishlist", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    } else {
      toast.error("Server error, try again later!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const confirmyes = async (val) => {
    console.log("del", val.wishlist_data[0]._id);
    let data = {};
    let result = await HttpClient.requestData(
      "product-wishlist/" + val.wishlist_data[0]._id,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      props.wishlistCallback(result.status);
    } else {
    }
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }

  return (
    <div className="row px-0">
      {/* <ToastContainer /> */}
      {props.data.length > 0 ? (
        props.data.map((item, index) => {
          let sum = 0;
          let avg = 0;
          item.review_data.map((itemm, index) => {
            return (sum = Number(sum) + Number(itemm.rating));
          });
          avg = Number(sum) / Number(item.review_data.length);
          return (
            <div
              className="col-6 col-md-4 col-lg-3 col-sm-6 my-3 px-2 px-md-3"
              key={index}
            >
              <div className="card service_list new_product_design">
                <Link to={"/product-details/" +  nameToSlug(item.name) + "/" + item._id} className="px-0 py-3">
                  <Slider {...settings}>
                    {item.image
                      ? item.image.map((item2, index2) => {
                          return (
                            <div key={index2}>
                              {" "}
                              <img
                                src={HttpClient.IMG_URL + item2}
                                alt="img"
                                className="relative service_list_img"
                              />
                              {/* <img
                            src={Favourite}
                            alt="img"
                            className="absolute m-2"
                          /> */}
                            </div>
                          );
                        })
                      : null}
                    {/* {loop.map((item2, index2) => {
                      return (
                        <div key={index2}>
                          {" "}
                          <img
                            src={
                              HttpClient.IMG_URL + item.image[0]
                            }
                            alt="img"
                            className="relative service_list_img"
                          />
                          <img
                            src={Favourite}
                            alt="img"
                            className="absolute m-2"
                          />
                        </div>
                      );
                    })} */}
                  </Slider>
                </Link>
                <div className="card-body product_list_text px-md-3 pt-2">
                  <Link to={"/product-details/" + nameToSlug(item.name) + "/" + item._id}>
                    <h6>{item.name}</h6>
                  </Link>
                  <div className="d-flex justify-content-between py-md-3">
                    <div className="pricing">
                      <p className="font-weight-bold  mb-0">
                        {" "}
                        {userData.currency == "INR"
                          ? "₹"
                          : userData.currency == "CAD"
                          ? "$"
                          : userData.currency == "USD"
                          ? "$"
                          : userData.currency == "GBP"
                          ? "£"
                          : userData.currency == "EUR"
                          ? "€"
                          : "₹"}{" "}
                        {item.selling_price}
                        {/* <del className="text-muted font-italic ml-2 small">
                        {userData.currency == "INR"
                          ? "₹"
                          : userData.currency == "CAD"
                          ? "$"
                          : userData.currency == "USD"
                          ? "$"
                          : userData.currency == "GBP"
                          ? "£"
                          : userData.currency == "EUR"
                          ? "€"
                          : "₹"}{" "}
                          {item.mrp}
                        </del> */}
                      </p>

                      {/* <h5 className="small mb-0 font-weight-bold">
                        {item.offer ? item.offer + "% offer+" : null}
                        {item.tax}tax
                      </h5> */}
                    </div>
                    <div className="rating">
                      <i className="fas fa-star my-1 mr-1 mr-md-2"></i>
                      {item.avgRating ? item.avgRating.toFixed(1) : "0"}/5
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-center">
                      {loginstatus ? (
                        <button
                          className="btn red_btn"
                          onClick={() => buyNow(item)}
                        >
                          Buy Now
                        </button>
                      ) : (
                        <button className="btn red_btn" onClick={alert}>
                          Buy Now
                        </button>
                      )}
                    </div>

                    {loginstatus ? (
                      typeof item.cart_details !== "undefined" &&
                      item.cart_details.length > 0 ? (
                        <button
                          className="cart btn p-0 bg-transparent "
                          style={styles}
                        >
                          <i className="fa fa-shopping-cart"></i>{" "}
                          <span> Already added</span>
                        </button>
                      ) : (
                        <button
                          className="cart btn p-0 bg-transparent text-dark"
                          onClick={() => props.addTocart(item)}
                        >
                          <i className="fas fa-cart-plus"></i>{" "}
                          <span> Add to Cart</span>
                        </button>
                      )
                    ) : (
                      <button
                        className="cart btn p-0 bg-transparent text-dark"
                        onClick={alert}
                      >
                        <i className="fas fa-cart-plus"></i>
                        <span> Add to Cart</span>
                      </button>
                    )}
                  </div>
                  {loginstatus ? (
                    <div>
                      {item.wishlist_data.length > 0 ? (
                        <div
                          className="absolute_bg"
                          onClick={() => {
                            confirmyes(item);

                            // confirmAlert({
                            //   title: "Already in Wishlist",
                            //   message: "Do you want to remove from wishlist?",
                            //   buttons: [
                            //     {
                            //       label: "Yes",
                            //       onClick: () => confirmyes(item),
                            //     },
                            //     {
                            //       label: "No",
                            //       // onClick: () => confirmno(),
                            //     },
                            //   ],
                            // });
                          }}
                        >
                          <i className="fas fa-heart text-danger"></i>
                        </div>
                      ) : (
                        <div
                          className="absolute_bg"
                          onClick={() => {
                            wishlist(item);
                          }}
                        >
                          <i className="far fa-heart text-danger"></i>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="absolute_bg" onClick={alert}>
                      <i className="far fa-heart text-danger"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyProduct />
      )}
    </div>
  );
};
export default ProductUpdated;
