import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { Link } from "react-router-dom";

export default function SiteLinks(props) {
  const [service, setService] = useState([]);

  useEffect(() => {
    fetchService();
  }, []);
  const fetchService = async () => {
    let result = await HttpClient.requestData("service", "GET");
    if (result && result.status) {
      // let data = result.data.filter(
      //   (item) => item._id === "6204f6f56dcb6904acb6a894"
      // );
      let data = result.data;
      setService(data);
    } else {
    }
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }
  return (
    // <div className="row my-5 px-md-0 px-5">
    //   <div className="col-md-3 ">
    //     <div className="Site_map_head">QUICK LINKS</div>
    //     <ul className="">
    //       <li>
    //         <Link to="/achivements" className="foot_menu">
    //           Achivements
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/about-us" className="foot_menu">
    //           About Us
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/contact-us" className="foot_menu">
    //           Contact Us
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/faq" className="foot_menu">
    //           FAQs
    //         </Link>
    //       </li>
    //       {props.page === "/site-map" ? null : (
    //         <li>
    //           <Link to="/site-map" className="foot_menu">
    //             SiteMap
    //           </Link>
    //         </li>
    //       )}

    //       <li>
    //         <Link to="/blog" className="foot_menu">
    //           Blog
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/privacy-policy" className="foot_menu">
    //           Privacy Policy
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/cookies-policy" className="foot_menu">
    //           Cookies Policy
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/return-policy" className="foot_menu">
    //           Return Policy
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/condition-policy" className="foot_menu">
    //           Condition of Use & Sell
    //         </Link>
    //       </li>
    //     </ul>
    //   </div>
    //   <div className="col-md-3 ">
    //     <div className="Site_map_head">MY LINKS</div>
    //     <ul className="">
    //       <li>
    //         <Link to="/my-profile" className="foot_menu">
    //           My Profile
    //         </Link>
    //       </li>
    //       {/* <li>
    //             <Link to="/messages" className="foot_menu">
    //               Messages
    //             </Link>
    //           </li> */}
    //       <li>
    //         <Link to="/orders" className="foot_menu">
    //           My Orders
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/seller" className="foot_menu">
    //           Seller
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/feedback" className="foot_menu">
    //           Feedback
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/customer-service" className="foot_menu">
    //           Customer Service
    //         </Link>
    //       </li>
    //       {/* <li>
    //             <Link to="/career-with-us" className="foot_menu">
    //               Career with Us
    //             </Link>
    //           </li> */}
    //       <li>
    //         <Link to="/report-problem" className="foot_menu">
    //           Report a Problem
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/legal-notice" className="foot_menu">
    //           Summons/ Legal Notice
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/grievance" className="foot_menu">
    //           Grievances
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="/safety-guide" className="foot_menu">
    //           Safety Guide
    //         </Link>
    //       </li>
    //       {/* <li>
    //         <Link to="/our-associate" className="foot_menu">
    //           Our Associates
    //         </Link>
    //       </li> */}
    //     </ul>
    //   </div>
    //   <div className="col-md-6">
    //     {/* <div className="Site_map_head">SERVICES WE OFFERS</div> */}
    //     <div className="d-flex justify-content-around">
    //       {service.map((pitem, pindex) => {
    //         return (
    //           <div>
    //             <span className="Site_map_head">{pitem.name}</span>
    //             <ul key={pindex}>
    //               {pitem.service_data.map((item, index) => {
    //                 return (
    //                   <li
    //                     key={index}
    //                     onClick={() =>
    //                       (window.location.to = "/services/" + item._id)
    //                     }
    //                     className="foot_menu"
    //                   >
    //                     {item.name}
    //                   </li>
    //                 );
    //               })}
    //             </ul>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   </div>
    // </div>
    <div className="site__Map py-4">
      <div className="container">
        <div className="row my-5 px-md-0 px-5">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="sitemap_Title">QUICK LINKS</div>
            <ul className="quick__Links">
              <li>
                <Link to="/achivements" className="foot_menu">
                  Achivements
                </Link>
              </li>
              <li>
                <a className="foot_menu" to="/about-us">
                  About Us
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/contact-us">
                  Contact Us
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/faq">
                  FAQs
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/blog">
                  Blog
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/privacy-policy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/cookies-policy">
                  Cookies Policy
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/return-policy">
                  Return Policy
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/condition-policy">
                  Condition of Use &amp; Sell
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="sitemap_Title">MY LINKS</div>
            <ul className="quick__Links">
              <li>
                <a className="foot_menu" to="/my-profile">
                  My Profile
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/orders">
                  My Orders
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/seller">
                  Seller
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/feedback">
                  Feedback
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/customer-service">
                  Customer Service
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/report-problem">
                  Report a Problem
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/legal-notice">
                  Summons/ Legal Notice
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/grievance">
                  Grievances
                </a>
              </li>
              <li>
                <a className="foot_menu" to="/safety-guide">
                  Safety Guide
                </a>
              </li>
            </ul>
          </div>
          {service.map((pitem, pindex) => {
            return (
              <>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                  <div className="sitemap_Title">{pitem.name}</div>
                  {pitem.service_data.map((item, index) => {
                    return (
                      <ul className="quick__Links">
                        <li
                          className="foot_menu"
                          onClick={() =>
                            (window.location.href = "/services/" + nameToSlug(item.name) + "/" + item._id)
                          }
                        >
                          <a to="javascript:void(0)">{item.name}</a>
                        </li>
                      </ul>
                    );
                  })}
                </div>
                {/* <div>
                  <span className="Site_map_head">{pitem.name}</span>
                  <ul key={pindex}>
                    {pitem.service_data.map((item, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            (window.location.href = "/services/" + item._id)
                          }
                          className="foot_menu"
                        >
                          {item.name}
                        </li>
                      );
                    })}
                  </ul>
                </div> */}
              </>
            );
          })}
          {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="sitemap_Title">Spell Casting</div>
            <ul className="quick__Links">
              <li className="foot_menu">
                <a to="javascript:void(0)">Love spell</a>
              </li>
              <li className="foot_menu">
                <a to="javascript:void(0)">Protection Spell</a>
              </li>
            </ul>
            <div className="sitemap_Title">Test A</div>
            <ul className="quick__Links" />
            <div className="sitemap_Title">New category 2</div>
            <ul className="quick__Links">
              <li className="">
                <a to="javascript:void(0)">New Sub category 2</a>
              </li>
            </ul>
            <div className="sitemap_Title">June10 cat bj</div>
            <ul className="quick__Links"></ul>
            <div className="sitemap_Title">Vastu</div>
            <ul className="quick__Links">
              <li className="">
                <a to="javascript:void(0)">Flat Vastu</a>
              </li>
            </ul>
            <div className="sitemap_Title">June17</div>
            <ul className="quick__Links">
              <li className="">
                <a to="javascript:void(0)">June 17 sub category</a>
              </li>
            </ul>
          </div> */}
          {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
            <div className="sitemap_Title">Astrology &amp; Reading</div>
            <ul className="quick__Links">
              <li className="">
                <a to="javascript:void(0)">Test A</a>
              </li>
              <li className="">
                <a to="javascript:void(0)">Test B</a>
              </li>
              <li className="">
                <a to="javascript:void(0)">Test j</a>
              </li>
              <li className="">
                <a to="javascript:void(0)">New Category</a>
              </li>
              <li className="">
                <a to="javascript:void(0)">new sub</a>
              </li>
              <li className="">
                <a to="javascript:void(0)">Related</a>
              </li>
              <li className="">
                <a to="javascript:void(0)">Mind Reading</a>
              </li>
            </ul>
            <div className="sitemap_Title">Product category to delete</div>
            <ul className="quick__Links"></ul>
            <div className="sitemap_Title">check from frontend 2</div>
            <ul className="quick__Links">
              <li className="">
                <a to="javascript:void(0)">SUB check from frontend 3</a>
              </li>
              <li className="">
                <a to="javascript:void(0)">June18</a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
}
