import React from "react";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
const OrderDLT = (props) => {
  console.log("jjjjj", props.servdetails);
  // const deletee = async () => {
  //   let data = {};

  //   let result = await HttpClient.requestData(
  //     "refundProduct/" + props.orderid,
  //     "PUT",
  //     data
  //   );
  //   console.log("result", result);
  //   if (result && result.status) {
  //     props.success(result.status);
  //   } else {
  //   }
  // };

  const Cancelorder = async () => {


    let result = await HttpClient.requestData("cancel-serv-order/" + props.servdetails._id, "PUT");
    console.log("order cancelled", result);
    if (result && result.status) {

      toast.success("Order Cancelled Successfully", {
        position: "bottom-right",
        autoClose: 3000,
      });
      props.callBackOrder(false, props.index)

    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });

    }
  };
  return (
    <div>
      <div className="modal-dialog  my-3 ">
        <div className="modal-content dlt_modal">
          <div className="login-body my-3 px-5 py-3 text-center ">
            <h3>Want to Cancel Order?</h3>
            <div className="d-flex justify-content-around mt-4">
              <button
                type="button"
                className="btn dltmodal_btn"
                onClick={Cancelorder}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn dltmodal_btn"
                onClick={() => props.callBackOrder(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderDLT;
