import React, { useState, useEffect, useCallback } from "react";
import order1 from "../../Assets/Images/il_794xN 4.png";
import moment from "moment";
import Provider_name from "../../Assets/Images/provider_name.png";
import ReactStars from "react-rating-stars-component";
import { reactLocalStorage } from "reactjs-localstorage";
import OrderDLT from "./OrderDLT";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../utils/HttpClient";
import RefundModal from "./refundmodal";
import ChatWithSeller from "./chatwithseller";
import StripeCheckout from "react-stripe-checkout";
import PayPal from "../Checkout/PayPal";
import { Row, Card, CardBody, Col, Input, Button } from "reactstrap";
const ServiceOrderDetails = (props) => {
  console.log("props", props.orderdetails);
  useEffect(() => {
    // console.log("useeffect running");
    console.log("in useeffect");
    // setratingValue(0);
    setreview("");
    setserviceDetailArr(props.orderdetails.servicecart_data);
    // setservicerefund(
    //   props.orderdetails.service_refund.length
    //     ? props.orderdetails.service_refund[0]
    //     : {}
    // );
    couponDiscount(props.orderdetails);
  }, [props.count]);

  const [isOpenmodal, setIsOpenmodal] = useState(false);
  const [isOpenmodal1, setisopenmodal1] = useState(false);
  const [orderid, setorderid] = useState("");
  const [servicename, setservicename] = useState("");
  const [orderDlt, setOrderDlt] = useState(false);
  const [ratingValue, setratingValue] = useState(0);
  const [tips, settips] = useState("");
  const [tipAmount, settipAmount] = useState("");
  const [tipsPaid, settipsPaid] = useState(false);
  const [review, setreview] = useState("");
  const [commentValue, setcommentValue] = useState("");
  const userData = reactLocalStorage.getObject("userData");
  const [index, setindex] = useState(0);
  const [eachservicedetails, seteachservicedetails] = useState({});
  const [servicedetailArr, setserviceDetailArr] = useState([]);
  const [servicerefund, setservicerefund] = useState({});
  const [local, setlocal] = useState(false);
  const [discount, setdiscount] = useState(0);
  const [isTipPaid, setIsTipPaid] = useState(false);
  const [payment, setpayment] = useState("");
  const [itemId, setitemId] = useState("");
  const [loader, setloader] = useState(false);
  const [showTipsForm, setshowTipsForm] = useState(true);
  const [paidOn, setpaidOn] = useState(false);
  const [refundOn, setrefundOn] = useState(false);

  const ratingChanged = async (newRating) => {
    console.log(newRating);
    setratingValue(newRating);
  };
  const callBackOrder = (val, index) => {
    setOrderDlt(val);
    //props.backcancelorder();
    let arr = servicedetailArr;
    arr[index].cancelstatus = "cancel";
    setserviceDetailArr([]);
    for (let i = 0; i < arr.length; i++) {
      setserviceDetailArr((prevState) => [...prevState, arr[i]]);
    }
  };
  const couponDiscount = async (orderdetails) => {
    if (orderdetails.coupon_used.length > 0) {
      let expdate = orderdetails.coupon_used[0].expdate;
      let expdate1 = moment(expdate).format("YYYY-MM-DD");
      let exp_date = expdate1;
      console.log("exp", exp_date);
      let expdate2 = moment(exp_date);
      let curdate1 = moment().format("YYYY-MM-DD");
      console.log("exp1", curdate1);
      let curdate2 = moment(curdate1);

      if (orderdetails.coupon_used[0].minprice < orderdetails.order_total) {
        if (orderdetails.coupon_used[0].discount_type == "Flat discount") {
          if (
            orderdetails.coupon_used[0].discount_value >=
            orderdetails.order_total
          ) {
            setdiscount(0);
          } else {
            setdiscount(orderdetails.coupon_used[0].discount_value);
          }
        } else {
          let amount_dis =
            (orderdetails.coupon_used[0].discount_value *
              orderdetails.order_total) /
            100;
          if (amount_dis >= orderdetails.order_total) {
            setdiscount(0);
          } else {
            setdiscount(amount_dis);
          }
        }
      } else {
        setdiscount(0);
      }
    } else {
      setdiscount(0);
    }
  };
  const submit = async (item, index) => {
    // if (rating != "" && review != "") {

    let data = {
      user_id: userData._id,
      service_id: item.serv_id,
      rating: ratingValue,
      comment: servicedetailArr[index].review_data.comment,
      order_id: item.order_id,
    };
    console.log(data);
    let result = await HttpClient.requestData("servicereview", "POST", data);
    console.log("result1", result);
    if (result && result.status) {
      setratingValue(0);
      setreview("");
      toast.success(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
      props.backreview(props.orderdetails, 3);
      let arr = servicedetailArr;
      setserviceDetailArr([]);
      for (let i = 0; i < arr.length; i++) {
        setserviceDetailArr((prevState) => [...prevState, arr[i]]);
      }
    } else {
      setratingValue(0);
      setreview("");
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
    // }
    // else {
    //   setrating(0);
    //   setreview("");
    //   toast.error("please Review first", {
    //     position: "bottom-right",
    //     autoClose: 3000,
    //   });
    // }
  };

  const senddetails = (val, details) => {
    if (val) {
      console.log("details paypal", details);
      // submitTips(details.id);
    }
  };
  const onToken = (token, addresses) => {
    // console.log("token", token);
    // console.log("addresses", addresses);
    stripeapicall(token.id);
  };
  const stripeapicall = async (token) => {
    // console.log(token);
    const card = {
      amount: tips,
      currency: userData.currency,
      source: token,
      description: "Astrophy Service Tips Payment",
    };

    let result = await fetch("https://api.stripe.com/v1/charges", {
      headers: {
        // Use the correct MIME type for your server
        Accept: "application/json",
        // Use the correct Content Type to send data to Stripe
        "Content-Type": "application/x-www-form-urlencoded",
        // Use the Stripe publishable key as Bearer
        Authorization: `Bearer sk_test_51JavJLSB5KAxkRypR3jypiPJk4I9nQ7L9uGMqDwQSPXCEhwFhYRMGVLpp5UvQTXFYJ6GQ4MqXfAW1cVFxbqEvpR900Bc3ynSKr`,
      },
      // Use a proper HTTP method
      method: "post",
      // Format the credit card data to a string of key-value pairs
      // divided by &
      body: Object.keys(card)
        .map((key) => key + "=" + card[key])
        .join("&"),
    }).then((response) => response.json());

    console.log("resultt", result);

    submitTips(result.amount);
  };

  const submitTips = async (id, index) => {
    if (tips == "") {
      toast.error("Please enter tips", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else {
      let data = {
        id: itemId,
        tip: tips,
      };
      // setloader(true);
      let result = await HttpClient.requestData("tips", "POST", data);
      console.log("paid tip", result);
      if (result && result.status) {
        settips("");
        settipsPaid(false);
        setpayment("");
        setshowTipsForm(false);
        setpaidOn(true);
        settipAmount(result.data.tip);
        // setIsTipPaid(true);
        toast.success(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        let arr = servicedetailArr;
        arr[index].tip = tips;
        console.log("tip", arr);
        setserviceDetailArr([]);
        for (let i = 0; i < arr.length; i++) {
          setserviceDetailArr((prevState) => [...prevState, arr[i]]);
        }
        // props.orderdetails.servicecart_data[index].tip = tips
        // setloader(false);
        props.updateData(result.status);
      } else {
        toast.error(result.message, {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    }
  };

  // const hasTip =  (item) => {
  //   if (item.hasOwnProperty("tip")) {
  //     setshowTipsForm(true);
  //   } else {
  //     setshowTipsForm(false);
  //   }
  // }
  const hasTip = useCallback((item) => {
    if (item.hasOwnProperty("tip")) {
      setshowTipsForm(true);
    } else {
      setshowTipsForm(false);
    }
  }, []);

  const success = (val) => {
    if (val) {
      props.refresh(val);
    }
  };

  const downloadReciept = async () => {
    console.log("456");
    let _id = props.orderdetails.order_id;
    console.log(" props.orderdetails._id", props.orderdetails.order_id);

    let result = await HttpClient.requestData(
      "serv-order-receipt/" + _id,
      "GET"
    );
    console.log("Reciept status", result, _id);
    if (result && result.status) {
      window.open(HttpClient.IMG_URL + result.file);
      toast.success("Reciept downloaded", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else {
      toast.error("Reciept not found", {
        position: "bottom-right",
        autoClose: 3000,
      });
      console.log("Reciept error", result);
    }
  };

  const Callbackmsg = async (val) => {
    setIsOpenmodal(false);
    setrefundOn(true);
    let arr = servicedetailArr;
    arr[index].refund_request = "yes";
    console.log("tip", arr);
    setserviceDetailArr([]);
    for (let i = 0; i < arr.length; i++) {
      setserviceDetailArr((prevState) => [...prevState, arr[i]]);
    }
    // props.updatedList(props.orderdetails, 2);
  };
  const Callbackmsg1 = async (val) => {
    setisopenmodal1(false);
    // props.updatedList(props.orderdetails, 2)
  };
  const handlepayment = (e) => {
    setpayment(e.target.value);
  };

  if (loader === true) {
    document.body.style.opacity = "0.3";
  } else {
    document.body.style.opacity = "1";
  }
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }
  return (
    <div>
      <ToastContainer />
      <h3>#{props.orderdetails.order_id}</h3>
      {servicedetailArr.length > 0
        ? servicedetailArr.map((item, index) => {
            // hasTip(item);
            return (
              <div className="row" key={index}>
                <div className="col-md-12">
                  <div className="d-md-flex  my-3 justify-content-around border-bottom p-3">
                    <div className="order_pic mx-3"
                     onClick={() => {
                      window.location.href = "service-details/" + nameToSlug(item.servicename) + "/" + item.serv_id
                    }}
                    >
                      <img src={HttpClient.IMG_URL + item.image} alt="img" />
                    </div>
                    <div style={{ maxWidth: "100%" }}>
                      <h4
                       onClick={() => {
                        window.location.href = "service-details/" + nameToSlug(item.servicename) + "/" + item.serv_id
                      }}
                      >{item.servicename}</h4>
                      <p>
                        {/* Personalisation : */}
                        {/* {item.service_data[0].details.replace(
                          /<[^>]+>/g,
                          ""
                        )} */}
                      </p>

                      <div>
                        {/* <h5>Review</h5> */}

                        <div className="form-group">
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows={3}
                            // defaultValue={"Write Review"}
                            // value={review}
                            placeholder="Write a review"
                            value={
                              item.review_data.length ? item.review_data[0].comment : commentValue
                            }
                            onChange={(val) => {
                              setlocal(true);
                              setcommentValue(val.target.value)
                              let arr = servicedetailArr;
                              let obj = { comment: val.target.value };
                              arr[index].review_data = obj;
                              setserviceDetailArr([]);
                              for (let i = 0; i < arr.length; i++) {
                                setserviceDetailArr((prevState) => [
                                  ...prevState,
                                  arr[i],
                                ]);
                              }
                            }}
                            disabled={
                              item.review_data.length ? true : false
                            }
                          />
                        </div>
                        <ReactStars
                          count={5}
                          // value={rating}
                          value={
                            item.review_data.length ? item.review_data[0].rating : ratingValue
                          }
                          onChange={ratingChanged}
                          size={24}
                          isHalf={true}
                          emptyIcon={<i className="far fa-star"></i>}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          activeColor="#ffd700"
                        />
                        {item.review_data.length ? null : (
                          <button
                            type="button "
                            className="btn submit_btn mt-2"
                            onClick={() => submit(item, index)}
                          >
                            Submit
                          </button>
                        )}
                        <div className="col-md-12">
                          <div
                            className="shipping  border-bottom"
                            style={{ paddingTop: "16", paddingBottom: "16", marginTop: "20px" }}
                          >
                            <h3>Tip</h3>
                            <div className="form-group text-align-center">
                              {item.tip || paidOn ? null : (
                                <div className="d-flex tip_box justify-content-between">
                                  <p className="pt-2">
                                    {userData.currency == "INR"
                                      ? "₹"
                                      : userData.currency == "CAD"
                                      ? "$"
                                      : userData.currency == "USD"
                                      ? "$"
                                      : userData.currency == "GBP"
                                      ? "£"
                                      : userData.currency == "EUR"
                                      ? "€"
                                      : "₹"}
                                  </p>
                                  <input
                                    type="number"
                                    className="form-control border-0"
                                    id="exampleInput"
                                    placeholder={
                                      item.tip
                                        ? "Tip Paid Successfully"
                                        : "Enter Tip Amount"
                                    }
                                    step="00.01"
                                    onChange={(val) => {
                                      settips(val.target.value);
                                    }}
                                    disabled={item.tip ? true : false}
                                    // value={item.tip ? item.tip : tips}
                                  />
                                  <div
                                    className={item.tip ? "d-none" : "d-block"}
                                  >
                                    <button
                                      className="btn pay_btn "
                                      onClick={() => {
                                        if (tips == 0 || tips < 0) {
                                          toast.error(
                                            "tip cannot be 0 or less than 0",
                                            {
                                              position: "bottom-right",
                                              autoClose: 3000,
                                            }
                                          );
                                        } else {
                                          // submitTips(item._id, index);
                                          settipsPaid(true);
                                          setitemId(item._id);
                                        }
                                      }}
                                    >
                                      Pay
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <br />

                            <div
                              className={
                                item.tip || paidOn ? "d-block" : "d-none"
                              }
                            >
                              <div className="d-flex tip_box justify-content-between">
                                {/* <input
                                type="text"
                                className=" form-control border-0"
                                id="exampleInput"
                                value={props.orderdetails ? props.orderdetails.tip : ""}
                              /> */}
                                <div>
                                  <p className="btn pay_btn text-success mb-0">
                                    Paid
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {tipsPaid == true ? (
                            <div className="d-block my-3">
                              <div className="custom-control custom-radio">
                                <input
                                  id="stripe"
                                  name="paymentMethod"
                                  type="radio"
                                  className="custom-control-input"
                                  value="stripe"
                                  checked={payment == "stripe" ? true : false}
                                  onChange={handlepayment}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="stripe"
                                >
                                  Stripe
                                </label>
                              </div>

                              <div className="custom-control custom-radio">
                                <input
                                  id="paypal"
                                  name="paymentMethod"
                                  type="radio"
                                  className="custom-control-input"
                                  value="paypal"
                                  checked={payment == "paypal" ? true : false}
                                  onChange={handlepayment}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="paypal"
                                >
                                  Paypal
                                </label>
                              </div>
                            </div>
                          ) : null}
                          {payment == "stripe" && (
                            <StripeCheckout
                              token={onToken}
                              stripeKey="pk_test_51JavJLSB5KAxkRypwpyFZVGF7Mxwel9iBFVqCbIaqDQERfj9A6NoeJEaBBZnnGRgSCasukWcLtWICm7SI0CtSDAl00bKUxkGVB"
                              amount={tips * 100}
                              name="Astrophy Service Tips"
                              description="Pay & Buy"
                              currency={userData.currency}
                            />
                          )}
                          {payment == "paypal" && (
                            <PayPal total={tips} senddetails={senddetails} />
                          )}
                        </div>
                        {/* <RatingChanged /> */}

                        {/* <h6>
                          Obsessed! I began going to her this week and I already
                          got 4 readings. She is super accurate. I think I
                          finally found the right one on here! Will continue to
                          purchase more gigs
                        </h6> */}
                      </div>
                      {item.cancelstatus == "cancel" ? (
                        <div className="col-md-12 text-right">
                          <div className="border-top py-2">
                            {" "}
                            {/* <h3 style={{ color: "red" }}>Order Cancelled</h3> */}
                            <Button
                            //  type="button"
                              style={{cursor: "not-allowed", pointerEvents: "none"}}
                             color="danger"                            
                            >
                            Order Cancelled
                            </Button>
                          </div>
                        </div>
                      ) : item.acceptstatus == "accept" ||
                        item.acceptstatus == "reject" ? null : (
                        <div className="col-md-12 text-right my-3">
                          <div className="border-top py-2">
                            {" "}
                            <Button
                            //  type="button"
                             color="danger"
                             onClick={() => {
                               setOrderDlt(true);
                               seteachservicedetails(item);
                               setindex(index);
                             }}
                            >
                            Cancel Order
                            </Button>
                            {/* <button
                              type="button"
                              className="btn cancel_btn"
                              onClick={() => {
                                setOrderDlt(true);
                                seteachservicedetails(item);
                                setindex(index);
                              }}
                            >
                              Cancel Order
                            </button> */}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mx-3 right">
                      <h6>
                        {/* ₹ */}
                        {userData.currency == "INR"
                          ? "₹"
                          : userData.currency == "CAD"
                          ? "$"
                          : userData.currency == "USD"
                          ? "$"
                          : userData.currency == "	GBP"
                          ? "£"
                          : userData.currency == "EUR"
                          ? "€"
                          : "₹"}
                        {item.price}
                      </h6>
                      {/* <p>Quantity : {item.qty}</p> */}
                    </div>
                    <div className="d-flex flex-column _cst_btn_43dsf2">
                      {item.acceptstatus == "accept" ? (
                        <p className="mb-3">
                          <Button
                            type="button "
                            color={
                              item.refund_request == "refund_initiated"
                                ? "success"
                                : servicerefund.admin_status ==
                                  "refund_rejected"
                                ? "danger"
                                : item.refund_request == "yes"
                                ? "warning"
                                : "info"
                            }
                            onClick={() => {
                              setIsOpenmodal(true);
                              seteachservicedetails(item);
                            }}
                          >
                            {item.refund_request == "refund_initiated"
                              ? "Refund Initiated"
                              : servicerefund.admin_status == "refund_rejected"
                              ? "Refund Rejected"
                              : item.refund_request == "yes"
                              ? "Refund Requested"
                              : "Refund"}
                          </Button>
                          &nbsp;
                        </p>
                      ) : item.acceptstatus == "reject" ? (
                        <p className="mb-3">
                          <Button type="button " color="danger">
                            Order Rejected
                          </Button>
                        </p>
                      ) : (
                        ""
                      )}
                      <div>
                        <Button
                          onClick={() => {
                            seteachservicedetails(item);
                            setorderid(item.order_id);
                            setisopenmodal1(true);
                          }}
                          className="_isActiveSell"
                        >
                          Chat with seller
                          <span style={{ top: "5px" }}></span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}

      <div className="row ">
        <div className="col-md-12">
          <div className="order_status p-3 border-bottom">
            <h3>Order Status</h3>
            <div className="d-flex">
              <i className="fas fa-check-circle mr-2 my-3"></i>
              <div>
                <h6>Order Placed</h6>
                <p>
                  Your order placed on{" "}
                  {moment(
                    props.orderdetails.servicecart_data[0].booking_date
                  ).format("MMM Do, h:mm a")}
                </p>
              </div>
            </div>
            {props.orderdetails.servicerefund_data.length ? (
              <div className="d-flex">
                <i className="fas fa-receipt mr-2 my-3"></i>
               
                <div>
                  <h6>Refund Requested</h6>
                  <p>
                    Refund requested on{" "}
                    {moment(
                      props.orderdetails.servicerefund_data[0].request_date
                    ).format("MMM Do, h:mm a")}
                  </p>
                </div>
              </div>
            ) : null}
            {props.orderdetails.servicecart_data[0].cancelstatus == "cancel" ? (
              <div className="d-flex">
                <i className="fas fa-times-circle mr-2 my-3"></i>
                <div>
                  <h6>Order Cancelled</h6>
                  <p>
                    Your order is cancelled on{" "}
                    {moment(
                      props.orderdetails.servicecart_data[0].cancel_date
                    ).format("MMM Do, h:mm a")}
                  </p>
                </div>
              </div>
            ) : null}

            <div className="d-flex my-2">
              <button
                className="btn btn-warning border-0 mx-3"
                onClick={downloadReciept}
                download
              >
                <i className="fas fa-file-download text-dark mr-2"></i>
                Download Reciept
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="shipping p-3 border-bottom">
            <h3>Billing Address</h3>
            <h6>
              Name:{" "}
              {props.orderdetails.firstname + " " + props.orderdetails.lastname}
              {/* {props.orderdetails.useraddress_data.length >= 1
                ? props.orderdetails.useraddress_data[0][
                    props.orderdetails.useraddress_data[0].length - 1
                  ].firstname +
                  " " +
                  props.orderdetails.useraddress_data[0][
                    props.orderdetails.useraddress_data[0].length - 1
                  ].lastname
                : props.orderdetails.useraddress_data[0][0].firstname +
                  " " +
                  props.orderdetails.useraddress_data[0][0].lastname} */}
            </h6>
            <h6>Email: {props.orderdetails.email}</h6>
            <h6>Phone: {props.orderdetails.phone}</h6>
            <h6>
              Adress:{" "}
              {props.orderdetails.address1 +
                ", " +
                props.orderdetails.state +
                ", " +
                props.orderdetails.country +
                ", " +
                props.orderdetails.zip}
            </h6>
            {props.orderdetails.servicecart_data.map((itemm,indexxx) => {
              return (
                <p key={indexxx}>Addtional Note : {itemm.addn_note}</p>
              )
            })}
            {/* <h6>
             Address:{" "} {props.orderdetails.useraddress_data.length >= 1
                ? props.orderdetails.useraddress_data[0][
                    props.orderdetails.useraddress_data[0].length - 1
                  ].address1 +
                  "," +
                  props.orderdetails.useraddress_data[0][
                    props.orderdetails.useraddress_data[0].length - 1
                  ].state +
                  "," +
                  props.orderdetails.useraddress_data[0][
                    props.orderdetails.useraddress_data[0].length - 1
                  ].country +
                  "," +
                  props.orderdetails.useraddress_data[0][
                    props.orderdetails.useraddress_data[0].length - 1
                  ].zip
                : props.orderdetails.useraddress_data[0][0].address1 +
                  "," +
                  props.orderdetails.useraddress_data[0][0].state +
                  "," +
                  props.orderdetails.useraddress_data[0][0].country +
                  "," +
                  props.orderdetails.useraddress_data[0][0].zip}
            </h6> */}
            {/* <p>Phone Number : +1 123 456 7895</p> */}
          </div>
        </div>
        {/* <div className="col-md-12">
          <div className="shipping p-3 border-bottom">
            <h3>Tip</h3>
            <div className="form-group">
              <div className="d-flex tip_box justify-content-between">
                <input
                  type="text"
                  className=" form-control border-0"
                  id="exampleInput"
                  placeholder={
                    isTipPaid ? "Tip Paid Successfully" : "Enter Tip Amount"
                  }
                  onChange={(val) => {
                    if (val.target.value.match("^[0-9]*$") != null) {
                      settips(val.target.value);
                    }
                  }}
                  disabled={isTipPaid ? true : false}
                  value={tips}
                />
                <div className={isTipPaid ? "d-none" : "d-block"}>
                  <button className="btn pay_btn " onClick={submitTips}>
                    Pay
                  </button>
                </div>
              </div>
            </div>
            <br />

            <div className={isTipPaid ? "d-block" : "d-none"}>
              <div className="d-flex tip_box justify-content-between">
                <input
                  type="text"
                  className=" form-control border-0"
                  id="exampleInput"
                  value={props.orderdetails ? props.orderdetails.tip : ""}
                />
                <div>
                  <p className="btn pay_btn text-success mb-0">Paid</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-md-12">
          <div className="p-3 invoice">
            <h3>Invoice</h3>
            <div className="d-flex justify-content-between">
              <p>Item(s) Total</p>
              <p>
                {/* ₹  */}
                {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "	GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}
                {props.orderdetails.subtotal.toFixed(2)}
              </p>
            </div>{" "}
            <div className="d-flex justify-content-between">
              <p>Coupon Code Applied</p>
              <p>
                {/* - ₹  */}
                {userData.currency == "INR"
                  ? "-₹"
                  : userData.currency == "CAD"
                  ? "-$"
                  : userData.currency == "USD"
                  ? "-$"
                  : userData.currency == "	GBP"
                  ? "-£"
                  : userData.currency == "EUR"
                  ? "-€"
                  : "-₹"}
                {(props.orderdetails.subtotal-props.orderdetails.total).toFixed(2)}
              </p>
            </div>
            <div className="d-flex justify-content-between border-bottom">
              <p>Tips Amount</p>
              {paidOn ? (
                <p>
                  {/* - ₹  */}
                  {userData.currency == "INR"
                    ? "₹"
                    : userData.currency == "CAD"
                    ? "$"
                    : userData.currency == "USD"
                    ? "$"
                    : userData.currency == "	GBP"
                    ? "£"
                    : userData.currency == "EUR"
                    ? "€"
                    : "₹"}
                  {tipAmount ? tipAmount : 0}
                </p>
              ) : (
                <p>
                  {/* - ₹  */}
                  {userData.currency == "INR"
                    ? "₹"
                    : userData.currency == "CAD"
                    ? "$"
                    : userData.currency == "USD"
                    ? "$"
                    : userData.currency == "	GBP"
                    ? "£"
                    : userData.currency == "EUR"
                    ? "€"
                    : "₹"}
                  {props.orderdetails.servicecart_data[0].tip
                    ? props.orderdetails.servicecart_data[0].tip
                    : 0}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="p-3 invoice">
            {/* <div className="d-flex justify-content-between">
              <p>Delivery Fees</p>
              <p>₹ 20.00</p>
            </div>{" "}
            <div className="d-flex justify-content-between border-bottom">
              <p>Coupon Code Applied</p>
              <p>- ₹ 20.00</p>
            </div> */}
          </div>
        </div>
        <div className="col-md-12">
          <div className="p-3 total">
            <div className="d-flex justify-content-between ">
              <p>Total Amount</p>
              <p>
                {/* ₹  */}
                {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "	GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}
                {props.orderdetails.total.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-12 ">
          <div className="d-flex justify-content-between px-3">
            <p>Disclaimer:</p>
            <p>It includes all the taxes</p>
          </div>
        </div>
        {/* {props.orderdetails.status == "cancel" ? (
          <div className="col-md-12 text-right ">
            <div className="border-top py-2">
              {" "}
              <h3 style={{ color: "red" }}>Order Cancelled</h3>
            </div>
          </div>
        ) : (
          <div className="col-md-12 text-right ">
            <div className="border-top py-2">
              {" "}
              <button
                type="button"
                className="btn cancel_btn"
                onClick={() => setOrderDlt(true)}
              >
                Cancel Order
              </button>
            </div>
          </div>
        )} */}
      </div>
      <div
        className={orderDlt ? "modal fade loginmodal show" : "modal fade"}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: orderDlt ? "block" : "none" }}
      >
        <OrderDLT
          callBackOrder={callBackOrder}
          orderid={props.orderdetails._id}
          success={success}
          servdetails={eachservicedetails}
          index={index}
        />
      </div>
      {isOpenmodal ? (
        <RefundModal
          status={true}
          orderdetails={props.orderdetails}
          onCloseCallBack={Callbackmsg}
          servdetails={eachservicedetails}
          image=""
        />
      ) : null}

      {/* {
        console.log("props.orderdetails", props.orderdetails)
      } */}

      {isOpenmodal1 ? (
        <ChatWithSeller
          status={true}
          remoteUserDetails={eachservicedetails.seller_data[0]}
          onCloseCallBack1={Callbackmsg1}
          image=""
        />
      ) : null}
    </div>
  );
};
export default ServiceOrderDetails;
