import React, { useState, useEffect } from "react";
// import Table from "./Table";
import { MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import SellerBreadcumb from "../Components/SellerBreadcumb";
// import Details from "./Details";
import Pdf from "react-to-pdf";
const ref = React.createRef();

const ViewOrderDetails = (props) => {
  const id = props.match.params.id;
  const [table, setTable] = useState(false);
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [disbale, setdisbale] = useState(false);
  const userData = reactLocalStorage.getObject("userData");
  const [dataa, setdata] = useState([]);

  useEffect(() => {
    getorderlist();
  }, []);
  console.log("props.id", props.match.params.id);

  const getorderlist = async () => {
    let result = await HttpClient.requestData(
      "sellersingleBookinghis/" +
        props.match.params.id +
        "?currency=" +
        userData.currency,
      "GET"
    );
    console.log("sellersingleBookinghis", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      let processing_fee = result.processing_fees.processing_fees;
      result.data.forEach((element, index) => {
        var calProcessingper = (element.price * element.processing) / 100;
        var remAmount = element.price - calProcessingper;
        console.log(element.order_id);
        let fname =
          element.user_data.length > 0 && element.user_data[0].firstName;
        let lname =
          element.user_data.length > 0 && element.user_data[0].lastName;
        let rows = {
          name: fname + lname,
          orderid: element.order_id,
          email: element.user_data.length > 0 ? element.user_data[0].email : "",
          phone: element.phone ? element.phone : "",
          amount: element.total,
          tip: element.tip ? element.tip : 0,
          currency: element.currency ? element.currency : "",
          processing: element.processing,
          remainingAmt:
            (userData.currency == "INR"
              ? "₹"
              : userData.currency == "CAD"
              ? "$"
              : userData.currency == "USD"
              ? "$"
              : userData.currency == "	GBP"
              ? "£"
              : userData.currency == "EUR"
              ? "€"
              : "₹") + remAmount.toFixed(2),
          price:
            (userData.currency == "INR"
              ? "₹"
              : userData.currency == "CAD"
              ? "$"
              : userData.currency == "USD"
              ? "$"
              : userData.currency == "	GBP"
              ? "£"
              : userData.currency == "EUR"
              ? "€"
              : "₹") + element.discountedPrice,
          catname: element.service_data[0].cat_name,
          subcatname: element.service_data[0].subcat_name,
          servicename: element.service_data[0].name,
          paydate_on: moment(element.booking_date).format("MMMM Do YYYY"),
          details: element.service_data[0].details.replace(/<[^>]+>/g, ""),
          image: (
            <img
              src={HttpClient.IMG_URL + element.service_data[0].image[0]}
              alt="img"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "6px",
              }}
            />
          ),

          download: (
            <Pdf targetRef={ref} filename="code-example.pdf">
              {({ toPdf }) => (
                <button onClick={toPdf}>
                  <i className="fas fa-download mr-2"></i>
                  Download
                </button>
              )}
            </Pdf>
          ),
          // paystatus: element.paystatus == true ? "Paid" : "Pending",
          // paystatus: element.paystatus ? (
          //     <button
          //         className="btn-paid"
          //     >
          //         {" "}
          //         Paid
          //     </button>
          // ) : (
          //     <button
          //         className="btn-pending"
          //     >
          //         {" "}
          //         Pending
          //     </button>
          // ),
        };
        i++;
        data.push(rows);
      });

      setdata(data);
      setTable(true);
    } else {
      setdata([]);
    }
  };

  const data = {
    columns: [
      {
        label: "OrderId",
        field: "orderid",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      // {
      //   label: "Phone",
      //   field: "phone",
      //   width: 150,
      // },
      {
        label: "Category Name",
        field: "catname",
        width: 150,
      },
      {
        label: "Subcategory Name",
        field: "subcatname",
        width: 150,
      },
      {
        label: "Servicename",
        field: "servicename",
        width: 270,
      },
      {
        label: "Image",
        field: "image",
        width: 270,
      },
      {
        label: "Paid On",
        field: "paydate_on",
        width: 270,
      },
      // {
      //   label: "Currency",
      //   field: "currency",
      //   width: 270,
      // },
      // {
      //   label: "Payment Type",
      //   field: "payment_type",
      //   width: 270,
      // },
      {
        label: "Price",
        field: "price",
        width: 270,
      },
      {
        label: "Tip Paid",
        field: "tip",
        width: 270,
      },
      {
        label: "Processing Fee(%)",
        field: "processing",
        width: 270,
      },
      {
        label: "Remaining Amount",
        field: "remainingAmt",
        width: 270,
      },
      // {
      //   label: "Details",
      //   field: "details",
      //   width: 270,
      // },
      // {
      //   label: "Total",
      //   field: "amount",
      //   width: 270,
      // },
      // {
      //     label: "Pay Status",
      //     field: "paystatus",
      //     width: 270,
      // },
      // {
      //   label: "Tip",
      //   field: "tip",
      //   width: 270,
      // },
      // {
      //   label: "Order Reciept",
      //   field: "download",
      //   width: 270,
      // },
    ],
    rows: dataa,
  };
  return (
    <div className="order-report ">
      <ToastContainer />
      <SellerBreadcumb
        title="Order Details"
        description="View Your Order Details"
      />
      <div className="container-fluid" style={{ maxWidth: "1200px" }}>
        {/* <div className="row my-4">
        <div className="col-md-12 text-center">
          <h1>Order Details</h1>
        </div>
      </div> */}
        {/* <div className="row">
        <div className="col-md-6 mx-auto">
          <form>
            <div className="row">
              <div className="col-12">
                <label htmlFor="inputFromDate">From Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="From Date"
                  value={fromdate}
                  onChange={(val) => {
                    setfromdate(val.target.value);
                  }}
                />
              </div>
              <div className="col-12 my-4">
                <label htmlFor="inputToDate">To Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="To Date"
                  value={todate}
                  onChange={(val) => {
                    settodate(val.target.value);
                    setdisbale(true);
                  }}
                />
              </div>
            </div>
          </form>
          <div className="text-center">
            <button
              type="button"
              className="btn report-btn"
              onClick={submit}
              disabled={!disbale}
            >
              Submit
            </button>
          </div>
        </div>
      </div> */}
        <div ref={ref}>
          <div className="row mt-5">
            <div className="col-md-12 mx-auto _breaking_word">
              <MDBDataTableV5
                hover
                bordered
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                data={data}
                responsive
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewOrderDetails;
