import React, { useState, useEffect } from "react";
import Blog_ban from "../../Assets/Images/blog_ban_img.png";
import Footer from "../../Defaults/Footer";
import MorePopular from "../BlogPage/MorePopular";
import SubsBox from "../BlogPage/SubsBox";
import Blog from "../Home/Blog/BlogUpdated";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "../../utils/HttpClient";
import moment from "moment";
import { Helmet } from "react-helmet";
import MetaTags from 'react-meta-tags';

function Index(props) {
  console.log("props id single blog", props.match.params.id);
  let blogSingleId = props.match.params.id;
  

 
  const [singleBlodDetails, setsingleBlodDetails] = useState();
  const [isBlogLoaded, setisBlogLoaded] = useState(false);
  const [blogData, setblogData] = useState([]);
  useEffect(() => {
    let mata = document.getElementsByTagName('meta') .namedItem('description')
    document
      .getElementsByTagName('meta')
      .namedItem('description')
      .setAttribute('content','My Meta Description Here')

      console.log(mata,"metaaaaa");
   }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
    getSingleBlogg();
    console.log("singleBlodDetails", singleBlodDetails);
    getBlogData();
  }, [props.match.params.id]);

  const getSingleBlogg = async () => {
    let result = await HttpClient.requestData(
      "singleBlog/" + blogSingleId,
      "GET"
    );
    console.log("result single blog data--", result);
    if (result && result.status) {
      setsingleBlodDetails(result.data);
      setisBlogLoaded(true);
    }
  };

  const getBlogData = async () => {
    let result = await HttpClient.requestData("Blog", "GET");
    console.log("result blog data--", result);
    if (result && result.status) {
      let filterBlog = result.data.filter((item) => item._id != blogSingleId);
      setblogData(filterBlog);
    }
  };
  return (
    <>
    
      <Helmet>
        <title>{isBlogLoaded ? singleBlodDetails.heading : null}</title>
        <meta
          property="og:title"
          content={isBlogLoaded ? singleBlodDetails.heading : null}
        />
        <meta
          property="og:description"
          content={isBlogLoaded ? singleBlodDetails.heading : null}
        />
      </Helmet>
      <MetaTags>
            <title>Page 1</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
      <div>
        <div className="wrapper">
          <ReactTitle
            title={
              isBlogLoaded
                ? singleBlodDetails.heading
                : null + " | " + " Blog | Astrophy"
            }
          />
        </div>
        <section>
          <div className="container-fluid" style={{ maxWidth: "1200px" }}>
            <div className="row pt-4">
              <div className="col-md-8 blog-detail-head">
                <div className="d-flex justify-content-between dlex-wrap   ">
                  {isBlogLoaded ? (
                    <h6 className="blog-date">
                      {moment(singleBlodDetails.created_on).format(
                        "MMMM Do YYYY"
                      )}
                    </h6>
                  ) : null}
                  {/* <h6 className="blog_view">
                  <i className="far fa-eye mx-2"></i>1024
                </h6> */}
                </div>
                <div>
                  {isBlogLoaded ? <h3>{singleBlodDetails.heading}</h3> : null}
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid" style={{ maxWidth: "1200px" }}>
            <div className="row py-md-4">
              <div className="col-lg-8 col-md-8">
                <div className="blog_ban_img">
                  {isBlogLoaded ? (
                    <img
                      src={HttpClient.IMG_URL + singleBlodDetails.image}
                      alt="img"
                      style={{ width: "100%", height: "510px" }}
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 my-lg-0 my-4">
                <SubsBox />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid" style={{ maxWidth: "1200px" }}>
            <div className="row py-0">
              <div className="col-md-12 blog_details_text">
                {isBlogLoaded ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: singleBlodDetails.content1,
                    }}
                  ></p>
                ) : null}
                <p>
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus{" "} */}
                </p>
                <br></br>

                {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus{" "}
              </p>
              <br></br> */}

                {/* <h5>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit.
                <span>
                  A, volutpat nibh cursus Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. A, volutpat nibh cursus. Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
                  nibh cursus Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit. A, volutpat nibh cursus. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. A, volutpat nibh cursus{" "}
                </span>
              </h5>
              <br></br> */}

                {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus{" "}
              </p>
              <br></br> */}

                {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. A, volutpat nibh cursus. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. A, volutpat nibh cursus{" "}
              </p> */}
              </div>

              {/* <div className="col-md-3 Latest_blog">
              <h6>More Popular</h6>
              <MorePopular blogData={blogData}/>
            </div> */}
            </div>
          </div>
        </section>
        <section>
          <div
            className="container-fluid related_blog"
            style={{ maxWidth: "1200px" }}
          >
            <div className="row pb-4">
              <div className="col-md-12">
                <div className="Latest_blog">
                  <h6>Related Blogs</h6>
                </div>
              </div>
            </div>
            <Blog blogData={blogData} />
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </div>
    </>
  );
}
export default Index;
