import React, { useState, useEffect } from "react";
import Footer from "../../Defaults/Footer";
import { reactLocalStorage } from "reactjs-localstorage";
import validator from "validator";
import HttpClient from "../../utils/HttpClient";
import { ReactTitle } from "react-meta-tags";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";

const LegalNotice = () => {
  const [other, setOther] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [url, seturl] = useState("");
  const [desc, setdesc] = useState("");
  const [against, setagainst] = useState("");
  const [image, setimage] = useState("");
  const [phoneError, setphoneError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [emailvalid, setemailvalid] = useState(false);
  const [isVerifiedRecaptcha, setisVerifiedRecaptcha] = useState(false);
  const userId = reactLocalStorage.getObject("userData");

  const Submit = async () => {
    if (
      name == "" &&
      email == "" &&
      mobile == "" &&
      url != "" &&
      against == "" &&
      desc == "" &&
      image == ""
    ) {
      toast.error("Please enter all fields", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (name == "") {
      toast.error("Please enter name", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (email == "") {
      toast.error("Please enter email", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (emailvalid == true) {
      toast.error("Please enter valid email", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (mobile == "") {
      toast.error("Please enter contact number", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (url == "") {
      toast.error("Please enter url", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (against == "") {
      toast.error("Please enter report against", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (desc == "") {
      toast.error("Please enter your report", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (image == "") {
      toast.error("Please choose image", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else {
      let data = new FormData();
      data.append("fullname", name);
      data.append("email", email);
      data.append("phone", mobile);
      data.append("url", url);
      data.append("report_against", against);
      data.append("report_details", desc);
      data.append("file", image);
      let result = await HttpClient.fileUplode("legal-notice", "POST", data);

      if (result && result.status) {
        toast.success(
          "Thank you for contacting us, we will reach back to you in a short time.",
          {
            position: "bottom-right",
            autoClose: 3000,
          }
        );
        setTimeout(function () {
          window.location.href = "/home";
        }, 3000);
      } else {
        toast.error(result.message, {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    }
  };

  // const Submit = async () => {
  //   if (
  //     name != "" &&
  //     email != "" &&
  //     mobile != "" &&
  //     url != "" &&
  //     against != "" &&
  //     desc != "" &&
  //     image != ""
  //   ) {
  //     let data = new FormData();
  //     data.append("fullname", name);
  //     data.append("email", email);
  //     data.append("phone", mobile);
  //     data.append("url", url);
  //     data.append("report_against", against);
  //     data.append("report_details", desc);
  //     data.append("file", image);
  //     let result = await HttpClient.fileUplode("legal-notice", "POST", data);

  //     if (result && result.status) {
  //       toast.success("Data saved successfully", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //       setTimeout(function () {
  //         window.location.href = "/home";
  //       }, 3000);
  //     } else {
  //       toast.error(result.message, {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     }
  //   } else if (emailvalid == true) {
  //     toast.error("Please enter valid email", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   } else {
  //     toast.warning("please fill up all the field", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //     });
  //   }
  // };

  const onImageChange = async (event) => {
    console.log("event", event.target.files);
    setimage(event.target.files[0]);
  };
  function onChange(value) {
    console.log("Captcha value:", value);
    setisVerifiedRecaptcha(true);
  }
  return (
    <div className="Customer-service feedback">
      <ReactTitle title="Astrophy | Legal Notice" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center my-4">
            <h2>Legal Notice</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8  my-4 m-auto">
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Person Name"
                  onChange={(val) => {
                    setname(val.target.value);
                  }}
                />
              </div>
              <div className="col">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  onChange={(val) => {
                    setemail(val.target.value);
                    if (val.target.value == "") {
                      setemailError(true);
                    } else {
                      setemailError(false);

                      if (validator.isEmail(email)) {
                        setemailvalid(false);
                      } else {
                        setemailvalid(true);
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Contact Number"
                  onChange={(val) => {
                    if (val.target.value.match("^[0-9]*$") != null) {
                      setmobile(val.target.value);
                      if (val.target.value.length > 10) {
                        setphoneError(true);
                      } else {
                        setphoneError(false);
                      }
                    }
                  }}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="URL"
                  onChange={(val) => {
                    seturl(val.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group my-3">
              <label htmlFor="exampleFormControlSelect1">Report Against</label>
              {/* <select className="form-control" id="exampleFormControlSelect1">
                <option>problem1</option>
                <option>problem2</option>
                <option onClick={() => setOther(true)}>others</option>
              </select> */}
              <input
                type="text"
                className="form-control"
                placeholder="Against.."
                onChange={(val) => {
                  setagainst(val.target.value);
                }}
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">
                Write Your Report
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                onChange={(val) => {
                  setdesc(val.target.value);
                }}
              />
            </div>
            <div className="form-group mt-3">
              <input
                type="file"
                className="form-control-file btn"
                id="exampleFormControlFile1"
                onChange={onImageChange}
              />
            </div>
            <div className="form-group mt-3">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={onChange}
              />
            </div>
            <div className="text-center my-4">
              <button
                className="btn submit_btn"
                onClick={Submit}
                disabled={!isVerifiedRecaptcha}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default LegalNotice;
