import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import ChatWithBuyer from "../../Seller/MyOrders/chatwithbuyer";
// import ViewOrderDetails from "./ViewOrderDetails";
import db from "../../Firebase/fbconfig";
import { ref, child, get, set, update } from "firebase/database";
import { v4 as uuidv4 } from "uuid";

const SellOrder = (props) => {
  const userData = reactLocalStorage.getObject("userData");
  useEffect(() => {
    window.scrollTo(0, 0);
    getorderlist();
  }, []);
  // console.log("props",props.data);
  const [show, setShow] = useState(true);
  const [hide, sethide] = useState(true);
  const [acceptstatus, setacceptstatus] = useState("");
  const [order, setorder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenmodalbuyer, setisopenmodalbuyer] = useState(false);
  const [orderid, setorderid] = useState("");
  const [name, setname] = useState("");
  const [servid, setservid] = useState("");

  const getorderlist = async () => {
    setLoading(true);
    let data = {};
    let result = await HttpClient.requestData(
      "sellerbookhistory/" + userData._id,
      "GET",
      data
    );
    console.log("order details", result);
    if (result && result.status) {
      let newArray = result.data.reverse();
      setorder(newArray);
      // setorder(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };

  const handleAccept = async (id) => {
    let sendData = {
      id: id,
      acceptstatus: "accept",
      currencyy: userData.currency,
    };
    console.log("data", sendData);
    // return false;
    let result = await HttpClient.requestData(
      "accept_status",
      "POST",
      sendData
    );
    console.log("accept_status", result);
    if (result && result.status) {
      toast.success("Accept successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getorderlist();
      props.update(result.status);
    } else {
      toast.error(result.error, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleReject = async (id) => {
    let sendData = {
      id: id,
      acceptstatus: "reject",
    };
    console.log("data", sendData);
    // return false;
    let result = await HttpClient.requestData(
      "accept_status",
      "POST",
      sendData
    );
    console.log("accept_status", result);
    if (result && result.status) {
      toast.success("Rejected", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getorderlist();
      props.update(result.status);
    } else {
      toast.error("Error", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const Callbackmsg1 = async (val) => {
    setisopenmodalbuyer(false);
  };

  const viewOrderDetail = (id) => {
    setTimeout(() => {
      window.location.href = "/view-order-details/" + id;
    }, 10);
  };

  const createchatList = (sellerId,serviceData) =>{
    console.log('userData._id',userData._id,serviceData)
      const dbRef = ref(db);
      get(
        child(
          dbRef,
          `/chatList/${userData._id}/${sellerId}`
        )
      )
        .then((snapshot) => {
          if (snapshot.exists()) {
            // setnewchat(item);
            // setnochatopen(true);
            console.log(snapshot.val());
            setTimeout(() => {
              window.location.href = `/messages/${snapshot.val().roomId}`;
            }, 100);
          } else {
            // setnewchat(item);
            // setnochatopen(true);
            let roomId = uuidv4();
            console.log("No data available");
            // console.log(`/chatList/${userData._id}/${item._id}`);
            let fbdata ={
              name:serviceData?.firstName+' '+serviceData?.lastName,
              displayName: serviceData?.firstName,
              userId: sellerId,
              roomId: roomId,
            }
            console.log('fbdata',fbdata)
            set(
              ref(
                db,
                `/chatList/${userData._id}/${sellerId}`
              ),
              fbdata
            );
            set(
              ref(
                db,
                `/SellerToSellerchatList/${userData._id}/${sellerId}`
              ),
              {
                name: userData.firstName + " " + userData.lastName,
                displayName: userData.firstName,
                userId: userData._id,
                roomId: roomId,
              }
            );
            setTimeout(() => {
              window.location.href = `/messages/${roomId}`;
            }, 100);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      // setTimeout(() => {
      //   window.location.href = "/messages";
      // }, 100);
  }
  return (
    <div className="row">
      <ToastContainer />
      {order.map((item, index) => {
        // console.log("item", item.user_data[0])
        return (
          <div className="col-md-6" key={index}>
            <div className="card sell_order my-3">
              <div className="card-body">
                <div className="d-flex justify-content-around">
                  <img src={HttpClient.IMG_URL + item.image} alt="img" />
                  <div
                    className="ml-3"
                    onClick={() => viewOrderDetail(item._id)}
                  >
                    <h6>{item.order_id}</h6>
                    <h5>{item.servicename}</h5>
                    {/* <p>{item.para}</p> */}
                  </div>
                  {item.cancelstatus == "" ? (
                    item.acceptstatus == "pending" ? (
                      <div className="ml-4 w-25">
                        <button
                          type="button"
                          className="btn acc_btn"
                          onClick={() => handleAccept(item._id)}
                        >
                          Accept
                        </button>

                        <button
                          type="button"
                          className="btn reject_btn my-3"
                          onClick={() => handleReject(item._id)}
                        >
                          Reject
                        </button>
                      </div>
                    ) : item.acceptstatus == "accept" ? (
                      <div className="ml-4 w-25">
                        <button type="button" className="btn acc_btn">
                          Accepted
                        </button>
                      </div>
                    ) : (
                      <div className="ml-4 w-25">
                        <button type="button" className="btn reject_btn my-3">
                          Rejected
                        </button>
                      </div>
                    )
                  ) : (
                    <div className="ml-4 w-25">
                      <button type="button" className="btn reject_btn my-3">
                        Order Cancelled
                      </button>
                    </div>
                  )}
                  {item.acceptstatus == "accept" ? (
                    <div className="ml-4 w-25">
                      <button
                        type="button"
                        className="btn reject_btn"
                        onClick={() => {
                          // setisopenmodalbuyer(!isOpenmodalbuyer);
                          createchatList(item.user_data[0]._id,item.user_data[0])
                          setorderid(item.order_id);
                          setname(
                            item.user_data[0].firstName +
                              " " +
                              item.user_data[0].lastName
                          );
                          setservid(item.serv_id);
                        }}
                      >
                        Chat with Buyer
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              {isOpenmodalbuyer ? (
                <ChatWithBuyer
                  status={true}
                  // orderdetails={sentorderdetails}
                  name={name}
                  orderid={orderid}
                  onCloseCallBack1={Callbackmsg1}
                  image=""
                  servid={servid}
                />
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SellOrder;
