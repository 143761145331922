import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import AuthModal from "../Auth/AuthModal";
import Frame from "../Assets/Images/Frame 1.png";
import SearchModal from "./SearchModal";
import { reactLocalStorage } from "reactjs-localstorage";
import Profile from "../Assets/Images/user.jpg";
import HttpClient from ".././utils/HttpClient";
import Loader from "../Component/loader";
import SellerModal from "./sellerModal";
import logo from "../Assets/Images/logo.png";
import { toast } from "react-toastify";
import db from "../Firebase/fbconfig";
import { ref, push, set, child, update, get } from "firebase/database";
import axios from "axios";

const Header = (props) => {
  const [search, setSearch] = useState(false);
  const [SearchShow, setSearchShow] = useState(false);
  // const [login, setLogin] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [showAuthmodal, setShowAuthmodal] = useState(false);
  const [servicedrop, setServicedrop] = useState(false);
  const [service, setService] = useState([]);
  const [reading, setReading] = useState(false);
  const [spelldrop, setSpelldrop] = useState(false);
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [searchstring, setsearchstring] = useState("");
  const [arr, setarr] = useState("");
  const [loading, setLoading] = useState(false);
  const [servicemodal, setServicemodal] = useState(false);
  const [approvedStatus, setapprovedStatus] = useState([]);
  const [count, setcount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [countser, setcountser] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [login, setLogin] = useState(
    reactLocalStorage.getObject("loginstatus")
  );
  const userData = reactLocalStorage.getObject("userData");
  const [ip, setIP] = useState("");

  const loginstatus = reactLocalStorage.getObject("loginstatus");
  const sellerApprovalId = reactLocalStorage.getObject("seller_approval_id");
  const [searchresult, setsearchresult] = useState([]);
  const [buttonStatus, setbuttonStatus] = useState(false);
  const [outSideClick, setOutsideClick] = useState(false);
  const [cartDropdown, setcartDropdown] = useState(false);
  const [wishlistDropdown, setwishlistDropdown] = useState(false);
  
  const pathName = window.location.pathname.split("/");
  console.log(pathName, "path");

  const wishShow = (e) => {
    // console.log("targets",e.target)
    document.addEventListener(
      "mousemove",
      (e) => {
        // console.clear()
        // console.log( document.elementFromPoint(e.clientX, e.clientY) )

        var hoverElement = document.elementFromPoint(e.clientX, e.clientY);
        console.log(hoverElement);
        if (hoverElement.classList.contains("wistlistcl")) {
          // console.log('here')
          setwishlistDropdown(true);
        }
      },
      { passive: true }
    );
  };

  //   Cart Show
  const cartShow = (e) => {
    // console.log("targets",e.target)
    document.addEventListener(
      "mousemove",
      (e) => {
        // console.clear()
        // console.log( document.elementFromPoint(e.clientX, e.clientY) )

        var hoverElement = document.elementFromPoint(e.clientX, e.clientY);
        console.log(hoverElement);
        if (hoverElement.classList.contains("shopcartcl")) {
          // console.log('here')
          setcartDropdown(true);
        }
      },
      { passive: true }
    );
  };

  //   const ref = useRef();
  // const setHover = (e)=>{
  //   // console.log("current",ref.current.classList)
  //   // if(ref.current.classList.contains("wishlist"))
  //   // {
  //   //   // console.log('here')
  //   //   setwishlistDropdown(true)
  //   // }
  //   // else
  //   // {
  //   //   console.log('a')
  //   //   setwishlistDropdown(false)
  //   // }

  // }

  // const headerOver=(e)=>{
  //   console.log(e.target)
  //   if(e.target.classList.contains('wishlist'))
  //   {
  //     setwishlistDropdown(true)
  //   }
  //   else if(e.target.classList.contains('fa-heart'))
  //   {
  //     setwishlistDropdown(true)
  //   }
  //   else if(e.target.classList.contains('wishlistDrop'))
  //   {
  //     setwishlistDropdown(true)
  //   }
  //   // else
  //   // {
  //   //   setwishlistDropdown(false)
  //   // }
  // }
  const ref = useRef();
  const callBackAuth = (val) => {
    setShowAuthmodal(val);
  };
  const callBackSearch = (val) => {
    setSearchShow(val);
  };
  const callBackService = (val) => {
    setServicemodal(val);
    fetchStatus();
  };
  const success = (status) => {
    // console.log("seller status", status);
    setbuttonStatus(status);
    // if (status) {
    //   setbuttonStatus(status);
    // }
  };
  const logout = async () => {
    reactLocalStorage.clear();
    reactLocalStorage.setObject("loginstatus", false);
    notificationoff();
    setTimeout(function () {
      window.location.href = "/home";
    }, 600);
  };

  useEffect(() => {
    // console.log("sellerApprovalId", sellerApprovalId);
    getData();

    fetchService();
    if (loginstatus && Object.keys(userData).length > 0) {
      fetchData();
      fetchStatus();
      const intervalid = setInterval(() => {
        getcart();
        getcartser();
      }, 1500);
      return () => clearInterval(intervalid);
    } else {
    }
    callBackService();
  }, []);

  const fetchService = async () => {
    let result = await HttpClient.requestData("service", "GET");
    console.log("Service category---", result);
    if (result && result.status) {
      let data = result.data.filter((item) => item.status == true);
      console.log("data", data);
      setService(data);
      // console.log("Servicedata", service);
    } else {
    }
  };
  const fetchStatus = async () => {
    // let sellerApprovalId = reactLocalStorage.getObject("seller_approval_id")
    // console.log("hhh", userData);
    let obj = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "seller-approval-status/" + reactLocalStorage.getObject("userData")._id,
      "GET"
    );
    // console.log("Seller approval", result);
    if (result && result.status) {
      // let data = result.data;
      setapprovedStatus(result.data);
      obj.type = result.data.type;
      reactLocalStorage.setObject("userData", obj);
      // console.log("updated react local", reactLocalStorage.getObject("userData"));
    } else {
    }
  };

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data, "ip");
    setIP(res.data.IPv4);
  };

  const searchh = async (keyword) => {
    console.log("serch.....", keyword);
    setsearchstring(keyword);
    setLoader(true);
    let data = {
      searchname: keyword,
      email: userData.email,
      // search_by: userData.firstName + " " + userData.lastName
      search_by: ip,
    };
    console.log("searchauto data", data);
    console.log("searchstring", searchstring);
    if (keyword == "" || keyword.length < 3) {
      // setSearch(false);
     
      setSearchShow(false);
    } else {
    
      if (Object.keys(userData).length > 0) {
        let result = await HttpClient.requestData(
          "searchauto?currency=" + userData.currency,
          "POST",
          data
        );
        console.log("data searchauto result", result);
        if (result && result.status) {
          // let extraData=[
          //   {
          //     type: 'show',
          //     val: 'Find service names containing '+ result.searchString
          //   }
          // ]

          // result.data.push(obj);
          setLoader(false);
          setsearchresult(result.data);
          setSearchShow(true);
        } else {
          setsearchresult([]);
        }
      } else {
        let result = await HttpClient.requestData(
          "searchauto?currency=" + "INR",
          "POST",
          data
        );
        console.log("else pasrt");
        console.log("data searchauto result", result);
        if (result && result.status) {
          // let obj={
          //   type: 'show',
          //   val: 'Find product/service names containing '+ result.searchString
          // }
          // result.data.push(obj);
          setsearchresult(result.data);
          setSearchShow(true);
        } else {
          setsearchresult([]);
        }
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    let result = await HttpClient.requestData("seller/" + userData._id, "GET");
    // console.log("Data", result);
    if (result && result.status) {
      // setuser(result.data);
      setfname(result.data.firstName);
      setlname(result.data.lastName);
      result.data.image
        ? setarr(HttpClient.IMG_URL + result.data.image)
        : setarr("");
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1);
    }
  };

  const getcart = async () => {
    // setLoading(true);
    let data = {};
    // console.log("data", data);
    let result = await HttpClient.requestData(
      "get-cart/" + userData._id,
      "GET",
      data
    );
    // console.log("result", result);
    if (result && result.status) {
      setcount(result.data.length);
    } else {
    }
  };

  const getcartser = async () => {
    let data = {};
    // console.log("data", data);
    let result = await HttpClient.requestData(
      "servicecart/" + userData._id,
      "GET",
      data
    );
    // console.log("result servicecart---", result);
    if (result && result.status) {
      setcountser(result.data.length);
    } else {
    }
  };

  const notificationoff = async () => {
    const dbRef = ref(db);
    // console.log("/Chat/" + props.roomId + "/messages/");
    get(child(dbRef, "/user/" + userData._id))
      .then((snapshot) => {
        if (snapshot.exists()) {
          update(ref(db, "user/" + userData._id), {
            fcmToken: "",
          });
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const alertt = () => {
    toast.error("You have to login first", {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  const badgeStyle = {
    position: "absolute",
    top: "-3px",
    left: "32px",
    fontSize: "11px",
    padding: "2px 4px",
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(" ", "-");
    return strReplace;
  };

  const popupQuerySelector = "#SearchShowModal";
  const popupEl = document.querySelector(popupQuerySelector);

  document.addEventListener("click", (e) => {
    // Check if the filter list parent element exist
    const popupQuerySelector = "#SearchShowModal";
    const popupEl = document.querySelector(popupQuerySelector);
    const isClosest = e.target.closest(popupQuerySelector);

    // If `isClosest` equals falsy & popup has the class `show`
    if (!isClosest && popupEl.classList.contains("show")) {
      popupEl.classList.remove("show");
      setSearchShow(false);
      setsearchstring("");
    }
  });
  //  return false;
  return (
    <div className="shadow-sm">
      <nav className="navbar navbar-expand-lg py-2 px-lg-3">
        <Link className="navbar-brand nav_logo " to="/home">
          {/* <h2 className="mr-lg-3">Astrophy</h2> */}
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <button
          className="navbar-toggler navbar-light p-0"
          type="button"
          data-toggle={outSideClick ? "collapse" : ""}
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setOutsideClick(!outSideClick)}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse header_nav"
          id="navbarSupportedContent"
        >
          {search ? (
            <form autoComplete="off" style={{ width: "75%" }}>
              {/* <div className="form-group serch_bar my-0 mx-lg-3">
                <div className="d-flex search ">
                  <input
                    type="text"
                    className="form-control border-0"
                    id="searchbar"
                    placeholder="Search"
                    // defaultValue={searchValue}
                    // onClick={() => setSearchShow(!SearchShow)}
                    value={searchstring}
                    onChange={(val) => {
                      searchh(val.target.value);
                    }}
                  />
                  <div className="my-auto text-muted d-flex">
                    <button
                      type="button"
                      className="cross close ml-auto px-3"
                      aria-label="Close"
                      onClick={() => {
                        setSearch(false);
                        setSearchShow(false);
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="bg-search">
                      <i className="fas fa-search mr-2 my-3"></i>
                    </div>
                  </div>
                </div>
                <div
                  className={SearchShow ? "search_modal show" : "search_modal"}
                  id="SearchShow"
                  tabIndex="-1"
                  aria-labelledby="SearchShowLabel"
                  aria-hidden="true"
                  style={{ display: SearchShow ? "block" : "none" }}
                >
                  <SearchModal
                    callBackSearch={callBackSearch}
                    searchresult={searchresult}
                  />
                </div>
              </div> */}
            </form>
          ) : (
            <div className="d-flex justify-content-between">
              <div className="form-inline flex-column flex-md-column flex-lg-row align-items-baseline my-lg-0 ">
                {(count > 0 || countser > 0) &&
                loginstatus &&
                (window.location.href.includes("checkout") ||
                  window.location.href.includes("checkoutservice")) ? (
                  <button
                    className="btn head_menu ml-lg-2"
                    activeClassName="active"
                    // to="#"
                    data-toggle={outSideClick ? "collapse" : ""}
                    data-target={outSideClick ? ".navbar-collapse" : ""}
                    //
                    onClick={() => {
                      if (window.confirm("Do you want to go back to home?")) {
                        window.location.href = "/home";
                      } else {
                      }
                    }}
                  >
                    Home
                  </button>
                ) : (
                  <NavLink
                    className="btn head_menu ml-lg-2"
                    activeClassName="active"
                    to="/home"
                    data-toggle={outSideClick ? "collapse" : ""}
                    data-target={outSideClick ? ".navbar-collapse" : ""}
                    //
                    onClick={() => setServicedrop(!servicedrop)}
                  >
                    Home
                  </NavLink>
                )}
                <div
                  className={`btn ml-lg-2 head_menu _head_trig ${
                    pathName[1] === "services" ||  pathName[1] === "service-details" ? "active" : ""
                  }`}
                  // className={
                  //   servicedrop
                  //     ? "btn ml-lg-2 head_menu  _head_trig"
                  //     : "btn head_menu ml-lg-2 _head_trig"
                  // }
                  // activeClassName="active"
                  // onBlur={() => setServicedrop(!servicedrop)}
                  // onFocus={() => setServicedrop(!servicedrop)}
                  tabIndex="0"
                  // onClick={() => setServicedrop(!servicedrop)}
                  role="button"
                >
                  Services <i className="fas fa-chevron-down mt-1"></i>
                  {/* {servicedrop ? ( */}
                  <div className="dropdown-menu d-block pt-0 _header_Dropmenus">
                    <div className="dropdown-list-items">
                      {service.map((pitem, pindex) => {
                        return (
                          <div key={pindex} className="anm_down">
                            <p className="dropdown-item dropdown_menu_title">
                              {pitem.name}
                            </p>
                            {pitem.service_data.length > 0 ? (
                              <ul
                                className="d-block"
                                // onClick={(e) => e.stopPropagation()}
                              >
                                {pitem.service_data.map((citem, cindex) => {
                                  return (
                                    <li
                                      key={cindex}
                                      className="dropdown-item"
                                      data-toggle={
                                        outSideClick ? "collapse" : ""
                                      }
                                      data-target={
                                        outSideClick ? ".navbar-collapse" : ""
                                      }
                                      onClick={() =>
                                        (window.location.href =
                                          "/services/" +
                                          nameToSlug(citem.name) +
                                          "/" +
                                          citem._id)
                                      }
                                    >
                                      {citem.name}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* ) : (
                    ""
                  )} */}
                </div>
                <NavLink
                  className={`btn head_menu ml-lg-2 ${
                    pathName[1] === "product-details" ? "active" : ""
                  }`}
                  activeClassName="active"
                  to="/astrophy-mall"
                  data-toggle={outSideClick ? "collapse" : ""}
                  data-target={outSideClick ? ".navbar-collapse" : ""}
                >
                  {/* Products */}
                  Astrophy Mall
                </NavLink>
                <NavLink
                  className={`btn head_menu ml-lg-2 ${
                    pathName[1] === "blog-details" ? "active" : ""
                  }`}
                  activeClassName="active"
                  to="/blog"
                  data-toggle={outSideClick ? "collapse" : ""}
                  data-target={outSideClick ? ".navbar-collapse" : ""}
                >
                  Blogs
                </NavLink>
                <NavLink
                  className="btn head_menu ml-lg-2"
                  activeClassName="active"
                  to="/customer-service"
                  data-toggle={outSideClick ? "collapse" : ""}
                  data-target={outSideClick ? ".navbar-collapse" : ""}
                >
                  {/* Feedback */}
                  Support
                </NavLink>
                {userData != null &&
                loginstatus &&
                Object.keys(userData).length > 0 ? (
                  <a
                    className={`btn head_menu ml-lg-2 ${
                      pathName[1] === "seller" ? "active" : ""
                    }`}
                    // activeClassName="active"
                    href="#"
                    data-toggle={outSideClick ? "collapse" : ""}
                    data-target={outSideClick ? ".navbar-collapse" : ""}
                    onClick={() => {
                      if (approvedStatus.seller_approval) {
                        window.location.href = "/seller/subscription";
                      } else if (approvedStatus.seller_request == true) {
                      } else {
                        setTimeout(() => {
                          setServicemodal(true);
                        }, 100);
                      }
                    }}
                  >
                    {approvedStatus.seller_approval
                      ? "Seller"
                      : approvedStatus.seller_request == true
                      ? "Applied for Seller"
                      : "Become a seller"}
                  </a>
                ) : (
                  <a
                    className="btn head_menu ml-lg-2"
                    activeClassName="active"
                    href="#"
                    data-toggle={outSideClick ? "collapse" : ""}
                    data-target={outSideClick ? ".navbar-collapse" : ""}
                    onClick={alertt}
                  >
                    Become a seller
                  </a>
                )}

                {/* <NavLink
                  className="btn head_menu ml-lg-2"
                  activeClassName="active"
                  to="/wishlist"
                  data-toggle={outSideClick ? "collapse" : ""}
                  data-target={outSideClick ? ".navbar-collapse" : ""}
                >
                  <i className="fa fa-heart mr-2" aria-hidden="true"></i>
                </NavLink> */}
                {/* <NavLink
                  className="btn head_menu ml-lg-2"
                  activeClassName="active"
                  to="/service-wishlist"
                  data-toggle={outSideClick ? "collapse" : ""}
                  data-target={outSideClick ? ".navbar-collapse" : ""}
                >
                  Service Wishlist
                </NavLink> */}
                {/* <div
                  className={
                    reading
                      ? "btn ml-lg-2 head_menu active"
                      : "btn head_menu ml-lg-2"
                  }
                  activeClassName="active"
                  to="/..."
                  onBlur={() => setReading(!reading)}
                  onFocus={() => setReading(!reading)}
                  tabIndex="0"
                 
                >
                  Reading
                  {reading ? (
                    <div>
                      <div className="dropdown_arrow reading_arrow">
                        <ul
                          className="dropdown-menu reading_menu d-block"
                          // onClick={(e) => e.stopPropagation()}
                        >
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/services")}
                          >
                            psychic Reading
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Tarot Reading
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Numerology
                          </li>{" "}
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Astrology
                          </li>{" "}
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Mind Reading
                          </li>{" "}
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Palm Reading
                          </li>{" "}
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Picture Reading
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                {/* <div
                  className={
                    spelldrop
                      ? "btn ml-lg-2 head_menu active"
                      : "btn head_menu ml-lg-2"
                  }
                  activeClassName="active"
                  to="/..."
                  onBlur={() => setSpelldrop(!spelldrop)}
                  onFocus={() => setSpelldrop(!spelldrop)}
                  tabIndex="0"
                  // onClick={() => setSpelldrop(!spelldrop)}
                >
                  Spell
                  {spelldrop ? (
                    <div>
                      <div className="dropdown_arrow spell_arrow">
                        <ul
                          className="dropdown-menu spell_menu d-block"
                          // onClick={(e) => e.stopPropagation()}
                        >
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Love Spell
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Protection Spell
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Cleansing Spell
                          </li>{" "}
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Custom Spell
                          </li>{" "}
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Obsession Spell
                          </li>{" "}
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Love Binding Spell
                          </li>{" "}
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Career Spell
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={() => (window.location.href = "/")}
                          >
                            Finance Spell
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                {/* <NavLink
                  className="btn head_menu ml-lg-2"
                  activeClassName="active"
                  to="/..."
                >
                  Support
                </NavLink> */}
                {/* <NavLink
                  className="btn head_menu ml-lg-2"
                  activeClassName="active"
                  to="/service-provider"
                >
                  Service Providers
                </NavLink> */}
              </div>
            </div>
          )}
          <div className=" my-2 my-lg-0 ml-lg-auto d-flex d-md-flex">
            {/* <div
              className={
                search
                  ? "d-none"
                  : "d-block search_icon order-lg-0 ml-lg-auto align-self-center"
              }
            >
              <i
                className="fas fa-search mr-3"
                onClick={() => setSearch(true)}
              ></i>
            </div> */}
            <form autoComplete="off" style={{ width: "75%" }}>
              <div className="serch_bar" style={{ position: "relative" }}>
                <div className="_searchE20_group mx-md-3">
                  <input
                    type="text"
                    className="_searchE20_in"
                    id="searchbar"
                    placeholder="Search"
                    value={searchstring}
                    onChange={(val) => {
                      searchh(val.target.value);
                    }}
                  />
                  <button className="_searchE20_btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
                <div
                  className={SearchShow ? "search_modal show" : "search_modal"}
                  id="SearchShowModal"
                  tabIndex="-1"
                  aria-labelledby="SearchShowLabel"
                  aria-hidden="true"
                  style={{ display: SearchShow ? "block" : "none" }}
                >
                  <div>
                    <SearchModal
                      callBackSearch={callBackSearch}
                      searchresult={searchresult}
                      searchstring={searchstring}
                      isLoading={loader}
                      
                    />
                  </div>
                </div>
              </div>
            </form>
            {loginstatus ? (
              <>
                {/* <div className="header-cart align-self-center">
                  <Link
                    to="wishlist"
                    className="mx-2"
                    data-toggle={outSideClick ? "collapse" : ""}
                    data-target={outSideClick ? ".navbar-collapse" : ""}
                  >
                    <i className="fa fa-heart mr-2" aria-hidden="true"></i>
                  </Link>
                  <p>1</p>
                </div> */}
                <div
                  className="header-cart align-self-center profile_drop wistlistcl"
                  onBlur={() => setwishlistDropdown(true)}
                  onFocus={() => setwishlistDropdown(true)}
                  ref={ref}
                  tabIndex="0"
                  role="button"
                  onMouseOver={(e) => {
                    wishShow(e);
                  }}
                >
                  <a
                    // to="/add-cart"
                    className="mx-2 wishlistHrt"
                    href="#"
                    // data-toggle={outSideClick ? "collapse" : ""}
                    // data-target={outSideClick ? ".navbar-collapse" : ""}
                  >
                    <i className="fa fa-heart mr-2" aria-hidden="true"></i>
                    {/* {count} */}
                  </a>

                  {wishlistDropdown && (
                    <div className="dropdown-menu mt-4 wishlistDrop">
                      <div
                        className="dropdown-item d-flex align-items-center"
                        onClick={() => (window.location.href = "/wishlist")}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-heart mr-2"></i>Your Product
                        Wishlist
                        {/* <span className="badge badge-danger ml-2">
                          {countser}
                        </span> */}
                      </div>

                      <div
                        className="dropdown-item d-flex align-items-center"
                        onClick={() =>
                          (window.location.href = "/service-wishlist")
                        }
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-heart mr-2"></i>
                        Your Service Wishlist
                        {/* <span className="badge badge-danger ml-2">{count}</span> */}
                      </div>
                      <div
                        className="dropdown-item d-flex align-items-center"
                        onClick={() =>
                          (window.location.href = "/wishlist-provider")
                        }
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-heart mr-2"></i>
                        Your Service Provider Wishlist
                        {/* <span className="badge badge-danger ml-2">{count}</span> */}
                      </div>
                    </div>
                  )}
                </div>
                <div className="header-cart align-self-center">
                  <Link
                    to="/my-profile"
                    className="mx-2"
                    data-toggle={outSideClick ? "collapse" : ""}
                    data-target={outSideClick ? ".navbar-collapse" : ""}
                  >
                    <i className="far fa-user mr-2" aria-hidden="false"></i>
                  </Link>
                </div>
                <div
                  className="header-cart align-self-center profile_drop shopcartcl"
                  onBlur={() => setcartDropdown(true)}
                  onFocus={() => setcartDropdown(true)}
                  tabIndex="0"
                  role="button"
                  onMouseOver={(e) => {
                    cartShow(e);
                  }}
                >
                  <a
                    // to="/add-cart"
                    className="mx-2"
                    href="#"
                    // data-toggle={outSideClick ? "collapse" : ""}
                    // data-target={outSideClick ? ".navbar-collapse" : ""}
                  >
                    <i className="fas fa-cart-plus"></i>
                    {/* {count} */}
                  </a>

                  {cartDropdown && (
                    <div className="dropdown-menu ">
                      <div
                        className="dropdown-item d-flex align-items-center position-relative"
                        onClick={() => (window.location.href = "/service-cart")}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-shopping-cart mr-2"></i> Service
                        Cart
                        <span
                          className="badge badge-danger ml-2"
                          style={badgeStyle}
                        >
                          {countser}
                        </span>
                      </div>

                      <div
                        className="dropdown-item d-flex align-items-center position-relative"
                        onClick={() => (window.location.href = "/add-cart")}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-shopping-cart mr-2"></i>
                        Product Cart
                        <span
                          className="badge badge-danger ml-2"
                          style={badgeStyle}
                        >
                          {count}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="header-cart align-self-center" onClick={alertt}>
                  <Link
                    to="/"
                    className="mx-2"
                    data-toggle={outSideClick ? "collapse" : ""}
                    data-target={outSideClick ? ".navbar-collapse" : ""}
                  >
                    <i className="fa fa-heart mr-2" aria-hidden="true"></i>
                  </Link>
                  {/* <p>1</p> */}
                </div>
                <div className="header-cart align-self-center" onClick={alertt}>
                  <Link
                    to="/"
                    className="mx-2"
                    data-toggle={outSideClick ? "collapse" : ""}
                    data-target={outSideClick ? ".navbar-collapse" : ""}
                  >
                    <i className="far fa-user"></i>
                  </Link>
                </div>
                <div
                  className="header-cart align-self-center profile_drop"
                  // onBlur={() => setcartDropdown(!cartDropdown)}
                  // onFocus={() => setcartDropdown(!cartDropdown)}
                  onClick={alertt}
                  tabIndex="0"
                  role="button"
                >
                  <a
                    // to="/add-cart"
                    className="mx-2"
                    href="#"
                    // data-toggle={outSideClick ? "collapse" : ""}
                    // data-target={outSideClick ? ".navbar-collapse" : ""}
                  >
                    <i className="fas fa-cart-plus"></i>
                    {/* {count} */}
                  </a>

                  {cartDropdown && (
                    <div className="dropdown-menu d-block">
                      <div
                        className="dropdown-item d-flex align-items-center position-relative"
                        // onClick={() => (window.location.href = "/service-cart")}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-shopping-cart mr-2"></i> Service
                        Cart
                        <span className="badge badge-danger ml-2">
                          {countser}
                        </span>
                      </div>

                      <div
                        className="dropdown-item d-flex align-items-center position-relative"
                        // onClick={() => (window.location.href = "/add-cart")}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-shopping-cart mr-2"></i>
                        Product Cart
                        <span className="badge badge-danger ml-2">{count}</span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="ml-md-3">
            {login && typeof login == "boolean" ? (
              <li
                className="nav-item relative profile_drop test"
                // onClick={() => setDropdown(!dropdown)}
              >
                <div
                  className="d-flex"
                  onBlur={() => setDropdown(!dropdown)}
                  onFocus={() => setDropdown(!dropdown)}
                  tabIndex="0"
                  role="button"
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="my-auto">
                        <img
                          src={arr ? arr : Profile}
                          alt="img"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <div className="px-2 my-auto">{fname + " " + lname}</div>
                    </>
                  )}

                  {dropdown && (
                    <div
                      className="dropdown-menu d-block"
                      // onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        className="dropdown-item"
                        onClick={() => (window.location.href = "/my-profile")}
                      >
                        <i className="far fa-user-circle mr-2"></i> My Profile
                      </div>
                      {/* <div
                          className="dropdown-item"
                          onClick={() => (window.location.href = "/messages")}
                        >
                          <i className="far fa-comment-dots mr-2"></i> Messages
                        </div> */}

                      <div
                        className="dropdown-item"
                        onClick={() => (window.location.href = "/orders")}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-shopping-cart mr-2"></i> My Orders
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={() =>
                          (window.location.href = "/service-booking")
                        }
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-shopping-cart mr-2"></i>My Service
                        Bookings
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={() => (window.location.href = "/messages")}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i class="far fa-envelope mr-2"></i>Messages
                      </div>

                      {/* <div
                        className="dropdown-item"
                        onClick={() => (window.location.href = "/service-cart")}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-shopping-cart mr-2"></i> Service
                        Cart
                      </div> */}
                      {/* <div
                        className="dropdown-item"
                        onClick={() => (window.location.href = "/add-cart")}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-shopping-cart mr-2"></i> Product
                        Cart
                      </div> */}
                      <div
                        className="dropdown-item"
                        onClick={() => (window.location.href = "/wishlist")}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-heart mr-2" aria-hidden="true"></i>{" "}
                        My Product Wishlist
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={() =>
                          (window.location.href = "/service-wishlist")
                        }
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i className="fa fa-heart mr-2" aria-hidden="true"></i>{" "}
                        My Service Wishlist
                      </div>
                      {/* <div
                          className="dropdown-item"
                          onClick={() => (window.location.href = "/seller")}
                        >
                          <i className="far fa-user-circle mr-2"></i> Seller
                        </div> */}
                      {approvedStatus.seller_approval ? (
                        <div
                          className="dropdown-item"
                          onClick={() =>
                            (window.location.href = "/seller/subscription")
                          }
                          data-toggle={outSideClick ? "collapse" : ""}
                          data-target={outSideClick ? ".navbar-collapse" : ""}
                        >
                          <i className="far fa-user-circle mr-2"></i> Seller
                        </div>
                      ) : approvedStatus.seller_request == true ? (
                        <div className="dropdown-item">
                          <i className="far fa-user-circle mr-2"></i>
                          {}
                          Applied For Seller
                        </div>
                      ) : (
                        <div
                          className="dropdown-item"
                          onClick={() => {
                            setTimeout(() => {
                              setServicemodal(true);
                            }, 100);
                          }}
                          data-toggle={outSideClick ? "collapse" : ""}
                          data-target={outSideClick ? ".navbar-collapse" : ""}
                        >
                          <i className="far fa-user-circle mr-2"></i>
                          {}
                          Apply For Seller
                        </div>
                      )}

                      {/* <div
                          className="dropdown-item"
                          onClick={() => (window.location.href = "/seller")}
                        >
                          <i className="far fa-user-circle mr-2"></i> Seller
                        </div> */}
                      <div
                        className="dropdown-item"
                        onClick={logout}
                        data-toggle={outSideClick ? "collapse" : ""}
                        data-target={outSideClick ? ".navbar-collapse" : ""}
                      >
                        <i class="fas fa-sign-out-alt mr-2"></i> Logout
                      </div>
                    </div>
                  )}
                </div>
              </li>
            ) : (
              <button
                className="btn login_btn"
                type="button"
                data-toggle={outSideClick ? "collapse" : ""}
                data-target={outSideClick ? ".navbar-collapse" : ""}
                onClick={() => {
                  setShowAuthmodal(true);
                  // setLogin(true);
                }}
              >
                Login
              </button>
            )}
          </div>
        </div>
      </nav>
      <div
        className={servicemodal ? "modal fade loginmodal show" : "modal fade"}
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: servicemodal ? "block" : "none" }}
      >
        <SellerModal callBackService={callBackService} success={success} />
        {/* {Object.keys(activemodal).length == 0 ? null : (
          <ServiceModal
            callBackService={callBackService}
            item={activemodal}
            success={success}
          />
        )} */}
      </div>
      <div
        className={showAuthmodal ? "modal fade loginmodal show" : "modal fade"}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showAuthmodal ? "block" : "none" }}
      >
        <AuthModal callBackAuth={callBackAuth} />
      </div>
      {/* <li>
        <div
          style={{ border: "1px solid #CCC" }}
          onBlur={() => setDropdown(!dropdown)}
          onFocus={() => setDropdown(!dropdown)}
          tabIndex="0"
        >
          Dropdown
          {dropdown && (
            <div className="sd">
              <div>Red</div>
              <div>Green</div>
              <div>Blue</div>
            </div>
          )}
        </div>
      </li> */}
    </div>
  );
};

export default Header;
