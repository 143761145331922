import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";

const deleteAccount = async () => {
  console.log("hiii");
  console.log("cc", reactLocalStorage.getObject("userData")._id);
  let result = await HttpClient.requestData("delete-profile/" + reactLocalStorage.getObject("userData")._id, "DELETE");
  console.log("result1", result);
  if (result && result.status) {

    toast.success("Account Deleted Successfully", {
      position: "bottom-right",
      autoClose: 3000,
    });
    reactLocalStorage.clear();
    reactLocalStorage.setObject("loginstatus", false);
    setTimeout(function () {
      window.location.href = "/home";
    }, 1000);

  } else {

    toast.error(result.message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  }
}

const CloseModal = (props) => {
  return (
    <div>
      <div className="modal-dialog  my-3 ">
        <div className="modal-content Close_modal">
          <button
            type="button"
            className="cross close ml-auto px-4 py-2"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.callBack(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="close-body my-5 px-5 ">
            <h4>Are you sure to close/delete your account ?</h4>
            <div className="text-center mt-5">
              <button type="button" className="btn close_btn1"
                onClick={deleteAccount}
              >
                Yes
              </button>
            </div>

            <div className="text-center my-3">
              <button type="button" className="btn close_btn2">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CloseModal;
