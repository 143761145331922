import React, { useState } from "react";
import Chat_sent from "../../../Assets/Images/chat_sent.png";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import moment from "moment";
import db from "../../../Firebase/fbconfig";
import { reactLocalStorage } from "reactjs-localstorage";
import { v4 as uuidv4 } from "uuid";
import { ref, push, set, child, update, get } from "firebase/database";
import HttpClient from "../../../utils/HttpClient";
const WriteChat = (props) => {
  const [showemoji, setshowemoji] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState("");
  const [msg, setmsg] = useState("");
  const [blankMsg, setblankMsg] = useState(false);
  const userData = reactLocalStorage.getObject("userData");
  const sender = props.sender;

  const onEmojiClick = (event, emojiObject) => {
    // console.log("object",emojiObject)
    setChosenEmoji(emojiObject.emoji);
    setmsg(msg !== undefined ? msg + emojiObject.emoji : emojiObject.emoji);
  };

  const uploadFile = async (event) => {
    let media = event.target.files[0];
    let data = new FormData();
    data.append("image", media);
    let result = await HttpClient.fileUplode("image-uploadurl", "POST", data);
    // console.log("result", result);
    if (result && result.status) {
      // console.log("result", result);
      let imageurl = HttpClient.IMG_URL + result.data;
      const postListRef = ref(db, "/Chat/" + props.roomId + "/messages/");
      const newPostRef = push(postListRef);
      set(newPostRef, {
        roomId: props.roomId,
        msgId: uuidv4(),
        sender_id: userData._id,
        message: imageurl,
        send_time: moment().format(),
        name: userData.firstName + " " + userData.lastName,
        image: true,
      });
      let postData = {
        lastMsg: imageurl,
        lastMsgTime: moment().format(),
        name: sender.displayName,
        displayName: sender.displayName,
        userId: sender.userId,
        roomId: sender.roomId,
        lastMsgtype: "image",
      };

      let postDataa = {
        lastMsg: imageurl,
        lastMsgTime: moment().format(),
        name: userData.firstName,
        displayName: userData.firstName + " " + userData.lastName,
        userId: userData._id,
        roomId: props.roomId,
        lastMsgtype: "image",
      };
      const updates = {};
      updates[`/chatList/${userData._id}/${sender.userId}`] = postData;
      updates[`/chatList/${sender.userId}/${userData._id}`] = postDataa;
      update(ref(db), updates);
      setmsg("");
    } else {
    }
  };
  const msgvalid = (txt) => txt && txt.replace(/\s/g, "").length;
  const sendmessage = async () => {
    if (msg == "" || msgvalid(msg) === 0) {
      return false;
    }
    // let roomId = "123456789";
    const postListRef = ref(db, "/Chat/" + props.roomId + "/messages/");
    const newPostRef = push(postListRef);
    set(newPostRef, {
      roomId: props.roomId,
      msgId: uuidv4(),
      sender_id: userData._id,
      message: msg,
      send_time: moment().format(),
      name: userData.firstName + " " + userData.lastName,
      user_type: userData.type,
      nodeId: newPostRef.key
    });

    let postData = {
      lastMsg: msg,
      lastMsgTime: moment().format(),
      name: sender.displayName,
      user_type: userData.type,
      displayName: sender.displayName,
      userId: sender.userId,
      roomId: sender.roomId,
    };

    let postDataa = {
      lastMsg: msg,
      lastMsgTime: moment().format(),
      name: userData.firstName + " " + userData.lastName,
      displayName: userData.firstName + " " + userData.lastName,
      user_type: userData.type,
      userId: userData._id,
      roomId: props.roomId,
      unseen: true,
    };
    const updates = {};
    updates[`/chatList/${userData._id}/${sender.userId}`] = postData;
    updates[`/chatList/${sender.userId}/${userData._id}`] = postDataa;
    update(ref(db), updates);
    sendnotification(msg);
    setmsg("");
    setshowemoji(false);
    // props.refresh(true);
  };

  const messageenter = async (e) => {
    if (e.keyCode === 13) {
      sendmessage();
    }
  };

  const sendnotification = async (msg) => {
    let token = "";
    const dbRef = ref(db);
    // console.log("/Chat/" + props.roomId + "/messages/");
    get(child(dbRef, "/user/" + userData._id))
      .then((snapshot) => {
        if (snapshot.exists()) {
          sendtoken(snapshot.val().fcmToken);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sendtoken = async (token) => {
    console.log("token", token);
    const FIREBASE_API_KEY =
      "AAAAkKuhlBw:APA91bHuUxvnxQR6VxdLpxkBsgyZjapd4ekX-eFV4EhKGjR0lFoALfCbGsg_U1IW1-jOuLd3vjYTWm2EaCUi64pQNrxirTCsV23IzgcXki3a3yROBY8tFPsWJ_w1sc_zmCNc8L8N5nO2";
    // data.notiType = 'Call';
    const message = {
      registration_ids: [token],
      priority: "high",
      // data: { ...data, notiType: 'Call', callDataType: 'start' },
      notification: {
        title: userData.firstName + " " + userData.lastName,
        body: "New message arrived",
        vibrate: 1,
        sound: 1,
        show_in_foreground: true,
        priority: "high",
        content_available: true,
      },
    };

    let headers = new Headers({
      "content-type": "application/json",
      Authorization: "key=" + FIREBASE_API_KEY,
    });
    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers,
      body: JSON.stringify(message),
    });
    response = await response.json();
    console.log("response55555", response, message);
  };

  return (
    <div className="w-100">
      <div className="border-top msg_write_area py-2 px-3">
        <div className="d-flex align-items-center test" style={{height: "52px"}}>
          <div className="d-flex my-auto">
            <button
              type="button"
              className="border-0 bg-transparent my-auto"
              onClick={() => {
                setshowemoji(!showemoji);
              }}
            >
              <i className="far fa-laugh"></i>
            </button>
            {showemoji ? (
              <div>
                <Picker
                  onEmojiClick={onEmojiClick}
                  skinTone={SKIN_TONE_MEDIUM_DARK}
                />
              </div>
            ) : null}
            <div className="border-0 bg-transparent my-auto mx-3 paper-clip">
              <input
                type="file"
                className="input_file"
                onChange={(val) => {
                  uploadFile(val);
                }}
              />
              <i className="fas fa-paperclip"></i>
            </div>
          </div>
          <div className="form-group mx-2 my-auto w-100">
            <textarea
              rows="1"
              className="form-control border-0"
              placeholder="Write Message..."
              onChange={(val) => {
                if (val.target.value.length > 0) {
                  setmsg(val.target.value);
                  setblankMsg(false);
                } else {
                  setmsg(val.target.value);
                  setblankMsg(true);
                }
              }}
              onClick={() => {
                setshowemoji(false);
              }}
              value={msg}
              onKeyUp={(e) => messageenter(e)}
            ></textarea>
          </div>
          <div className="ml-auto my-auto">
            <button
              type="button"
              className="border-0 bg-transparent"
              onClick={sendmessage}
            >
              <img src={Chat_sent} alt="img" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WriteChat;
