import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Insta from "../Assets/Images/insta.png";
import FB from "../Assets/Images/fb.png";
import P_icon from "../Assets/Images/p icon.png";
import Youtube from "../Assets/Images/youtube.png";
import HttpClient from ".././utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import SellerModal from "./sellerModal";
import play_store from "../Assets/Images/play_store.png";
import app_store from "../Assets/Images/app_store.png";
import { reactLocalStorage } from "reactjs-localstorage";
import ReCAPTCHA from "react-google-recaptcha";
import { FaInstagram } from "react-icons/fa"
import { FaFacebookSquare } from "react-icons/fa"
import { FaPinterest } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"

const Footer = () => {
  const [service, setService] = useState([]);
  const [email, setemail] = useState("");
  const loginstatus = reactLocalStorage.getObject("loginstatus");
  // console.log("check----",loginstatus);
  const userdata = reactLocalStorage.getObject("userData");
  const [servicemodal, setServicemodal] = useState(false);
  const [isVerifiedRecaptcha, setisVerifiedRecaptcha] = useState(false);
  const [forRecaptcha, setforRecaptcha] = useState(false);
  const [approvedStatus, setapprovedStatus] = useState([]);

  useEffect(() => {
    fetchService();
    fetchStatus();
  }, []);
  const callBackService = (val) => {
    setServicemodal(val);
    fetchStatus();
  };
  const success = (status) => {
    // console.log("seller status", status);
    // setbuttonStatus(status);
    // if (status) {
    //   setbuttonStatus(status);
    // }
  };
  const fetchStatus = async () => {
    // let sellerApprovalId = reactLocalStorage.getObject("seller_approval_id")
    // console.log("hhh", userData);
    let obj = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "seller-approval-status/" + reactLocalStorage.getObject("userData")._id,
      "GET"
    );
    // console.log("Seller approval", result);
    if (result && result.status) {
      // let data = result.data;
      setapprovedStatus(result.data);
      obj.type = result.data.type;
      reactLocalStorage.setObject("userData", obj);
      // console.log("updated react local", reactLocalStorage.getObject("userData"));
    } else {
    }
  };

  const fetchService = async () => {
    let result = await HttpClient.requestData("service", "GET");
    if (result && result.status) {
      // let data = result.data.filter(
      //   (item) => item._id === "6204f6f56dcb6904acb6a894"
      // );
      let data = result.data.filter((item) => item.status == true);
      // let data = result.data;
      setService(data);
    } else {
    }
  };

  const contactsubmit = async () => {
    if (email == "") {
      toast.warning("Enter Your Email", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!validator.isEmail(email)) {
      toast.warning("Enter Valid Email", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let data = {
      email: email,
    };

    let result = await HttpClient.requestData("contactus", "POST", data);
    console.log("contactus", result);
    if (result && result.status) {
      toast.success("Sent Successfully", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setemail("");
    } else {
    }
  };

  const alertt = () => {
    toast.error("You have to login first", {
      position: "bottom-right",
      autoClose: 3000,
    });
  };
  function onChange(value) {
    console.log("Captcha value:", value);
    setisVerifiedRecaptcha(true);
  }

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }

  return (
    <div className="Footer_clip test">
      {/* <ToastContainer /> */}
      <div className="container-fluid" style={{ maxWidth: "1200px" }}>
        <div className="footer_store d-flex justify-content-center mb-3  pt-3">
          <a href="#">
            <img src={play_store} alt="img" />
          </a>
          <a href="#">
            <img src={app_store} alt="img" />
          </a>
        </div>
        <div className="row foot_head">
          <div className="col-md-12 text-center">
            <h1 className="text-white">Astrophy</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 footer_text mb-2">
            {/* <h5>About Astrophy</h5> */}
            <p className="text-center">
              Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Deleniti
              Consequatur Corrupti Culpa Natus Est Aspernatur Vitae, Odio Quasi.
              Officiis Aperiam Voluptas Repellat Fugit Eligendi Dignissimos,
              Numquam Quisquam Perferendis Quos Culpa Amet Officia Aspernatur
              Cum Tenetur Assumenda Nulla, Eveniet, Quas Dicta.
            </p>
          </div>{" "}
          <div className="col-md-3 footer_text">
            <h5 className="my-2">Quick Links</h5>
            <hr className="footer_hr" />
            <ul>
              <li>
                <Link to="/achievements" className="foot_menu">
                  Achievement
                </Link>
              </li>
              <li>
                <Link to="/about-us" className="foot_menu">
                  About Us
                </Link>
              </li>{" "}
              <li>
                <Link to="/contact-us" className="foot_menu">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/faq" className="foot_menu">
                  FAQs
                </Link>
              </li>{" "}
              <li>
                <Link to="/seller-faq" className="foot_menu">
                  Seller FAQs
                </Link>
              </li>{" "}
              <li>
                <Link to="/site-map" className="foot_menu">
                  SiteMap
                </Link>
              </li>{" "}
              <li>
                <Link to="/blog" className="foot_menu">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="foot_menu">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/cookies-policy" className="foot_menu">
                  Cookies Policy
                </Link>
              </li>
              <li>
                <Link to="/return-policy" className="foot_menu">
                  Return Policy
                </Link>
              </li>
              <li>
                <Link to="/condition-policy" className="foot_menu">
                  Condition of Use & Sell
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 footer_text">
            <h5 className="my-2">My Links</h5>
            <hr className="footer_hr" />
            <ul>
              {/* <li>
                <Link to="/messages" className="foot_menu">
                  Messages
                </Link>
              </li> */}
              {userdata != null &&
              loginstatus &&
              Object.keys(userdata).length > 0 ? (
                <>
                  <li>
                    <Link to="/my-profile" className="foot_menu">
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link to="/orders" className="foot_menu">
                      My Orders
                    </Link>
                  </li>
                  {approvedStatus.seller_approval ? (
                    <li>
                      {/* <div 
                        onClick={() =>
                          (window.location.href = "/seller/subscription")
                        } className="foot_menu">
                         Seller
                        </div> */}
                      <Link to="/seller/subscription" className="foot_menu">
                        Seller
                      </Link>
                    </li>
                  ) : approvedStatus.seller_request == true ? (
                    <li>
                      <div className="foot_menu">Applied For Seller</div>
                    </li>
                  ) : (
                    <li>
                      <div
                        className="foot_menu"
                        onClick={() => {
                          setTimeout(() => {
                            setServicemodal(true);
                          }, 100);
                        }}
                      >
                        Apply For Seller
                      </div>
                    </li>

                    //   <Link to="" className="foot_menu">
                    //   Apply For Seller
                    //  </Link>
                  )}
                </>
              ) : (
                <>
                  <li>
                    <Link onClick={alertt} className="foot_menu">
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="foot_menu" onClick={alertt}>
                      My Orders
                    </Link>
                  </li>
                  <li>
                    <Link className="foot_menu" onClick={alertt}>
                      Apply For Seller
                    </Link>
                  </li>
                </>
              )}

              {/* <li>
                      <Link to="/seller" className="foot_menu">
                        Seller
                      </Link>
                    </li> */}
              <li>
                <Link to="/feedback" className="foot_menu">
                  Feedback
                </Link>
              </li>
              <li>
                <Link to="/customer-service" className="foot_menu">
                  Customer Service
                </Link>
              </li>
              {/* <li>
                <Link to="/career-with-us" className="foot_menu">
                  Career with Us
                </Link>
              </li> */}
              {loginstatus ? (
                <li>
                  <Link to="/report-problem" className="foot_menu">
                    Report a Problem
                  </Link>
                </li>
              ) : null}
              <li>
                <Link to="/legal-notice" className="foot_menu">
                  Summons/ Legal Notice
                </Link>
              </li>

              <li>
                <Link to="/grievance" className="foot_menu">
                  Grievances
                </Link>
              </li>
              <li>
                <Link to="/safety-guide" className="foot_menu">
                  Safety Guide
                </Link>
              </li>
              <li>
                <Link to="/our-associates" className="foot_menu">
                  Our Associates
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 footer_text">
            <h5 className="my-2">Services We Offer</h5>
            <hr className="footer_hr" />
            <div>
              {service.map((pitem, pindex) => {
                return (
                  <ul key={pindex}>
                    {pitem.service_data.slice(0, 4).map((item, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            (window.location.href = "/services/"  + nameToSlug(item.name) + "/" + item._id)
                          }
                          className="foot_menu"
                        >
                          {/* <Link to={"/services/" + item._id} className="foot_menu"> */}
                          {item.name}
                          {/* </Link> */}
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </div>
          </div>
          <div className="col-md-3 footer_text">
            <h5>Subscribe Us</h5>
            <hr className="footer_hr" />
            <div className="d-flex">
              <div className="form-group  mb-2" style={{ width: "100%" }}>
                {/* <label htmlFor="inputPassword2" className="sr-only">
                Password
              </label> */}
                <input
                  type="email"
                  className="form-control"
                  id="inputPassword2"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(val) => {
                    setemail(val.target.value);
                    setforRecaptcha(true);
                  }}
                />
                <button
                  type="button"
                  className="btn send_btn"
                  onClick={contactsubmit}
                  disabled={!isVerifiedRecaptcha}
                >
                  send
                </button>
              </div>
            </div>
            {forRecaptcha ? (
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                size="normal"
                onChange={onChange}
              />
            ) : null}
            <div className="row my-md-5 my-3 align-items-center no-gutters">
              <div className="col-5 col-md-12 col-lg-5">
                <h5 className=" my-2 mr-0 text-capitalize">Follow Us:-</h5>
              </div>
              <div className="col-7 col-md-12 col-lg-7 text-lg-right">
                <a
                  className="btn p-0 mr-2"
                  href="https://www.instagram.com/"
                  target="_blank"
                >
                  {" "}
                  {/* <img
                    src={Insta}
                    alt="img"
                    className="app_img"
                  /> */}
                  <FaInstagram className="icons_white"/>
                </a>
                <a
                  className="btn p-0 mr-2"
                  href="https://www.facebook.com/"
                  target="_blank"
                >
                  {/* <img
                    src={FB}
                    alt="img"
                    className="app_img"
                  /> */}
                  <FaFacebookSquare className="icons_white"/>
                </a>
                <a
                  className="btn p-0 mr-2"
                  href="https://pinterest.com/"
                  target="_blank"
                >
                  {" "}
                  {/* <img
                    src={P_icon}
                    alt="img"
                    className="app_img"
                  /> */}
                  <FaPinterest className="icons_white"/>
                </a>
                <a
                  className="btn p-0 mr-0"
                  href="https://www.youtube.com/"
                  target="_blank"
                >
                  {" "}
                  {/* <img
                    src={Youtube}
                    alt="img"
                    className="app_img"
                  /> */}
                  <FaYoutube className="icons_white"/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row border-top pt-3 mt-3">
          <div className="col-md-12 text-center">
            <h6 className="text-white mb-0">
              © {new Date().getFullYear()}{" "}
              <Link to="/" className="text-light">
                Astrophy
              </Link>
            </h6>
          </div>
        </div>
        <div
          className={servicemodal ? "modal fade loginmodal show" : "modal fade"}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: servicemodal ? "block" : "none" }}
        >
          <SellerModal callBackService={callBackService} success={success} />
          {/* {Object.keys(activemodal).length == 0 ? null : (
          <ServiceModal
            callBackService={callBackService}
            item={activemodal}
            success={success}
          />
        )} */}
        </div>
      </div>
    </div>
  );
};
export default Footer;
