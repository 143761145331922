import React from "react";

const VerifiedModal = (props) => {
  return (
    <div>
      <div className="modal-dialog  my-3 ">
        <div className="modal-content LogIn_modal">
          <div className="login-body my-3 px-5 text-center">
            <h5>Confirm Your E-mail</h5>
            <p className="text-dark">
              A confirmation link was sent to your email.
            </p>
            <button
              type="submit"
              className="btn mt-3 ok_btn"
              onClick={() => props.closeverify(false)}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VerifiedModal;
