import React, { useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

const AfterVerify = (props) => {
  const email = props.match.params.email;
  console.log("email", email);
  useEffect(() => {
    verifyemail();
  }, []);

  const verifyemail = async () => {
    let data = {
      email: email,
    };
    let result = await HttpClient.requestData("verify-email", "POST", data);
    console.log("result", result);
    if (result && result.status) {
      reactLocalStorage.setObject("userData", result.data);
      reactLocalStorage.setObject("loginstatus", true);
      reactLocalStorage.setObject("loginToken", result.data.token);

      setTimeout(function () {
        window.location.href = "/dashboard";
      }, 1);
    } else {
    }
  };
  return (
    <div>
      <section className="container after-verify my-4">
        <div className="row">
          <div className="col-md-12 text-center ">
            <h3>Thank You !!</h3>
            <p className="">You have successfully done your registration</p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default AfterVerify;
