import React from "react";
import { Link } from "react-router-dom";
import RatingChanged from "../../Component/RatingChanged";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../utils/HttpClient";
import blankImg from "../../Assets/Images/blank.jpg";
const ListProvider = (props) => {
  console.log("providerDetails",props.data);
  let providerDetails = props.data;
  const userData = reactLocalStorage.getObject("userData");
  const addcart = async (val) => {
    let data = {
      prod_id: val.prod_id,
      user_id: userData._id,
      currency: userData.currency,
      productname: val.productname,
      qty: 1,
      price: val.price,
      image: val.image,
    };
    console.log("data", data);

    let result = await HttpClient.requestData("add-to-cart", "POST", data);
    console.log("result", result);
    if (result && result.status) {
      toast.success(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
      props.success(result.status);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const deletee = async (val) => {
    console.log("del", val._id);
    let data = {};
    let result = await HttpClient.requestData(
      "product-wishlist/" + val._id,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      props.success(result.status);
    } else {
    }
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }
  return (
    <div className="bottomSection mt-4">
      <ToastContainer />
      <div className="card wish_card">
        <div className="card-body" style={{padding: 0}}>
          <div
            className="web_img"
            onClick={() => {
              window.location.href =
                "/service-provider/" + nameToSlug(providerDetails.providername) + "/" + providerDetails.seller_id;
            }}
          >
            <img
              src={
                providerDetails.provider_data[0].image
                  ? HttpClient.IMG_URL + providerDetails.provider_data[0].image
                  : blankImg
              }
              alt="img"
              className="img-fluid"
            />
          </div>

          <div className="web_box my-lg-0 my-3 w-100" style={{padding: "1.25rem"}}>
            <div className="row  ">
              <div className="col-md-12">
                <h4>{providerDetails.providername}</h4>
                <p>
                {providerDetails.provider_data.length
                      ? providerDetails.provider_data[0].about ? providerDetails.provider_data[0].about : ""
                      : ""}
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  elementum ac diam at convallis. Nulla diam libero, maximus sed
                  justo et, convallis tempus dolor. Etiam non magna vel felis. */}
                </p>
              </div>
              <div className="col-md-12">
                {/* <p className=" text-right">Item Added 28 Oct </p> */}
                {/* <div className="d-flex justify-content-end ">
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => addcart(productDetails)}
                  >
                    Add to Cart
                  </button>

                  <button
                    className="btn btn-secondary register_btn ml-3"
                    type="button"
                    onClick={() => deletee(productDetails)}
                  >
                    Delete
                  </button>
                </div> */}
              </div>
            </div>
            <div>{/* <RatingChanged /> */}</div>
            <div className="d-flex">
              <h5>
                {/* ₹ */}
                {/* {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "	GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"} */}
                {/* {productDetails.length ? productDetails[0].selling_price : ""} */}
              </h5>
              {/* <p className="mx-md-3 mt-1 text-success">
                <i className="fas fa-check-circle mr-2" />
                In Stock
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListProvider;
