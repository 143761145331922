import React, { useState, useEffect } from "react";
import Tarot from "../../../Assets/Images/tarot.png";
import Numeric from "../../../Assets/Images/Numerology.png";
import Astrophy from "../../../Assets/Images/Astrology.png";
import Astro from "../../../Assets/Images/Astro.png";
import Mind from "../../../Assets/Images/Mind Reading.png";
import Psychic from "../../../Assets/Images/Psychic Reading.png";
import Palm from "../../../Assets/Images/Palm Reading.png";
import Picture from "../../../Assets/Images/Picture Reading.png";
import HttpClient from "../../../utils/HttpClient";
import { Link } from "react-router-dom";
const Service = () => {
  const [service, setService] = useState([]);
  useEffect(() => {
    fetchService();
  }, []);

  const fetchService = async () => {
    let result = await HttpClient.requestData("service", "GET");
    console.log("Service", result);
    if (result && result.status) {
      // let data = result.data;
      let data = result.data.filter(
        (item) => item.name === "Astrology & Reading"
      );
      // let data = result.data.filter((item) => item.status == true);
      console.log("data service_data", data);
      setService(data);
      console.log("Servicedata", service);
    } else {
    }
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }
  return (
    <div className="row px-2 px-md-3">
      {service.length > 0
        ? service[0].service_data.map((item, index) => {
            return index < 8 ? (
              <div
                className="col-6 col-lg-3 col-md-4 mb-md-3 p-2 px-md-4"
                key={index}
              >
                <div className="card text-center service p-md-2 my-2 my-md-3">
                  <div className="card-body service_part">
                    <img src={HttpClient.IMG_URL + item.image} alt="img" />
                    <h5 className="card-title my-3">{item.name}</h5>
                    <p className="card-text">
                      {item.description.substring(0, 85)}
                    </p>
                    {/* <Link to={"/services/" + item.id}> */}
                    <button
                      className="btn my-3 "
                      onClick={() =>
                        (window.location.href = "/services/" + nameToSlug(item.name) + "/" + item._id)
                      }
                    >
                      View More
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            ) : null;
          })
        : null}
    </div>
  );
};
export default Service;
