import React, { useEffect } from "react";

const CopyRight = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section className="container-fluid condition my-4">
        <div className="row px-5">
          <div className="col-md-12 text-center mb-4">
            <h3>@Copy-Right</h3>
          </div>
          <div className="col-md-12">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to make a type specimen book.{" "}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CopyRight;
