import React from "react";

const EmptyCart = () => {
  return (
    <div className="col-md-12 text-center blank_product">
      <h4>
        Your Cart is Empty <br></br>
        {/* <span>Choose your product first</span>{" "} */}
      </h4>
    </div>
  );
};
export default EmptyCart;
