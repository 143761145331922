import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Select from "react-select";
import HttpClient from "../../utils/HttpClient";
import { Editor } from "@tinymce/tinymce-react";
import Loader from "../../Component/loader";
import { reactLocalStorage } from "reactjs-localstorage";

const optionss = [
  { value: "todaysTotal", label: "Today" },
  { value: "yestardayTotal", label: "Yesterday" },
  { value: "sevendaysTotal", label: "Last 7 Days" },
  { value: "monthTotal", label: "Last 30 Days" },
  { value: "yearTotal", label: "Last Year" },
];

export default function Graph() {
  const [graphData, setgraphData] = useState();

  const [graphValue, setgraphValue] = useState("");
  const [shopData, setshopData] = useState([])
  const [name, setname] = useState("");
  const [loading, setLoading] = useState(false);

  const userdata = reactLocalStorage.getObject("userData");

  const [title, settitle] = useState("");

  const [tags, settags] = useState("");
  const [desc, setdesc] = useState("");
  const [personalization, setpersonalization] = useState("");
  const [suspendstatus, setsuspendstatus] = useState("");

  const [banner, setbanner] = useState("");

  const [arr, setarr] = useState([]);

  const [shop, setshop] = useState("");

  const [shoparr, setshoparr] = useState([]);

  const handleChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    setgraphValue(selectedOption);
  };

  useEffect(() => {
    fetchGraph();
    getshop();
  }, []);

  const getshop = async () => {
    let data = {};
    console.log("object", data);
    // setLoading(true);
    let result = await HttpClient.requestData(
      "shop/" + userdata._id,
      "GET",
      data
    );
    console.log("shop result===", result);
    if (result && result.status) {
      setshopData(result.data);
      if (result.data != null) {
        if (result.data.status == false) {
          setsuspendstatus("suspend");
        } else {
          var dom = new DOMParser().parseFromString(result.data.description, 'text/html');
          var txtDesc = dom.body.textContent;
          var dom1 = new DOMParser().parseFromString(result.data.personalization, 'text/html');
          var txtPer = dom1.body.textContent;
          setsuspendstatus("");
          setname(result.data ? result.data.name : "");
          settitle(result.data ? result.data.title : "");
          settags(result.data ? result.data.tags : "");
          setdesc(txtDesc ? txtDesc : "");
          setpersonalization(txtPer ? txtPer : "");
          result.data
            ? setarr([
                {
                  preview:
                    HttpClient.IMG_URL + result.data.banner_img,
                },
              ])
            : setarr([]);
          result.data
            ? setshoparr([
                {
                  preview:
                    HttpClient.IMG_URL + result.data.shop_img,
                },
              ])
            : setshoparr([]);
        }
      } else {
        setsuspendstatus("deleted");
      }
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1);
    } else {
      setshopData([]);
    }
  };

  const fetchGraph = async () => {
    let userData = reactLocalStorage.getObject("userData");
    let sendData = {
      id: userData._id,
    };
    let result = await HttpClient.requestData(
      "getGraphcomission",
      "POST",
      sendData
    );
    // console.log("graph data", result);
    if (result && result.status) {
      setgraphData(result);
    } else {
    }
  };

  const values = graphData
    ? [
        graphData.todaysTotal,
        graphData.yestardayTotal,
        graphData.sevendaysTotal,
        graphData.monthTotal,
        graphData.yearTotal,
      ]
    : "";
  console.log("values", values);

  const options = {
    xaxis: {
      categories: [
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "Last Year",
      ],
    },
  };

  const series = [
    {
      name: "series-1",
      // data: [30, 40, 45, 50, 49, 60, 70, 91],
      data: [...values],
    },
  ];
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="row">
        {suspendstatus == "suspend" ? (
          <span className="required" style={{ color: "red" }}>
            ***
            <h3>
              Your Shop is temporarily Suspended please contact with Astrophy
            </h3>
          </span>
        ) : suspendstatus == "deleted" ? (
          <span className="required" style={{ color: "red" }}>
            ***
            <h3>
              Your shop is deleted permanantly,please contact with Astrophy
            </h3>
          </span>
        ) : (
          Object.keys(shopData).length ? (
            <>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="shop_name">Banner Image</label>
                {arr.map((item, index) => {
                  console.log("object", item);
                  return (
                    <>
                      <div className="img-wrap">
                        {/* <span ></span> */}
                        {/* <button className="close">&times;</button> */}
                        <img
                          className="avatar ml-3 img-fluid img-thumbnail"
                          key={index}
                          alt="img"
                          style={{ width: "54px", height: "54px" }}
                          src={item.preview}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="shop_name">Shop Image</label>
                {shoparr.map((item, index) => {
                  console.log("object", item);
                  return (
                    <>
                      <div className="img-wrap">
                        {/* <span ></span> */}
                        {/* <button className="close">&times;</button> */}
                        <img
                          className="avatar ml-3 img-fluid img-thumbnail"
                          key={index}
                          alt="img"
                          style={{ width: "54px", height: "54px" }}
                          src={item.preview}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="shop_name">Shop Name </label>
                <input
                  type="text"
                  className="form-control"
                  id="shop_name"
                  placeholder="Please Enter Shop Name"
                  readOnly
                  value={name}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="shop_name">Shop Title </label>
                <input
                  type="text"
                  className="form-control"
                  id="shop_title"
                  placeholder="Please Enter Shop Title"
                  readOnly
                  value={title}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="shop_name">Tags </label>
                <input
                  type="text"
                  className="form-control"
                  id="tags"
                  placeholder="Please Enter Tags"
                  readOnly
                  value={tags}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="shop_name">Description </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Please Enter Description"
                  readOnly
                  value={desc}
                ></textarea>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="shop_name">About Shop </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Please Enter About Shop"
                  readOnly
                  value={personalization}
                ></textarea>
              </div>
            </div>
          </>
          ) : 
          <span className="required" style={{ color: "red" }}>
          ***
          <h3>
           Create Shop
          </h3>
        </span>
         
        )}

        {/* <div className="mixed-chart">
        <Chart options={options} series={series} type="bar" width="100%" />
      </div> */}
      </div>
      )}
      
    </div>
  );
}
