import React from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { reactLocalStorage } from "reactjs-localstorage";

const Pagination = (props) => {
  const paginator = props.productpaginator;
  console.log("paginator", props);

  const array = Array.from(Array(props.productpaginator.pageCount).keys());

  console.log(array,'arrr');

  const pages = (item) => {
    // console.log("i", item);

    // props.nextpage(item,props.min,props.max,props.sortBy,props.ratee,props.del);
    props.nextpage(
      item,
      props.del,
      props.min,
      props.max,
      props.sortBy,
      props.ratee
    );
  };

  const previous = () => {
    const prev = paginator.prev;
    props.nextpage(prev);
  };

  const next = () => {
    const next = paginator.next;
    props.nextpage(next);
  };
  return (
    <div className="Pagination_sec">
      <nav aria-label="Page navigation example  ">
        <ul className="pagination flex-wrap mb-4">
          {paginator.hasPrev ? (
            <li className="page-item mx-3 my-md-0 my-3 ">
              <Link
                className="page-link"
                to="#"
                aria-label="Previous"
                onClick={previous}
              >
                <span aria-hidden="true">
                  <i className="fas fa-chevron-left"></i>
                </span>
              </Link>
            </li>
          ) : null}

          {array.map((item, index) => {
         let   currentPag = reactLocalStorage.getObject("pagiNation").currentPage
            return (
              <li
             
                className={`page-item mx-3  my-md-0 my-3 `}
                key={index}
                onClick={() => pages(item + 1)}

              >
                <Link className={`page-link ${currentPag==item+1 ? "current-page-bg" : ""}`} to="#">
                  {item + 1}
                </Link>
              </li>
            );
          })}

          {paginator.hasNext ? (
            <li className="page-item mx-3  my-md-0 my-3">
              <Link
                className="page-link "
                to="#"
                aria-label="Next"
                onClick={next}
              >
                <span aria-hidden="true">
                  <i className="fas fa-chevron-right"></i>
                </span>
              </Link>
            </li>
          ) : null}
        </ul>
      </nav>
    </div>
  );
};
export default Pagination;
