import React, { useState, useEffect } from "react";
import Footer from "../../Defaults/Footer";
import ServiceList from "../../Views/Services/ServiceList";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
import { Link } from "react-router-dom";
const Index = () => {
  const [service, setService] = useState([]);
  const [shopid, setshopid] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchShopId();
    fetchService();
  }, []);

  const refresh = (val) => {
    if (val) {
      fetchShopId();
    }
  };
  const userdata = reactLocalStorage.getObject("userData");
  const fetchService = async (shopid) => {
    // console.log("idd", id);
    setLoading(true);
    let result = await HttpClient.requestData(
      "shop/all-services/" + shopid,
      "GET"
    );
    console.log("ownservicedataforseller123", result);
    if (result && result.status) {

      setService(result.data);
      console.log("service", service);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } else {
    }
  };

  const fetchShopId = async () => {
    let result = await HttpClient.requestData("seller/" + userdata._id, "GET");
    console.log("seller", result.data);
    if (result && result.status) {
      // let data = result.shop_id;
      // console.log("shopId",data);
      fetchService(result.shop_id);
    } else {
    }
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid my-4" style={{ maxWidth: "1200px" }}>
          <div className="row py-4 px-md-5">
            <div className="col-md-12 back_to">
              {/* <Link to="/seller/services">
                <p>
                  {" "}
                  <i className="fas fa-chevron-left mr-2"></i>Back to Services
                </p>
              </Link> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center mb-4">
              <h1>My Services</h1>
              <Link to="/seller/services" className="add_new_serv">
                Add new service
                <i className="fas fa-chevron-right ml-2"></i>
              </Link>
            </div>
          </div>
          {service.length ? (
            <ServiceList
            data={service}
            type="edit"
            // key={service.toString()}
            refresh={refresh}
          />
          ) : (
            <p>No Service Added</p>
          )}
          
        </div>
      )}
      <section>
        {/* <Footer /> */}
      </section>
    </div>
  );
};
export default Index;
