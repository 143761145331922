import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import Profile from "../../Assets/Images/user.jpg";
import HttpClient from "../../utils/HttpClient";

const ReviewSec = (props) => {
  const [tab, setTab] = useState("review");
  const [sorted, setSorted] = useState();
  const [sortedData, setSortedData] = useState(props.data);

  useEffect(() => {
    setSortedData(props.data);
  }, [props]);

  const handleSortBy = async (e) => {
    let val = e.target.value;
    setSorted(val);
    let data = {
      serv_id: props.serv_id,
      sortby: val,
    };
    let result = await HttpClient.requestData("filter-reviews", "POST", data);
    if (result && result.status) {
      // console.log("sort result", result.data);
      setSortedData(result.data);
    } else {
      // console.log("erroe result", result);
    }
  };

  return (
    <div>
      <div className="d-md-flex border_bottom ">
        <div
          className={
            tab === "review"
              ? "select active mr-5 my-md-0 my-3"
              : "select mr-5 my-md-0 my-3"
          }
          onClick={() => setTab("review")}
        >
          Reviews for this Service ({props.reviewcount})
        </div>
        {/* <div
          className={
            tab === "review1"
              ? "select active my-md-0 my-3"
              : "select my-md-0 my-3"
          }
          onClick={() => setTab("review1")}
        >
          Reviews for this Shop (200)
        </div> */}
        <div className="form-group d-flex ml-auto review_sort my-md-0 my-3">
          <h6 className="mr-3 my-2">SortBy:</h6>
          <select
            className="form-control"
            id="sortBy"
            value={sorted}
            onChange={handleSortBy}
          >
            <option selected>Sort by</option>
            <option value="Newly added">Newly Added</option>
            <option value="1 star">1 Star</option>
            <option value="2 star">2 Star</option>
            <option value="3 star">3 Star</option>
            <option value="4 star">4 Star</option>
            <option value="5 star">5 Star</option>
          </select>
        </div>
      </div>
      {console.log("sortedData", sortedData)}
      <div className="my-4">
        {tab === "review" ? (
          <div>
            {sortedData.length > 0 ? (
              <>
                {sortedData.map((item, index) => {
                  return (
                    <div className="review_list my-3" key={index}>
                      <div className="d-flex">
                        <img
                          src={
                            item.user_data.image
                              ? `https://admin.astrophy.com/${item.user_data.image}`
                              : Profile
                          }
                          alt="img"
                          style={{
                            width: "100%",
                            height: "30px",
                            maxWidth: "30px",
                          }}
                        />
                        <div className="my-lg-0 my-2 mx-4">
                          <h5>
                            {item.user_data
                              ? item.user_data.firstName +
                                " " +
                                item.user_data.lastName
                              : null}
                          </h5>
                        </div>
                        <div className="ml-auto">
                          <span>
                            {" "}
                            {moment(item.rev_date).format("D-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                      <div className="mx-5">
                        <ReactStars
                          count={5}
                          value={item.rating}
                          size={24}
                          isHalf={true}
                          emptyIcon={<i className="far fa-star"></i>}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          activeColor="#ffd700"
                          edit={false}
                        />
                      </div>
                      <div className="mx-5 my-3">
                        <p>{item.comment}</p>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <h5>Oops! No data found.</h5>
              </>
            )}
          </div>
        ) : tab === "review1" ? (
          "abc"
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default ReviewSec;
