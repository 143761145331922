import React, { useEffect, useState } from "react";
import Footer from "../../Defaults/Footer";
import Faq from "../Home/Faq/Faq";
import HttpClient from "../../utils/HttpClient";
import { ReactTitle } from "react-meta-tags";
import Category from "./category";
const Index = () => {
  const [faqData, setfaqData] = useState([]);
  const [allCategory, setallCategory] = useState([]);

  const [category_id, setcategory_id] = useState("");
  const [subCatgory_id, setsubCatgory_id] = useState("");
  const [data, setdata] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllCategory();
  }, []);
 
  const fetchAllCategory = async () => {
    let result = await HttpClient.requestData("viewallcat", "GET");
    // console.log("All Category Data --", result);
    if (result && result.status) {
      let data = result.data[0]._id
      fetchSubCategory(result.data[0]._id)
      setcategory_id(data);
      // console.log("category_id",data);
    } else {
    }
  };
  const fetchSubCategory = async (id) => {
    let result = await HttpClient.requestData("viewallsubcat/" + id, "GET");
    // console.log("All Sub Category Data --", result);
    if (result && result.status) {
      let data = result.data[0]._id
      setsubCatgory_id(data);
      // console.log("subCatgory_id",subCatgory_id);
      fetchFaqFirst(id,data)
    } else {
    }
  };
  const fetchFaqFirst = async (catId,subCatId) => {
    let data = {
      category_id: catId,
      subcategory_id: subCatId,
    };
    // console.log("ddddd-----",data);
    // let data = {data}
    let result = await HttpClient.requestData("allFaq", "POST", data);
    // console.log("faq Data --", result);
    if (result && result.status) {
      setfaqData(result.data);
    } else {
      setfaqData([]);
    }
  };

  const recievedata = (data) => {
    // console.log("recieve data", data);
    // setdata(data)
    // setcategory_id(data.cat_id);
    // setsubCatgory_id(data.subCat_id);
    fetchFaq(data);
  };

  const fetchFaq = async (val) => {
    let data = {
      category_id: val.cat_id,
      subcategory_id: val.subCat_id,
    };
    // console.log(data);
    // let data = {data}
    let result = await HttpClient.requestData("allFaq", "POST", data);
    // console.log("faq Data --", result);
    if (result && result.status) {
      setfaqData(result.data);
    } else {
      setfaqData([]);
    }
  };
  const [cat1, setCat1] = useState(false);
  const [cat2, setCat2] = useState(false);
  const [cat3, setCat3] = useState(false);
  const [cat4, setCat4] = useState(false);
  const [cat5, setCat5] = useState(false);
  return (
    <div>
      <div className="wrapper">
        <ReactTitle title="Astrophy | Faq" />
      </div>
      <section className="container">
        <div className="row">
          <div className="col-md-12 text-center faq_head mt-5">
            <h3>FAQ</h3>
          </div>
        </div>
        <div className="row ">
          {/* {allCategory.length
            ? allCategory.map((item, index) => {
                return (
                 <Category data={item} recieveData={recievedata}/>
                );
              })
            : null} */}
          <div className="col-md-3 faq_catagory mt-3">
            <Category recieveData={recievedata} catId={category_id} subCatId={subCatgory_id}/>
            {/* <div>
              <div>
                <div
                  onClick={() => {
                    setCat1(!cat1);
                    setCat2(false);
                  }}
                  className="catagory_list my-4"
                >
                  Category 1{" "}
                  {cat1 ? (
                    <i className="fas fa-chevron-right"></i>
                  ) : (
                    <i className="fas fa-chevron-down"></i>
                  )}
                </div>

                <div
                  className={cat1 ? "d-block ml-4  faq_sub_catagory" : "d-none"}
                >
                  <li>1</li>
                  <li>2</li>
                  <li>3</li>
                  <li>4</li>
                </div>
              </div>
              <div>
                <div
                  onClick={() => {
                    setCat2(!cat2);
                    setCat1(false);
                  }}
                  className="catagory_list my-4"
                >
                  Category 2{" "}
                  {cat2 ? (
                    <i className="fas fa-chevron-right"></i>
                  ) : (
                    <i className="fas fa-chevron-down"></i>
                  )}
                </div>

                <div
                  className={
                    cat2 ? "d-block ml-4   faq_sub_catagory" : "d-none"
                  }
                >
                  <li>Sub Category 1</li>
                  <li>Sub Category 2</li>
                  <li>Sub Category 3</li>
                  <li>Sub Category 4</li>
                  <li>Sub Category 5</li>
                  <li>Sub Category 6</li>
                </div>
              </div>
              <div className="catagory_list my-4">
                Category 3 <i className="fas fa-chevron-down"></i>
              </div>
              <div className="catagory_list my-4">
                Category 4 <i className="fas fa-chevron-down"></i>
              </div>
              <div className="catagory_list my-4">
                Category 5 <i className="fas fa-chevron-down"></i>
              </div>
            </div> */}
          </div>
          <div className="col-md-9 answer my-3">
            {faqData.length ? (
              faqData.map((item, index) => {
                return (
                  <div className="pl-lg-3">
                    <Faq data={item} />
                  </div>
                );
              })
            ) : (
              <div className="pl-lg-3">No FAQ Found</div>
            )}

            {/* <div className="text-center my-5">
              <button href="#" className="btn mb-4  ">
                Load More
              </button>
            </div> */}
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Index;
