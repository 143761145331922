import React, { useEffect, useState } from "react";
import Peer from "peerjs";
import * as uuid from "uuid";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  ref,
  get,
  set,
  child,
  limitToLast,
  onChildAdded,
  query,
  orderByKey,
  endAt,
  onValue,
  update,
  push,
} from "firebase/database";
// import myMusic from '../../../assets/classical-demo.mp3'
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import db from "../../../Firebase/fbconfig";

const configuration = {
  iceServers: [
    { url: "stun:stun.l.google.com:19302" },
    { url: "stun:stun1.l.google.com:19302" },
    { url: "stun:stun2.l.google.com:19302" },
    { url: "stun:stun3.l.google.com:19302" },
    { url: "stun:stun4.l.google.com:19302" },
    {
      url: "turn:numb.viagenie.ca",
      credential: "Bokachoda@2020",
      username: "soumya.webhibe@gmail.com",
    },
  ],
};

var peerServer = null;
var myStream = null;
var remoteUserId = "";
var remoteUserStream = null;
var peerCall = null;
var callNodeId = null;
var callIsRecieved = false;
var hour = 0;
var min = 0;
var sec = 0;
var timerRef = null;

const VideoModal = (props) => {
  // console.log("remote", props.remoteData);
  const { remoteData, roomId, type, chatRoomId } = props;

  const [iconoff, setIconoff] = useState("");
  const [recieved, setRecieved] = useState(false);
  const [remoteUserToken, setRemoteUserToken] = useState("");
  const [callState, setCallState] = useState("Connecting");
  const [audioMute, setAudioMute] = useState(false);
  const [remoteUserMute, setRemoteUserMute] = useState(false);
  const [refress, setRefress] = useState(false);

  // const [audio] = useState(new Audio(myMusic))
  const myUserData = reactLocalStorage.getObject("userData");

  useEffect(() => {
    console.log(type,"video");
    if (type == "Incomming") {
      callNodeId = remoteData.nodeId;
      FBGetValue(remoteData.nodeId);
    }

    getUserMedia();
    return () => {
      // stopvideo();
    };
  }, []);

  useEffect(() => {
    if (callState == "Recieved") {
      // console.log("call start")

      startTimer();
    }
  }, [callState]);

  const startTimer = () => {
    let re = false;
    timerRef = setInterval(() => {
      if (sec < 59) {
        sec = sec + 1;
      } else {
        min = min + 1;
        sec = 0;
      }

      if (min == 60) {
        min = 0;
        hour = hour + 1;
      }
      // console.log("balance", balance)
      setRefress(!re);
      re = !re;
    }, 1000);
  };

  const sendNotificationEnd = async (token) => {
    const FIREBASE_API_KEY =
      "AAAAkKuhlBw:APA91bHuUxvnxQR6VxdLpxkBsgyZjapd4ekX-eFV4EhKGjR0lFoALfCbGsg_U1IW1-jOuLd3vjYTWm2EaCUi64pQNrxirTCsV23IzgcXki3a3yROBY8tFPsWJ_w1sc_zmCNc8L8N5nO2";
    // data.notiType = 'Call';
    const message = {
      registration_ids: [token],
      priority: "high",
      data: { notiType: "Call", callDataType: "end" },
    };

    let headers = new Headers({
      "content-type": "application/json",
      Authorization: "key=" + FIREBASE_API_KEY,
    });
    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers,
      body: JSON.stringify(message),
    });
    response = await response.json();
    console.log("response55555", response, message);
  };

  const sendNotification = async (data, token) => {
    const FIREBASE_API_KEY =
      "AAAAkKuhlBw:APA91bHuUxvnxQR6VxdLpxkBsgyZjapd4ekX-eFV4EhKGjR0lFoALfCbGsg_U1IW1-jOuLd3vjYTWm2EaCUi64pQNrxirTCsV23IzgcXki3a3yROBY8tFPsWJ_w1sc_zmCNc8L8N5nO2";
    // data.notiType = 'Call';
    const message = {
      registration_ids: [token],
      priority: "high",
      data: { ...data, notiType: "Call", callDataType: "start" },
      notification: {
        title: myUserData.firstName + " " + myUserData.lastName,
        body: "Incoming Call",
        vibrate: 1,
        sound: 1,
        show_in_foreground: true,
        priority: "high",
        content_available: true,
      },
    };

    let headers = new Headers({
      "content-type": "application/json",
      Authorization: "key=" + FIREBASE_API_KEY,
    });
    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers,
      body: JSON.stringify(message),
    });
    response = await response.json();
    console.log("response55555", response, message);
  };

  const FBGetValue = (nodeId) => {
    onValue(ref(db, `/call/${myUserData._id}/${nodeId}`), (snapshot) => {
      let data = snapshot.val();
      setCallState(data.status);

      if (data.status == "Recieved") {
        // InCallManager.stopRingtone();
        // InCallManager.stopRingback();
      }

      if (data.muted) {
        setRemoteUserMute(true);
      } else {
        setRemoteUserMute(false);
      }

      if (data.endStatus) {
        get(ref(db, `/user/${remoteData.userId}`)).then((snap) => {
          let remoteToken = snapshot.val().fcmToken;

          sendNotificationEnd(remoteToken);
          endCall(data.status);
        });
      }
    });
  };

  const getUserMedia = () => {
    navigator.getUserMedia(
      {
        audio: true,
        video: {
          height: 300,
          width: 300,
          frameRate: 30,
        },
      },
      (stream) => {
        console.log("stream", stream)
        const ownVideoGreed = document.getElementById("ownVideo");
        // console.log("videoGreed", videoGreed)
        ownVideoGreed.srcObject = stream;
        // ownVideoGreed.muted = true
        ownVideoGreed.addEventListener("loadedmetadata", () => {
          ownVideoGreed.play();
        });
        initCall(stream);
      },
      (err) => {
        // console.log("err", err)

        toast.error("Permission Denied!!! Please allow permission.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          updateMyFb({ endStatus: true });
          updateClientFb({ endStatus: true });
        }, 5000);
      }
    );
  };

  const initCall = (stream) => {
    myStream = stream;
    peerServer = new Peer(undefined, {
      host: "peer.astrophy.com",
      path: "/",
      secure: true,
      config: configuration,
    });

    peerServer.on("open", (userId) => {
      if (type == "Incomming") {
        // audio.play()
        callNodeId = remoteData.nodeId;
        // FBGetValue(remoteData.nodeId)
        updateMyFb({ status: "Ringing" });
        updateClientFb({ status: "Ringing" });
      } else {
        // console.log("remoteData", remoteData)

        const dbRef = ref(db, `/user/${remoteData.userId}`);
        get(dbRef).then((snapshot) => {
          let remoteToken = snapshot.val()?.fcmToken;
          setRemoteUserToken(remoteToken);
          let callId = uuid.v4();
          callNodeId = callId;

          let myData = {
            userId: remoteData.userId,
            name: remoteData.name,
            nodeId: callId,
            time: moment().format(),
            type: "Outgoing",
            status: "Connecting",
            endStatus: false,
            videoCall: true,
          };

          set(ref(db, `/call/${myUserData._id}/${callId}`), myData).then(() => {
            FBGetValue(callId);
          });

          let clientData = {
            userId: myUserData._id,
            _id: myUserData._id,
            name: myUserData.firstName + " " + myUserData.lastName,
            firstName: myUserData.firstName,
            lastName: myUserData.lastName,
            peerId: userId,
            time: moment().format(),
            type: "Incomming",
            status: "Connecting",
            nodeId: callId,
            endStatus: false,
            videoCall: true,
          };

          set(ref(db, `/call/${remoteData.userId}/${callId}`), clientData).then(
            () => {
              sendNotification(clientData, remoteToken);
            }
          );
        });
      }
    });

    peerServer.on("call", (call) => {
      const conn = peerServer.connect(call.peer);
      call.answer(stream);

      call.on("stream", (userStream) => {
        console.log("userStream peer", userStream);
        peerCall = call;
        remoteUserStream = userStream;
        const videoGreed = document.getElementById("remote-video");
        const remoteImage = document.getElementById("remote-image");
        // console.log("videoGreed",videoGreed)
        videoGreed.srcObject = userStream;
        videoGreed.addEventListener("loadedmetadata", () => {
          videoGreed.play();
          remoteImage.style.display = "contents";
          videoGreed.style.display = "block";
        });
      });

      call.on("close", () => {
        console.log("streamstream1");
      });
    });
  };

  const connectUser = (userId) => {
    // console.log("called")
    let data = {};
    const conn = peerServer.connect(userId);
    const call = peerServer.call(userId, myStream);

    call.on("stream", (remoteVideoStream) => {
      if (remoteVideoStream) {
        peerCall = call;
        console.log("user stream", remoteVideoStream);
        remoteUserStream = remoteVideoStream;
        const videoGreed = document.getElementById("remote-video");
        const remoteImage = document.getElementById("remote-image");
        console.log("videoGreed", videoGreed);
        videoGreed.srcObject = remoteVideoStream;
        videoGreed.addEventListener("loadedmetadata", () => {
          videoGreed.play();
          remoteImage.style.display = "contents";
          videoGreed.style.display = "block";
        });
      }
    });
    call.on("close", () => {
      console.log("streamstream");
      // dispatch({ type: REMOVE_REMOTE_STREAM, payload: peers[userId].stream.id })
      // this.props.dispatch(removeRemoteStream(peers[userId].stream.id))
    });
  };

  const reciveCall = () => {
    connectUser(remoteData.peerId);
    // audio.pause()
    updateMyFb({ status: "Recieved" });
    updateClientFb({ status: "Recieved" });
    setRecieved(true);
  };

  const endCall = (callStat) => {
    remoteUserStream = null;
    if (peerCall != null) {
      peerCall.close();
    }

    if (timerRef != null) {
      clearInterval(timerRef);
    }

    if (type != "Incomming") {
      // let callMsgRef = Firebase.database()
      //   .ref(`/chat/${chatRoomId}/messages`)
      //   .push()

      let callMsgRef = push(ref(db, `/Chat/${chatRoomId}/messages`));

      let msdId = callMsgRef.key;
      // console.log("callState", callStat)

      set(callMsgRef, {
        sender_id: myUserData._id,
        type: "call",
        msgid: msdId,
        send_time: moment().format(),
        roomid: chatRoomId,
        name: myUserData.firstName + " " + myUserData.lastName,
        callDuration: `${hour.toString().padStart(2, "0")} : ${min
          .toString()
          .padStart(2, "0")} : ${sec.toString().padStart(2, "0")}`,
        callTime: moment().format(),
        callStatus: callStat == "Recieved" ? "Recieved" : "Outgoing",
      });
    }

    hour = 0;
    min = 0;
    sec = 0;

    if (myStream != null) {
      myStream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
    // audio.pause()
    props.callBackcall1(false);
  };

  const updateMyFb = (data) => {
    console.log(callNodeId,"cakk");
    // console.log("myUserData", myUserData)
    // console.log("updateMy", '/user/' + myUserData._id + '/call/' + callNodeId)
    if (callNodeId) {
      update(ref(db, `/call/${myUserData._id}/${callNodeId}`), data).then(
        () => {
          console.log("done");
        }
      );
    } else {
      if (data.endStatus) {
        props.callBackcall1(false);
      }
    }
  };

  const updateClientFb = (data) => {
    console.log("iss call");
    // console.log("updateClient", '/user/' + remoteUserData.userId + '/call/' + callNodeId)
    if (callNodeId) {
      update(ref(db, `/call/${remoteData.userId}/${callNodeId}`), data).then(
        (res) => {console.log(res,"resuuu");}
      );
    } else {
      if (data.endStatus) {
        props.callBackcall1(false);
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="modal-dialog video-modal my-5 ">
        <div
          className="modal-content call_modal"
          style={{
            width: "100%",
            overflowY: "hidden",
            maxHeight: "none",
            borderRadius: "8px",
          }}
        >
          <div className="call-body">
            <div className="">
              <div className="_large_video">
                <img
                  src={props.userImage? `https://admin.astrophy.com/${props.userImage}` :"https://png.pngitem.com/pimgs/s/130-1300344_user-symbol-png-transparent-png.png"}
                  height="100%"
                  width="100%"
                  id="remote-image"
                  style={{
                    objectFit: "contain",
                    display: "block",
                  }}
                />
                <video
                  // src="https://www.w3schools.com/html/mov_bbb.mp4"
                  width="100%"
                  height="100%"
                  id="remote-video"
                  class = "remote_video"
                  // style={{
                  //   display: "contents",
                  // }}
                  muted={remoteUserMute}
                  //   autoPlay
                />
                <div className="_video_intro d-flex pl-lg-5">
                  <button
                    className="_meet_act_btn px-3"
                    style={{ width: "auto" }}
                  >
                    {`${remoteData.name}`}
                  </button>

                  <button
                    className="_meet_act_btn px-3"
                    style={{ width: "auto" }}
                  >
                    {callState != "Recieved"
                      ? `${callState}...`
                      : `${hour.toString().padStart(2, "0")} : ${min
                          .toString()
                          .padStart(2, "0")} : ${sec
                          .toString()
                          .padStart(2, "0")}`}
                  </button>
                </div>
                <div>
                  <img
                    src="https://png.pngitem.com/pimgs/s/130-1300344_user-symbol-png-transparent-png.png"
                    alt="img"
                    className="video-img"
                    style={{
                      display: "contents",
                    }}
                  />

                  <video
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    width="100%"
                    height="100%"
                    id="ownVideo"
                    className="own-video"
                    muted={true}
                    style={{
                      display: "block",
                    }}
                    //   autoPlay
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" down-part mt-3">
            <div className="d-flex justify-content-around">
              {/* {iconoff === "speaker" ? (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("")}
                >
                  <i className="fas fa-volume-mute"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("speaker")}
                >
                  <i className="fas fa-volume-down"></i>
                </button>
              )}
              {iconoff === "micro" ? (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("")}
                >
                  <i className="fas fa-microphone-slash"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("micro")}
                >
                  <i className="fas fa-microphone"></i>
                </button>
              )}
              {iconoff === "pause" ? (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("")}
                >
                  <i className="fas fa-video-slash"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn down-btn"
                  onClick={() => setIconoff("pause")}
                >
                  <i className="fas fa-video"></i>
                </button>
              )} */}
              {!recieved && type == "Incomming" ? (
                <button
                  type="button"
                  className="btn receive-btn"
                  onClick={reciveCall}
                >
                  <i className="fas fa-phone" />
                </button>
              ) : null}

              {callState == "Recieved" ? (
                !audioMute ? (
                  <button
                    type="button"
                    className="btn down-btn"
                    onClick={() => {
                      setAudioMute(true);
                      updateClientFb({ muted: true });
                    }}
                  >
                    <i className="fas fa-microphone"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn down-btn"
                    onClick={() => {
                      setAudioMute(false);
                      updateClientFb({ muted: false });
                    }}
                    style={{
                      borderRadius: 30,
                      background: "#ffffff33",
                    }}
                  >
                    <i
                      className="fas fa-microphone-slash"
                      aria-hidden="true"
                    ></i>
                  </button>
                )
              ) : null}

              <button
                type="button"
                className="btn decline-btn "
                onClick={() => {
                  updateMyFb({ endStatus: true });
                  updateClientFb({ endStatus: true });
                }}
              >
                <i className="fas fa-phone"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
