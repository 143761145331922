import React, { useState, useEffect } from "react";
import Footer from "../../Defaults/Footer";
import { ReactTitle } from "react-meta-tags";
import MessageList from "./MessageList/messageList";
import CommonList from "./commonList";
import { DemoData } from "./components/demodata";
import WriteChat from "./ChatBody/writeChat";
import ChatHead from "./ChatBody/chatHead";
import NoChat from "./ChatBody/NoChat";
import { v4 as uuidv4 } from "uuid";
import { reactLocalStorage } from "reactjs-localstorage";
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import db from "../../Firebase/fbconfig";
import { ref, child, get, set, update } from "firebase/database";
import ImageMsg from "./MessageList/ImageMsg";
import { useParams } from "react-router-dom";

const Chat = () => {
  const [data, setdata] = useState(DemoData);
  const [newchat, setnewchat] = useState({});
  const [nochatopen, setnochatopen] = useState(false);
  const [send, setsend] = useState(true);
  const userData = reactLocalStorage.getObject("userData");
  const [roomId, setroomId] = useState("");
  const [chatlist, setchatlist] = useState([]);
  const [data1, setdata1] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [searchText, setSearchText] = useState('');

  const { alreadySelectedRoomId } = useParams();
  // console.log("alreadySelectedRoomId", alreadySelectedRoomId)

  // const [senderid, setsenderid] = useState("");
  useEffect(() => {
    getchatlist();
    // checkchatopen();
  }, []);
  const getchatlist = async () => {
    const dbRef = ref(db);
    get(child(dbRef, `/chatList/${userData._id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          // console.log(snapshot.val());
          setchatlist(Object.values(snapshot.val()));
          if (alreadySelectedRoomId) {
            let selectedChatData = Object.values(snapshot.val()).find(it => it.roomId == alreadySelectedRoomId);
            openchat(true, selectedChatData);
          }
          // console.log(Object.values(snapshot.val()));
        } else {
          console.log("No Chat data data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UserFind = (keyword) => {
    let searchString = keyword.trim().toLowerCase();
    let result = [];
    result = chatlist.filter((it) => it.name.toLowerCase().match(searchString));
    // console.log("result", result);
    // setdata(result);
    if (searchString.length > 0) {
      // setrefresh(!refresh)
      console.log("search result", result);
      // setdata(result);
      setdata1(result);
      setrefresh(!refresh);
      // console.log("SETTTT result", data1);

      // setTimeout(async () => {
      //   setdata(chatlist);
      // }, 100);
    } else {
      // setrefresh(!refresh)
      setdata(chatlist);
      setrefresh(!refresh);
    }
  };

  const openchat = (val, item) => {
    // console.log(item);
    if (val) {
      setnewchat(item);
      setnochatopen(true);
      setroomId(item.roomId);
      // setsenderid(item.userId);
      // console.log(`/chatList/${userData._id}/${item.userId}/unseen`);
      const updates = {};
      updates[`/chatList/${userData._id}/${item.userId}/unseen`] = false;
      update(ref(db), updates);
    }
  };

  return (
    <div>
      <div className="wrapper chat_mainpage">
        <ReactTitle title="Astrophy - Messages" />
      </div>
      <div className="container px-lg-0 mt-3 mb-5">
        <div className="row">
          <div className="col-md-12 ">
            <p className="text-muted">All Conversations</p>
          </div>
        </div>
        <div className="row px-3">
          <div className="col-lg-4 p-0 mb-3 pl-lg-0 pr-lg-3">
            <div className="chat_list">
              {/* search user */}
              <div className="form-group py-2 px-3 mb-0 ">
                <div className="d-flex Search_bar">
                  <span className="my-auto mr-0 text-muted">
                    <i className="fas fa-search mx-3"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control border-0  px-2 my-auto w-100"
                    id="searchChat"
                    placeholder="Seach by Name "
                    onChange={(val) => {
                      UserFind(val.target.value);
                    }}
                  />
                </div>
              </div>
              {/* {data1.length > 0 ? (
                <div>
                  <UserList userlist={data1} openchat={openchat} />
                </div>
              ) : (
                <div>
                  <UserList userlist={data} openchat={openchat} />
                </div>
              )} */}

              <div>
                <CommonList
                  openchat={openchat}
                  searchText={searchText}
                />
              </div>
            </div>
          </div>
          {nochatopen ? (
            <div className="col-lg-8 chat_details">
              <div>
                <ChatHead type="userToSeller" sender={newchat} roomId={roomId} />
              </div>
              <div className="message-area">
                <MessageList sender={newchat} roomId={roomId} />
              </div>
              <div>
                <WriteChat sender={newchat} roomId={roomId} />
              </div>
            </div>
          ) : (
            <NoChat />
          )}
        </div>
      </div>
      {/* <section>
        <Footer />
      </section> */}
    </div>
  );
};
export default Chat;
