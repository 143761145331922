import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import avatar from "../../Assets/Images/avatar.png";
import Footer from "../../Defaults/Footer";
import CloseModal from "./CloseModal";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Component/loader";
import axios from "axios";

import "react-toastify/dist/ReactToastify.css";
const Edit = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  const [closemodal, setClosemodal] = useState(false);

  const [user, setuser] = useState({});
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [about, setabout] = useState("");
  const [city, setcity] = useState("");
  const [oldpassword, setoldpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [profileimg, setprofileimg] = useState("");

  const [arr, setarr] = useState([]);
  const [checkArr, setcheckArr] = useState([]);

  const [npass, setnpass] = useState(false);

  const [cpass, setcpass] = useState(false);

  const [password, setpassword] = useState("");
  const userdata = reactLocalStorage.getObject("userData");
  const [loading, setLoading] = useState(false);

  const [isChecked, setisChecked] = useState(false);

  const [isCheckeditem, setisCheckeditem] = useState(false);

  const [isCheckedshop, setisCheckedshop] = useState(false);
  const [selectcountry, setselectcountry] = useState("");
  const [country, setcountry] = useState([]);
  const [currency, setcurrency] = useState("");
  const [currencyTyp, setcurrencyTyp] = useState([
    {
      name: "INR",
    },
    {
      name: "USD",
    },
    {
      name: "GBP",
    },
    {
      name: "EUR",
    },
    {
      name: "CAD",
    },
    {
      name: "cdd",
    },
  ]);

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = () => {
    let response = axios
      .get("https://countriesnow.space/api/v0.1/countries/capital")
      .then((response) => {
        console.log("response", response);
        if (response.data.data.length > 0) {
          setcountry(response.data.data);
          console.log("country", country);
        }
      });
  };
  const callBack = (val) => {
    setClosemodal(val);
  };

  const handleCheck = async (e) => {
    // console.log(e.target.name);
    if (e.target.value == "shop") {
      setisChecked(!isChecked);
    } else if (e.target.value == "favourite_item") {
      setisCheckeditem(!isCheckeditem);
    } else {
      setisCheckedshop(!isCheckedshop);
    }
    if (e.target.checked === true) {
      checkArr.push(e.target.value);

      // console.log("checkarr", checkArr);
    } else {
      let deleteIndex = checkArr.indexOf(e.target.value);
      if (deleteIndex > -1) {
        checkArr.splice(deleteIndex, 1);
      }

      console.log("checkarr", checkArr);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    let result = await HttpClient.requestData("seller/" + userdata._id, "GET");
    console.log("Data", result);
    if (result && result.status) {
      setuser(result.data);
      setfname(result.data.firstName);
      setlname(result.data.lastName);
      setemail(result.data.email);
      setpassword(result.data.password);
      setcity(result.data.city ? result.data.city : "");
      setabout(result.data.about ? result.data.about : "");
      setselectcountry(result.data.country ? result.data.country.label : "");
      setcurrency(result.data.currency ? result.data.currency : "");
      //selectcountry(result.data. ? result.data.city : "")
      // setcheckArr(result.data.include ? result.data.include : []);
      // setisChecked(
      //   result.data.include.filter((item) => item == "shop").length > 0
      //     ? true
      //     : false
      // );
      // setisCheckeditem(
      //   result.data.include.filter((item) => item == "favourite_item").length >
      //     0
      //     ? true
      //     : false
      // );
      // setisCheckedshop(
      //   result.data.include.filter((item) => item == "favourite_shop").length >
      //     0
      //     ? true
      //     : false
      // );
      result.data.image
        ? setarr([
          {
            avatar: HttpClient.IMG_URL + result.data.image,
          },
        ])
        : setarr([]);

      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };

  const onImageId = async (event) => {
    console.log("ss", event);

    let val = validation(event);
    console.log("img val", val);
    console.log("object", event.target.files[0]);
    setprofileimg(event.target.files[0]);
    if (val == true) {
      if (event.target.files[0] !== undefined) {
        setarr([
          {
            avatar: URL.createObjectURL(event.target.files[0]),
          },
        ]);
      } else {
        setarr([]);
      }
    }
  };

  const validation = (event) => {
    if (event.target.files[0] !== undefined) {
      let extension = event.target.files[0].name.split(".").pop();
      console.log("extension", extension);
      if (extension === "png" || extension === "jpg" || extension === "jpeg") {
        return true;
      } else {
        toast.warning(
          "File does not support. You must use .png or .jpg or.jpeg format",
          {
            position: "bottom-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return false;
      }
    } else {
    }
  };

  const save = async () => {
    // let data=formdata=();
    // console.log("checkArr", JSON.stringify(checkArr));

    const res = checkArr.map((data, index) => {
      return {
        position: data,
      };
    });
    console.log("checkArr", JSON.stringify(res));
    // return;
    let obj = { label: selectcountry, value: selectcountry };
    let myJSON = JSON.stringify(obj);
    let data = new FormData();

    if (fname != "") {
      data.append("firstName", fname);
    }
    if (lname != "") {
      data.append("lastName", lname);
    }
    if (profileimg != "") {
      data.append("image", profileimg);
    }
    data.append("email", email);
    data.append("password", password);

    if (city != "") {
      data.append("city", city);
    }
    if (selectcountry != "") {
      data.append("country", myJSON);
    }
    if (currency != "") {
      data.append("currency", currency);
    }

    if (about != "") {
      data.append("about", about);
    }

    // if (checkArr.length > 0) {
    //   data.append("include", checkArr);
    // }

    let result = await HttpClient.fileUplode(
      "update-profile/" + userdata._id,
      "PUT",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      // console.log("result", result);
      reactLocalStorage.setObject("userData",result.data)
      toast.success("Profile Updated Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // fetchData();
      setTimeout(() => {
        window.location.href = "/edit-profile";
      }, 3000);
      
    } else {
    }
  };

  const changepassword = async () => {
    if (oldpassword == "" || newpassword == "" || confirmPassword == "") {
      toast.warning("Please Enter All fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } 
    // else if (oldpassword != password) {
    //   toast.warning("Old password in wrong", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    else {
      // console.log("object")
      let sendData = {
        new_password: newpassword,
        old_password: oldpassword,
        cnf_password: confirmPassword,
      };

      let result = await HttpClient.requestData(
        "update-password/" + userdata._id,
        "PUT",
        sendData
      );

      if (result && result.status) {
        setconfirmPassword("");
        setoldpassword("");
        setnewpassword("");

        toast.success("Password Updated Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // console.log("favourite_shop",favourite_shop);
  return (
    <div>
      <div className="wrapper">
        <ReactTitle title="Astrophy-Edit Profile" />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <section>
            <div className="container edit_head my-3">
              <div className="row p-4">
                <ToastContainer />
                <div className="col-md-5">
                  <div className="d-lg-flex  justify-content-around">
                    {/* <div>
                  <img
                    src={avatar}
                    alt="img"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                </div> */}
                    <div>
                      {arr.length > 0 ? (
                        arr.map((item, index) => {
                          // console.log("object", item);
                          return (
                            <img
                              key={index}
                              alt="img"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%",
                              }}
                              src={item.avatar}
                            />
                          );
                        })
                      ) : (
                        <img
                          src={avatar}
                          // key={index}
                          alt="img"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </div>

                    <div className="choose_file my-4">
                      <input
                        type="file"
                        className="input_file"
                        onChange={onImageId}
                      />
                      Choose File
                    </div>

                    {/* <Link to="/">
                  <div className="no_file my-4">No File Chosen</div>
                </Link> */}
                  </div>
                  <p>
                    Must be a .jpg or .png file smaller than 10MB and at
                    least 400px by 400px.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 edit_form_left">
                  <div className="d-flex w-100">
                    <div className="form-group my-2 " style={{ width: "100%" }}>
                      <label htmlFor="fname">First Name</label>
                      <input
                        type="text"
                        id="fname"
                        placeholder="Enter First Name"
                        className="form-control"
                        onChange={(val) => {
                          if (
                            val.target.value.match("^[a-zA-Z0-9]*$") != null
                          ) {
                            setfname(val.target.value);
                          }
                        }}
                        value={fname}
                      />
                    </div>
                    <div
                      className="form-group my-2 ml-3 "
                      style={{ width: "100%" }}
                    >
                      <label htmlFor="lname">Last Name</label>
                      <input
                        type="text"
                        id="lname"
                        placeholder="Enter Last Name"
                        className="form-control"
                        onChange={(val) => {
                          if (
                            val.target.value.match("^[a-zA-Z0-9]*$") != null
                          ) {
                            setlname(val.target.value);
                          }
                        }}
                        value={lname}
                      />
                    </div>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter Email address"
                      aria-describedby="emailHelp"
                      value={email}
                      readOnly
                    />
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="inputCity">City</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCity"
                      placeholder="Enter City"
                      onChange={(val) => {
                        if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                          setcity(val.target.value);
                        }
                      }}
                      value={city}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="inputCity">Country</label>
                    <select
                      className="form-control contact_form_text"
                      id="exampleFormControlSelect1"
                      onChange={(val) => {
                        console.log("hjhj", val.target.value);
                        setselectcountry(val.target.value);
                      }}
                      value={selectcountry}
                    >
                      <option>Select Country</option>
                      {/*  */}
                      {country.length > 0 &&
                        country.map((item, index) => {
                          return (
                            <option value={item.name} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  {/* <div className="form-group mt-2">
                    <label htmlFor="inputCity">Currency</label>
                    <select
                      className="form-control"
                      onChange={(val) => {
                        setcurrency(val.target.value);
                      }}
                      value={currency}
                    >
                      <option value="">Select Currency</option>
                      {currencyTyp.map((item, index) => {
                        return <option value={item.name}>{item.name}</option>;
                      })}
                    </select>
                  </div> */}
                  <div className="form-group mt-2">
                    <label htmlFor="inputCity">Currency</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCity"
                      placeholder="Enter City"
                      readOnly
                      value={currency}
                    />
                  </div>
                  
                  <div className="form-group my-2">
                    <label htmlFor="exampleFormControlTextarea1">About</label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Enter About"
                      rows={3}
                      defaultValue={""}
                      onChange={(val) => {
                        // if (val.target.value.match("^[a-zA-Z0-9]*$") != null) {
                        setabout(val.target.value);
                        // }
                      }}
                      value={about}
                    />
                  </div>
                  {/* <h6 className="mt-3">Include on Your Profile</h6> */}
                  {/* <div>
                    <div className="form-check form-check-inline my-2 mr-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        name="check_shop"
                        value="shop"
                        onChange={handleCheck}
                        checked={isChecked}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        Shop
                      </label>
                    </div>
                    <div className="form-check form-check-inline my-2 mr-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox2"
                        name="check_favourite_item"
                        value="favourite_item"
                        onChange={handleCheck}
                        checked={isCheckeditem}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox2"
                      >
                        Favourite items
                      </label>
                    </div>
                    <div className="form-check form-check-inline my-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox3"
                        name="check_favourite_shop"
                        value="favourite_shop"
                        onChange={handleCheck}
                        checked={isCheckedshop}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox3"
                      >
                        Favourite shops
                      </label>
                    </div>
                  </div> */}
                  <div className="text-center my-4">
                    <button
                      type="button"
                      className="btn save_btn"
                      onClick={save}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
                <div className="col-md-6 edit_form_right">
                  <h4>Change Password ?</h4>
                  <div className="form-group my-2">
                    <label htmlFor="exampleInputPassword1">Old Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Old Password"
                      id="exampleInputPassword1"
                      value={oldpassword}
                      onChange={(val) => setoldpassword(val.target.value)}
                      // readOnly
                    />
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="exampleInputPassword1">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter New Password"
                      id="exampleInputPassword1"
                      onChange={(val) => {
                        setnewpassword(val.target.value);
                        var pattern = new RegExp(
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/
                        );

                        console.log("pass", val.target.value);
                        if (pattern.test(val.target.value)) {
                          setnpass(false);
                        } else {
                          setnpass(true);
                        }
                      }}
                      value={newpassword}
                    />
                  </div>
                  {npass ? (
                    <span className="text-danger">
                      At least 7 characters, 1 lower case, 1 upper case, and 1
                      number
                    </span>
                  ) : null}
                  <div className="form-group my-2">
                    <label htmlFor="exampleInputPassword1">
                      Confirm New Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter Confirm New Password"
                      onChange={(val) => {
                        setconfirmPassword(val.target.value);
                        if (val.target.value != newpassword) {
                          setcpass(true);
                        } else {
                          setcpass(false);
                        }
                      }}
                      value={confirmPassword}
                    />
                  </div>
                  {cpass ? (
                    <span className="text-danger">
                      Confirm Password must be matched with New Password
                    </span>
                  ) : null}
                  <div className="text-center my-4">
                    <button
                      type="button"
                      className="btn save_btn"
                      disabled={npass || cpass}
                      onClick={changepassword}
                    >
                      Save Changes
                    </button>
                  </div>
                  <h4>Want to permanently delete your account instead?</h4>
                  <div className=" mt-4">
                    <button
                      type="button"
                      className="btn close_btn"
                      onClick={() => setClosemodal(true)}
                    >
                      Close Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                closemodal ? "modal fade close_bg_modal show" : "modal fade"
              }
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: closemodal ? "block" : "none" }}
            >
              <CloseModal callBack={callBack} />
            </div>
          </section>
          <section>
            <Footer />
          </section>
        </>
      )}
    </div>
  );
};
export default Edit;
