import React, { useEffect, useState } from "react";
import Filter from "../Services/Filter";
import ListProvider from "./ListProvider";
import ServiceList1 from "../../Assets/Images/service_list 1.png";
import Footer from "../../Defaults/Footer";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
import { reactLocalStorage } from "reactjs-localstorage";
import EmptyWishlist from "../EmptyWishlist/EmptyWishlist";
import bgBlog from "../../Assets/Images/blogBg.jpg";
const WishlistProvider = () => {
  // const loop = [];
  const [loop, setloop] = useState([]);
  const [productDetails, setproductDetails] = useState([]);
  useEffect(() => {
    getProviderWishlist();
  }, []);
  const [loading, setLoading] = useState(false);
  const userdata = reactLocalStorage.getObject("userData");

  const getProviderWishlist = async () => {
    setLoading(true);
    let sendData = {};
    // console.log("object", sendData);
    let result = await HttpClient.requestData(
      "providergetWishlist/" + userdata._id,
      "GET",
      sendData
    );
    console.log("result wislist provider", result);
    if (result && result.status) {
      setloop(result.data);
      // setproductDetails(result.data[0].product_data);
      setTimeout(() => {
        setLoading(false);
      }, 10);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 10);
    }
  };
  const success = (val) => {
    if (val) {
      getProviderWishlist();
    }
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className="bGimg"
            style={{
              background: `url(${bgBlog})`,
            }}
          >
            <section className="container py-5">
              <div className="row">
                <div className="col-md-12 text-center service_head wishlist-provider">
                  <h3 className="m-0">Your Service Provider Wish List</h3>
                </div>
              </div>
            </section>
            {/* <section>
            <Filter />
          </section> */}
            <section className="pb-5">
              <div className="container">
                <div className="row">
                  {loop != null && loop.length > 0 ? (
                    loop.map((item, index) => {
                      return (
                        <div className="col-md-3  mb-4" key={index}>
                          <ListProvider
                            data={item}
                            key={index}
                            success={success}
                            providerDetails={item.provider_data}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <EmptyWishlist type="services_provider" />
                  )}
                </div>
              </div>
            </section>
          </div>
          <section>
            <Footer />
          </section>
        </>
      )}
    </div>
  );
};
export default WishlistProvider;
