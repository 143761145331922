import React, { useEffect, useState } from "react";
import Footer from "../../Defaults/Footer";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "../../utils/HttpClient";
import { toast, ToastContainer } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
// import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
const Contact = () => {
  const [type, settype] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [selectquestion, setselectquestion] = useState("");
  const [desc, setdesc] = useState("");
  const userdata = reactLocalStorage.getObject("userData");
  const [blankemail, setblankemail] = useState(false);
  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);
  const [isVerifiedRecaptcha, setisVerifiedRecaptcha] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const gender = [
    {
      gender: "Male",
    },
    {
      gender: "Female",
    },
    {
      gender: "Other",
    },
  ];
  const question = [
    {
      ques: "What are the main reasons you chose us?",
    },
    {
      ques: "Would you use our [product/service] again?",
    },
    {
      ques: "What did you like best about your experience?",
    },
  ];

  const submit = async () => {
    // if (type != "" && name != "" && email != "" && selectquestion != "" && desc != "") {
    if (isEmailFromatValid) {
      setisEmailFromatValid(true);
    }
    if (
      type == "" &&
      name == "" &&
      email == "" &&
      selectquestion == "" &&
      desc == ""
    ) {
      toast.error("Please Enter All Fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type == "") {
      toast.error("Please Select Gender", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (name == "") {
      toast.error("Please Enter Name", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (email == "") {
      toast.error("Please Enter Email", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (selectquestion == "") {
      toast.error("Please Enter Question", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (desc == "") {
      toast.error("Please Enter Description", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let data = {
        // user_id: userdata._id,
        user_type: { label: type, value: type },
        email: email,
        question: selectquestion,
        additional_details: desc,
        user_category: "User",
        name: name,
      };
      console.log("data", data);
      let result = await HttpClient.requestData("user_questions", "POST", data);

      console.log("result1", result);
      if (result && result.status) {
        toast.success("Information Added Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setname("");
        setemail("");
        settype("");
        setselectquestion("");
        setdesc("");
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    // } else {
    //   toast.error("Please Enter All Fields", {
    //     position: "bottom-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };

  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    console.log(valid);
    setisEmailFromatValid(valid ? false : true);
  }
  function onChange(value) {
    console.log("Captcha value:", value);
    setisVerifiedRecaptcha(true);
  }

  return (
    <div>
      <div className="wrapper">
        <ToastContainer />
        <ReactTitle title="Astrophy | Contact Us" />
      </div>
      <div className="container-fluid contact">
        <div className="row">
          <div className="col-md-12 text-center mt-5">
            <h3>Contact us</h3>
          </div>
        </div>
        <div className="row p-4">
          <div className="col-md-12 ">
            <p>
              If you have any questions regarding your Astrophy account, our
              Customer Service team is happy to assist you. As a friendly
              reminder, if you wish to contact an advisor, please visit the
              Psychic Readings page to find an advisor you wish to speak to.
              <br></br> <br></br>
              If you were dissatisfied with a recent reading, you may be
              eligible for a Satisfaction Guarantee credit. Simply visit the
              Satisfaction Guarantee page to submit your request.<br></br>
              <br></br> If you still need assistance, please use the form below
              to contact our Customer Service team or give us a call at
              877-Astrophy-Help. Our hours of operation are Monday-Friday: 6:00
              AM - 6:00 PM Pacific Time and Saturday-Sunday: 8:00 AM - 4:30 PM
              Pacific Time.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-4 offset-md-4">
            <div className="form-group">
              <select
                className="form-control contact_form_text"
                id="exampleFormControlSelect1"
                onChange={(val) => {
                  settype(val.target.value);
                }}
                value={type}
              >
                <option>Select Gender</option>
                {/*  */}
                {gender.length > 0 &&
                  gender.map((item, index) => {
                    return (
                      <option value={item.gender} key={index}>
                        {item.gender}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control contact_form_text"
                placeholder="Your Name"
                onChange={(val) => {
                  setname(val.target.value);
                }}
                value={name}
              />
            </div>
            <div className="form-group mb-3">
              <input
                type="email"
                className="form-control contact_form_text"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Your Email"
                onChange={(val) => {
                  setemail(val.target.value);
                  if (val.target.value == "") {
                    setblankemail(true);
                    validateEmail(val.target.value);
                  } else {
                    setblankemail(false);
                    validateEmail(val.target.value);
                  }
                }}
                value={email}
              />
            </div>
            {blankemail ? (
              <small style={{ color: "red" }}> Please enter email. </small>
            ) : isEmailFromatValid ? (
              <small style={{ color: "red" }}>
                {" "}
                Please enter a valid email.{" "}
              </small>
            ) : null}
            <h5>How may we assist you ?</h5>
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control contact_form_text"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Question"
                onChange={(val) => {
                  setselectquestion(val.target.value);
                }}
                value={selectquestion}
              />
            </div>
            {/* <div className="form-group">
              <select
                className="form-control contact_form_text"
                id="exampleFormControlSelect1"
                onChange={(val) => {
                  setselectquestion(val.target.value);
                }}
              >
                <option>Questin about ?</option>
                {question.length > 0 &&
                  question.map((item, index) => {
                    return (
                      <option value={item.ques} key={index}>
                        {item.ques}
                      </option>
                    );
                  })}
              </select>
            </div> */}
            <div className="form-group mb-3">
              <textarea
                className="form-control contact_form_text"
                id="exampleFormControlTextarea1"
                rows={3}
                onChange={(val) => {
                  setdesc(val.target.value);
                }}
                placeholder="Please describe the issue"
                value={desc}
              />
            </div>
            <div className="form-group">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={onChange}
              />
            </div>
            <div className="text-center my-4">
              <button
                type="submit"
                className="btn contactUsButton"
                onClick={() => submit()}
                disabled={!isVerifiedRecaptcha}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Contact;
