import React, { useState, useEffect } from "react";
import List from "./List";
import Footer from "../../Defaults/Footer";
import serviceImg from "../../Assets/Images/service_list 3.png";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import EmptyWishlist from "../EmptyWishlist/EmptyWishlist";
import Loader from "../../Component/loader";
import bgBlog from "../../Assets/Images/blogBg.jpg";

const Index = () => {
  const userData = reactLocalStorage.getObject("userData");
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [servicesDetails, setservicesDetails] = useState([]);
  useEffect(() => {
    getwishlist();
  }, []);

  const getwishlist = async () => {
    setLoading(true);
    let data = {};

    console.log("data", data);
    let result = await HttpClient.requestData(
      "servicegetWishlist/" + userData._id + "?currency=" + userData.currency,
      "GET",
      data
    );
    console.log("resultservice wishlist", result);
    if (result && result.status) {
      setdata(result.data);
      // setservicesDetails(result.data[0].service_data);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };

  const success = (val) => {
    if (val) {
      getwishlist();
    }
  };
  return (
    <div>
      <div
        className="bGimg"
        style={{
          background: `url(${bgBlog})`,
        }}
      >
        <section className="container py-3">
          <div className="row">
            <div className="col-md-12 text-center service_head service-wishlist">
              <h3 className="mb-0">Your Service Wish List</h3>
            </div>
          </div>
        </section>

        {loading ? (
          <Loader />
        ) : (
          <section className="pb-5">
            <div className="container">
              <div className="row">
                {data.length > 0 ? (
                  data.map((item, index) => {
                    return (
                      <div className="col-md-4 mb-4" key={index}>
                        <List
                          data={item}
                          success={success}
                          servicesDetails={item.service_data}
                        />
                      </div>
                    );
                  })
                ) : (
                  <EmptyWishlist type="service" />
                )}
              </div>
            </div>
          </section>
        )}
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Index;
