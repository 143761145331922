import React, { useState, useEffect } from "react";
import HttpClient from "../../../utils/HttpClient";

const Faq = (props) => {
  console.log("faq props", props.data);
  console.log("faq props About", props.dataFaq);
  console.log("type",props.type);

  const [faqData, setfaqData] = useState([]);
  const propsFaq = props.data;
  const faqType = props.type;
  const gaqAbout = props.dataFaq;

  useEffect(() => {
    window.scrollTo(0, 0);
    // fetchFaq();
  }, []);

  // const fetchFaq = async() => {
  //   let result = await HttpClient.requestData(
  //     "listProducts/" + 1,
  //     "GET",
  //   );
  //   console.log("faq Data --",result);
  //   if (result && result.status) {
  //     setfaqData(result.data);
  //   } else {
  //     setfaqData([]);
  //   }
  // };
  const [ans1, setAns1] = useState(false);
  const [ans2, setAns2] = useState(false);
  const [ans3, setAns3] = useState(false);
  const [ans4, setAns4] = useState(false);

  return (
    <div className="row">
      <div className="col-md-12 Faq-border-bottom py-3">
        <div className=" my-3 ans_text">
          <p
            onClick={() => {
              setAns1(!ans1);
              // setAns2(false);
              // setAns3(false);
              // setAns4(false);
            }}
          >
            {ans1 ? (
              <i className="fas fa-minus mr-3  "></i>
            ) : (
              <i className="fas fa-plus mr-3  plus_sign "></i>
            )}
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
            nibh cursus{" "} */}
            {faqType == "aboutFaq" ? (
            gaqAbout.qstn ? gaqAbout.qstn : "" 
            ) : (
              propsFaq.qstn.replace(/<[^>]+>/g, "")
            )}
            
            
          </p>

          <div className={ans1 ? "d-block pt-4" : "d-none"}>
            <h6>
            {faqType == "aboutFaq" ? (
            gaqAbout.ans ? gaqAbout.ans : "" 
            ) : (
              propsFaq.ans.replace(/<[^>]+>/g, "")
            )}
            {/* {gaqAbout.ans ? gaqAbout.ans : ""} */}
              {/* {propsFaq.ans.replace(/<[^>]+>/g, "")} */}
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
              volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. A, volutpat nibh cursus{" "} */}
            </h6>
          </div>
        </div>
      </div>
      {/* <div className="col-md-12 Faq-border-bottom py-3">
        <div className=" my-3 ans_text">
          <p
            onClick={() => {
              setAns2(!ans2);
              setAns1(false);
              setAns3(false);
              setAns4(false);
            }}
          >
            {ans2 ? (
              <i className="fas fa-minus mr-3 text-muted"></i>
            ) : (
              <i className="fas fa-plus mr-3 plus_sign"></i>
            )}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
            nibh cursus{" "}
          </p>
          <div className={ans2 ? "d-block pt-4" : "d-none"}>
            <h6>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
              volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. A, volutpat nibh cursus{" "}
            </h6>
          </div>
        </div>
      </div>{" "} */}
      {/* <div className="col-md-12 Faq-border-bottom py-3">
        <div className=" my-3 ans_text">
          <p
            onClick={() => {
              setAns3(!ans3);
              setAns2(false);
              setAns1(false);
              setAns4(false);
            }}
          >
            {ans3 ? (
              <i className="fas fa-minus mr-3 text-muted"></i>
            ) : (
              <i className="fas fa-plus mr-3 plus_sign"></i>
            )}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
            nibh cursus{" "}
          </p>
          <div className={ans3 ? "d-block pt-4" : "d-none"}>
            <h6>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
              volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. A, volutpat nibh cursus{" "}
            </h6>
          </div>
        </div>
      </div>{" "} */}
      {/* <div className="col-md-12 Faq-border-bottom py-3">
        <div className=" my-3 ans_text">
          <p
            onClick={() => {
              setAns4(!ans4);
              setAns2(false);
              setAns3(false);
              setAns1(false);
            }}
          >
            {ans4 ? (
              <i className="fas fa-minus mr-3 text-muted"></i>
            ) : (
              <i className="fas fa-plus mr-3 plus_sign"></i>
            )}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
            nibh cursus{" "}
          </p>
          <div className={ans4 ? "d-block pt-4" : "d-none"}>
            <h6>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
              volutpat nibh cursus Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. A, volutpat nibh cursus{" "}
            </h6>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default Faq;
