import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import moment from "moment";
function Details(props) {
  useEffect(() => {
    console.log("objecttt", props.data);
  }, [props.data]);

  const onCloseMethod = async () => {
    props.onCloseCallBack(false);
  };

  // const { selectedOption } = this.state;
  return (
    <div
      className={props.status ? "modal show fade" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: props.status ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="add-new-task-modalLabel">
              Service Details
            </h4>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => {
                onCloseMethod();
              }}
            >
              ×
            </button>
          </div>
          <div
            className="modal-body"
            style={{ height: "360px", overflowY: "auto" }}
          >
            <div className="px-2"></div>

            {props.data.servicecart_data.map((item, index) => {
              return (
                <>
                  <div className="d-md-flex  my-2  ">
                    <div className="order_pic ">
                      <img
                        src={HttpClient.IMG_URL + item.image}
                        alt="img"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "6px",
                        }}
                      />
                    </div>
                    <div className="px-3 py-2">
                      <h4>{item.servicename}</h4>
                      <h6>₹ {item.price}</h6>
                      {/* <p>Quantity: {item.qty} </p> */}
                    </div>
                  </div>
                </>
              );
            })}

            <div className="form-group row">
              <div className="col-md-4">
                <label htmlFor="task-title">Seller Name/User Name</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.firstname + " " + props.data.lastname}
                  readOnly
                />
              </div>

              <div className="col-md-8">
                <label htmlFor="task-title">Address</label>

                <textarea
                  style={{ height: "37px" }}
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={
                    props.data.address1 +
                    "," +
                    props.data.state +
                    "," +
                    props.data.zip
                  }
                  readOnly
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="task-title">Payment Type</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.paymenttype}
                  readOnly
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="task-title">Booking Date</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={moment(props.data.booking_date).format("D-MM-YYYY")}
                  readOnly
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="task-title">Sub Total</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.subtotal}
                  readOnly
                />
              </div>
            </div>

            <div className="text-right">
              <button
                type="button"
                className="btn btn-warning mr-3"
                data-dismiss="modal"
                onClick={() => {
                  onCloseMethod();
                }}
              >
                Close
              </button>
              {/* {this.state.verifyotp == this.props.otp ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  // className="close"

                  onClick={() => {
                    this.AddDriver();
                  }}
                  data-dismiss="modal"
                >
                  verify
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  // className="close"

                  onClick={() => {
                    this.AddDriver();
                  }}
                  data-dismiss="modal"
                >
                  verify
                </button>
              )} */}
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
export default Details;
