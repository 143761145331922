import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Blog_ban from "../../Assets/Images/blog.png";
import HttpClient from "../../utils/HttpClient";
import moment from "moment";

const LatestBlog = (props) => {
  // const [blogData, setblogData] = useState([]);

  // useEffect(() => {
  //   getBlogData();
  // }, []);

  // const getBlogData = async () => {
  //   let result = await HttpClient.requestData("Blog", "GET");
  //   console.log("result blog data--", result);
  //   if (result && result.status) {
  //     setblogData(result.data);
  //   }
  // };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }

  return (
    <div className="row">
      {props.blogData.map((item, index) => {
        return (
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" key={index}>
            <Link to={"/blog-details/" + nameToSlug(item.heading) + "/" + item._id} className="d-block">
              <div className="latest_box my-4" key={index}>
                <div className="row flex-column flex-md-row no-gutters">
                  <div className="col-md-12">
                    <div>
                      <img src={HttpClient.IMG_URL + item.image} alt="img" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="px-3 py-2">
                      <h6> {moment(item.created_on).format("MMMM Do YYYY")}</h6>

                      <h4>{item.heading}</h4>

                      <p dangerouslySetInnerHTML={{__html: item.content1}}></p>
                      {/* <p>{item.content1.replace(/<[^>]+>/g, "")} </p> */}
                      <div style={{textAlign: "center"}}>
                      <Link to={"/blog-details/" + nameToSlug(item.heading) + "/" + item._id} className="btn view_btn">
                        <span>View</span>
                      </Link>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
export default LatestBlog;
