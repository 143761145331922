import React, { useEffect, useState } from "react";
import axios from "axios";
import Spell from "../Components/Spell";
import Filter from "../Services/Filter";
import ProductList from "./ProductList";
import Footer from "../../Defaults/Footer";
import { ReactTitle } from "react-meta-tags";
import HttpClient from "../../utils/HttpClient";
// import currencyConvertrt from "../../utils/HttpClient";
import Loader from "../../Component/loader";
import ProductFilter from "./ProductFilter";
import Pagination from "../Components/Pagination";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { useLocation } from "react-router";
import bgBlog from "../../Assets/Images/blogBg.jpg";
const Index = () => {
  let cUrl = window.location.href;
  let url = new URL(cUrl);
  let search_params = url.searchParams;
  search_params.set("page", "1");
  url.search = search_params.toString();
  var newUrl = url.toString();
  // let newUrl = url.append('page', '1');
  console.log("url", url);
  console.log("newUrl", newUrl);

  // cUrl.searchParams.set('page', '1');

  useEffect(() => {
    window.scrollTo(0, 0);
    // setTimeout(() => {
    //   getproduct();
    // }, 2000);
    getproduct(1, "", "", "", "", "");
    // currPageData();
    // pathNameData();
    // crr();
  }, []);

  const [productpaginator, setproductpaginator] = useState({});
  const [pagevalue, setpagevalue] = useState(1);
  const [currPage, setcurrPage] = useState(1);
  const [userdata, setuserdata] = useState(
    reactLocalStorage.getObject("userData")
  );
  const [item, setitem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMrp, setnewMrp] = useState();
  const [newSelling, setnewSelling] = useState();
  const [minum, setminum] = useState("");
  const [maxnum, setmaxnum] = useState("");
  const [sortBy, setsortBy] = useState("");
  const [ratee, setratee] = useState("");
  const [del, setdel] = useState("");
  const [rat, setrat] = useState("");
  const [short, setshort] = useState("");
  const [priceby, setpriceby] = useState("");

  // const currPageData = (curPageVal) => {
  //   console.log("currPage",curPageVal);
  //   const params = curPageVal ? curPageVal : "1";
  //   history.replace({ pathname: location.pathname, search: params.toString() });
  //   // getproduct(params)
  //   pathNameData();shortby
  //   const url = window.location.href;
  //   console.log("pathname",pathname);
  //   console.log("url",url);
  //   if (url.includes(pathname)) {
  //     pageValuee = url.slice(-1);
  //     console.log("pageValur",pageValuee);
  //     getproduct(pageValuee)
  //   }
  // }
  const currentPagination = reactLocalStorage.getObject("pagiNation")
  const getproduct = async (next, deli, min, max, shortby, rateby) => {
    console.log("fect dsta ---", next, deli, min, max, shortby, rateby);
    // console.log("next", next);
    setLoading(true);
    // console.log("userdata", userdata);
    let datass = "/k";
    if (Object.keys(userdata).length > 0) {
      // if (typeof userdata._id !== "undefined") {
      datass = "/" + userdata._id;
    } else {
      datass = "/91a953b1effa7e26d08fff8a";
    }
    // }
    let sendData = next ? next : "1";
    // console.log("data......", datass);
    if (Object.keys(userdata).length > 0) {
      let result = await HttpClient.requestData(
        "listProducts/" +
          userdata._id +
          "/" +
          sendData +
          `?currency=` +
          userdata.currency +
          `&delivery=` +
          deli +
          `&min=` +
          min +
          `&max=` +
          max +
          `&shortby=` +
          shortby +
          `&rateby=` +
          rateby,
        "GET"
      );
      console.log(
        "result",
        "listProducts/" +
          userdata._id +
          "/" +
          sendData +
          `?currency=` +
          userdata.currency +
          `&delivery=` +
          deli +
          `&min=` +
          min +
          `&max=` +
          max +
          `&shortby=` +
          shortby +
          `&rateby=` +
          rateby
      );
      if (result && result.status) {
        let newItem = result.data.itemsList.filter(
          (item) => item.adminStatus === true
        );
        setitem(newItem);
      //  if( Object.keys(currentPagination).length === 0){
      //   setproductpaginator(result.data.paginator);
      //  }else{
      //   setproductpaginator(currentPagination);
      //  }
      //   // setproductpaginator(currentPagination);
      //   console.log(result.data.paginator,'pagn');
      //   if(result.data.paginator.currentPage!=1){
      //     reactLocalStorage.setObject("pagiNation",result.data.paginator)
      //   }
        
      reactLocalStorage.setObject("pagiNation",result.data.paginator)
       
       
        console.log(currentPagination,"pgi");
        // setproductpaginator(currentPagination);
        setproductpaginator(result.data.paginator);
        setcurrPage(result.data.paginator.currentPage);
        setdel(deli);
        setrat(rateby);
        setshort(shortby);
        setpriceby(min == "151" ? "above151" : min + "-" + max);

        // currPageData(result.data.paginator.currentPage)
        setTimeout(() => {
          setLoading(false);
        }, 1);
      } else {
      }
    } else {
      let result = await HttpClient.requestData(
        // eslint-disable-next-line no-useless-concat
        "listProducts/" +
          "91a953b1effa7e26d08fff8a/" +
          sendData +
          "?currency=" +
          "INR" +
          `&delivery=` +
          deli +
          `&min=` +
          min +
          `&max=` +
          max +
          `&shortby=` +
          shortby +
          `&rateby=` +
          rateby,
        "GET"
      );
      console.log("productttt", result);
      if (result && result.status) {
        let newItem = result.data.itemsList.filter(
          (item) => item.adminStatus === true
        );
        setitem(newItem);
        setproductpaginator(result.data.paginator);
        setitem(newItem);
        setproductpaginator(result.data.paginator);
        setcurrPage(result.data.paginator.currentPage);
        setdel(deli);
        setrat(rateby);
        setshort(shortby);
        setpriceby(min == "151" ? "above151" : min + "-" + max);
        setTimeout(() => {
          setLoading(false);
        }, 1);
      } else {
      }
    }
  };

  const nextpage = (val, deli, min, max, shortby, rateby) => {
    console.log("nextmin", val, deli, min, max, shortby, rateby);
    setpagevalue(val);
    getproduct(val, deli, min, max, shortby, rateby);
    setdel(deli);
    setrat(rateby);
    setshort(shortby);
    setpriceby(min == "151" ? "above151" : min + "-" + max);
    // currPageData(val)
    // pathNameData();
  };
  const min = (val) => {
    console.log("index prop", val.max, val.min);
    setminum(val.min);
    setmaxnum(val.max);
  };
  const sortby = (val) => {
    console.log("props sort", val.sort);
    setsortBy(val.sort);
  };
  const rate = (val) => {
    console.log("props rate", val.rate);
    setratee(val.rate);
  };
  const delivery = (val) => {
    console.log("props deli", val.deli);
    setdel(val.deli);
  };
  const filterProduct = (data, paginator) => {
    console.log("dataval", data);
    setitem(data);
    setproductpaginator(paginator);
    // getproduct();
  };

  const addcart = async (val) => {
    let tax_percent = val.tax.replace("%", "");
    let b = Number(val.selling_price) * (Number(tax_percent) / 100);
    let selling_priceWithTax = (val.selling_price + b).toFixed(2);
    let data = {
      prod_id: val._id,
      user_id: userdata._id,
      productname: val.name,
      qty: 1,
      currency: userdata.currency,
      // price: selling_priceWithTax,
      price: val.selling_price,
      image: val.image[0],
      orderFrom: "web",
    };
    let result = await HttpClient.requestData("add-to-cart", "POST", data);
    if (result && result.status) {
      if (val.wishlist_data.length > 0) {
        confirmAlert({
          title: "",
          message: "After adding to cart product will be removed from wishlist",
          buttons: [
            {
              label: "Ok",
              onClick: () => {},
            },
          ],
        });
      }
      getproduct(1, "", "", "", "", "");
      toast.success(result.message, {
        position: "bottom-right",
      });
    } else {
      toast.error(result.message, {
        position: "bottom-right",
      });
    }
  };

  const wishlistCallback = (val) => {
    if (val) {
      // window.location.reload(false);
      const currentPag = reactLocalStorage.getObject("pagiNation").currentPage;
      console.log(currentPag,"currentPage");
      getproduct(currentPag, "", "", "", "", "");
    }
  };

  return (
    <div>
      <div className="wrapper">
        <ReactTitle title="Astrophy Mall | Astrophy" />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className="bGimg"
            style={{
              background: `url(${bgBlog})`,
            }}
          >
            <section className="Spell">
              {/* <div className="container" style={{ maxWidth: "1200px" }}>
              <Spell />
            </div> */}
            </section>
            <div className="container mt-2">
              <div class="service_head">
                <h6>Product</h6>
              </div>
              <div className="row">
                <div className="col-md-12 text-center service_head ">
                  <h3 className="my-4 px-1">
                    Product For <span style={{ color: "#a024b7" }}>Sell</span>
                  </h3>
                </div>
              </div>
            </div>
            <section className="Services">
              <div
                className="container Product_list"
                style={{ maxWidth: "1200px" }}
              >
                <ProductFilter
                  page="products"
                  pagevalue={pagevalue ? pagevalue : "1"}
                  filterProduct={filterProduct}
                  parentmin={min}
                  sortby={sortby}
                  rate={rate}
                  delivery={delivery}
                  deli={del}
                  rat={rat}
                  short={short}
                  priceby={priceby}
                />
              </div>
            </section>
          </div>
          <section className="mb-3">
            <div
              className="container Product_list"
              style={{ maxWidth: "1200px" }}
            >
              <ProductList
                data={item}
                addTocart={addcart}
                wishlistCallback={wishlistCallback}
              />
              {/* <div className="text-center">
                <button href="#" className="btn my-4 product_list_btn ">
                  View More
                </button>
              </div> */}
            </div>
          </section>
          <section className="container">
            <div className="row">
              <div className="col-md-12 ">
                <Pagination
                  productpaginator={productpaginator}
                  nextpage={nextpage}
                  min={minum}
                  max={maxnum}
                  sortBy={sortBy}
                  ratee={ratee}
                  del={del}
                />
              </div>
            </div>
          </section>
        </>
      )}

      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Index;
