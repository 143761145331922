import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import { Row, Card, CardBody, Col, Input, Button } from "reactstrap";

const Index = () => {
  const [amountValue, setamountValue] = useState("");
  const [comissionData, setcomissionData] = useState([]);
  const [dataa, setdata] = useState([]);
  const [dataa1, setdata1] = useState([]);
  const [table, setTable] = useState(false);
  const [wallet, setwallet] = useState(0);
  const [result, setresult] = useState([]);
  const [WithdrawHistory, setWithdrawHistory] = useState([]);
  const [processing_fee, setprocessing_fee] = useState(0);
  const userData = reactLocalStorage.getObject("userData");

  useEffect(() => {
    // getComission();
    fetchcount();
    getWalletBalance();
    getWithdrawHistory();
  }, []);
  // useEffect(() => {

  //   fetchcount();

  // }, [result]);

  // useEffect(() => {

  //   getWithdrawHistory();

  // }, [wallet]);

  // const fetchcount = async () => {
  //   let userData = reactLocalStorage.getObject("userData");
  //   let result = await HttpClient.requestData(
  //     "getSellersettlement/" + userData._id,
  //     "GET"
  //   );
  //   console.log("Count", result);
  //   if (result && result.status) {
  //     // settotalEarning(result.total_earnings);

  //     // settotalSettle(result.earning_settled);
  //     // settotalSettle(result.earning_settled);
  //     // setpending(result.pending_settlement);
  //     setresult(result)
  //     setwallet(result.in_wallet);
  //     // setuser(result.data);

  //     // console.log("setuser", user);
  //   } else {
  //   }
  // };

  // const getComission = async () => {
  //   let userData = reactLocalStorage.getObject("userData");
  //   let result = await HttpClient.requestData(
  //     "sellercomission/" + userData._id,
  //     "GET"
  //   );
  //   console.log("sellercomission", result);
  //   if (result && result.status) {
  //     setcomissionData(result.data)
  //     let data = [];
  //     let i = 1;
  //     result.data.forEach((element, index) => {
  //       let rows = {
  //         sl: i,
  //         order_id: element.order_id,
  //         // comission_all: element.comission_all,
  //         comission_total: element.seller_commission,
  //         commision_type: element.commision_type,
  //         date: moment(element.created_on).format('MMMM Do YYYY hh:mm A'),

  //       };
  //       i++;
  //       data.push(rows);
  //     });

  //     setdata(data);
  //     setTable(true);
  //   } else {
  //     setcomissionData([]);
  //     setdata([]);
  //   }
  // };
  // const getComissions = async () => {
  //   let userData = reactLocalStorage.getObject("userData");
  //   let result = await HttpClient.requestData(
  //     "sellercomission/" + userData._id,
  //     "GET"
  //   );
  //   console.log("comissionn data", result);
  //   if (result && result.status) {
  //     setcomissionData(result.data)
  //   } else {
  //     setcomissionData([]);
  //   }
  // };

  // const totalComission = comissionData.reduce(
  //   (prevValue, currentValue) => prevValue + currentValue.seller_commission,
  //   0);
  // console.log(totalComission);

  // const sendWithdrawRequest = async () => {
  //   let userData = reactLocalStorage.getObject("userData");
  //   let sendData = {
  //     seller_id: userData._id,
  //     amount: Number(amountValue),
  //   };
  //   // console.log("sendData",sendData);
  //   let result = await HttpClient.requestData("withdraw-request", "POST", sendData);
  //   // console.log("send Request", result);
  //   if (result && result.status) {
  //     toast.success(result.message, {
  //       position: "bottom-right",
  //       autoClose: 1000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     setamountValue("");
  //   } else {
  //     toast.error("Error in sending request", {
  //       position: "bottom-right",
  //       autoClose: 1000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // };

  const getWithdrawHistory = async () => {
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "withdraw-history/" + userData._id,
      "GET"
    );
    console.log("withdraw-history", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      result.data.forEach((element, index) => {
        let rows = {
          sl: i,
          transactionid: element.transactionid,
          amount:
            (userData.currency == "INR"
              ? "₹"
              : userData.currency == "CAD"
              ? "$"
              : userData.currency == "USD"
              ? "$"
              : userData.currency == "GBP"
              ? "£"
              : userData.currency == "EUR"
              ? "€"
              : "₹") + element.seller_commission,
          date: moment(element.created_on).format("MMMM Do YYYY  "),
          // paystatus: element.paystatus == true ? "Paid" : "Pending",
        };
        i++;
        data.push(rows);
      });

      setdata(data);
      setTable(true);
    } else {
      setdata([]);
    }
  };

  const WithdrawBalance = async (id) => {
    let result = await HttpClient.requestData(
      "claimable-commissions/" + id,
      "PUT"
    );
    // console.log("send Request", result);
    if (result && result.status) {
      toast.success(result.message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getWithdrawHistory();
      getWalletBalance();
    } else {
      toast.error("Error in sending request", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const fetchcount = async () => {
    let dataa = {
      datefrom: "",
      dateto: "",
      last_month: "",
      yesterday: "",
      today: "today",
      id: userData._id,
      currencyy: userData.currency,
    };
    // let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "getSellersettlement",
      "POST",
      dataa
    );
    console.log("getGraphcomission", result.processingfees.processing_fees);
    if (result) {
      setprocessing_fee(result.processingfees.processing_fees);
      // getWalletBalance(result.processingfees.processing_fees)
    } else {
    }
  };

  const getWalletBalance = async () => {
    let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "claimable-commissions/" + userData._id,
      "GET"
    );
    console.log("wallethistory", result);
    if (result && result.status) {
      let data1 = [];
      let i = 1;
      result.data.forEach((element, index) => {
        let sellerCommision = Math.abs(
          element.seller_commission -
            (element.seller_commission * processing_fee) / 100
        ).toFixed(2);
        let rows = {
          sl: i,
          transactionid: element.transactionid,
          orderid: element.order_id,
          amount:
            (userData.currency == "INR"
              ? "₹"
              : userData.currency == "CAD"
              ? "$"
              : userData.currency == "USD"
              ? "$"
              : userData.currency == "GBP"
              ? "£"
              : userData.currency == "EUR"
              ? "€"
              : "₹") + sellerCommision,
          paydate_on: moment(element.created_on).format("MMMM Do YYYY  "),

          withdraw: element.sellerapply ? (
            <Button color="secondary"> Withdrawn</Button>
          ) : (
            <Button
              color="success"
              onClick={() => {
                WithdrawBalance(element._id);
              }}
            >
              {" "}
              Withdraw
            </Button>
          ),
          image: HttpClient.IMG_URL + element.image,
        };
        i++;
        data1.push(rows);
      });

      setdata1(data1);
    } else {
      setdata1([]);
    }
  };

  const data = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        width: 150,
      },
      // {
      //   label: "Transaction Id",
      //   field: "transactionid",
      //   width: 270,
      // },
      {
        label: "Amount",
        field: "amount",
        width: 270,
      },
      {
        label: "Date of withdrawn",
        field: "date",
        width: 270,
      },
      // {
      //   label: "Pay Status",
      //   field: "paystatus",
      //   width: 270,
      // },
      // {
      //   label: "Transaction Image",
      //   field: "Image",
      //   width: 270,
      // },
    ],
    rows: dataa,
  };

  const data1 = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        width: 150,
      },
      {
        label: "Order Id",
        field: "orderid",
        width: 270,
      },
      {
        label: "Amount",
        field: "amount",
        width: 270,
      },
      // {
      //   label: "Paid On",
      //   field: "paydate_on",
      //   width: 270,
      // },
      {
        label: "Action",
        field: "withdraw",
        width: 270,
      },
      // {
      //   label: "Transaction Image",
      //   field: "Image",
      //   width: 270,
      // },
    ],
    rows: dataa1,
  };
  return (
    <div className="withdraw">
      {/* <ToastContainer /> */}
      <div className="row mt-4">
        <div className="col-md-12 text-center">
          <h1>Claimable Balance List</h1>
        </div>
        {/* <div className="col-md-6 offset-md-3 my-3">
          <div className="card py-3">
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="exampleInput">Total Comission</label>
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  value={wallet}
                  style={{ border: "none", backgroundColor: "white" }}
                  readOnly
                /><br />
                <label htmlFor="exampleInput">Amount</label>
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  onChange={(val) => {
                    setamountValue(val.target.value);
                  }}
                  value={amountValue}
                />
              </div>
              
              <br />
              <div className="text-center">
                <button className="btn process_btn" onClick={sendWithdrawRequest}>Withdraw Request</button>
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-md-10 mr-auto">
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={data1}
            responsive
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12 text-center">
          <h1>Withdraw History</h1>
        </div>
        <br />
        <br />
        <div className="col-md-10 mr-auto">
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};
export default Index;
