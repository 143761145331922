import React from "react";

const EmptyProduct = () => {
  return (
    <div className="col-md-12 text-center blank_product">
      <h4>
        No products found <br></br>
        {/* <span>Choose your product first</span>{" "} */}
      </h4>
    </div>
  );
};
export default EmptyProduct;
