import React from "react";
import Activity from "../../Views/Seller/Activity";
import Cat5 from "../../Assets/Images/cast5.png";
import DashboardActivity from "./DashboardActivity";
import ReviewList from "../../Views/ServiceDetails/ReviewList";
import { Link } from "react-router-dom";

const Active = () => {
  const item = [
    {
      img: Cat5,
      name: "Lorem Ipsum",
      para: "Description",
      status: "18 minutes",
    },
    {
      img: Cat5,
      name: "Lorem Ipsum",
      para: "Description",
      status: "18 minutes",
    },
    {
      img: Cat5,
      name: "Lorem Ipsum",
      para: "Description",
      status: "18 minutes",
    },
  ];
  const item2 = [
    {
      img: Cat5,
      name: "Lorem Ipsum",
      para: "Description",
      status: "yesterday",
    },
    {
      img: Cat5,
      name: "Lorem Ipsum",
      para: "Description",
      status: "yesterday",
    },
    {
      img: Cat5,
      name: "Lorem Ipsum",
      para: "Description",
      status: "yesterday",
    },
  ];
  const item3 = [1, 2, 3, 4];
  return (
    <div>
      <div className="row mt-4">
        <div className="col-md-12 text-center">
          <h1>Astrophy Seller</h1>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <h4>Recent Activity</h4>
          <h6 className="tag">Today</h6>
        </div>
      </div>
      <div>
        <DashboardActivity data={item} />
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <h4>Yesterday Activity</h4>
          <h6 className="tag">Yesterday</h6>
        </div>
      </div>
      <div>
        <DashboardActivity data={item2} />
      </div>
      <div className="row my-4">
        <div className="col-md-6">
          <h4>Review</h4>
        </div>
        {/* <div className="col-md-6 text-right">
          <Link to="/..">View More</Link>
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-12">
          <ReviewList data={item3} />
        </div>
      </div>
    </div>
  );
};
export default Active;
