import React, { useState, useEffect } from "react";
import Pagination from "../Components/Pagination";
import DescriptionPro from "./DescriptionPro";
import DetailsPro from "./DetailsPro";
import ReviewList from "./ReviewList";
import RelatedProduct from "./RelatedProduct";
import OtherService from "../Components/OtherService";
import Footer from "../../Defaults/Footer";
import { Link } from "react-router-dom";
import { ReactTitle } from "react-meta-tags";
import { reactLocalStorage } from "reactjs-localstorage";
// import Loader from "../../Component/loader";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Index = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getproduct();
    getreview();
  }, []);
  // const loop = [1, 2, 3, 4, 5, 6, 7];
  const userData = reactLocalStorage.getObject("userData");
  // const [id, setid] = useState(props.match.params.id);
  const { id } = useParams();
  const [pro, setpro] = useState({});
  const [loading, setLoading] = useState(false);
  const [reviewlist, setreviewlist] = useState([]);
  const [avg, setavg] = useState(0);
  const [reviewcount, setreviewcount] = useState(0);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [sorted, setSorted] = useState();
  const [showReview, setshowReview] = useState(false);

  const callbackResult = (val) => {
    getproduct();
  };

  const getproduct = async () => {
    setLoading(true);
    let sendData = {};
    console.log("object getproduct", sendData);
    if (Object.keys(userData).length > 0) {
      let result = await HttpClient.requestData(
        "viewproduct/" + id + "?currency=" + userData.currency,
        "GET",
        sendData
      );
      console.log("singlproresult", result);
      if (result && result.status) {
        setpro(result.data[0]);
        setisDataLoaded(true);
        setTimeout(() => {
          setLoading(false);
        }, 1);
      } else {
      }
    } else {
      let result = await HttpClient.requestData(
        "viewproduct/" + id + "?currency=" + "INR",
        "GET",
        sendData
      );
      console.log("singlproresult", result);
      if (result && result.status) {
        setpro(result.data[0]);
        setisDataLoaded(true);
        setTimeout(() => {
          setLoading(false);
        }, 1);
      } else {
      }
    }
  };

  const showReviewData = (val) => {
    console.log("showReviewData", val);
    setshowReview(val);
  };

  const getreview = async () => {
    // setLoading(true);
    let sendData = {};
    // console.log("object", sendData);
    let result = await HttpClient.requestData(
      "productreview/" + id,
      "GET",
      sendData
    );
    console.log("review", result);
    if (result && result.status) {
      // setTimeout(() => {
      //   setreviewlist(result.data);
      // }, 1);
      // console.log("sort result.data", result.data);
      setreviewlist(result.data);
      setreviewcount(result.data.length);
      let sum = 0;
      let avg = 0;
      // console.log("reviewlist", reviewlist);
      result.data.map((itemm, index) => {
        return (sum = Number(sum) + Number(itemm.rating));
      });
      // console.log("sum",sum);
      avg = Number(sum) / Number(result.data.length);

      setavg(avg);
    } else {
    }
  };

  const handleSortBy = async (e) => {
    let val = e.target.value;
    setSorted(val);
    let data = {
      prod_id: id,
      sortby: val,
      // prod_id: "61f4d6c2a401752f9bb2d2da",
    };
    let result = await HttpClient.requestData(
      "filter-product-reviews",
      "POST",
      data
    );
    if (result && result.status) {
      // console.log("sort result", result.data);
      setreviewlist(result.data);
    } else {
      // console.log("erroe result", result);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pro.name}</title>
        <meta property="og:title" content={pro.name} />
        <meta property="og:description" content={pro.description} />
      </Helmet>
      <div>
        <div className="wrapper">
          <ReactTitle title={pro.name + " | " + " Astrophy Mall | Astrophy"} />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="container-fluid" style={{ maxWidth: "1200px" }}>
              <div className="row p-4">
                <div className="col-md-12 back_to">
                  <Link to="/astrophy-mall">
                    <p>
                      {" "}
                      <i className="fas fa-chevron-left mr-2"></i>Back to
                      Products
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            {Object.keys(pro).length ? (
              <>
                <section>
                  <div
                    className="container-fluid"
                    style={{ maxWidth: "1200px" }}
                  >
                    {isDataLoaded ? (
                      <DetailsPro
                        details={pro}
                        avg={pro.avgRating}
                        reviewcount={reviewcount}
                        callbackResult={callbackResult}
                        showReviewData={showReviewData}
                      />
                    ) : null}
                  </div>
                </section>
                <section>
                  <div
                    className="container-fluid"
                    style={{ maxWidth: "1200px" }}
                  >
                    <div className="row p-4">
                      <div className="col-md-12">
                        {/* {reviewcount} */}
                        <DescriptionPro description={pro.description} />
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <p>No product Found</p>
            )}
            {showReview ? (
              <section>
                <div className="container-fluid" style={{ maxWidth: "1200px" }}>
                  <div className="row p-4">
                    <div className="col-md-12">
                      <div className="d-flex border-bottom">
                        <div className="select active mr-5">
                          Product Reviews
                        </div>

                        <div className="form-group d-flex ml-auto review_sort">
                          <h6 className="mr-3 my-2">SortBy:</h6>
                          <select
                            className="form-control"
                            id="sortby"
                            value={sorted}
                            onChange={handleSortBy}
                          >
                            <option selected>Sort by</option>
                            <option value="Newly added">Newly Added</option>
                            <option value="1 star">1 Star</option>
                            <option value="2 star">2 Star</option>
                            <option value="3 star">3 Star</option>
                            <option value="4 star">4 Star</option>
                            <option value="5 star">5 Star</option>
                          </select>
                        </div>
                      </div>
                      <ReviewList reviewlist={reviewlist} />
                    </div>
                  </div>
                </div>
              </section>
            ) : null}

            <section className="container">
              <div className="row">
                <div className="col-md-12 ">{/* <Pagination /> */}</div>
              </div>
            </section>
            <section className="my-5">
              <div className="container-fluid" style={{ maxWidth: "1200px" }}>
                <div className="row p-4">
                  <div className="col-md-12 Related">
                    <h4>Related Products</h4>
                  </div>
                </div>

                <RelatedProduct />
              </div>
            </section>
            <section>
              <div className="container-fluid" style={{ maxWidth: "1200px" }}>
                <div className="row">
                  <div className="col-md-12 my-2">
                    <div className="service_head">
                      <h6>Reading</h6>
                      <h3 className="mt-4">Other Services</h3>
                    </div>
                  </div>
                </div>
                <OtherService />
              </div>
            </section>
          </>
        )}
        <section>
          <Footer />
        </section>
      </div>
    </>
  );
};
export default Index;
