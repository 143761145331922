import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Blog_img from "../../../Assets/Images/blog.png";
import { Link } from "react-router-dom";
import moment from "moment";
import HttpClient from "../../../utils/HttpClient";

const Blog = (props) => {
  // console.log("blog props",props.blogData);
  let blogData = props.blogData;
  console.log("blogData--", blogData);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }
  
  const loop = [1, 2, 3, 4];

  return (
    <div className="provider_sec">
      <Slider {...settings}>
        {blogData
          ? blogData.map((item, index) => {
              return (
                <div className="px-4 mb-2 test" key={index}>
                  <div className="card blog_box2 mb-2">
                    <Link
                      to={"/blog-details/" + nameToSlug(item.heading) + "/" + item._id}
                      //onClick={window.location.href = "/blog-details/" + item._id}
                    >
                      <img
                        src={HttpClient.IMG_URL + item.image}
                        className="card-img-top"
                        alt="img"
                        // style={{ width: "270px" }}
                      />
                    </Link>
                    <div className="card-body blog_text">
                      <Link
                        to={"/blog-details/" + nameToSlug(item.heading) + "/" + item._id}
                        //onClick={window.location.href = "/blog-details/" + item._id}
                      >
                        <h5>{item.heading}</h5>
                      </Link>
                      <h6>{moment(item.created_on).format("MMMM Do YYYY")}</h6>
                      <p className="card-text" dangerouslySetInnerHTML={{__html: item.content1}}>
                        {/* {item.content1} */}
                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
                      volutpat nibh cursus A, volutpat nibh cursus */}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </Slider>
    </div>
  );
};
export default Blog;
