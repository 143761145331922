

// *********Note *************
// here we change   
//         disabled={false}
//         to   
        // disabled={true ? false : true}
//         in hurry

 


import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Avatar from "../../Assets/Images/avatar.png";
import Subscription from "../Subscription/Index";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { equalTo, onValue, orderByChild, query, ref } from "firebase/database";
import db from "../../Firebase/fbconfig";

const LeftBar = (props) => {
  // const [tab, setTab] = useState("subscription");
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [checkk, setcheckk] = useState(false);

  const [usertype, setusertype] = useState("");
  const [userDataImg, setuserDataImg] = useState("");
  const [unSeenMsg, setUnSeenMsg] = useState(false);
  const msgCounterRef = useRef(null);

  const changeTabHere = (evt) => {
    // props.handleClickTab(evt);
  };
  const tab = props.tab;
  const userdata = reactLocalStorage.getObject("userData");

  useEffect(() => {
    getuser();
    checksub();
    getFirebaseListener();
  }, []);

  const getuser = async () => {
    let data = {};
    console.log("object", data);
    let result = await HttpClient.requestData(
      "seller/" + userdata._id,
      "GET",
      data
    );
    console.log("result user", result);
    if (result && result.status) {
      setusertype(result.data.type);
      result.data.image
        ? setuserDataImg(HttpClient.IMG_URL + result.data.image)
        : setuserDataImg("");
      console.log(usertype);
    } else {
      // setusertype(result.data.type);
    }
  };

  const getFirebaseListener = () => {
    let dbRef = ref(db, `/chatList/${userdata._id}`);
    let dbQuery = query(dbRef, orderByChild('unseen'), equalTo(true));
    msgCounterRef.current = onValue(dbQuery, snapshot => {
      console.log("list of messages", snapshot.val());
      if (snapshot.exists()) {
        setUnSeenMsg(true);
      } else {
        setUnSeenMsg(false);
      }
    })
    // database()
    //   .ref(`/chatList/${userinfo._id}`)
    //   .orderByChild('unseen')
    //   .equalTo(true)
    //   .on('value', snapshot => {
    //     console.log("snapshot", snapshot.val());
    //     if(snapshot.exists()){
    //       let data = Object.values(snapshot.val());

    //       setMsgCount(data.length);
    //     }else{
    //       setMsgCount(0);
    //     }
    //   })
  }

  const checksub = async () => {
    // setLoading(true);
    let sendData = {
      userid: userdata._id,
    };
    // console.log("object", sendData);
    let result = await HttpClient.requestData("userSubs", "POST", sendData);
    console.log("userSubs", result);
    if (result && result.status) {
      if (result.data != null) {
        setcheckk(true);
        console.log(checkk);
      } else {
        setcheckk(false);
        console.log(checkk);
      }
    } else {
    }
  };

  return (
    <div className="seller_LeftBar py-3">
      <div className="text-center">
        <img
          src={userDataImg ? userDataImg : Avatar}
          alt="img"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: "70px",
            height: "70px",
            borderRadius: "50%",
          }}
        />
      </div>
      <div className="border-bottom text-center p-2">
        <h5>{userdata ? userdata.firstName + " " + userdata.lastName : ""}</h5>
        <p>{userdata ? userdata.email : ""}</p>
      </div>
      <div className="d-flex flex-column" style={{ padding: '20px' }}>
        <button
          className={`btn left_menu text-left ${tab === "subscription" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("subscription")}
        >
          <i className="fas fa-print mr-2"></i> Subscription
        </button>
        <button
          className={`btn left_menu text-left  ${tab === "overview" ? "active" : ""
            }`}
          // onClick={() => setDropdown2(!dropdown2)}
          onClick={() => props.handleClickTab("overview")}
          disabled={false}
        >
          <i className="fas fa-columns mr-2"></i> Dashboard
        </button>
        <button
          className={`btn left_menu text-left ${
            tab === "associated" ? "active" : ""
          }`}
          onClick={() => props.handleClickTab("service-request")}
          disabled={false}
        >
          <i className="fas fa-clipboard-check mr-2"></i> Service Request
        </button>
        {/* <button
          className={`btn left_menu text-left ${tab === "service-list" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("service-list")}
          disabled={false}
        >
          <i className="fas fa-shopping-cart mr-2"></i> Service List
        </button>{" "} */}
        <div>
          {/* {dropdown2 ? (
            <div className="d-block d-flex flex-column pl-4">
              <div
                className={`btn left_menu text-left  ${
                  tab === "overview" ? "active" : ""
                }`}
                onClick={() => props.handleClickTab("overview")}
              >
                <i className="fas fa-chevron-right mr-2"></i> Overview
              </div>
              <div
                className={`btn left_menu text-left  ${tab === "activity" ? "active" : ""
                  }`}
                onClick={() => props.handleClickTab("activity")}
              >
                <i className="fas fa-chevron-right mr-2"></i> Activity
              </div>
            </div>
          ) : (
            ""
          )} */}
        </div>{" "}
        {/* <div> */}
          <button
            className="btn left_menu text-left"
            onClick={() => setDropdown1(!dropdown1)}
            disabled={false}
          >
            <i className="fab fa-first-order-alt mr-2"></i> My Orders
          </button>
          {dropdown1 ? (
            <div className="d-block d-flex flex-column pl-4">
              <div
                className={`btn left_menu text-left  ${tab === "openOrder" ? "active" : ""
                  }`}
                onClick={() => props.handleClickTab("openOrder")}
              >
                <i className="fas fa-chevron-right mr-2"></i> Open Orders
              </div>
              <div
                className={`btn left_menu text-left  ${tab === "orderReport" ? "active" : ""
                  }`}
                onClick={() => props.handleClickTab("orderReport")}
              >
                <i className="fas fa-chevron-right mr-2"></i> Order Report
              </div>
              {/* <div
                className={`btn left_menu text-left  ${
                  tab === "completeOrder" ? "active" : ""
                }`}
                onClick={() => props.handleClickTab("completeOrder")}
              >
                <i className="fas fa-chevron-right mr-2"></i> Complete Orders
              </div> */}
            </div>
          ) : (
            ""
          )}
        {/* </div> */}
        <button
          className={`btn left_menu text-left ${tab === "message" ? "active" : ""
            } d-flex flex-row align-items-center justify-content-between`}
          onClick={() => props.handleClickTab("message")}
          disabled={false}
        >
          <div>
            <i className="far fa-comment-dots mr-2"></i> Message
          </div>
          {
            unSeenMsg ?
              <div
                style={{
                  height: 10,
                  width: 10,
                  backgroundColor: 'red',
                  borderRadius: 5
                }}
              />
              :
              null
          }

        </button>
        <button
          className={`btn left_menu text-left ${tab === "cancelorder" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("cancelorder")}
          disabled={false}
        >
          <i className="fab fa-first-order-alt mr-2"></i> Cancel Order
        </button>
        <button
          className={`btn left_menu text-left ${tab === "refund" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("refund")}
          disabled={false}
        >
          <i className="fab fa-first-order-alt mr-2"></i> Refund Claim
        </button>
        <button
          className={`btn left_menu text-left ${tab === "withdraw" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("withdraw")}
          disabled={false}
        >
          <i className="fas fa-rupee-sign mr-2"></i> Withdraw Balance
        </button>
        <button
          className={`btn left_menu text-left ${tab === "comission" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("commission")}
          disabled={false}
        >
          <i className="fas fa-rupee-sign mr-2"></i> Commission
        </button>
        {/* <button
          className={`btn left_menu text-left ${tab === "profile" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("profile")}
          // disabled={true}
          disabled={false}
        >
          <i className="fas fa-user-circle mr-2"></i> My Profile
        </button> */}
        <button
          className={`btn left_menu text-left ${tab === "shop" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("shop")}
          disabled={false}
        >
          <i className="fas fa-shopping-cart mr-2"></i> My Shop
        </button>{" "}
        <button
          className={`btn left_menu text-left ${tab === "seller-service" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("seller-service")}
          disabled={false}
        >
          <i className="fas fa-clipboard-check mr-2"></i>My Services
        </button>{" "}
        {/* <button
          className={`btn left_menu text-left ${
            tab === "myticket" ? "active" : ""
          }`}
          onClick={() => props.handleClickTab("myticket")}
          disabled={false}
        >
          <i className="fas fa-shopping-cart mr-2"></i> My Ticket
        </button>{" "} */}
        
        <button
          className={`btn left_menu text-left ${tab === "services" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("services")}
          disabled={false}
        >
          <i className="fas fa-clipboard-check mr-2"></i>Add Service
        </button>{" "}
        <button
          className={`btn left_menu text-left ${tab === "MyBankDetails" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("MyBankDetails")}
          disabled={false}
        >
          <i className="fas fa-rupee-sign mr-2"></i> My Bank Details
        </button>
        <button
          className={`btn left_menu text-left ${tab === "contact" ? "active" : ""
            }`}
          onClick={() => props.handleClickTab("contact")}
          disabled={false}
        >
          <i className="far fa-comment-dots mr-2"></i> Contact Us
        </button>
        
        {/* <button
          className={`btn left_menu text-left ${
            tab === "WithdrawHistory" ? "active" : ""
          }`}
          onClick={() => props.handleClickTab("WithdrawHistory")}
          disabled={usertype == "Seller" ? false : true}
        >
          <i className="fas fa-rupee-sign mr-2"></i> Withdraw History
        </button> */}
      </div>
    </div>
  );
};
export default LeftBar;
