import React, { useState, useEffect } from "react";

import OrderList from "../../Views/Orders/OrderList";
import OpenOrderList from "./OpenOrderList";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
const Index = () => {
  useEffect(() => {
    // window.scrollTo(0, 0);
    getorderlist();
  }, []);

  const Sendcall = async (val) => {
    console.log(val);
    setorder(val);
    getorderlist();
  }

  const userData = reactLocalStorage.getObject("userData");
  const [order, setorder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderdetails, setorderdetails] = useState({});
  const getorderlist = async () => {
    setLoading(true);
    let data = {};
    let result = await HttpClient.requestData(
      "sellerbookhistory/" + userData._id + "?currency=" + userData.currency,
      "GET",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      // let a = result.data.length > 0 && result.data.filter(it => it.acceptstatus == "accept");
      // console.log("filterdata", a);
      let newArray = result.data.reverse()
      setorder(newArray);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };
  return (
    <div className="My-orders">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row my-3">
            <div className="col-md-12 text-center">
              <h1>Open Orders </h1>
            </div>
          </div>
          <OpenOrderList
            order={order}
            callback={Sendcall}
          />
        </>
      )}
    </div>
  );
};
export default Index;
