import React, { useState, useEffect } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../../utils/HttpClient";
import {
  ref,
  get,
  child,
  limitToLast,
  onChildAdded,
  query,
  orderByKey,
  endAt,
  onValue,
  update,
  off
} from "firebase/database";
import db from "../../../Firebase/fbconfig";


const userData = reactLocalStorage.getObject("userData");

const MessageBody = (props) => {
  console.log("message body",props.accepted);
  const { accepted, rejected, nodeId, sender, roomId } = props;

  // useEffect(() => {
  //   const formatedAbout = new DOMParser().parseFromString(props.details, 'text/html');

  //   document.getElementById("contentDiv").innerHTML = formatedAbout.body.innerHTML;
  // }, [])



  const [readMore, setReadMore] = useState(true);

  const accept = async () => {
    let data = {
      user_id: userData._id,
      serv_id: props.serviceid,
      servicename: props.offername,
      currency: userData.currency,
      price: props.price,
      image: props.image,
      seller_id: props.tosendwhom,
      orderFrom: "web"
    };
    console.log("data", data);
    let result = await HttpClient.requestData("servicecart", "POST", data);
    console.log("result", result);
    if (result && result.status) {
      update(ref(db, `/Chat/${roomId}/messages/${nodeId}`), { accepted: true })
      window.history.pushState(null, "", "/checkoutservice");
      window.location.href = "/checkoutservice";
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const rejectBtn = () => {
    console.log("`/Chat/${roomId}/messages/${nodeId}`", `/Chat/${roomId}/messages/${nodeId}`)
    // alert()
    // update(ref(db, `/Chat/${roomId}/messages/${nodeId}`), { rejected: true })
  };

  return (
    <div className="card chat_card  ">
      <ToastContainer />
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <h6>{props.offername}</h6>
          <h4 className="ml-5">{userData.currency == "INR"
            ? "₹"
            : userData.currency == "CAD"
              ? "$"
              : userData.currency == "USD"
                ? "$"
                : userData.currency == "GBP"
                  ? "£"
                  : "€"} {props.price}</h4>
        </div>
        <h5 className={readMore ? 'readMoreText' : 'readLessText'} >
          {props.details.replace(/<[^>]+>/g, "")}
        </h5>
        <div className="text-right text-muted">
          <a onClick={() => setReadMore(!readMore)} className="text-muted" style={{ cursor: "pointer" }}>
            {
              readMore ?
                'Read More'
                :
                'Read Less'
            }

          </a>
        </div>
        <div>
          <p>
            Offer Time :<span>2 days</span>{" "}
          </p>
        </div>
        {
          accepted ?
            <div>
              <button className="btn card_btn">
                Accepted
              </button>
            </div>
            :
            rejected ?
              <div>
                <button className="btn card_btn bg2">Rejected</button>
              </div>
              :
              !sender ?
                <div className="d-flex">
                  <div>
                    <button className="btn card_btn" onClick={accept}>
                      Accept
                    </button>
                  </div>
                  <div className="mx-3">
                    <button className="btn card_btn bg2" onClick={rejectBtn}>Reject</button>
                  </div>
                </div>
                :
                null
        }
      </div>
    </div>
  );
};
export default MessageBody;
