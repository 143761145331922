import React, { useEffect, useState } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

// import routes from "../route";
import Header from "../Defaults/Header";
import Footer from "../Defaults/Footer";
import { reactLocalStorage } from "reactjs-localstorage";
import { PublicRoute, PrivateRoute } from "../route";
import {
  ref,
  get,
  set,
  child,
  limitToLast,
  onChildAdded,
  query,
  orderByKey,
  endAt,
  onValue,
  update,
} from "firebase/database";
import db from "../Firebase/fbconfig";
import AudioModal from "../Views/Messages/ChatBody/AudioModal";
import VideoModal from "../Views/Messages/ChatBody/VideoModal";

const Index = (props) => {
  const [login, setLogin] = useState(
    reactLocalStorage.getObject("loginstatus")
  );
  const myUserData = reactLocalStorage.getObject("userData");
  const [call, setCall] = useState(false);
  const [videoCall, setVideoCall] = useState(false)
  const [callData, setCallData] = useState({})

  useEffect(() => {
    if (login) {
      onChildAdded(ref(db, `/call/${myUserData._id}`), snapshot => {
        // console.log("snapshot", snapshot.val())
        if (snapshot.exists()) {
          let fbData = snapshot.val()
          // console.log("fbData", fbData)
          if (fbData.type == 'Incomming' && !fbData.endStatus) {
            // console.log("Layout clg")
            setCallData(fbData);

            if (fbData.videoCall == true) {
              setVideoCall(true)
            } else {
              setCall(true)
            }

          }
        }
      })
    }
  }, [])

  const callBackcall = (val) => {
    setCall(false);
    // setactivemodal({});
  };

  const callBackcall1 = () => {
    setVideoCall(false)
  }

  // console.log("loginstatus", login);

  return (
    <div>
      {/* {
        console.log("callData", callData)
      } */}
      <Header />

      {login && typeof login == "boolean" ? (
        <Switch>
          {PrivateRoute.map((route, index) => {
            return route.component ? (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
          
                render={(props) => <route.component {...props} />}
              />
            ) : null;
          })}
          <Redirect to="/home" from="/" />
        </Switch>
      ) : (
        <Switch>
          {PublicRoute.map((route, index) => {
            return route.component ? (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={(props) => <route.component {...props} />}
              />
            ) : null;
          })}
          <Redirect to="/home" from="/" />
        </Switch>
      )}
      {/* <Footer /> */}

      {
        call ?
          <div
            className={"modal fade loginmodal show"}
            id="exampleModal1"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            // style={{ display: call === "audio" ? "block" : "none" }}
            style={{ display: 'block' }}
          >
            {/* {Object.keys(activemodal).length == 0 ? null : ( */}
            <AudioModal
              remoteData={callData}
              type="Incomming"
              callBackcall1={callBackcall}
            />
          </div>
          :
          null
      }

      {
        videoCall ?
          <div
            className="modal fade single_modal show videoModal"
            id="exampleModal1"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <VideoModal
              remoteData={callData}
              type="Incomming"
              callBackcall1={callBackcall1}
            />
          </div>
          :
          null
      }

    </div>
  );
};
export default Index;
