import React, { useState } from "react";
import BlankOrder from "./BlankOrder";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
const OrderList = (props) => {
  const [active,setActive]= useState("")
  
  const userData = reactLocalStorage.getObject("userData");
  console.log("orderid", props.order);
  const orderdertail = (item) => {
    // console.log("object",item);
    window.scrollTo(0, 0);
    let countInr = props.count + 1;
    props.sendata(item, countInr);
  };
  const ActiveBar = (id)=>{
setActive(id)
  }
  const styles = {
    cursor: "pointer",
  };
  return (
    <div className="order_list">
      <div className="row">
        {props.order.length > 0 ? (
          props.order.map((item, index) => {
            return (
              <div
                className="col-md-12"
                style={styles}
                key={index}
                onClick={() => orderdertail(item)}
              >
                <div className={active ==item._id?"border-bottom p-3 highlight" :"border-bottom p-3 "} onClick={()=>ActiveBar(item._id)}>
                  <h5>Order Number : {item._id ? item._id : ""}</h5>
                  <div className="d-flex justify-content-between">
                    <p>
                      <span>
                        Order On:{" "}
                        {moment(item.cart_data[0].buy_date).format("D-MM-YYYY")}
                      </span>
                      <br></br>
                      {}
                    </p>
                    <p>
                      <span>Total Amount:</span>
                      <br></br>
                      {/* ₹  */}
                      {userData.currency == "INR"
                        ? "₹"
                        : userData.currency == "CAD"
                        ? "$"
                        : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "	GBP"
                        ? "£"
                        : userData.currency == "EUR"
                        ? "€"
                        : "₹"}
                      {item.order_subtotal.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <BlankOrder />
        )}
      </div>
    </div>
  );
};
export default OrderList;
