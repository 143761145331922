import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { toast } from "react-toastify";
import validator from "validator";
import { MDBDataTableV5 } from "mdbreact";
import Warning from "../../Component/Warning";
import { reactLocalStorage } from "reactjs-localstorage";
import Success from "../../Component/Success";

const Contact = () => {
  let userData = reactLocalStorage.getObject("userData");
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [category, setCategory] = useState([]);
  const [data, setdata] = useState([]);
  const [subcat, setsubcat] = useState([]);
  const [issue, setIssue] = useState("");
  const [cat, setcat] = useState([]);
  const [blankemail, setblankemail] = useState(false);
  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);
  const [emailerror, setemailerror] = useState(false);

  const [emailvalid, setemailvalid] = useState(false);
  const [warning, setwarning] = useState(false);
  const [successs, setsuccesss] = useState(false);
  const [subcategory, setSubcategory] = useState("");

  useEffect(() => {
    fetchCat();
    getContactUs();
  }, []);

  const getContactUs = async () => {
   
    let result = await HttpClient.requestData("sellercontact/" + userData._id, "GET");
    console.log("sellercontact get ---", result);
    if (result && result.status) {
      // let newarr = result.data.filter((item) => item.email == userData.email);
      // console.log("new---",newarr);
      let data = [];
      let i = 1;
      result.data.forEach((element, index) => {
        let rows = {
          sl: i,

          name: element.name,

          complain_email: element.email,
          desc: element.message,
          replyans: element.reply ? element.reply : "",
        };
        i++;
        data.push(rows);
      });

      setdata(data);
      // setTable(true);
    } else {
      setdata([]);
    }
  };

  const fetchCat = async () => {
    let result = await HttpClient.requestData("service", "GET");
    // console.log("Cat", result);
    if (result && result.status) {
      let data = result.data.filter((item) => item.status == true);
      // console.log("data", data);
      setcat(data);
      // console.log("setcat", cat);
    } else {
    }
  };

  const handleSubmit = async () => {
    if (
      name != "" &&
      email != "" &&
      category != "" &&
      subcategory != "" &&
      issue != ""
    ) {
      if (isEmailFromatValid) {
        setisEmailFromatValid(true);
      } else {
        let sendData = {
          name: name,
          email: email,
          cat_id: category,
          subcat_id: subcategory,
          message: issue,
          seller_id: userData._id
        };
        console.log("data", sendData);
        let result = await HttpClient.requestData(
          "sellercontact",
          "POST",
          sendData
        );
        console.log("sellercontact", result);
        if (result && result.status) {
          toast.success("Submit successfully", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setName("");
          setemail("");
          setCategory("");
          setSubcategory("");
          setIssue("");
          getContactUs();
        } else {
          toast.error("Already Submitted", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      toast.warning("Please Fill up all the fields", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handlecategory = (event) => {
    const selectedcategory = event.target.value;
    setCategory(selectedcategory);
    alreadyfetchSubCat(event.target.value);
  };
  const alreadyfetchSubCat = async (id) => {
    let result = await HttpClient.requestData(
      "service/subcategory/" + id,
      "GET"
    );
    // console.log("SubCat", result);
    if (result && result.status) {
      let data = result.data;
      // console.log("subdata", data);
      setsubcat(data);
      // console.log("SubCatdata", subcat);
    } else {
    }
  };
  const handleSubcategory = (event) => {
    console.log("opopo", event.target.value);
    const selectedSubcategory = event.target.value;
    setSubcategory(selectedSubcategory);
  };

  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    console.log(valid);
    setisEmailFromatValid(valid ? false : true);
  }

  const dataa = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        width: 150,
      },

      {
        label: "Name",
        field: "name",
        width: 270,
      },
      {
        label: "Email",
        field: "complain_email",
        width: 270,
      },
      {
        label: "Query",
        field: "desc",
        width: 270,
      },
      {
        label: "Reply",
        field: "replyans",
        width: 270,
      },
    ],
    rows: data,
  };

  return (
    <div className="Add_service  ">
      <div className="row mt-3">
        <div className="col-md-12 text-center">
          <h1>Contact Us</h1>
        </div>
        <div className="col-md-8 mx-auto">
          <div className="form-group my-4">
            <input
              type="text"
              className="form-control _borderRed"
              id="exampleInput"
              aria-describedby="emailHelp"
              placeholder="Your Name"
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  setName(val.target.value);
                }
              }}
              value={name}
            />
          </div>

          <div className="form-group my-4">
            <input
              type="email"
              className="form-control _borderRed"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(val) => {
                setemail(val.target.value);
                if (val.target.value == "") {
                  setblankemail(true);
                  validateEmail(val.target.value);
                } else {
                  setblankemail(false);
                  validateEmail(val.target.value);
                }
              }}
            />
            {blankemail ? (
              <small style={{ color: "red" }}> Please enter email. </small>
            ) : isEmailFromatValid ? (
              <small style={{ color: "red" }}>
                {" "}
                Please enter a valid email.{" "}
              </small>
            ) : null}
          </div>
          <div className="form-group my-4">
            <select
              className="form-control _borderRed"
              id="exampleFormControlSelect1"
              onChange={handlecategory}
              value={category}
            >
              <option value="">Select Catagory</option>
              {cat.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group my-4">
            <select
              className="form-control _borderRed"
              id="exampleFormControlSelect1"
              onChange={handleSubcategory}
              value={subcategory}
            >
              <option>Select Sub Catagory</option>
              {subcat.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group my-4">
            <textarea
              className="form-control _borderRed"
              id="exampleFormControlTextarea1"
              rows={3}
              placeholder="Write here.."
              onChange={(val) => {
                // if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                setIssue(val.target.value);
                //}
              }}
              value={issue}
            />
          </div>

          <div className="d-flex justify-content-between my-5">
            <button
              // type="button"
              className="btn publish_btn"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="col-md-12 text-center mt-5">
          <div className="col-md-12 text-center">
            <h1>Contact Us Detail</h1>
          </div>
          <br />
          <br />
          <div className="col-md-8 mx-auto">
            <MDBDataTableV5
              hover
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              data={dataa}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
