import React, { useState, useEffect, useRef } from "react";
// import EditBudgetModal from "./EditBudgetModal";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

const Filter = (props) => {
  console.log("sevfil", props);
  const ref = useRef();
  const userData = reactLocalStorage.getObject("userData");
  // const [budget, setBudget] = useState(false);
  const [service, setService] = useState([]);
  const [price, setprice] = useState(props.priceby);
  const [name, setname] = useState("");
  const [catid, setCatid] = useState("");
  const [search, setsearch] = useState("");
  const [rate, setRate] = useState(props.rat);
  const [sortBy, setSortBy] = useState(props.short);
  const [minn, setminn] = useState("");
  const [maxx, setmaxx] = useState("");

  const [options, setoptions] = useState([
    // { label: "Best Selling", value: "bestselling" },
    // { label: "New Arrivals", value: "newarrivals" },
    // { label: "Top Customer Reviews", value: "highlowrev" },
    // { label: "Total Sales : Low to High", value: "lowhigh" },
    // { label: "Total Sales : High to Low", value: "highlow" },
    // { label: "Price : High to Low", value: "highlowsell" },
    // { label: "Price : Low to High", value: "lowhighsell" },
    // { label: "Rating : High to Low", value: "highlowrev" },

    { label: "New Arrivals", value: "newarrivals" },
    { label: "Best Selling", value: "bestselling" },
    { label: "Total Sales : Low to High", value: "lowhighsell" },
    { label: "Total Sales : High to Low", value: "highlowsell" },
    { label: "Price : High to Low", value: "highlow" },
    { label: "Price : Low to High", value: "lowhigh" },
    { label: "Top Customer Reviews", value: "highlowrev" },
  ]);

  const [rating, setrating] = useState([
    { label: "Hight to low", value: "highlowrev" },
    { label: "Low to high", value: "lowhighrev" },
  ]);
  const [servicearr, setservicearr] = useState([]);

  // const callBackBudget = (val) => {
  //   setBudget(val);
  // };
  useEffect(() => {
    fetchServiceCat();
  }, []);

  const fetchServiceCat = async () => {
    let result = await HttpClient.requestData("service", "GET");
    // console.log("Service", result);
    if (result && result.status) {
      let data = result.data;
      // console.log("data",data);
      setService(data);
      // console.log("Servicedata", service);
    } else {
    }
  };

  const fetchServiceSubcat = async (id) => {
    // console.log("idd", id);
    // let result = await HttpClient.requestData(
    //   "service/shop-services/" + id,
    //   "GET"
    // );
    let result = service.filter((item) => item._id === id);
    if (result && result.length > 0) {
      let data = result[0].service_data;
      // console.log("SubCat data", data);
      setservicearr(data);
    } else {
    }
  };

  const handleService = (event) => {
    console.log("event object event", event.target.value);
    setCatid(event.target.value);
    fetchServiceSubcat(event.target.value);
  };

  const handleSubcategory = async (event) => {
    // setname(event.target.value);
    // let _id = event.target.value;
    // let sendData = "1";
    console.log("fgff", event.target.value);
    let result = await HttpClient.requestData(
      "service/shop-services/" + event.target.value + "/" + props.pagevalue,
      "GET"
    );
    console.log("object data get by result", result);
    console.log("props.pagevalue", props.pagevalue);

    if (result && result.status) {
      let data = [];
      props.catName(result.category_data.name);

      if (result.data.itemsList.length > 0) {
        data = result.data.itemsList.filter(
          (item) => item.shop_details[0].userid !== userData._id
        );
        // console.log("object data get by data", data);
      } else {
        data = [];
      }
      props.serviceFilter(result.data.itemsList);
    } else {
      props.serviceFilter(result.data.itemsList);
    }
  };

  const handlePrice = async (event) => {
    // setprice(event.target.value);
    let val = event.target.value;
    setprice(val);
    let min = 0;
    let max = 0;

    if (event.target.value == "0-50") {
      min = 0;
      max = 50;
    } else if (event.target.value == "51-100") {
      min = 51;
      max = 100;
    } else if (event.target.value == "101-150") {
      min = 101;
      max = 150;
    } else if (event.target.value == "above151") {
      min = 151;
      max = 10000;
    } else {
      min = 0;
      max = 10000;
    }

    setminn(min);
    setmaxx(max);
    props.parentmin({ min: min, max: max });
    // props.parentmin({"max":max})

    // let data = {
    //   // category_id: id,
    //   providername: search ? search : "",
    //   serv_cat_name: "",
    //   servicename: name ? name : "",
    //   min: min,
    //   max: max,
    //   shortby: sortBy ? sortBy : "",
    // };
    console.log("min", min);
    console.log("max", max);
    // if (Object.keys(userData).length > 0) {
    //   let result = await HttpClient.requestData(
    //     "service/shop-services/" +
    //       userData._id +
    //       "/" +
    //       props.catid +
    //       "/" +
    //       props.pagevalue +
    //       "?currency=" +
    //       userData.currency +
    //       "&min=" +
    //       min +
    //       "&max=" +
    //       max +
    //       "&shortby=" +
    //       "",
    //     "GET"
    //   );
    //   console.log("service searchSevice price", result);
    //   if (result && result.status) {
    //     props.serviceFilter(result.data.itemsList, result.data.paginator);
    //   } else {
    //     props.serviceFilter(result.data.itemsList, result.data.paginator);
    //   }
    // } else {
    //   let result = await HttpClient.requestData(
    //     "service/shop-services/" +
    //       "91a953b1effa7e26d08fff8a" +
    //       "/" +
    //       props.catid +
    //       "/" +
    //       props.pagevalue +
    //       "?currency=" +
    //       "INR" +
    //       "&min=" +
    //       min +
    //       "&max=" +
    //       max +
    //       "&shortby=" +
    //       "",
    //     "GET"
    //   );
    //   console.log("service searchSevice price", result);
    //   if (result && result.status) {
    //     props.serviceFilter(result.data.itemsList, result.data.paginator);
    //   } else {
    //     props.serviceFilter(result.data.itemsList, result.data.paginator);
    //   }
    // }
    searchByFilter(min, max, sortBy, rate);
  };

  const sortByRate = (event) => {
    console.log("hhhh", event.target.value);
    // setSortBy(event.target.value);
    // setRate(event.target.value);
    setRate(event.target.value);
    props.rate({ rate: event.target.value });
    searchByFilter(minn, maxx, sortBy, event.target.value);
    // }, 100);
  };

  const sortByChoose = async (event) => {
    console.log("kolkl", event.target.value);
    props.sortby({ sort: event.target.value });
    // let data = {
    //   providername: search ? search : "",
    //   servicename: name ? name : "",
    //   serv_cat_name: "",
    //   min: "",
    //   max: "",
    //   shortby: event.target.value ? event.target.value : "",
    // };
    // console.log("sort data", data);
    // if (Object.keys(userData).length > 0) {
    //   let result = await HttpClient.requestData(
    //     "service/shop-services/" +
    //       userData._id +
    //       "/" +
    //       props.catid +
    //       "/" +
    //       props.pagevalue +
    //       "?currency=" +
    //       userData.currency +
    //       "&min=" +
    //       "" +
    //       "&max=" +
    //       "" +
    //       "&shortby=" +
    //       event.target.value,
    //     "GET"
    //   );
    //   console.log("service sortByChoose", result);
    //   if (result && result.status) {
    //     props.serviceFilter(result.data.itemsList, result.data.paginator);
    //   } else {
    //     props.serviceFilter(result.data.itemsList, result.data.paginator);
    //   }
    // } else {
    //   let result = await HttpClient.requestData(
    //     "service/shop-services/" +
    //       "91a953b1effa7e26d08fff8a" +
    //       "/" +
    //       props.catid +
    //       "/" +
    //       props.pagevalue +
    //       "?currency=" +
    //       "INR" +
    //       "&min=" +
    //       "" +
    //       "&max=" +
    //       "" +
    //       "&shortby=" +
    //       event.target.value,
    //     "GET"
    //   );
    //   console.log("service sortByChoose", result);
    //   if (result && result.status) {
    //     props.serviceFilter(result.data.itemsList, result.data.paginator);
    //   } else {
    //     props.serviceFilter(result.data.itemsList, result.data.paginator);
    //   }
    // }
    setSortBy(event.target.value);
    searchByFilter(minn, maxx, event.target.value, rate);
  };

  const searchByFilter = async (min, max, short, rateby) => {
    // let data = {
    //   providername: search ? search : "",
    //   servicename: name ? name : "",
    //   serv_cat_name: "",
    //   min: "",
    //   max: "",
    //   shortby: sortBy ? sortBy : "",
    // };
    if (Object.keys(userData).length > 0) {
      let result = await HttpClient.requestData(
        "service/shop-services/" +
          userData._id +
          "/" +
          props.catid +
          "/" +
          props.pagevalue +
          "?currency=" +
          userData.currency +
          "&min=" +
          min +
          "&max=" +
          max +
          "&shortby=" +
          short +
          `&rateby=` +
          rateby,
        "GET"
      );
      console.log(
        "service searchByFilter",
        "service/shop-services/" +
          userData._id +
          "/" +
          props.catid +
          "/" +
          props.pagevalue +
          "?currency=" +
          userData.currency +
          "&min=" +
          min +
          "&max=" +
          max +
          "&shortby=" +
          short +
          `&rateby=` +
          rateby
      );
      if (result && result.status) {
        props.serviceFilter(result.data.itemsList, result.data.paginator);
      } else {
        props.serviceFilter(result.data.itemsList, result.data.paginator);
      }
    } else {
      let result = await HttpClient.requestData(
        "service/shop-services/" +
          "91a953b1effa7e26d08fff8a" +
          "/" +
          props.catid +
          "/" +
          props.pagevalue +
          "?currency=" +
          "INR" +
          "&min=" +
          min +
          "&max=" +
          max +
          "&shortby=" +
          short +
          `&rateby=` +
          rateby,
        "GET"
      );
      console.log("service searchByFilter", result);
      if (result && result.status) {
        props.serviceFilter(result.data.itemsList, result.data.paginator);
      } else {
        props.serviceFilter(result.data.itemsList, result.data.paginator);
      }
    }
  };

  const searchbyname = async () => {
    // let data = {
    //   providername: search ? search : "",
    //   servicename: name ? name : "",
    //   serv_cat_name: "",
    //   min: "",
    //   max: "",
    //   shortby: sortBy ? sortBy : "",
    // };

    console.log("name", search);
    console.log("catid", props.catid);
    let result = await HttpClient.requestData(
      "service/shop-services/" +
        userData._id +
        "/" +
        props.catid +
        "/" +
        props.pagevalue +
        "?servicename=" +
        search,
      "GET"
    );
    console.log("service", result);
    if (result && result.status) {
      props.serviceFilter(result.data.itemsList);
    } else {
      props.serviceFilter(result.data.itemsList);
    }
  };

  return (
    <div className="d-lg-flex justify-content-between py-1 filter_part">
      <div className="d-lg-flex">
        {/* <div className="my-3 my-md-0 mx-2">
          <div className="form-group">
            <select
              className="form-control"
              id="category"
              // value={id}
              onClick={handleService}
            >
              <option selected>Select Service Category</option>

              {service.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div> */}
        {/* <div className="my-3 my-md-0 mx-2">
          <div className="form-group">
            <select
              className="form-control"
              id="subcat"
              // value={name}
              onChange={handleSubcategory}
            >
              <option className="option_sec " selected>
                Select Services
              </option>

              {servicearr.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div> */}
        <div className="my-3 my-md-0 mx-2">
          <div className="form-group">
            <select
              className="form-control"
              id="price"
              onChange={handlePrice}
              value={price}
            >
              <option selected>Select Price</option>
              <option value="0-50">
                {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}{" "}
                0 -{" "}
                {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}{" "}
                50
              </option>
              <option value="51-100">
                {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}{" "}
                51 -{" "}
                {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}{" "}
                100{" "}
              </option>
              <option value="101-150">
                {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}{" "}
                101 -{" "}
                {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}{" "}
                150
              </option>
              <option value="above151">
                more then{" "}
                {userData.currency == "INR"
                  ? "₹"
                  : userData.currency == "CAD"
                  ? "$"
                  : userData.currency == "USD"
                  ? "$"
                  : userData.currency == "GBP"
                  ? "£"
                  : userData.currency == "EUR"
                  ? "€"
                  : "₹"}{" "}
                151
              </option>
            </select>
          </div>
        </div>
        <div className="my-3 my-md-0 mx-2">
          <div className="form-group">
            <select
              className="form-control"
              id="rate"
              onChange={sortByRate}
              value={rate}
            >
              <option selected>Rating</option>

              {rating.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {/* <div className="mx-3"> */}{" "}
        {/* <button
            type="button"
            className="btn budget_btn"
            onClick={() => setBudget(true)}
          >
            Edit Price
          </button> */}
        {/* </div> */}
      </div>
      <div className="px-2">
        {/* <form className="d-flex align-items-center service-search my-3 my-md-0">
          <input
            className="form-control border-0 h-100"
            type="search"
            placeholder="Search Service "
            aria-label="Search"
            value={search}
            onChange={(val) => setsearch(val.target.value)}
            style={{ minWidth: "172px" }}
          />
          <span>
            <button
              className="btn pr-1 pl-0"
              type="button"
              onClick={searchbyname}
            >
              <i className="fas fa-search  align-self-center "></i>
            </button>
          </span>
        </form> */}
      </div>
      <div>
        <div className="px-2">
          <div className="form-group">
            {/* <h6 className="mr-3 my-2">SortBy:</h6> */}
            <select
              className="form-control"
              id="sortBy"
              onChange={sortByChoose}
              value={sortBy}
            >
              <option selected>Services Sorting</option>

              {options.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
          {props.page === "services" ? (
            <p className="mb-0 mb-n3 mt-1 small text-black-50 text-right">
              {props.count} Results
            </p>
          ) : null}
        </div>
      </div>
      {/* <div
        className={budget ? "modal fade loginmodal show" : "modal fade"}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: budget ? "block" : "none" }}
      >
        <EditBudgetModal callBackBudget={callBackBudget} />
      </div> */}
    </div>
  );
};
export default Filter;
