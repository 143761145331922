import React, { useState, useEffect } from "react";
import BlankOrder from "../../Views/Orders/BlankOrder";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import { Row, Card, CardBody, Col, Input, Button } from "reactstrap";
import ChatWithPersonel from "./chatwithpersonel";
import ChatWithBuyer from "./chatwithbuyer";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  getDatabase,
  ref,
  push,
  set,
  onValue,
  get,
  onChildAdded,
  child
} from "firebase/database";
import db from "../../Firebase/fbconfig";
import { v4 as uuidv4 } from "uuid";

const OpenOrderList = (props) => {
  const userData = reactLocalStorage.getObject("userData");
  const [isOpenmodal, setisopenmodal] = useState(false);
  const [isOpenmodalbuyer, setisopenmodalbuyer] = useState(false);
  const [orderid, setorderid] = useState("");
  const [firebaseData, setfirebaseData] = useState([]);
  const [name, setname] = useState("");
  const [servid, setservid] = useState("");

  console.log("props.order", props.order);
  useEffect(() => {
    checklist();
  }, []);

  const checklist = async () => {
    const dbRef = getDatabase();
    const starCountRef = ref(dbRef, "PersonelSellerchat/");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      let arr = [];
      // console.log("data", data);
      if (snapshot.exists()) {
        snapshot.forEach((ele) => {
          // console.log("ele", ele.val());
          let dom = {
            id: ele.val(),
          };
          // console.log(dom);
          arr.push(dom);
          // console.log("arr", arr);
        });
        setfirebaseData(arr);
      }
    });
  };

  const orderdertail = (item) => {
    // console.log("object",item);
    props.sendata(item);
  };
  const createchatList = (sellerId,serviceData) =>{
    console.log('userData._id',userData._id,serviceData)
      const dbRef = ref(db);
      get(
        child(
          dbRef,
          `/chatList/${userData._id}/${sellerId}`
        )
      )
        .then((snapshot) => {
          if (snapshot.exists()) {
            // setnewchat(item);
            // setnochatopen(true);
            console.log(snapshot.val());
            setTimeout(() => {
              window.location.href = `/messages/${snapshot.val().roomId}`;
            }, 100);
          } else {
            // setnewchat(item);
            // setnochatopen(true);
            let roomId = uuidv4();
            console.log("No data available");
            // console.log(`/chatList/${userData._id}/${item._id}`);
            let fbdata ={
              name:serviceData?.firstName+' '+serviceData?.lastName,
              displayName: serviceData?.firstName,
              userId: sellerId,
              roomId: roomId,
            }
            console.log('fbdata',fbdata)
            set(
              ref(
                db,
                `/chatList/${userData._id}/${sellerId}`
              ),
              fbdata
            );
            set(
              ref(
                db,
                `/SellerToSellerchatList/${userData._id}/${sellerId}`
              ),
              {
                name: userData.firstName + " " + userData.lastName,
                displayName: userData.firstName,
                userId: userData._id,
                roomId: roomId,
              }
            );
            setTimeout(() => {
              window.location.href = `/messages/${roomId}`;
            }, 100);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      // setTimeout(() => {
      //   window.location.href = "/messages";
      // }, 100);
  }
  const viewOrderDetail = async (id) => {
    setTimeout(() => {
      window.location.href = "/view-order-details/" + id;
    }, 10);
  };
  // const handleAccept = async (id) => {
  //   let result = await HttpClient.requestData(
  //     "complete-service-request/" + id,
  //     "PUT"
  //   );
  //   console.log("status", result);
  //   if (result && result.status) {
  //     toast.success("Service Completed successfully", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     // getorderlist();
  //     props.callback(props.order);
  //   } else {
  //     toast.error("Error", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // };

  const Callbackmsg = async (val) => {
    setisopenmodal(false);
  };

  const Callbackmsg1 = async (val) => {
    setisopenmodalbuyer(false);
  };

  return (
    <div className="order_list">
      <div className="row">
        {props.order.length > 0 ? (
          props.order.map((item, index) => {
            return (
              <div
                className="col-md-12 "
                key={index}
                //onClick={() => viewOrderDetail(item._id)}
                // onClick={() => orderdertail(item)}
              >
                <div className="border-bottom p-3 highlight">
                  <h5>Order Number : {item.order_id}</h5>
                  {/* <h6>Service Name: {item.servicename}</h6> */}

                  <div className="d-flex">
                    <img
                      src={HttpClient.IMG_URL + item.image}
                      className="mr-4"
                      alt="img"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "6px",
                      }}
                    />
                    <h6>
                      Service Name: {item.servicename} (
                      {item.service_data[0].stype
                        ? item.service_data[0].stype
                        : "Custom"}
                      )
                    </h6>
                  </div>
                  <h6>
                    Price: 
                    {userData.currency == "INR"
                      ? "₹"
                      : userData.currency == "CAD"
                      ? "$"
                      : userData.currency == "USD"
                      ? "$"
                      : userData.currency == "	GBP"
                      ? "£"
                      : userData.currency == "EUR"
                      ? "€"
                      : "₹"}{" "}
                    {/* {item.service_data[0].price} */}
                    {item.discountedPrice}
                    
                    {/* {item.price} */}
                  </h6>
                  <div className="d-flex justify-content-between">
                    <p>
                      <span>
                        Order On:{" "}
                        {moment(item.booking_date).format("D-MM-YYYY")}
                      </span>
                    </p>

                    {/* <p>
                      <span>Total Amount:</span> <b>₹ {item.price}</b>
                    </p> */}
                    <div>
                      <Button
                        
                        type="button"
                        className="btn-sm _openbtn"
                        onClick={() => viewOrderDetail(item._id)}
                      >
                        View Details
                      </Button>
                    </div>
                    {/* <div>
                      <Button
                        color={item.completestatus ? "success" : "primary"}
                        type="button"
                        className="btn-sm"
                        onClick={() => {
                          if (item.completestatus == true) {
                          } else {
                            handleAccept(item._id);
                          }
                        }}
                      >
                        {item.completestatus
                          ? "Service Completed"
                          : "Complete Service"}
                      </Button>
                    </div> */}
                    <div className="_btn_width">
                      {firebaseData.length > 0 &&
                        firebaseData.map((it, index) => {
                          // console.log("firebaseid", it.id.order_id);
                          // console.log("uu1", item.order_id);
                          if (it.id.order_id == item.order_id) {
                            return (
                              <Button
                               
                                type="button"
                                className="btn-sm _openbtn"
                                onClick={() => {
                                  setisopenmodal(!isOpenmodal);
                                  setorderid(item.order_id);
                                }}
                              >
                                Chat with Admin
                              </Button>
                            );
                          }
                        })}
                    </div>
                    <div className="_btn_width">
                      <Button
                       
                        type="button"
                        className="btn-sm _openbtn"
                        onClick={() => {
                          // setisopenmodalbuyer(!isOpenmodal);
                          createchatList(item.user_data[0]._id,item.user_data[0])
                          setorderid(item.order_id);
                          setname(
                            item.user_data[0].firstName +
                              " " +
                              item.user_data[0].lastName
                          );
                          setservid(item.serv_id);
                        }}
                      >
                        Chat with Buyer
                      </Button>
                    </div>
                  </div>
                </div>
                {isOpenmodal ? (
                  <ChatWithPersonel
                    status={true}
                    // orderdetails={sentorderdetails}
                    orderid={orderid}
                    onCloseCallBack={Callbackmsg}
                    image=""
                  />
                ) : null}

                {isOpenmodalbuyer ? (
                  <ChatWithBuyer
                    status={true}
                    // orderdetails={sentorderdetails}
                    name={name}
                    orderid={orderid}
                    onCloseCallBack1={Callbackmsg1}
                    image=""
                    servid={servid}
                  />
                ) : null}
              </div>
            );
          })
        ) : (
          <BlankOrder />
        )}
      </div>
    </div>
  );
};
export default OpenOrderList;
