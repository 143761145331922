import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Defaults/Footer";
import HttpClient from "../../utils/HttpClient";
import { ReactTitle } from "react-meta-tags";

const MoreProvider = () => {
  const [spellCastingData, setspellCastingData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getSpellCastingData();
  }, []);
  const getSpellCastingData = async () => {
    let result = await HttpClient.requestData("service", "GET");
    console.log("spellCasting data--", result);
    if (result && result.status) {
      let data = result.data.filter(
        (item) => item._id === "6204f6f56dcb6904acb6a894"
      );
      setspellCastingData(data[0].service_data);
    }
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }
  const loop = [1, 2, 3, 4, 5, 6];
  return (
    <div>
      <div className="provider_sec">
      <ReactTitle title="We offer Spell Casting | Astrophy" />
        <div className="container-fluid px-lg-4" style={{ maxWidth: "1200px" }}>
          <div className="row my-4">
            <div className="col-md-12 text-center more-pell">
              <h1>We offer Spell Casting</h1>
            </div>
          </div>

          <div className="row px-4">
            {spellCastingData.length > 0
              ? spellCastingData.map((item, index) => {
                  return (
                    <div className="col-md-3 mb-3" key={index}>
                      <div className="card text-center service p-2 my-3">
                        <div className="card-body service_part">
                          <img
                            src={HttpClient.IMG_URL + item.image}
                            alt="img"
                          />
                          <h5 className="card-title my-3">{item.name}</h5>
                          <p className="card-text">
                            {item.description.substring(0, 85)}
                          </p>
                          {/* <Link to={"/services/" + item.id}> */}
                          <button
                            className="btn"
                            onClick={() =>
                              (window.location.href =
                                "/services/" + nameToSlug(item.name) + "/" + item._id)
                            }
                          >
                            View More
                          </button>
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default MoreProvider;
