import React, { useState, useEffect } from "react";
// import Table from "./Table";
import { MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Details from "./Details";
const Comission = () => {
  const [table, setTable] = useState(false);
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [disbale, setdisbale] = useState(false);
  const userData = reactLocalStorage.getObject("userData");
  const [processing_fee, setprocessing_fee] = useState(0);
  const [dataa, setdata] = useState([]);

  useEffect(() => {
    fetchcount();
    getcomission();
  }, []);

  const fetchcount = async () => {
    let dataa = {
      datefrom: "",
      dateto: "",
      last_month: "",
      yesterday: "",
      today: "today",
      id: userData._id,
      currencyy: userData.currency,
    };
    // let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "getSellersettlement",
      "POST",
      dataa
    );
    console.log("getGraphcomission", result.processingfees.processing_fees);
    if (result) {
      setprocessing_fee(result.processingfees.processing_fees);
      console.log("processing_fee", processing_fee);
      // getcomission(result.processingfees.processing_fees);
    } else {
    }
  };

  const getcomission = async () => {
    let result = await HttpClient.requestData(
      "sellercomission/" + userData._id,
      "GET",
      data
    );
    console.log("result commission", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      result.data.forEach((element, index) => {
        let sellerCommision = Math.abs(
          element.seller_commission -
            (element.seller_commission * processing_fee) / 100
        ).toFixed(2);
        let curr = element.seller_data[0].currency == "INR" ? "₹" : "";
        console.log("sellerCommision", sellerCommision);
        let rows = {
          sl: i,

          order_id: element.order_id,
          seller_commission:
            (element.seller_data[0].currency == "INR"
              ? "₹"
              : element.seller_data[0].currency == "CAD"
              ? "$"
              : element.seller_data[0].currency == "USD"
              ? "$"
              : element.seller_data[0].currency == "GBP"
              ? "£"
              : element.seller_data[0].currency == "EUR"
              ? "€"
              : "₹") + Number(sellerCommision),
              service_name: element.service_data.length ? element.service_data[0].name : "",
          image: (
            <img
              src={HttpClient.IMG_URL + element.image}
              width="50"
              height="50"
            />
          ),
          txnid: element.txnid,
          action : ( <button className="btn btn-primary btn-sm">action</button>)
        };
        i++;
        data.push(rows);
      });

      setdata(data);
      setTable(true);
    } else {
      setdata([]);
    }
  };

  const data = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        width: 150,
      },
      {
        label: "Order Id",
        field: "order_id",
        width: 270,
      },
      {
        label: "Service Name",
        field: "service_name",
        width: 270,
      },
      {
        label: "Seller Commission",
        field: "seller_commission",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        width: 270,
      },
      // {
      //   label: "Image",
      //   field: "image",
      //   width: 270,
      // },
      // {
      //   label: "Transaction Id",
      //   field: "txnid",
      //   width: 270,
      // },
    ],
    rows: dataa,
  };
  return (
    <div className="order-report">
      {/* <ToastContainer /> */}
      <div className="row my-4">
        <div className="col-md-12 text-center">
          <h1>Seller Commission</h1>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-6 mx-auto">
          <form>
            <div className="row">
              <div className="col-12">
                <label htmlFor="inputFromDate">From Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="From Date"
                  value={fromdate}
                  onChange={(val) => {
                    setfromdate(val.target.value);
                  }}
                />
              </div>
              <div className="col-12 my-4">
                <label htmlFor="inputToDate">To Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="To Date"
                  value={todate}
                  onChange={(val) => {
                    settodate(val.target.value);
                    setdisbale(true);
                  }}
                />
              </div>
            </div>
          </form>
          <div className="text-center">
            <button
              type="button"
              className="btn report-btn"
              onClick={submit}
              disabled={!disbale}
            >
              Submit
            </button>
          </div>
        </div>
      </div> */}

      <div className="row mt-5">
        <div className="col-md-8 mx-auto">
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};
export default Comission;
