import React from "react";

const EmptySearch = () => {
  return (
    <div className="col-md-12 text-center blank_product mb-5 fw-normal">
      <h6>
        No Result Found
        <br></br>
      </h6>
    </div>
  );
};

export default EmptySearch;
