import React, { useState, useEffect } from "react";
// import Table from "./Table";
import { MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
// import Details from "./Details";

const Comission = () => {
  const [table, setTable] = useState(false);
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [disbale, setdisbale] = useState(false);
  const userData = reactLocalStorage.getObject("userData");
  const [dataa, setdata] = useState([]);

  useEffect(() => {
    getWithdrawHistory();
  }, []);

  const getWithdrawHistory = async () => {
    let result = await HttpClient.requestData(
      "withdraw-history/" + userData._id,
      "GET"
    );
    console.log("withdraw-history", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      result.data.forEach((element, index) => {
        let rows = {
          sl: i,
          transactionid: element.transactionid,
          amount: element.amount,
          paydate_on: moment(element.created_on).format('MMMM Do YYYY'),
          // paystatus: element.paystatus == true ? "Paid" : "Pending",
          paystatus: element.paystatus ? (
            <button
              className="btn-paid"
            >
              {" "}
              Paid
            </button>
          ) : (
            <button
              className="btn-pending"
            >
              {" "}
              Pending
            </button>
          ),
          image: HttpClient.IMG_URL + element.image,
        };
        i++;
        data.push(rows);
      });

      setdata(data);
      setTable(true);
    } else {
      setdata([]);
    }
  };



  const data = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        width: 150,
      },
      // {
      //   label: "Transaction Id",
      //   field: "transactionid",
      //   width: 270,
      // },
      {
        label: "Amount",
        field: "amount",
        width: 270,
      },
      {
        label: "Paid On",
        field: "paydate_on",
        width: 270,
      },
      {
        label: "Pay Status",
        field: "paystatus",
        width: 270,
      },
      // {
      //   label: "Transaction Image",
      //   field: "Image",
      //   width: 270,
      // },
    ],
    rows: dataa,
  };
  return (
    <div className="order-report">
      {/* <ToastContainer /> */}
      <div className="row my-4">
        <div className="col-md-12 text-center">
          <h1>Withdraw History</h1>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-6 mx-auto">
          <form>
            <div className="row">
              <div className="col-12">
                <label htmlFor="inputFromDate">From Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="From Date"
                  value={fromdate}
                  onChange={(val) => {
                    setfromdate(val.target.value);
                  }}
                />
              </div>
              <div className="col-12 my-4">
                <label htmlFor="inputToDate">To Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="To Date"
                  value={todate}
                  onChange={(val) => {
                    settodate(val.target.value);
                    setdisbale(true);
                  }}
                />
              </div>
            </div>
          </form>
          <div className="text-center">
            <button
              type="button"
              className="btn report-btn"
              onClick={submit}
              disabled={!disbale}
            >
              Submit
            </button>
          </div>
        </div>
      </div> */}

      <div className="row mt-5">
        <div className="col-md-10 mx-auto">
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};
export default Comission;
