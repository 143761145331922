// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1MYuBFMmpy2n-FgTx6u0AajgJtg46EV0",
  authDomain: "astrophy-61b1d.firebaseapp.com",
  projectId: "astrophy-61b1d",
  storageBucket: "astrophy-61b1d.appspot.com",
  messagingSenderId: "621354783772",
  appId: "1:621354783772:web:0df713e6608c1bcbdc3bd9",
  measurementId: "G-Z59SFJS221"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getDatabase();

export default db;