import Chat1 from "../../../Assets/Images/chat_img1.png";
import Chat2 from "../../../Assets/Images/chat_img2.png";
import Chat3 from "../../../Assets/Images/chat_img3.png";
import Chat4 from "../../../Assets/Images/chat_img4.png";
import Chat5 from "../../../Assets/Images/chat_img5.png";
import Chat6 from "../../../Assets/Images/chat_img6.png";
import Chat7 from "../../../Assets/Images/chat_img7.png";

export const DemoData = [
  {
    id: 1,
    img: Chat1,
    name: "Alex Martin",
    time: "Today",
    active: false,
    sendby: "user",
    offer: false,
    message: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
  },
  {
    id: 2,
    img: Chat2,
    name: "uamar Murty",
    time: "Today",
    active: true,
    sendby: "user",
    offer: false,
    message: "Lorem ipsum dolor sit amet",
  },
  {
    id: 3,
    img: Chat3,
    name: " Sajal Innuganti",
    time: "Yesterday",
    active: false,
    sendby: "admin",
    offer: false,
    message: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
  },
  {
    id: 4,
    img: Chat4,
    name: " Kanha Pendyala",
    time: "Yesterday",
    active: false,
    sendby: "user",
    offer: false,
    message: "Yes, please",
  },
  {
    id: 5,
    img: Chat5,
    name: " Hemangi Chandna",
    time: "2 month ago",
    active: false,
    sendby: "admin",
    offer: false,
    message: "Lorem ipsum dolor sit amet",
  },
  {
    id: 6,
    img: Chat6,
    name: " Himaghna Tarpana",
    time: "2 month ago",
    active: false,
    sendby: "admin",
    offer: true,
    message: "Lorem ipsum dolor sit amet",
  },
  {
    id: 7,
    img: Chat7,
    name: " Suhali Balakrishnan",
    time: "2 month ago",
    active: false,
    sendby: "user",
    offer: false,
    message: "Lorem ipsum dolor sit amet",
  },
  {
    id: 8,
    img: Chat4,
    name: "uamar Murty",
    time: "Today",
    active: false,
    sendby: "user",
    offer: false,
    message: "Lorem ipsum dolor sit amet",
  },
  {
    id: 9,
    img: Chat1,
    name: "User Name",
    time: "Today",
    active: true,
    sendby: "user",
    offer: false,
    message: "Lorem ipsum dolor sit amet",
  },
  {
    id: 10,
    img: Chat7,
    name: "Placeholder",
    time: "Today",
    active: false,
    sendby: "admin",
    offer: false,
    message: "Lorem ipsum dolor sit amet",
  },
];
