import React, { useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { NavItem } from "reactstrap";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";

const ServiceDlt = (props) => {
  const Delete = async () => {
    console.log("hh", props.delid);
    let result = await HttpClient.requestData(
      "shop/services/" + props.delid,
      "DELETE"
    );
    console.log("ownservicedata", result);
    if (result && result.status) {
      toast.success("Service Deleted Successfully", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      props.callBackServiceDlt(false, "");
      window.location.href="seller-service"
    } else {
      toast.warning("Server error,Please try again", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div>
      {/* <ToastContainer /> */}
      <div className="modal-dialog  my-3 ">
        <div className="modal-content dlt_modal">
          <div className="login-body my-3 px-5 py-3 text-center ">
            <h3>Want to delete Service?</h3>
            <div className="d-flex justify-content-around mt-4">
              <button
                type="button"
                className="btn dltmodal_btn"
                onClick={() => {
                  Delete();
                }}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn dltmodal_btn"
                onClick={() => props.callBackServiceDlt(false, "cancel")}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceDlt;
