import React, { useState, useEffect } from "react";
import { DemoData } from "./components/demodata";
import { reactLocalStorage } from "reactjs-localstorage";
import Profile from "../../Assets/Images/user.jpg";
import {
  getDatabase,
  ref,
  child,
  get,
  limitToLast,
  onChildAdded,
  onChildChanged,
  query,
} from "firebase/database";
import db from "../../Firebase/fbconfig";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
// import { List } from "@material-ui/core";


const UserList = (props) => {
  console.log("search props", props.userlist);
  // const chatuser = reactLocalStorage.getObject("chatuser");
  const chattap = (item) => {
    // console.log("item", item);
    props.openchat(true, item);
  };

  // const chatlist = props.userlist;
  const [chatlist, setchatlist] = useState([]);
  const [backupChatlist, setBackupChatlist] = useState([]);
  const [refress, setRefress] = useState(false);
  const [countRe, setCountRe] = useState(0);
  const userData = reactLocalStorage.getObject("userData");
  useEffect(() => {
    getchatlist();
    // getFirebaseListener(chatlist[0]);
  }, []);

  useEffect(() => {
    userFind(props.searchText)
  },[props.searchText])

  const userFind = (keyword) => {
    let searchString = keyword.trim().toLowerCase();
    let result = [];
    result = backupChatlist.filter((it) => it.name.toLowerCase().match(searchString));
    setchatlist(result)
  };

  const getChatDate = (date) => {
    let dateArr = moment(date).calendar().split(' at ')
    if (dateArr.length > 1) {
      return dateArr[0];
    } else {
      return moment(date).fromNow();
    }
  }

  const getchatlist = async () => {
    const dbRef = ref(db);
    get(child(dbRef, `/chatList/${userData._id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          setchatlist(Object.values(snapshot.val()));
          setBackupChatlist(Object.values(snapshot.val()))
          getUsersImages(Object.values(snapshot.val()))
        } else {
          getFirebaseListener([]);
          console.log("No Chat data data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getUsersImages = async (chats) => {
    let arr = chats;
    arr.map(async (item, index) => {
      let result = await HttpClient.requestData(`seller/${item.userId}`, "GET");

      if (result && result.status) {
        arr[index].image = result.data.image
      }

      if (index == (arr.length - 1)) {
        setchatlist(arr);
        setBackupChatlist(arr);
        getFirebaseListener(arr)
        setRefress(!refress);
        
      }
    })
  }

  const getFirebaseListener = (oldVal) => {
    let re = false;
    console.log("oldval", oldVal);
    // console.log(`/chatList/${userData._id}`);
    const dbRef = ref(db, `/chatList/${userData._id}`);

    const dbquery = query(dbRef);
    let test = 1;
    // limitToLast(1)
    onChildChanged(dbquery, (snapshot) => {
      if (snapshot.exists()) {
        // console.log("fbData", snapshot.val());
        console.log("re", re);
        // console.log("oldVal", oldVal);
        let fbData = snapshot.val();
        let msgIndex = oldVal.findIndex((it) => it.roomId == fbData.roomId);
        console.log(msgIndex);
        if (msgIndex >= 0) {
          let statedata = oldVal;
          test = test + 1;
          console.log("snapshot.val()", snapshot.val(), re, test);

          if (statedata[msgIndex].lastMsg != fbData.lastMsg) {
            fbData.image = statedata[msgIndex].image
            statedata[msgIndex] = fbData;
            setchatlist(statedata);
            setBackupChatlist(statedata)
            setRefress(!re);
            re = !re;
            setCountRe(test)
          }
          // console.log("moment()", moment());
        } else {
          // setchatlist((chatlist) => [...chatlist, fbData]);
        }
      } else {
        console.log("No data available");
      }
    });
  };

  const sorted = () => {
    let a;
    a = chatlist.sort(function (a, b) {
      return new Date(b.lastMsgTime ? b.lastMsgTime : '01-01-2015') < new Date(a.lastMsgTime ? a.lastMsgTime : '01-01-2015') ? -1
        : new Date(b.lastMsgTime ? b.lastMsgTime : '01-01-2015') > new Date(a.lastMsgTime ? a.lastMsgTime : '01-01-2015') ? 1
          : 0
    })


    return a.filter(it => it.userId != userData._id && it.userId);
  }
  return (
    <div className="userList">
      {/* {console.log("chatlist", chatlist, re, refress)} */}
      {sorted().map((item, index) => {
        // console.log("item", item);
        return (
          <div className="highlight">
            <div
              // className={
              //   item.active ? "d-flex w-100 p-3 active" : "d-flex w-100 p-3"
              // }
              className="d-flex w-100 px-3 py-2"
              onClick={() => {
                chattap(item);
              }}
            >
              <div className="user_image">
                {
                  item.image ?
                    <img
                      src={`https://admin.astrophy.com/${item.image}`}
                      alt="img"
                      className="avatar_50"
                    />
                    :
                    <img
                      src={Profile}
                      alt="img"
                      className="avatar_50"
                    />
                }
              </div>
              <div className="ml-3">
                <div className="d-flex">
                  <h6 className="mr-auto my-auto">{item.name}</h6>
                  <span className="ml-auto my-auto">
                    {item.lastMsgTime
                      ? getChatDate(item.lastMsgTime)
                      : ""}
                  </span>
                </div>
                {/* {item.lastMsgtype == "image" ? (
                  <div className="d-flex">
                    <i className="fas fa-camera text-muted mr-2 align-self-center"></i>
                    <span className="text-muted">Photo</span>
                  </div>
                ) : (
                 
                  <p className="mb-0 text-truncate">{item.lastMsg}</p>
                )} */}
                <div className="d-flex">
                  {item.unseen ? (
                    <div className="d-flex">
                      {/* <div className="number align-self-center unread">2</div> */}
                      <p className="mb-0 text-truncate ">{item.lastMsg}</p>
                    </div>
                  ) : item.lastMsgtype == "image" ? (
                    <div className="d-flex">
                      <i className="fas fa-camera text-muted mr-2 align-self-center"></i>
                      <span className="text-muted">Photo</span>
                    </div>
                  ) : (
                    <p className="mb-0 text-truncate">{item.lastMsg}</p>
                  )}

                  {
                    item.unseen ?
                      <div
                        className="ml-auto my-auto"
                        style={{
                          backgroundColor: 'red',
                          height: 10,
                          width: 10,
                          borderRadius: 5
                        }}
                      />
                      :
                      null
                  }

                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default UserList;
