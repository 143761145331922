import React, { useState, useEffect } from "react";
import SellerServices from "./SellerServices";
import Service_1 from "../../Assets/Images/service_list 3.png";
import Service_2 from "../../Assets/Images/service_list 4.png";
import Service_3 from "../../Assets/Images/service_list 5.png";
import Service_4 from "../../Assets/Images/service_list 6.png";
import { Link } from "react-router-dom";
import Graph from "../component/Graph";
import CreateShop from "./CreateShop";
import Loader from "../../Component/loader";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";

const Index = () => {
  const userData = reactLocalStorage.getObject("userData");
  const [create, setCreate] = useState(false);
  const [suspendstatus, setsuspendstatus] = useState("");
  const [shopData, setshopData] = useState([]);
  const [totalview, settotalview] = useState(0);
  const [total_active_services, settotal_active_services] = useState(0);
  const [allservices, setallservices] = useState(0);
  const [total_inactive_Services, settotal_inactive_Services] = useState(0);
  const [revenue_this_year, setrevenue_this_year] = useState(0);
  const [loading, setLoading] = useState(false);

  const [totalorder, settotalorder] = useState(0);
  const [processing_fee, setprocessing_fee] = useState(0);
  const [totalwhishlist, settotalwhishlist] = useState(0);
  const [service, setService] = useState([]);
  const [shoppersView, setshoppersView] = useState([]);
  const userdata = reactLocalStorage.getObject("userData");
  useEffect(() => {
    getDashboardData();
    getshop();
    getShoppersView();
  }, []);

  const getShoppersView = async () => {
    // setLoading(true);
    let data = {};
    let result = await HttpClient.requestData(
      "viewAllshopservicelistviews/" + userData._id,
      "GET",
      data
    );
    console.log("view All shop service list views------", result);
    if (result && result.status) {
      let newArr = result.data.filter((item) => item.stype == "Normal");
      setshoppersView(newArr);
      setprocessing_fee(
        result.processingfees ? result.processingfees.processing_fees : 0
      );
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1);
    } else {
      setshoppersView([]);
    }
  };
  const getDashboardData = async () => {
    let data = {};
    let result = await HttpClient.requestData(
      "summary-stats/" + userData._id,
      "GET",
      data
    );
    console.log("summ", result);
    if (result && result.status) {
      settotalorder(result.total_orders);
      settotalwhishlist(result.in_wishlist);
    } else {
    }
  };
  const fetchService = async (shopid) => {
    // console.log("idd", id);
    // setLoading(true);
    let result = await HttpClient.requestData(
      "shop/all-services/" + shopid,
      "GET"
    );
    console.log("ownservicedataforseller123", result);
    if (result && result.status) {
      setService(result.data);
      console.log("service", service);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1);
    } else {
    }
  };
  const moveToPage = () => {
    // console.log(shopData,"shopdata");
    const userName = reactLocalStorage.getObject("userData").firstName
    console.log('shooop');
    window.location.href = "/service-provider/" +userName+"/"+ shopData.userid;
    // window.open("/astrophy-mall","_blank")
  };
  const getshop = async () => {
    let data = {};
    console.log("object", data);
    // setLoading(true);
    let result = await HttpClient.requestData(
      "shop/" + userdata._id,
      "GET",
      data
    );
    console.log("shop", result);
    if (result && result.status) {
      setshopData(result.data);
      fetchService(result.data._id);
      if (result.data != null) {
        if (result.data.status == false) {
          setsuspendstatus("suspend");
        } else {
          setsuspendstatus("");
        }
      } else {
        setsuspendstatus("deleted");
      }
      // setTimeout(() => {
      //   setLoading(false);
      // }, 2000);
    } else {
    }
  };
  const loop = [1, 2, 3, 4];
  return (
    <div className="seller_shop">
      {create ? (
        <div>
          <div className="row mt-4">
            <div className="col-md-3">
              <div onClick={() => setCreate(false)}>
                <a href="#" className="text-dark">
                  <i className="fas fa-chevron-left mr-2"></i>Back
                </a>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <h1>Create Shop</h1>
              {suspendstatus == "suspend" ? (
                <span className="required" style={{ color: "red" }}>
                  ***
                  <h3>
                    Your Shop is temporarily Suspended please contact with
                    Astrophy
                  </h3>
                </span>
              ) : null}
            </div>
          </div>
          <CreateShop />
        </div>
      ) : (
        <div>
          {/* <ToastContainer /> */}
          <div className="row my-4 px-4">
            <div className="col-xl-7 offset-xl-1">
              <h4>Overview</h4>
              {Object.keys(shopData).length && shopData != null ? (
                <>
                  <Graph />
                </>
              ) : (
                <span className="required" style={{ color: "red" }}>
                  ***
                  <h3>Edit Shop</h3>
                </span>
              )}
            </div>
            {suspendstatus == "deleted" ? (
              <div className="col-xl-3 text-center">
                <div
                  onClick={() => {
                    toast.warning(
                      "Your shop is deleted permanantly,please contact with Astrophy",
                      {
                        position: "bottom-right",
                        autoClose: 3000,
                      }
                    );
                  }}
                >
                  <button type="button" className="btn shop_create_btn">
                    Edit Shop
                  </button>
                </div>
              </div>
            ) : (
              <div className="col-xl-3 text-center">
                <div onClick={() => setCreate(true)}>
                  <button type="button" className="btn shop_create_btn">
                    Edit Shop
                  </button>
                </div>
                {Object.keys(shopData).length && shopData != null ? (
                <>
                  <div onClick={() => setCreate(true)}>
                  <button
                    className="btn shop_create_btn"
                    onClick={moveToPage}
                    style={{ marginTop: 20 }}
                  >
                    Visit Shop
                  </button>
                </div>
                </>
              ) : null}
                
              </div>
            )}
          </div>
          {/* <div className="row px-4">
            <div className="col-md-2 offset-md-4 mt-3">
              <div className="card fav_card">
                <div className="card-body text-center p-2">
                  <h6 className="m-0">
                    <i className="far fa-heart mr-2"></i>
                    {totalwhishlist}

                    <span className="pl-2">Favourite</span>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <div className="card order_card">
                <div className="card-body text-center p-2">
                  <h6 className="m-0">
                    <i className="far fa-check-square mr-2"></i>
                    {totalorder}

                    <span className="pl-2">Order</span>
                  </h6>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="row my-4 px-5">
            <div className="col-md-12 ">
              <div className="d-lg-flex justify-content-between">
                <h4>Your Services</h4>
                <Link to="/seller-service">View More</Link>
              </div>
            </div>
          </div> */}
          {/* <div className="">
            <SellerServices data={service} />
          </div> */}
        </div>
      )}
      <div className="shop_listing py-4">
        <table border="0" className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Shop Performance</th>
              <th>Total Views</th>
              <th>Total Favourites</th>
              <th>Total Orders</th>
              <th>Total Revenue</th>
            </tr>
          </thead>
          <tbody>
            {shoppersView.length ? (
              shoppersView.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link
                        to={"/service-details/" + item._id}
                        className="d-flex align-items-center justify-content-start text-dark"
                      >
                        <img
                          src={HttpClient.IMG_URL + item.image[0]}
                          alt="img"
                          style={{
                            height: "80px",
                            width: "80px",
                            borderRadius: "6px",
                          }}
                        />
                        <div className="ml-3">
                          <h6 className="m-0 font-weight-normal">
                            {item.name}
                          </h6>
                          {/* <span
                            className="badge rounded py-1 px-1 font-weight-normal"
                            style={{ background: "#ffbd5fad" }}
                          >
                            Active
                          </span> */}
                        </div>
                      </Link>
                    </td>
                    <td>
                      <p>{item.pageViews}</p>
                    </td>
                    <td>
                      <p>{item.favourate_data.length}</p>
                    </td>
                    <td>
                      <p>{item.order_data.length}</p>
                    </td>
                    <td>
                      <p>
                        {userData.currency == "INR"
                          ? "₹"
                          : userData.currency == "CAD"
                          ? "$"
                          : userData.currency == "USD"
                          ? "$"
                          : userData.currency == "GBP"
                          ? "£"
                          : userData.currency == "EUR"
                          ? "€"
                          : "₹"}
                        {Math.abs(
                          item.totalComission -
                            item.refundedAmtTotal -
                            (item.totalComission - item.refundedAmtTotal) *
                              processing_fee
                        ).toFixed(2)}
                      </p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className="col-md-12 text-center blank_product">
                <h4>
                  No shoppers view found <br></br>
                </h4>
              </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Index;
