import React, { useEffect, useState } from "react";
import shop1 from "../../Assets/Images/service_list 9.png";
import shop2 from "../../Assets/Images/service_list 8.png";
import shop3 from "../../Assets/Images/service_list 10.png";
import shop4 from "../../Assets/Images/service_list 14.png";
import Provider_name from "../../Assets/Images/provider_name.png";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import EmptyWishlist from "../EmptyWishlist/EmptyWishlist";

const Shop = () => {
  useEffect(() => {
    getproduct();
  }, []);
  const [loading, setLoading] = useState(false);
  const [loopp, setloopp] = useState([]);
  const userdata = reactLocalStorage.getObject("userData");

  const getproduct = async () => {
    setLoading(true);
    let sendData = {};
    // console.log("object", sendData);
    let result = await HttpClient.requestData(
      "servicegetWishlist/" + userdata._id + "?currency=" + userdata.currency,
      "GET",
      sendData
    );
    console.log("servicegetWishlist", result);
    if (result && result.status) {
      setloopp(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 10);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 10);
    }
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }


  return (
    <div className="row px-md-4">
      {loopp.length > 0 ? (
        loopp.map((item, index) => {
          return (
            <div className="col-lg-3 col-md-4 my-3" key={index}>
              <div className="card service_list _service_Allpage" onClick={() => {
                window.location.href =
                "/service-details/" + nameToSlug(item.servicename) + "/" + item.serv_id;
              }}>
                <a href="#">
                  <img
                    src={HttpClient.IMG_URL + item.image}
                    alt="img"
                    className="relative service_list_img"
                  />
                </a>

                <div className="card-body service_list_text p-3">
                  <div className="d-flex justify-content-between align-items-center ">
                    <div className="d-flex">
                      <i className="fas fa-star my-1 mr-2" />
                      <h6 className="mb-0">
                        {item.avgRating != null
                          ? item.avgRating.toFixed(1)
                          : "0"}
                        /5
                      </h6>
                    </div>
                  </div>
                  <a href="#">
                    <p className="card-text">{item.servicename}</p>
                  </a>
                  <h5 className="my-3">
                    {userdata.currency == "INR"
                      ? "₹"
                      : userdata.currency == "CAD"
                      ? "$"
                      : userdata.currency == "USD"
                      ? "$"
                      : userdata.currency == "	GBP"
                      ? "£"
                      : userdata.currency == "EUR"
                      ? "€"
                      : "₹"}{" "}
                    {item.price}
                  </h5>
                  <div className="d-flex mt-3">
                    <img
                      src="https://admin.astrophy.com/uploads/shop_services/shop_Screenshot_2022-04-02-01-59-09-14_d14d11d2fb0db653a82b6e492fd2a369.png"
                      alt="img"
                      className="mr-2 rounded-circle"
                      style={{
                        width: "100%",
                        height: "25px",
                        maxWidth: "25px",
                      }}
                    />
                    <h4>{item.service_data.shop_data.name}</h4>
                  </div>
                  {/* <p>{item.service_data.details}</p> */}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyWishlist type="service" />
      )}
      {/* {loop.map((item, index) => {
        return (
          <div className="col-lg-4 col-md-6  my-3" key={index}>
            <div className="card shop">
              <img src={item.img} className="card-img-top" alt="img" />
              <div className="card-body mt-3">
                <div className="d-flex px-2 ">
                  <div>
                    <img
                      src={Provider_name}
                      alt="img"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="mx-2">
                    <Link to="/astrophy-mall">
                      <h5 className="card-title">SeraphDragonaara</h5>
                    </Link>
                    <p>
                      <i className="fas fa-star my-1 mr-1"></i> 4.6/5{" "}
                      <span>(15 Reviews)</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })} */}
    </div>
  );
};
export default Shop;
