import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import Favourite from "../../Assets/Images/favourite.png";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import HttpClient from "../../utils/HttpClient";
import EmptyProduct from "../EmptyProduct/EmptyProduct";
import { confirmAlert } from "react-confirm-alert";
const ProductList = (props) => {
  console.log("ppp", props.data);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    // each useEffect can return a cleanup function
    return () => {
      componentIsMounted.current = false;
    };
  }, []);
  useEffect(() => {
    // getWishlistProduct();
  }, [props]);
  const product = props.data;
  const [isWishlisted, setisWishlisted] = useState(false);
  const [cartBtn, setCartBtn] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const loop = [1];
  const userData = reactLocalStorage.getObject("userData");
  const styles = {
    color: "green",
    cursor: "pointer",
    // fontSize: "15px",
  };

  const loginstatus = reactLocalStorage.getObject("loginstatus");

  async function convertMrp(mrpPrice, proCurrency) {
    let res1 = await HttpClient.currencyConvertrt(
      mrpPrice,
      proCurrency,
      userData.currency
    );
    console.log("return mrp===", res1);
    return res1;
  }

  // const addcart = async (val) => {
  //   let data = {
  //     prod_id: val._id,
  //     user_id: userData._id,
  //     productname: val.name,
  //     qty: 1,
  //     price: val.selling_price,
  //     image: val.image[0],
  //   };
  //   let result = await HttpClient.requestData("add-to-cart", "POST", data);
  //   if (result && result.status) {
  //     toast.success(result.message, {
  //       position: "bottom-right",
  //     });
  //   } else {
  //     toast.error(result.message, {
  //       position: "bottom-right",
  //     });
  //   }
  // };
  const buyNow = async (val) => {
    if (val.cart_details.length > 0) {
      window.location.href = "/checkout";
    } else {
      let tax_percent = val.tax.replace("%", "");
      let b = Number(val.selling_price) * (Number(tax_percent) / 100);
      let selling_priceWithTax = (val.selling_price + b).toFixed(2);
      let data = {
        prod_id: val._id,
        user_id: userData._id,
        productname: val.name,
        qty: 1,
        currency: userData.currency,
        // price: selling_priceWithTax,
        price: val.selling_price,
        image: val.image[0],
        orderFrom: "web",
      };

      let result = await HttpClient.requestData("add-to-cart", "POST", data);
      // console.log("result", result);
      if (result && result.status) {
        window.location.href = "/checkout";
      } else {
      }
    }
  };
  const alert = () => {
    toast.error("You have to login first", {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  // const getWishlistProduct = async () => {
  //   let itemWishlist = reactLocalStorage.getObject("wishlisted");
  //   console.log("itemWishlist",itemWishlist);
  //   let user_id = userData._id;
  //   let result = await HttpClient.requestData(
  //     "product-wishlist/" + user_id,
  //     "GET"
  //   );
  //   // let _id = propsDetails._id;
  //   if (result && result.status) {
  //     console.log("wishlisted data", result.data);
  //     product.forEach((itemm) => {
  //       let newData = result.data.filter((item) => item.prod_id == itemm._id);
  //       console.log(newData);
  //       if (newData.length > 0) {
  //         setisWishlisted(true);
  //       }
  //     })

  //   } else {
  //     console.log("error data", result.data);
  //   }
  // };

  const wishlist = async (item) => {
    // e.preventDefault();
  
    console.log("propsDetails", item);
    let userId = userData._id;
    let _id = item._id;
    let sending = {
      user_id: userId,
      prod_id: _id,
      productname: item.name,
      qty: "1",
      price: item.selling_price,
      image: item.image,
    };
    let result = await HttpClient.requestData(
      "product-wishlist",
      "POST",
      sending
    );
    console.log("wish---", result);
    if (result && result.status) {
      props.wishlistCallback(result.status);
      if (item.cart_details.length > 0) {
        confirmAlert({
          title: "",
          message: "After wishlisting product will be removed from your cart",
          buttons: [
            {
              label: "Ok",
              onClick: () => {},
            },
          ],
        });
      }
      reactLocalStorage.setObject("wishlisted");
      // console.log(reactLocalStorage.getObject("wishlisted"),"wishlisted");
      localStorage.setItem("wishlisted", result.data);
      setisWishlisted(true);
      setCartBtn(false);
      console.log("toast");
      toast.success("Added to Wishlist", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else {
      toast.error("Server error, try again later!", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };
  const confirmyes = async (val) => {
    console.log("jkkkjjjjjjjjjjjjjjjj");
    console.log("del", val.wishlist_data[0]._id);
    let data = {};
    let result = await HttpClient.requestData(
      "product-wishlist/" + val.wishlist_data[0]._id,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      props.wishlistCallback(result.status);
    } else {
    }
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(" ", "-");
    return strReplace;
  };

  return (
    <div className="row px-0">
      <ToastContainer />
      {props.data.length > 0 ? (
        props.data.map((item, index) => {
          let sum = 0;
          let avg = 0;
          // let a = item.cart_details.length > 0 && item.cart_details.filter(it => it.user_id ==
          //   userData._id && it.status == true);
          // console.log("filter dta", a);
          item.review_data.map((itemm, index) => {
            return (sum = Number(sum) + Number(itemm.rating));
          });
          // console.log("sum", sum);
          avg = Number(sum) / Number(item.review_data.length);
          let tax_percent = item.tax.replace("%", "");
          let b = Number(item.mrp) * (Number(tax_percent) / 100);
          let selling_priceWithTax = (item.mrp + b).toFixed(2);
          // let arr = item.cart_details.filter(it => it.prod_id == item._id && it.user_id == userData._id);
          // console.log("arr", arr);
          // if (
          //   userData.currency != "" &&
          //   typeof userData.currency != "undefined" &&
          //   item.currency != userData.currency
          // ) {
          //   let mrpPrice = item.mrp;
          //   let sellingPrice = item.selling_price;
          //   let proCurrency = item.currency;
          //   let newMrp = await HttpClient.currencyConvertrt(
          //     mrpPrice,
          //     proCurrency,
          //     userData.currency
          //   );
          //   console.log("new MRP-----",newMrp);
          //   // item[index].mrp = newMrp;
          //   let newSelling = await HttpClient.currencyConvertrt(
          //     sellingPrice,
          //     proCurrency,
          //     userData.currency
          //   );
          // }
          return (
            <div
              className="col-6 col-md-4 col-lg-3 col-sm-6 my-3 px-2 px-md-3"
              key={index}
            >
              <div className="card service_list new_product_design">
                <Link
                  to={
                    "/product-details/" + nameToSlug(item.name) + "/" + item._id
                  }
                >
                  <Slider {...settings}>
                    {item.image
                      ? item.image.map((item2, index2) => {
                          return (
                            <div key={index2}>
                              {" "}
                              <img
                                src={HttpClient.IMG_URL + item2}
                                alt="img"
                                className="relative service_list_img"
                              />
                              {/* <img
                            src={Favourite}
                            alt="img"
                            className="absolute m-2"
                          /> */}
                            </div>
                          );
                        })
                      : null}
                    {/* {loop.map((item2, index2) => {
                      return (
                        <div key={index2}>
                          {" "}
                          <img
                            src={
                              HttpClient.IMG_URL + item.image[0]
                            }
                            alt="img"
                            className="relative service_list_img"
                          />
                          <img
                            src={Favourite}
                            alt="img"
                            className="absolute m-2"
                          />
                        </div>
                      );
                    })} */}
                  </Slider>
                </Link>
                <div className="card-body product_list_text px-md-3 pt-2">
                  <Link
                    to={
                      "/product-details/" +
                      nameToSlug(item.name) +
                      "/" +
                      item._id
                    }
                  >
                    <h6>{item.name}</h6>
                  </Link>
                  {selling_priceWithTax <= item.selling_price ? (
                    <div className="d-flex justify-content-between py-md-3">
                      <div className="pricing">
                        <p className="font-weight-bold  mb-0">
                          {userData.currency == "INR"
                            ? "₹"
                            : userData.currency == "CAD"
                            ? "$"
                            : userData.currency == "USD"
                            ? "$"
                            : userData.currency == "GBP"
                            ? "£"
                            : userData.currency == "EUR"
                            ? "€"
                            : "₹"}{" "}
                          {item.selling_price.toFixed(2)}
                          {/* {convertMrp(item.mrp,item.currency)} */}
                          {/* <del className="text-muted font-italic ml-2 small">
                            {userData.currency == "INR"
                              ? "₹"
                              : userData.currency == "CAD"
                              ? "$"
                              : userData.currency == "USD"
                              ? "$"
                              : userData.currency == "GBP"
                              ? "£"
                              : userData.currency == "EUR"
                              ? "€"
                              : "₹"}{" "}
                            {item.mrp.toFixed(2)}
                          </del> */}
                        </p>
                        <h5 className="small mb-0 font-weight-bold">
                          {item.offer ? item.offer + "% Off+" : null}
                          {item.tax}Tax
                        </h5>
                      </div>
                      <div className="rating">
                        <i className="fas fa-star my-1 mr-1 mr-md-2"></i>
                        {item.avgRating ? item.avgRating.toFixed(1) : "0"}/5
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between py-md-3">
                      <div className="pricing">
                        <p className="font-weight-bold  mb-0">
                          {userData.currency == "INR"
                            ? "₹"
                            : userData.currency == "CAD"
                            ? "$"
                            : userData.currency == "USD"
                            ? "$"
                            : userData.currency == "GBP"
                            ? "£"
                            : userData.currency == "EUR"
                            ? "€"
                            : "₹"}{" "}
                          {item.selling_price.toFixed(2)}
                          {/* {convertMrp(item.mrp,item.currency)} */}
                          <del className="text-muted font-italic ml-2 small">
                            {userData.currency == "INR"
                              ? "₹"
                              : userData.currency == "CAD"
                              ? "$"
                              : userData.currency == "USD"
                              ? "$"
                              : userData.currency == "GBP"
                              ? "£"
                              : userData.currency == "EUR"
                              ? "€"
                              : "₹"}{" "}
                            {selling_priceWithTax}
                          </del>
                        </p>
                        <h5 className="small mb-0 font-weight-bold">
                          {item.offer ? item.offer + "% Offer+" : null}
                          {item.tax}Tax
                        </h5>
                      </div>
                      <div className="rating">
                        <i className="fas fa-star my-1 mr-1 mr-md-2"></i>
                        {item.avgRating ? item.avgRating.toFixed(1) : "0"}/5
                      </div>
                    </div>
                  )}

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-center">
                      {loginstatus ? (
                        <button
                          className="btn my-3 red_btn"
                          onClick={() => buyNow(item)}
                        >
                          Buy Now
                        </button>
                      ) : (
                        <button className="btn my-3 red_btn" onClick={alert}>
                          Buy Now
                        </button>
                      )}
                    </div>
                    {/* <Link to="/add-cart"> */}
                    {loginstatus ? (
                      item.cart_details.length > 0 ? (
                        <button
                          className="cart btn p-0 bg-transparent"
                          style={styles}
                          onClick={() => {
                            window.location.href = "/add-cart";
                          }}
                        >
                          <i className="fas fa-shopping-cart text-success"></i>{" "}
                          <span> Already added</span>
                        </button>
                      ) : (
                        <button
                          className="cart btn p-0 bg-transparent text-dark"
                          onClick={() => props.addTocart(item)}
                          style={styles}
                        >
                          <i className="fas fa-cart-plus text-dark"></i>{" "}
                          <span>Add to Cart</span>
                        </button>
                      )
                    ) : (
                      <button
                        className="cart btn p-0 bg-transparent text-dark"
                        onClick={alert}
                        style={styles}
                      >
                        <i className="fas fa-cart-plus text-dark"></i>{" "}
                        <span>Add to Cart</span>
                      </button>
                    )}
                    {/* </Link> */}
                  </div>

                  {loginstatus ? (
                    <div>
                      {item.wishlist_data.length > 0 ? (
                        <div
                          className="absolute_bg"
                          onClick={() => {
                            confirmyes(item);
                          }}
                        >
                          <i className="fas fa-heart text-danger"></i>
                        </div>
                      ) : (
                        <div
                          className="absolute_bg"
                          onClick={() => {
                            wishlist(item);
                          }}
                        >
                          <i className="far fa-heart text-danger"></i>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="absolute_bg" onClick={alert}>
                      <i className="far fa-heart text-danger"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyProduct />
      )}
    </div>
  );
};
export default ProductList;
