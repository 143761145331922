import React, { useState, useEffect } from "react";
import OrderDetails from "./OrderDetails";
import OrderList from "./OrderList";
import Footer from "../../Defaults/Footer";
import { ReactTitle } from "react-meta-tags";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
import moment from "moment";
import bgBlog from "../../Assets/Images/blogBg.jpg";
const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getorderlist();
  }, []);
  const userData = reactLocalStorage.getObject("userData");
  const [order, setorder] = useState([]);
  const [orderStatus, setorderStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderdetails, setorderdetails] = useState({});
  const [count, setcount] = useState(0);
  const getorderlist = async () => {
    setLoading(true);
    let result = await HttpClient.requestData(
      "orderdetails/" + userData._id,
      "GET"
    );
    console.log("result", result);
    if (result && result.status) {
      // let arr = result.data.sort(function (a, b) {
      //   return new Date(b.cart_data[0].buy_date) - new Date(a.cart_data[0].buy_date);
      // });
      let data = result.data;
      let arr = result.data.sort(
        (a, b) =>
          new Date(moment(b.cart_data[0].buy_date).format("MMM Do YY")) -
          new Date(moment(a.cart_data[0].buy_date).format("MMM Do YY"))
      );
      const arr1 = data.reverse();
      console.log("order list", result.data);
      console.log(" reverse order list", arr1);
      console.log("new arr", arr);
      setorder(data);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };

  // const getorderlistStatus = async () => {
  //   setLoading(true);
  //   let result = await HttpClient.requestData(
  //     "orderdetailsHistry/" + userData._id,
  //     "GET"
  //   );
  //   console.log("orderdetailsHistry----", result);
  //   if (result && result.status) {
  //     setorderStatus(result.data)
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 1);
  //   } else {
  //   }
  // };

  const sendata = (val, count) => {
    // console.log("object", val);
    setcount((prevState) => prevState + count);
    setorderdetails(val);
  };

  const refresh = (val) => {
    if (val) {
      getorderlist();
    }
  };
  return (
    <div>
      <div className="wrapper">
        <ReactTitle title="Astrophy-Orders" />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <section>
            <div
              className="bGimg_My_Orders"
              style={{
                background: `url(${bgBlog})`,
              }}
            >
              <div className="container my-2">
                <div className="row">
                  <div className="col-md-12 order_head my-3">
                    <h3>My Orders</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="container my-2">
              <div className="row">
                <div className="col-md-4">
                  <OrderList order={order} sendata={sendata} count={count} />
                </div>
                <div className="col-md-8 my-md-0 my-4">
                  {Object.keys(orderdetails).length != 0 ? (
                    <div className="order_details">
                      <OrderDetails
                        orderdetails={orderdetails}
                        refresh={refresh}
                        count={count}
                      />
                    </div>
                  ) : (
                    <div className="text-center mt-5">
                      <img
                        src="https://c1.alamy.com/thumbs/trta2g/online-shopping-icon-vector-with-male-customer-person-profile-avatar-symbol-for-buying-goods-in-glyph-pictogram-illustration-trta2g.jpg"
                        alt="img"
                        style={{
                          width: "200px",
                          height: "200px",
                          borderRadius: "50%",
                        }}
                      />
                      <h4 className="pt-3">
                        Click on the orders list there, to view order status
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section>
            <Footer />
          </section>
        </>
      )}
    </div>
  );
};
export default Index;
