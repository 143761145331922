import React, { useEffect, useState } from "react";
import HttpClient from "../../utils/HttpClient";

const MoreInfo = (props) => {
  const gaqAbout = props.dataFaq;
  const [ans1, setAns1] = useState(false);
  const [ans2, setAns2] = useState(false);
  const [ans3, setAns3] = useState(false);
  const [ans4, setAns4] = useState(false);
  return (
    <div className="row my-4">
      <div className="col-md-12">
        <div className="border-bottom my-3 ans_text">
          <p
            onClick={() => {
              setAns1(!ans1);
              setAns2(false);
              setAns3(false);
              setAns4(false);
            }}
          >
            {ans1 ? (
              <i className="fas fa-minus mr-3"></i>
            ) : (
              <i className="fas fa-plus mr-3"></i>
            )}
            {/* Why Astrophy ? */}
            {gaqAbout.qstn}
          </p>

          <div className={ans1 ? "d-block py-4" : "d-none"}>
            <h6>
            {gaqAbout.ans}
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
              volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. A, volutpat nibh cursus. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
              nibh cursus Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. A, volutpat nibh cursus. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. A, volutpat nibh cursus */}
            </h6>
          </div>
        </div>
      </div>
      {/* <div className="col-md-12">
        <div className="border-bottom my-3 ans_text">
          <p
            onClick={() => {
              setAns2(!ans2);
              setAns1(false);
              setAns3(false);
              setAns4(false);
            }}
          >
            {ans2 ? (
              <i className="fas fa-minus mr-3 text-muted"></i>
            ) : (
              <i className="fas fa-plus mr-3 text-muted"></i>
            )}
            Your Safety is our Priority
          </p>
          <div className={ans2 ? "d-block py-4" : "d-none"}>
            <h6>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
              volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. A, volutpat nibh cursus. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
              nibh cursus Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. A, volutpat nibh cursus. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. A, volutpat nibh cursus
            </h6>
          </div>
        </div>
      </div>{" "} */}
      {/* <div className="col-md-12">
        <div className="border-bottom my-3 ans_text">
          <p
            onClick={() => {
              setAns3(!ans3);
              setAns2(false);
              setAns1(false);
              setAns4(false);
            }}
          >
            {ans3 ? (
              <i className="fas fa-minus mr-3 text-muted"></i>
            ) : (
              <i className="fas fa-plus mr-3 text-muted"></i>
            )}
            Written User Reviews
          </p>
          <div className={ans3 ? "d-block py-4" : "d-none"}>
            <h6>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
              volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. A, volutpat nibh cursus. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
              nibh cursus Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. A, volutpat nibh cursus. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. A, volutpat nibh cursus
            </h6>
          </div>
        </div>
      </div>{" "} */}
      {/* <div className="col-md-12">
        <div className=" my-3 ans_text">
          <p
            onClick={() => {
              setAns4(!ans4);
              setAns2(false);
              setAns3(false);
              setAns1(false);
            }}
          >
            {ans4 ? (
              <i className="fas fa-minus mr-3 text-muted"></i>
            ) : (
              <i className="fas fa-plus mr-3 text-muted"></i>
            )}
            Customer Satisfaction Guaranteed
          </p>
          <div className={ans4 ? "d-block py-4" : "d-none"}>
            <h6>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. A,
              volutpat nibh cursus. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. A, volutpat nibh cursus Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. A, volutpat nibh cursus. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. A, volutpat
              nibh cursus Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. A, volutpat nibh cursus. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. A, volutpat nibh cursus
            </h6>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default MoreInfo;
