import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "@tinymce/tinymce-react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../../Component/loader";
const userdata = reactLocalStorage.getObject("userData");

const fileObj = [];
const fileArray = [];
const Index = () => {
  const [service, setService] = useState([]);
  const [name, setname] = useState("");
  const [price, setprice] = useState("");
  const [details, setdetails] = useState("");
  const [personalisation, setpersonalisation] = useState("");
  const [hashtag, sethashtag] = useState("");
  const [serviceimg, setserviceimg] = useState("");
  const [loading, setLoading] = useState(false);

  const [arr, setarr] = useState([]);

  const [category, setcategory] = useState("");
  const [subcategory, setSubcategory] = useState("");

  const [categoryName, setcategoryName] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");
  const [inputFields, setInputFields] = useState([{ highlight: "" }]);

  const [cat, setcat] = useState([]);
  const [subcat, setsubcat] = useState([]);
  const [shopid, setshopid] = useState("");
  const [preview, setpreview] = useState([]);
  const [multiImages, setmultiImages] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [tax, settax] = useState(0);
  const [currency, setcurrency] = useState(userdata.currency);
  const [status, setStatus] = useState("");
  const [sellerData, setsellerData] = useState({});
  const [suspendstatus, setsuspendstatus] = useState("");
  const [currencyTyp, setcurrencyTyp] = useState([
    {
      name: "INR",
    },
    {
      name: "USD",
    },
    {
      name: "GBP",
    },
    {
      name: "EUR",
    },
    {
      name: "CAD",
    },
    // {
    //   name: "cdd",
    // },
  ]);

  const onImageId = async (e) => {
    // if (e.target.files.length > 0) {
    //   const imageArr = Array.from(e.target.files);
    //   console.log("imageArr", imageArr);
    //   const imageUrlArray = [];
    //   imageArr.forEach((file) => {
    //     preview.push({ preview: URL.createObjectURL(file) });
    //   });
    //   setpreview(preview);
    imageUploadApi(e.target.files[0]);
    // setrefresh(!refresh);
    // setTimeout(() => {
    //   console.log("previeww", preview);
    // }, 1000);
    //}
  };

  // const setImagesUrl = (url) => {
  //   let tempUrlArr = multiImages;
  //   tempUrlArr.push(url);
  //   setmultiImages(tempUrlArr);
  //   console.log("multiImages", multiImages);
  // };

  const imageUploadApi = async (files) => {
    console.log("image upload", files);
    //need to start loader
    //  setshowLoder(true);
    // console.log("files", files);
    // for (const file of files) {
    // if (files.length <= 5) {
    //   let data = new FormData();
    //   data.append("image", files);
    //   let result = await HttpClient.fileUplode(
    //     "shop-service-images",
    //     "POST",
    //     data
    //   );
    //   console.log("reuslkyet", result);

    //   if (result && result.status) {
    //     // setImagesUrl(result.data);
    //     setpreview((prevState) => [...prevState, result.data]);
    //     console.log("preview", preview);
    //   }
    //   // }
    //   //need to stop loader
    //   //  setshowLoder(false);
    // } else {
    //   toast.warning("Number of Image Upload should be 5 or less than 5", {
    //     position: "bottom-right",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    let data = new FormData();
    data.append("image", files);
    let result = await HttpClient.fileUplode(
      "shop-service-images",
      "POST",
      data
    );
    console.log("reuslkyet", result);

    if (result && result.status) {
      // setImagesUrl(result.data);
      setpreview((prevState) => [...prevState, result.data]);
      console.log("preview", preview);
    }
    // }
    //need to stop loader
    //  setshowLoder(false);
  };

  useEffect(() => {
    console.log("preview", preview);
    fetchCat();
    fetchShopId();
    getshop();
    fetchData();
  }, []);

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    console.log(inputFields);
  };
  const handleAddFields = () => {
    setInputFields([...inputFields, { highlight: "" }]);
  };
  const handleRemoveFields = (index) => {
    const values = [...inputFields];

    values.splice(index, 1);
    setInputFields(values);
  };

  const handlecategory = (event) => {
    const selectedcategory = event.target.value;
    let filterCategory = cat.filter((item) => item._id === selectedcategory);
    setcategoryName(filterCategory[0].name);
    setcategory(selectedcategory);
    fetchSubCat(selectedcategory);
  };

  const handleSubcategory = (event) => {
    const selectedSubcategory = event.target.value;
    let filterSubCategory = subcat.filter(
      (item) => item._id === selectedSubcategory
    );
    setSubcategoryName(filterSubCategory[0].name);
    setSubcategory(selectedSubcategory);
  };
  const fetchCat = async () => {
    let result = await HttpClient.requestData("service", "GET");
    // console.log("Cat", result);
    if (result && result.status) {
      let data = result.data.filter((item) => item.status == true);
      // console.log("data", data);
      setcat(data);
      // console.log("setcat", cat);
    } else {
    }
  };

  const fetchShopId = async () => {
    let userdata = reactLocalStorage.getObject("userData");
    // console.log("userdata", userdata);
    let result = await HttpClient.requestData("seller/" + userdata._id, "GET");
    console.log("Shop", result);
    if (result && result.status) {
      setshopid(result.shop_id ? result.shop_id : "");
      fetchService(result.shop_id);
      // console.log("shopid", shopid);
    } else {
    }
  };

  const fetchData = async () => {
    let result = await HttpClient.requestData("seller/" + userdata._id, "GET");
    console.log("Data seller", result);
    if (result && result.status) {
      setsellerData(result.data);
      // console.log("setuser", user);
    } else {
    }
  };

  const getshop = async () => {
    let data = {};
    console.log("object", data);
    let result = await HttpClient.requestData(
      "shop/" + userdata._id,
      "GET",
      data
    );

    if (result && result.status) {
      if (result.data != null) {
        console.log("result status", result.data.status);
        if (result.data.status === false) {
          setsuspendstatus("suspend");
        } else {
          setsuspendstatus("");
        }
      } else {
        setsuspendstatus("deleted");
      }
    } else {
    }
  };
  console.log("shopstat", suspendstatus);
  const fetchService = async (shopid) => {
    // console.log("idd", id);
    // setLoading(true);
    let result = await HttpClient.requestData(
      "shop/all-services/" + shopid,
      "GET"
    );
    console.log("ownservicedata", result);
    if (result && result.status) {
      setService(result.data);
      console.log("service", service);
    } else {
    }
  };

  const deleteFile = (e) => {
    // const s = preview.filter((item, index) => index !== e);
    // setpreview(s);
    let arr = preview;
    setpreview([]);
    console.log(e);
    console.log("preview", preview);
    arr.splice(e, 1);
    console.log("arr", arr);
    arr.forEach((item) => {
      setpreview((prevState) => [...prevState, item]);
    });

    console.log("after", preview);
  };

  const fetchSubCat = async (id) => {
    // let result = await HttpClient.requestData(
    //   "service/subcategory/" + id,
    //   "GET"
    // );
    let result = cat.filter((item) => item._id === id);
    console.log("SubCat", result);
    if (result && result.length > 0) {
      let data = result[0].service_data;
      console.log("SubCat data", data);
      setsubcat(data);
    } else {
    }
  };

  const handleStatus = (event) => {
    console.log(event.target.value);
    setStatus(event.target.value);
  };
  const suspendStatus = () => {
    toast.warning("Due to Suspension you can't create service", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const publish = async () => {
    if (
      name == "" ||
      price == "" ||
      details == "" ||
      // personalisation == "" ||
      // hashtag == "" ||
      category.length == 0 ||
      subcategory.length == 0
    ) {
      toast.warning("Please Enter All Fields", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (preview.length == 0) {
      toast.warning("Please Enter Images", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (preview.length > 5) {
      toast.warning("Number of Image Upload should be 5 or less than 5", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (shopid == "") {
      toast.warning("Please Create a shop first", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // console.log("inputFields", inputFields);

    let data = new FormData();
    data.append("name", name);
    data.append("price", price);
    data.append("details", details);
    data.append("category_id", category);
    data.append("subcategory_id", subcategory);
    data.append("personalization", personalisation);
    data.append("cat_name", categoryName);
    data.append("subcat_name", subcategoryName);
    data.append("hashtags", hashtag);
    data.append("image", JSON.stringify(preview));
    data.append("highlights", JSON.stringify(inputFields));
    data.append("shop_id", shopid);
    data.append("seller_id", userdata._id);
    data.append("currency", currency);
    console.log("data", data.name);
    setLoading(true);
    let result = await HttpClient.fileUplode("shop/services", "POST", data);
    console.log("result services add----", result);
    if (result && result.status) {
      setLoading(false);
      setname("");
      setprice("");
      setdetails("");
      setpersonalisation("");
      sethashtag("");
      setserviceimg("");
      setcategory("");
      setcurrency("");
      setSubcategory("");
      setpreview([]);
      setInputFields([]);
      setmultiImages([]);
      fetchService(shopid);
      toast.success("Services Added Successfully!", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        window.location.href = "/seller/seller-service";
      }, 1000);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // const getTax = async (curr) => {
  //   let data = {
  //     currency: curr
  //   };
  //   console.log("data", data);
  //   let result = await HttpClient.requestData(
  //     "currency/tax-rates",
  //     "POST",
  //     data
  //   );
  //   console.log("result", result);
  //   if (result && result.status) {
  //     console.log("result", result.data);

  //     settax(result.data.tax_rate);

  //   }
  // }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="Add_service  ">
          <ToastContainer />
          <div className="row mt-3">
            <div className="col-md-12 text-center">
              <h1>Add Services</h1>
              {suspendstatus == "suspend" || sellerData.status === false ? (
                <p style={{ color: "red" }}>
                  Due to Suspension you can't create service
                </p>
              ) : null}
            </div>
            <div className="col-md-8 mx-auto">
              <div className="form-group my-4">
                <label htmlFor="name">
                  Service Name{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control _borderRed"
                  id="name"
                  aria-describedby="nameHelp"
                  placeholder="Service Name"
                  onChange={(val) => {
                    // setname(val.target.value);
                    if (val.target.value.match("^[a-zA-Z0-9 ]*$") != null) {
                      setname(val.target.value);
                    }
                  }}
                  value={name}
                />
              </div>

              <div className="form-group my-4">
                <label htmlFor="currency">
                  Currency{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control _borderRed"
                  id="name"
                  aria-describedby="nameHelp"
                  placeholder="Currency"
                  value={currency}
                  readOnly
                />
                {/* <select
              id="currency"
              className="form-control _borderRed"
              onChange={(val) => {
                setcurrency(val.target.value);

                // getTax(val.target.value);
              }}
              value={currency}
            >
              <option value="">Select Currency</option>
              {currencyTyp.map((item, index) => {
                return <option value={item.name}>{item.name}</option>;
              })}
            </select> */}
              </div>
              {/* {currency != "" ?
            <>
              <div className="form-group my-4">
                <label htmlFor="text_input  ">
                  Tax(%){" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={tax}
                  placeholder="Tax"
                  id="text_input"
                  onChange={(val) => {
                    settax(val.target.value);
                  }}
                />
              </div> */}

              <div className="form-group my-4">
                <label htmlFor="price">
                  Service Price{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  // type="number"
                  inputMode="numeric"
                  className="form-control _borderRed"
                  id="price"
                  aria-describedby="emailHelp"
                  placeholder="Service Price"
                  onChange={(val) => {
                    if (
                      val.target.value.match("^[+]?([0-9]*[.])?[0-9]*$") != null
                    ) {
                      setprice(val.target.value);
                    }
                  }}
                  value={price}
                />
              </div>
              {/* </>
            : null} */}

              <div className="form-group my-4">
                <label htmlFor="details">
                  Service Details{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                {/* <textarea
              className="form-control"
              id="details"
              rows={3}
              placeholder="Please Add Your Service Details"
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  setdetails(val.target.value);
                }
              }}
              value={details}
            /> */}
                <Editor
                  apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                  value={details}
                  onEditorChange={(newValue, editor) => {
                    setdetails(newValue);
                  }}
                />
              </div>

              <div className="form-group my-4">
                {/* <label htmlFor="personalization">
              Add Your Personalization{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label> */}
                {/* <textarea
              className="form-control"
              id="personalization"
              rows={3}
              placeholder=" Please Add Your Personalization"
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  setpersonalisation(val.target.value);
                }
              }}
              value={personalisation}
            /> */}
                {/* <Editor
              apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
              value={personalisation}
              onEditorChange={(newValue, editor) => {
                setpersonalisation(newValue);
              }}
            /> */}
              </div>
              <div className="row">
                <div className="col-md-6  ">
                  <div className="form-group">
                    <select
                      className="form-control _borderRed"
                      id="exampleFormControlSelect1"
                      onChange={handlecategory}
                      value={category}
                    >
                      <option value="">Select Catagory</option>
                      {cat.map((item, index) => {
                        return (
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <select
                      className="form-control _borderRed"
                      id="exampleFormControlSelect1"
                      onChange={handleSubcategory}
                      value={subcategory}
                    >
                      <option>Select Sub Catagory</option>
                      {subcat.map((item, index) => {
                        return (
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </div>
                </div>
              </div>

              {/* <div className="form-group my-4">
            <label htmlFor="hashTag">
              Hashtag for Services{" "}
              <span className="required" style={{ color: "red" }}>
                *
              </span>
            </label>
            <textarea
              className="form-control"
              id="hashTag"
              rows={3}
              placeholder="Please Add Hashtag for Service"
              onChange={(val) => {
                if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                  sethashtag(val.target.value);
                }
              }}
              value={hashtag}
            />
          </div> */}
              <div className="form-group my-4">
                <button
                  type="button"
                  className="btn preview_btn"
                  onClick={handleAddFields}
                >
                  Highlights
                </button>
                <br />
                <br />
                {inputFields.map((inputField, index) => {
                  return (
                    <div key={index}>
                      <div className="d-flex justify-content-between add-product-field my-3">
                        <input
                          type="text"
                          name="highlight"
                          className="form-control _borderRed"
                          placeholder="Add Highlights"
                          value={inputField.highlight}
                          onChange={(event) => handleChangeInput(index, event)}
                        />
                        {index ? (
                          <span
                            className="align-self-center"
                            onClick={() => handleRemoveFields(index)}
                          >
                            <i className="far fa-times-circle text-muted"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <h5>
                    Service Image{" "}
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </h5>

                  {preview.length > 0 &&
                    preview.map((item, index) => (
                      <div className="product-thumb">
                        <img
                          className="avatar ml-3 img-fluid img-thumbnail "
                          key={index}
                          style={{ width: "54px", height: "54px" }}
                          src={HttpClient.IMG_URL + item}
                          alt="..."
                        />
                        <button
                          type="button"
                          className="btn cross-btn"
                          onClick={() => deleteFile(index)}
                        >
                          <i className="far fa-times-circle"></i>
                        </button>
                      </div>
                    ))}
                </div>
                <div className="choose_file my-4">
                  <input
                    type="file"
                    multiple
                    className="input_file"
                    onChange={onImageId}
                  />
                  Choose File
                </div>
              </div>

              <div className="d-flex justify-content-between my-5">
                {/* {service.length > 0 ? (
              <Link
                to="/seller-service"
                type="button"
                className="btn preview_btn"
              >
                Preview
              </Link>
            ) : null} */}
                {suspendstatus == "suspend" || sellerData.status === false ? (
                  <button
                    type="button"
                    className="btn publish_btn"
                    onClick={suspendStatus}
                  >
                    Publish
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn publish_btn"
                    onClick={publish}
                  >
                    Publish
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Index;
