import React, { useEffect, useState } from "react";
import Footer from "../../Defaults/Footer";
import HttpClient from "../../utils/HttpClient";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactTitle } from "react-meta-tags";
import { reactLocalStorage } from "reactjs-localstorage";
import ReCAPTCHA from "react-google-recaptcha";

const Feedback = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [feedback, setfeedback] = useState("");
  const [isVerifiedRecaptcha, setisVerifiedRecaptcha] = useState(false);
  const userData = reactLocalStorage.getObject("userData");

  const feedbackSubmit = async () => {
    if (feedback == "") {
      toast.error("Please enter feedback", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (Object.keys(userData).length > 0) {
        let sendData = {
          name: userData.firstName + " " + userData.lastName,
          email: userData.email,
          feedback_detail: feedback,
        };
        let result = await HttpClient.requestData("feedback", "POST", sendData);
        // console.log("submit feedback", result);
        if (result && result.status) {
          toast.success("Thank you for your valuable feedback.", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setfeedback("");
        } else {
          toast.error("Error in Adding Feedback", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("You have to login first", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const regex = /^[ A-Za-z0-9_!@./*$%()#&+-]*$/;
  function onChange(value) {
    console.log("Captcha value:", value);
    setisVerifiedRecaptcha(true);
  }

  return (
    <div className="feedback">
      <ReactTitle title="Astrophy | Feedback" />
      <div className="container-fluid">
        <div className="row">
          <ToastContainer />
          <div className="col-md-12 text-center my-4">
            <h2>Feedback</h2>
          </div>
          <div className="col-md-6 m-auto">
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">
                Give Your Feedback Here
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={5}
                placeholder="Write Here..."
                value={feedback}
                onChange={(val) => {
                  if (val.target.value.match(regex) != null) {
                    setfeedback(val.target.value);
                  }
                }}
              />
            </div>
            <br />
            <div className="form-group">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={onChange}
              />
            </div>
            <div className="text-center my-4">
              <button
                className="btn submit_btn"
                onClick={feedbackSubmit}
                disabled={!isVerifiedRecaptcha}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="mt-5">
        <Footer />
      </section>
    </div>
  );
};

export default Feedback;
