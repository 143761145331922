import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import itemImg from "../../Assets/Images/service_list 10.png";
import Favourite from "../../Assets/Images/favourite.png";
import blankImg from "../../Assets/Images/blank.jpg";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../utils/HttpClient";
const SerarchListService = (props) => {
  console.log("search data list", props.pro);
  const userData = reactLocalStorage.getObject("userData");
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const loop = [1, 2, 3];

  const type = props.type;

  const singlepage = (item) => {
    if (type == "product") {
      window.location.href = "/product-details/" + item._id;
      // window.history.pushState(null, '', '/checkoutservice');
      // window.location.href = "/checkoutservice";
    } else {
      window.location.href = "/service-details/" + item._id;
    }
  };

  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(" ", "-");
    return strReplace;
  };

  return (
    <div className="row">
      <ToastContainer />
      {props.pro
        ? props.pro.map((item, index) => {
            let tax_percent = item.tax ? item.tax.replace("%", "") : 0;
            let b = Number(item.mrp) * (Number(tax_percent) / 100);
            let selling_priceWithTax = (item.mrp + b).toFixed(2);
            return (
              <div className="col-lg-3 col-md-6 my-3" key={index}>
                <div>
                  <div className="card service_list _service_Allpage">
                    <Slider {...settings}>
                      {item.image
                        ? item.image.map((item2, index2) => {
                            return (
                              <div key={index2}>
                                {props.type == "service" ? (
                                  <Link
                                    to={
                                      "/service-details/" +
                                      nameToSlug(item.name) +
                                      "/" +
                                      item._id
                                    }
                                  >
                                    <img
                                      src={
                                        item.image
                                          ? HttpClient.IMG_URL + item.image[0]
                                          : blankImg
                                      }
                                      alt="img"
                                      className="relative service_list_img"
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    to={
                                      "/product-details/" +
                                      nameToSlug(item.name) +
                                      "/" +
                                      item._id
                                    }
                                  >
                                    <img
                                      src={
                                        item.image
                                          ? HttpClient.IMG_URL + item.image[0]
                                          : blankImg
                                      }
                                      alt="img"
                                      className="relative service_list_img"
                                    />
                                  </Link>
                                )}
                              </div>
                            );
                          })
                        : ""}
                    </Slider>
                    <div className="card-body service_list_text p-3">
                      <div className="d-flex justify-content-between align-items-center ">
                        <div className="d-flex">
                          <i className="fas fa-star my-1 mr-2" />
                          <h6 className="mb-0">
                            {item.avgRating != null ? item.avgRating : 0}/5
                          </h6>
                        </div>
                        <div />
                      </div>
                      {/* <div>
                        <div className="absolute_bg">
                          <i className="far fa-heart text-danger" />
                        </div>
                      </div> */}
                      {props.type == "service" ? (
                        <Link
                          to={
                            "/service-details/" +
                            nameToSlug(item.name) +
                            "/" +
                            item._id
                          }
                        >
                          <p className="card-text">{item.name}</p>
                        </Link>
                      ) : (
                        <Link
                          to={
                            "/product-details/" +
                            nameToSlug(item.name) +
                            "/" +
                            item._id
                          }
                        >
                          <p className="card-text">{item.name}</p>
                        </Link>
                      )}

                      <h5 className="my-3">
                        {/* ₹  */}
                        {userData.currency == "INR"
                          ? "₹"
                          : userData.currency == "CAD"
                          ? "$"
                          : userData.currency == "USD"
                          ? "$"
                          : userData.currency == "	GBP"
                          ? "£"
                          : userData.currency == "EUR"
                          ? "€"
                          : "₹"}
                        {props.type == "service"
                          ? item.price
                          : item.selling_price}
                        {/* {item.mrp} */}
                      </h5>
                      {props.type == "product" ? (
                        <>
                       {selling_priceWithTax>item.selling_price&&   
                       <del className="text-muted font-italic ml-2 small">
                            {userData.currency == "INR"
                              ? "₹"
                              : userData.currency == "CAD"
                              ? "$"
                              : userData.currency == "USD"
                              ? "$"
                              : userData.currency == "GBP"
                              ? "£"
                              : userData.currency == "EUR"
                              ? "€"
                              : "₹"}{" "}
                            {selling_priceWithTax}
                          </del>}
                        </>
                      ) : null}
                      {props.type == "service" ? (
                        <div className="d-flex mt-3">
                          <img
                            src={
                              item.shop_details.length
                                ? HttpClient.IMG_URL +
                                  item.shop_details[0].shop_img
                                : blankImg
                            }
                            alt="img"
                            className="mr-2 rounded-circle"
                            style={{
                              width: "100%",
                              height: "25px",
                              maxWidth: "25px",
                            }}
                          />
                          <h4>
                            {item.shop_details.length
                              ? item.shop_details[0].name
                              : ""}
                          </h4>
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="text-center d-flex ml-2">
                      <button className="btn my-3 red_btn">Buy Now</button>
                      <button
                        className="cart btn "
                        style={{ color: "rgb(232, 74, 86)", fontWeight: 600 }}
                        // onClick={() => addcart(item)}
                      >
                        + Add to Cart
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
              // <div
              //   className="card service_list"
              //   style={{ minHeight: "400px" }}
              // >
              //   {/* <Link to={"product-details/"+item._id}> */}
              //   <div onClick={() => singlepage(item)}>
              //     <Slider {...settings}>
              //       {loop.map((item2, index2) => {
              //         return (
              //           <div key={index2}>
              //             {" "}
              //             <img
              //               src={HttpClient.IMG_URL + item.image}
              //               alt="img"
              //               className="relative service_list_img"
              //             />
              //             <img
              //               src={Favourite}
              //               alt="img"
              //               className="absolute m-2"
              //             />
              //           </div>
              //         );
              //       })}
              //     </Slider>
              //     {/* </Link> */}
              //   </div>
              //   <div className="card-body product_list_text py-2">
              //     <h6>{item.name}</h6>
              //     <div className="d-flex py-3">
              //       {type == "product" ? (
              //         <del className="text-muted">$ {item.mrp}</del>
              //       ) : (
              //         ""
              //       )}
              //       <p className="card-text px-3 text-dark">
              //         $ {type == "product" ? item.selling_price : item.price}
              //       </p>
              //     </div>

              //     {/* <div className="d-flex justify-content-between align-items-center"> */}
              //     {/* <div className="rating">
              //           {" "}
              //           <i className="fas fa-star my-1 mr-2"></i>
              //           {item.avgRating ? item.avgRating : "0"}/5
              //         </div> */}
              //     {/* <Link to="/add-cart"> */}
              //     {/* {type == "product" ? (
              //         <button className="cart btn text-danger " onClick={() => addcart(item)}>
              //           + Add to Cart
              //         </button>
              //       ) : (
              //         <button className="cart btn text-danger ">
              //           Book Now
              //         </button>
              //       )} */}

              //     {/* </Link> */}
              //     {/* </div> */}
              //   </div>
              // </div>
            );
          })
        : null}
    </div>
  );
};
export default SerarchListService;
