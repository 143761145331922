import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { confirmAlert } from "react-confirm-alert";
import Confirmation from "./Confirmation";
import Loader from "../Component/loader";
import ReCAPTCHA from "react-google-recaptcha";
import chatsent from "../Assets/Images/chat_sent.png";

const ServiceModal = (props) => {
  const [serviceimg, setserviceimg] = useState();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [address, setaddress] = useState("");
  const [govtIdName, setgovtIdName] = useState("");
  const [govtId, setgovtId] = useState("");
  const [preview, setpreview] = useState([]);
  const [multiImages, setmultiImages] = useState([]);
  const [arr, setarr] = useState([]);
  const userData = reactLocalStorage.getObject("userData");
  const [blankemail, setblankemail] = useState(false);
  const [isEmailFromatValid, setisEmailFromatValid] = useState(false);
  const [confirm, setconfirm] = useState(false);
  const [isVerifiedRecaptcha, setisVerifiedRecaptcha] = useState(false);
  const [loader, setloader] = useState(false);

  useEffect(() => {}, []);

  const onImageId = async (e) => {
    if (e.target.files.length > 0) {
      const imageArr = Array.from(e.target.files);
      console.log("imageArr", imageArr);
      const imageUrlArray = [];
      imageArr.forEach((file) => {
        preview.push({ preview: URL.createObjectURL(file) });
      });
      setpreview(preview);
      imageUploadApi(imageArr);
      //   setrefresh(!refresh);
      // setTimeout(() => {
      //   console.log("previeww", preview);
      // }, 1000);
    }
  };

  const setImagesUrl = (url) => {
    let tempUrlArr = multiImages;
    tempUrlArr.push(url);
    setmultiImages(tempUrlArr);
    console.log("multiImages", multiImages);
  };

  const imageUploadApi = async (files) => {
    //need to start loader
    //  setshowLoder(true);
    console.log("files", files);
    for (const file of files) {
      let data = new FormData();
      data.append("image", file);
      setloader(true);
      let result = await HttpClient.fileUplode(
        "shop-service-images",
        "POST",
        data
      );
      console.log("reuslkyet", result);

      if (result && result.status) {
        setImagesUrl(result.data);
        setloader(false);
      }
    }
    //need to stop loader
    //  setshowLoder(false);
  };

  const deleteFile = (e) => {
    const s = arr.filter((item, index) => index !== e);
    setarr(s);
    // console.log(s);
  };

  const onImage = async (event) => {
    setserviceimg(event.target.files[0]);
    setarr([
      {
        preview: URL.createObjectURL(event.target.files[0]),
      },
    ]);
  };
  const closeConfirmation = (val) => {
    setconfirm(val);
    props.callBackService(false);
    props.success(true);
  };

  const apply = async () => {
    if (
      name != "" &&
      email != "" &&
      phone != "" &&
      address != "" &&
      govtIdName != "" &&
      govtId != "" &&
      arr.length > 0
    ) {
      if (isEmailFromatValid) {
        setisEmailFromatValid(true);
      } else {
        let data = new FormData();
        data.append("name", name);
        data.append("email", email);
        data.append("phone", phone);
        data.append("address", address);
        data.append("govt_id_name", govtIdName);
        data.append("govt_id", govtId);
        data.append("image", serviceimg);
        data.append("seller_id", userData._id);
        setloader(true);
        let result = await HttpClient.fileUplode(
          "apply-for-seller",
          "POST",
          data
        );
        console.log("Apply data", result);
        if (result && result.status) {
          setname("");
          setemail("");
          setphone("");
          setaddress("");
          setgovtIdName("");
          setserviceimg("");
          setgovtId("");
          // toast.success(result.message, {
          //   position: "bottom-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          setconfirm(true);
          setloader(false);
          reactLocalStorage.setObject("seller_approval_id", result.data._id);
        } else {
          toast.error(result.message, {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      toast.warning("Please Enter All Fields", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  function validateEmail(emailId) {
    let regex = /\S+@\S+\.\S+/;
    let valid = regex.test(emailId);
    console.log(valid);
    setisEmailFromatValid(valid ? false : true);
  }

  function onChange(value) {
    console.log("Captcha value:", value);
    setisVerifiedRecaptcha(true);
  }
  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="modal-dialog  my-3 ">
            {/* <ToastContainer /> */}
            <div className="modal-content add-service_modal">
              <button
                type="button"
                className="cross _bgclose close px-4 py-4"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.callBackService(false)}
              >
                <span aria-hidden="true" style={{ float: "right" }}>
                  ×
                </span>
              </button>
              <img src={chatsent} className="img_arrow"></img>
              <div className="login-body mt-5 mb-3 px-5 ">
                <div className="Add_service ">
                  <div className="Toastify" />
                  <div className="text-center">
                    <h2>APPLY FOR SELLER</h2>
                  </div>

                  <div className="form-group my-4">
                    <label htmlFor="name">
                      Name{" "}
                      <span className="required" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="email"
                      className="form-control _strbrdr"
                      id="name"
                      aria-describedby="emailHelp"
                      placeholder="Name"
                      onChange={(val) => {
                        setname(val.target.value);
                      }}
                      value={name}
                    />
                  </div>
                  <div className="form-group my-4">
                    <label htmlFor="exampleInputEmail1">
                      Email{" "}
                      <span className="required" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="email"
                      className="form-control _strbrdr"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      onChange={(val) => {
                        setemail(val.target.value);
                        if (val.target.value == "") {
                          setblankemail(true);
                          validateEmail(val.target.value);
                        } else {
                          setblankemail(false);
                          validateEmail(val.target.value);
                        }
                      }}
                      value={email}
                    />
                    {blankemail ? (
                      <small style={{ color: "red" }}>
                        {" "}
                        Please enter email.{" "}
                      </small>
                    ) : isEmailFromatValid ? (
                      <small style={{ color: "red" }}>
                        {" "}
                        Please enter a valid email.{" "}
                      </small>
                    ) : null}
                  </div>
                  <div className="form-group my-4">
                    <label htmlFor="phone">
                      Phone{" "}
                      <span className="required" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="number"
                      // inputmode="numeric"
                      className="form-control _strbrdr"
                      id="phone"
                      aria-describedby="emailHelp"
                      placeholder="Phone Number"
                      onChange={(val) => {
                        setphone(val.target.value);
                      }}
                      value={phone}
                    />
                  </div>
                  <div className="form-group my-4">
                    <label htmlFor="address">
                      Address{" "}
                      <span className="required" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <textarea
                      className="form-control _strbrdr"
                      id="address"
                      rows={3}
                      placeholder="Address"
                      onChange={(val) => {
                        setaddress(val.target.value);
                      }}
                      value={address}
                    />
                  </div>
                  <div className="form-group my-4">
                    <label htmlFor="govtIdname">
                      Goverement Id Name{" "}
                      <span className="required" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control _strbrdr"
                      id="govtIdname"
                      aria-describedby="emailHelp"
                      placeholder="Goverement Id Name"
                      onChange={(val) => {
                        setgovtIdName(val.target.value);
                      }}
                      value={govtIdName}
                    />
                  </div>
                  <div className="form-group my-4">
                    <label htmlFor="govid">
                      Goverement Id Number{" "}
                      <span className="required" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      // inputmode="numeric"
                      className="form-control _strbrdr"
                      id="govid"
                      aria-describedby="emailHelp"
                      placeholder="Goverement Id Number"
                      onChange={(val) => {
                        setgovtId(val.target.value);
                      }}
                      value={govtId}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>
                        Image{" "}
                        <span className="required" style={{ color: "red" }}>
                          *
                        </span>
                      </h5>

                      {arr.map((item, index) => (
                        <div className="product-thumb">
                          <img
                            className="avatar ml-3 img-fluid img-thumbnail "
                            key={index}
                            style={{ width: "54px", height: "54px" }}
                            src={item.preview}
                            alt="..."
                          />
                          <button
                            type="button"
                            className="btn cross-btn"
                            onClick={() => deleteFile(index)}
                          >
                            <i className="far fa-times-circle"></i>
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="choose_file my-4">
                      <input
                        type="file"
                        multiple
                        className="input_file"
                        onChange={onImage}
                      />
                      Choose File
                    </div>
                  </div>
                  <div className="form-group my-4">
                    <ReCAPTCHA
                      sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      onChange={onChange}
                    />
                  </div>
                  <div className="d-flex justify-content-between my-2">
                    <button
                      type="button"
                      className="btn publish_btn"
                      onClick={apply}
                      disabled={!isVerifiedRecaptcha}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={confirm ? "modal fade loginmodal show" : "modal fade"}
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: confirm ? "block" : "none" }}
          >
            <Confirmation closeConfirmation={closeConfirmation} />
          </div>
        </>
      )}
    </div>
  );
};
export default ServiceModal;
