import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "@tinymce/tinymce-react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

const ServiceModal = (props) => {
  console.log("propsdata---", props.item);
  console.log("id", props.item.subcategory_id);
  const [currency, setcurrency] = useState(props.item.currency);
  const [currencyTyp, setcurrencyTyp] = useState([
    {
      name: "INR",
    },
    {
      name: "USD",
    },
    {
      name: "GBP",
    },
    {
      name: "EUR",
    },
    {
      name: "CAD",
    },
    // {
    //   name: "cdd",
    // },
  ]);
  const [name, setname] = useState(props.item.name);
  const [price, setprice] = useState(props.item.price);
  const [details, setdetails] = useState(props.item.details);
  const [personalisation, setpersonalisation] = useState(
    props.item.personalization
  );
  const [hashtag, sethashtag] = useState(props.item.hashtags);
  const [serviceimg, setserviceimg] = useState(props.item.serviceimg);
  // const {service_items}=props.item;

  const [shopid, setshopid] = useState("");
  const [inputFields, setInputFields] = useState(
    props.item.highlights ? props.item.highlights : [{ highlight: "" }]
  );

  // const [preview, setpreview] = useState([HttpClient.IMG_URL + props.item.image]);
  const [preview, setpreview] = useState(props.item.image);
  const [multiImages, setmultiImages] = useState(props.item.image);

  const [category, setcategory] = useState(props.item.category_id);

  const [subcategory, setSubcategory] = useState(props.item.subcategory_id);

  const [categoryName, setcategoryName] = useState(props.item.cat_name);
  const [subcategoryName, setSubcategoryName] = useState(
    props.item.subcat_name
  );

  const [cat, setcat] = useState([]);
  const [subcat, setsubcat] = useState([]);

  useEffect(() => {
    console.log("first preview", preview);
    fetchCat();

    fetchShopId();
    alreadyfetchSubCat(props.item.category_id);
  }, []);

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };
  const handleAddFields = () => {
    setInputFields([...inputFields, { highlight: "" }]);
  };
  const handleRemoveFields = (index) => {
    const values = [...inputFields];

    values.splice(index, 1);
    setInputFields(values);
  };

  const onImageUpload = async (e) => {
    console.log(e.target.files);

    imageUploadApi(e.target.files[0]);
  };

  const imageUploadApi = async (files) => {
    let imageUrlArray = [];

    let data = new FormData();
    data.append("image", files);
    let result = await HttpClient.fileUplode(
      "shop-service-images",
      "POST",
      data
    );
    console.log("image", result);
    if (result && result.status) {
      // preview.push(result.data);
      setpreview((prevState) => [...prevState, result.data]);
      setmultiImages((prevState) => [...prevState, result.data]);
    } else {
    }

    console.log("preview", multiImages);
  };

  const deleteFile = (e) => {
    let arr = preview;
    console.log("e", e);
    setpreview([]);
    setmultiImages([]);
    arr.splice(e, 1);
    console.log("arr", arr);
    arr.forEach((item) => {
      setpreview((prevState) => [...prevState, item]);
      setmultiImages((prevState) => [...prevState, item]);
    });
    //setpreview(arr);
    //setmultiImages(preview);
    console.log(preview);
  };

  const handlecategory = (event) => {
    const selectedcategory = event.target.value;
    setcategory(selectedcategory);
    fetchSubCat(selectedcategory);
  };

  const handleSubcategory = (event) => {
    console.log("opopo", event.target.value);
    const selectedSubcategory = event.target.value;
    setSubcategory(selectedSubcategory);
  };

  const fetchCat = async () => {
    console.log("chotolok", preview);
    let result = await HttpClient.requestData("service", "GET");
    // console.log("Cat", result);
    if (result && result.status) {
      let data = result.data.filter((item) => item.status == true);
      // console.log("data", data);
      setcat(data);
      // fetchSubCat(props.item.category_id);
      // console.log("setcat", cat);
    } else {
    }
  };

  const alreadyfetchSubCat = async (id) => {
    let result = await HttpClient.requestData(
      "service/subcategory/" + id,
      "GET"
    );
    // console.log("SubCat", result);
    if (result && result.status) {
      let data = result.data;
      // console.log("subdata", data);
      setsubcat(data);
      // console.log("SubCatdata", subcat);
    } else {
    }
  };

  const fetchSubCat = async (id) => {
    // let result = await HttpClient.requestData(
    //   "service/subcategory/" + id,
    //   "GET"
    // );
    // // console.log("SubCat", result);
    // if (result && result.status) {
    //   let data = result.data;
    //   // console.log("subdata", data);
    //   setsubcat(data);
    //   // console.log("SubCatdata", subcat);
    // } else {
    // }
    console.log("jjjjjjjj", id);
    console.log("kat", cat);
    let result = cat.filter((item) => item._id === id);
    console.log("SubCat", result);
    if (result && result.length > 0) {
      let data = result[0].service_data;
      console.log("SubCat data", data);
      setsubcat(data);
    } else {
    }
  };

  const fetchShopId = async () => {
    let userdata = reactLocalStorage.getObject("userData");
    // console.log("userdata", userdata);
    let result = await HttpClient.requestData("seller/" + userdata._id, "GET");
    // console.log("Shop", result.shop_id);
    if (result && result.status) {
      setshopid(result.shop_id);
      // console.log("shopid", shopid);
    }
  };
  const update = async () => {
    // let userdata = reactLocalStorage.getObject("userData");
    if (multiImages.length > 5) {
      toast.warning("Number of Image Upload should be 5 or less than 5", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (
      name != "" &&
      price != "" &&
      details != "" &&
      category != "true" &&
      subcategory != "Select Sub Catagory" &&
      // personalisation != "" &&
      // hashtag != "" &&
      currency != "" &&
      multiImages.length > 0
    ) {
      console.log("multi", multiImages);
      let data = new FormData();
      data.append("name", name);
      data.append("price", price);
      data.append("details", details);
      data.append("category_id", category);
      data.append("subcategory_id", subcategory);
      data.append("cat_name", categoryName);
      data.append("subcat_name", subcategoryName);
      data.append("personalization", personalisation);
      data.append("hashtags", hashtag);
      data.append("currency", currency);
      data.append("highlights", JSON.stringify(inputFields));
      data.append(
        "image",
        multiImages.length
          ? JSON.stringify(multiImages)
          : JSON.stringify(props.item.image)
      );
      data.append("shop_id", shopid);
      console.log();

      let result = await HttpClient.fileUplode(
        "shop/services/" + props.item._id,
        "PUT",
        data
      );
      console.log("Update services", result);
      if (result && result.status) {
        setname("");
        setprice("");
        setdetails("");
        setpersonalisation("");
        sethashtag("");
        setserviceimg("");
        setcategory("");
        setInputFields([]);
        setSubcategory("");
        toast.success(result.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // props.callBackService(false, "");
        // props.success(result.status);
        setTimeout(() => {
          window.location.href = "/seller/seller-service";
        }, 3000);
      } else {
        toast.error(result.message, {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning("Please Fillup All the fields", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div>
      <div className="modal-dialog  my-3 ">
        {/* <ToastContainer /> */}
        <div className="modal-content add-service_modal">
          <button
            type="button"
            className="cross close ml-auto px-4 py-2"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.callBackService(false, "cancel")}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="login-body my-3 px-5 ">
            <div className="Add_service ">
              <div className="Toastify" />
              <div className="text-center">
                <h1>Edit Services</h1>
              </div>

              <div className="form-group my-4">
                <label htmlFor="name2">
                  Service Name{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="name2"
                  aria-describedby="emailHelp"
                  placeholder="Service Name"
                  onChange={(val) => {
                    if (val.target.value.match("^[a-zA-Z0-9 ]*$") != null) {
                      setname(val.target.value);
                      // if (val.target.value == "") {
                      //   setnameerror(true);
                      // } else {
                      //   setnameerror(false);
                      // }
                    }
                    // setname(val.target.value);
                  }}
                  value={name}
                />
              </div>
              <div className="form-group my-4">
                <label htmlFor="currency">
                  Currency{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control _borderRed"
                  id="name"
                  aria-describedby="nameHelp"
                  placeholder="Currency"
                  value={currency}
                  readOnly
                />
                {/* <select
                  className="form-control"
                  id="currency"
                  onChange={(val) => {
                    setcurrency(val.target.value);

                    // getTax(val.target.value);
                  }}
                  value={currency}
                >
                  <option value="">Select Currency</option>
                  {currencyTyp.map((item, index) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
                </select> */}
              </div>
              <div className="form-group my-4">
                <label htmlFor="price">
                  Service Price{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  aria-describedby="emailHelp"
                  placeholder="Service Price"
                  onChange={(val) => {
                    if (
                      val.target.value.match("^[+]?([0-9]*[.])?[0-9]*$") != null
                    ) {
                      setprice(val.target.value);
                    }
                  }}
                  value={price}
                />
              </div>
              <div className="form-group my-4">
                <label htmlFor="details">
                  Service Details{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                {/* <textarea
                  className="form-control"
                  id="details"
                  rows={3}
                  placeholder="Please Add Your Service Details"
                  onChange={(val) => {
                    if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                      setdetails(val.target.value);
                    }
                  }}
                  value={details}
                /> */}
                <Editor
                  apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                  value={details}
                  onEditorChange={(newValue, editor) => {
                    setdetails(newValue);
                  }}
                />
              </div>
              <div className="form-group my-4">
                {/* <label htmlFor="personaliz">
                  Add Your Personalization{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label> */}
                {/* <textarea
                  className="form-control"
                  id="personaliz"
                  rows={3}
                  placeholder=" Please Add Your Personalization"
                  onChange={(val) => {
                    if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                      setpersonalisation(val.target.value);
                    }
                  }}
                  value={personalisation}
                /> */}
                {/* <Editor
                  apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                  value={personalisation}
                  onEditorChange={(newValue, editor) => {
                    setpersonalisation(newValue);
                  }}
                /> */}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={handlecategory}
                      value={category}
                    >
                      <option value>Select Catagory</option>
                      {cat.map((item, index) => {
                        return (
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={handleSubcategory}
                      value={subcategory}
                    >
                      <option>Select Sub Catagory</option>
                      {subcat.map((item, index) => {
                        return (
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="form-group my-4">
                <label htmlFor="hashtag">
                  Hashtag for Services{" "}
                  <span className="required" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <textarea
                  className="form-control"
                  id="hashtag"
                  rows={3}
                  placeholder="Please Add Hashtag for Service"
                  onChange={(val) => {
                    if (val.target.value.match("^[a-zA-Z ]*$") != null) {
                      sethashtag(val.target.value);
                    }
                  }}
                  value={hashtag}
                />
              </div> */}
              <div className="form-group my-4">
                <button
                  type="button"
                  className="btn preview_btn"
                  onClick={handleAddFields}
                >
                  Highlights
                </button>
                <br />
                <br />
                {inputFields.map((inputField, index) => {
                  return (
                    <div key={index}>
                      <div className="d-flex justify-content-between add-product-field my-3">
                        <input
                          type="text"
                          name="highlight"
                          className="form-control"
                          placeholder="Add Highlights"
                          value={inputField.highlight}
                          onChange={(event) => handleChangeInput(index, event)}
                        />
                        {index ? (
                          <span
                            className="align-self-center"
                            onClick={() => handleRemoveFields(index)}
                          >
                            <i className="far fa-times-circle text-muted"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <h5>
                    Service Image{" "}
                    <span className="required" style={{ color: "red" }}>
                      *
                    </span>
                  </h5>
                  {preview.map((item, index) => {
                    return (
                      <div className="product-thumb" key={index}>
                        <img
                          className="avatar ml-3 img-fluid img-thumbnail"
                          style={{ width: "54px", height: "54px" }}
                          src={HttpClient.IMG_URL + item}
                          alt="..."
                        />
                        <button
                          type="button"
                          className="btn cross-btn"
                          onClick={() => deleteFile(index)}
                        >
                          <i className="far fa-times-circle"></i>
                        </button>
                      </div>
                    );
                  })}
                  {/* {} */}
                </div>
                <div className="choose_file my-4">
                  <input
                    type="file"
                    className="input_file"
                    onChange={onImageUpload}
                  />
                  Choose File
                </div>
              </div>
              <div className="d-flex justify-content-between my-5">
                <button
                  type="button"
                  className="btn publish_btn"
                  onClick={update}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceModal;
