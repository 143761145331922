// import React, { useEffect } from "react";
import Provider_banner from "../../Assets/Images/provider_banner.png";
import provider_card_img from "../../Assets/Images/provider_card_img.png";
import Provider_name from "../../Assets/Images/user.jpg";
import contact_logo from "../../Assets/Images/Vector (6).png";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShowMore from "./ShowMore";
const ProviderBanner = (props) => {
  useEffect(() => {
    getdetails();
  }, []);

  useEffect(() => {
    const aboutFromate =
      Object.keys(details).length != 0 ? details.shop_data.personalization : "";

    // const formatedAbout = new DOMParser().parseFromString(
    //   aboutFromate,
    //   "text/html"
    // );

    // document.getElementById("contentDiv").innerHTML =
    //   formatedAbout.body.innerHTML;
  }, 2000);
  const [servicedetails, setservicedetails] = useState([]);
  const details = props.details;
  console.log("details", details);
  const userData = reactLocalStorage.getObject("userData");

  const idd = Object.keys(details).length != 0 ? details.shop_data.userid : "";

  const shop_id = Object.keys(details).length != 0 ? details.shop_data._id : "";

  const date = Object.keys(details).length != 0 ? details.shop_data.start : "";

  const getdetails = async () => {
    let sendData = {};
    console.log("object", sendData);
    let result = await HttpClient.requestData(
      "shop/all-services/" + shop_id,
      "GET",
      sendData
    );
    // console.log("shop/allprovidedServiceList/", result);
    if (result && result.status) {
      setservicedetails(result.data);
    } else {
    }
  };

  const handleChangeProvider = (event) => {
    console.log(event.target.value);
    let id = event.target.value;
    window.location.href = "/service-details/" + id;
  };

  const redirect = (id) => {
    console.log(id);
    // alert("tp")
    window.location.href = "/service-details/" + id;
  };
  return (
    <div>
      <div className="Provider_banner">
        {/* {Object.keys(details).length != 0 ? (
          <img
            src={HttpClient.IMG_URL + details.shop_details.banner_img}
            alt="img"
          />
        ) : ( */}
        {Object.keys(details).length != 0 ? (
          <img
            src={HttpClient.IMG_URL + details.shop_data.banner_img}
            alt="img"
            style={{ height: "400px", width: "100%"}}
          />
        ) : (
          <img src={provider_card_img} alt="img" style={{ height: "400px" }}/>
        )}
        {/* <img src={Provider_banner} alt="img" style={{ height: "400px" }} /> */}
        {/* )} */}
      </div>
      <div
        className="container provider_banner_card"
        style={{ maxWidth: "1200px" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-md-flex">
                  <div className="provider_card_img">
                    {Object.keys(details).length != 0 ? (
                      <img
                        src={HttpClient.IMG_URL + details.shop_data.shop_img}
                        alt="img"
                      />
                    ) : (
                      <img src={provider_card_img} alt="img" />
                    )}
                  </div>
                  <div className="provider_card_head px-3 test">
                    <div className="d-flex flex-wrap mb-2 ">
                      <p className="my-2">
                        <i className="fas fa-star icon  mr-2"></i>
                        {Object.keys(details).length != 0
                          ? details.avgRating ? details.avgRating.toFixed(2) : 0
                          : 0}
                        /5
                      </p>
                      <p className="sales-count my-2">{Object.keys(details).length != 0
                          ? details.salesCount
                          : 0}{" "}Sales</p>
                      
                      <button
                        className=" btn provider_shop_btn mx-2"
                        type="button"
                      >
                        <i className="far fa-heart"></i> Favourite Shop {" "}
                        {Object.keys(details).length != 0
                          ? details.wish_data.length
                          : 0}
                      </button>
                      <button
                        className="btn provider_shop_btn mt-md-0 mt-3"
                        type="button"
                      >
                        <i className="fas fa-shopping-cart"></i>{" "}
                        {Object.keys(details).length != 0
                          ? details.salesCount
                          : 0}{" "}
                        Sales
                      </button>
                    </div>

                    <h4>
                      {Object.keys(details).length != 0
                        ? details.shop_data.name
                        : null}
                    </h4>
                    {/* <span>{details.firstName + " " + details.lastName}</span> */}
                    <span>
                      {Object.keys(details).length != 0
                        ? details.shop_data.title
                        : null}
                    </span>
                    <p>
                      <i className="fas fa-map-marker-alt location mr-2"></i>
                      {Object.keys(details).length != 0
                        ? details.country.value
                        : ""}
                    </p>
                    {/* <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={handleChangeProvider}
                      >
                        <option>Provided Services</option>
                        {servicedetails.length > 0 &&
                          servicedetails.map((item, index) => {
                            return (
                               <Link to={"/service-details/" + item._id}>
                              <option
                                key={index}
                                value={item._id}
                                // onClick={() => redirect(item._id)}
                              >
                                {item.name}
                              </option>
                              </Link>
                            );
                          })}
                      </select>
                    </div> */}
                  </div>
                  <div className="provider_card_right ml-auto">
                    {/* <p className="text-right">
                      Last updated on {moment(date).format("MMMM Do YYYY")}
                    </p> */}
                    <div className="d-flex  details_sub_text">
                      <div className="d-flex border-left px-2 align-items-center test">
                        <img
                          src={
                            details.image
                              ? HttpClient.IMG_URL + details.image
                              : Provider_name
                          }
                          alt="img"
                          style={{
                            width: "100%",
                            height: "50px",
                            maxWidth: "50px",
                            borderRadius: "50%",
                            objectFit: "cover"
                          }}
                        />
                        <div className=" mx-2">
                          <h5 className="mb-0">
                            {Object.keys(details).length != 0
                              ? details.firstName + " " + details.lastName
                              : null}
                          </h5>
                          <p className="mb-0">
                            Owner of{" "}
                            {Object.keys(details).length != 0
                              ? details.shop_data.name
                              : null}
                          </p>
                        </div>
                      </div>

                      {Object.keys(userData).length != 0 ? (
                        userData._id != idd ? (
                          <div className="contact" onClick={() => window.location.href = "/messages"}>
                            <img
                              src={contact_logo}
                              alt="img"
                              className="mx-3"
                            />
                            <span>Contact</span>
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="provider_card_text mt-4">
                  <h5>About Shop</h5>

                  {/* <p>
                    {aboutShop}
                  </p> */}
                  {/* <p>
                    {Object.keys(details).length != 0 ? details.shop_data.personalization.replace(/(<([^>]+)>)/ig, '') : ""}
                  </p> */}
                  {/* <div id="contentDiv"></div> */}
                  {Object.keys(details).length != 0 ? (
                    <ShowMore data={details.shop_data.personalization} />
                  ) : (
                    ""
                  )}
                  {/* <div dangerouslySetInnerHTML={{ __html: Object.keys(details).length != 0
                        ? <ShowMore data={details.shop_data.personalization} />
                        : "" }}></div> */}
                  {/* <h6 className="text-right">Read More</h6>  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProviderBanner;
