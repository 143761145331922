import React, { useState, useEffect } from "react";
import Footer from "../../Defaults/Footer";
import HttpClient from "../../utils/HttpClient";
import { ReactTitle } from "react-meta-tags";
const Associate = () => {
  const loop = [1, 2, 3, 4];
  const [associates, setassociates] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAssociates();
  }, []);
  const fetchAssociates = async () => {
    let result = await HttpClient.requestData("cms/Associates", "GET");
    console.log("fetchAssociates", result);
    if (result && result.status) {
      setassociates(result.data);
    } else {
    }
  };
  return (
    <div className="associate casting_area">
      <ReactTitle title="Astrophy | Our Associates" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center my-3">
            <h2>Our Associates</h2>
          </div>
          {associates.length ? (
            associates.map((item, index) => {
              return (
                <div className="col-md-3" key={index}>
                  <div className="card text-center casting_sec p-3 my-3">
                    <div className="card-body casting_text p-0">
                      <img
                        className=""
                        // src="https://www.logodesign.net/logo/abstract-cuboid-building-4519ld.png"
                        src={HttpClient.IMG_URL + item.image}
                        alt="img"
                      />

                      <h4 className="my-3">{item.heading}</h4>
                      <p>{item.content1}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-md-12 text-center blank_product">
              <h4>
                No Associates found <br></br>
              </h4>
            </div>
          )}
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Associate;
