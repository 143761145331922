import React from "react";
import backImg from "../../Assets/Images/Background.jpg";

export default function SellerBreadcumb(props) {
  return (
    <section
      className="page_top_section"
      style={{ backgroundImage: `url('${backImg}')` }}
    >
      <div className="page_top">
        <div className="page_top_title">
          <h3>{props.title ? props.title : ""}</h3>
          <p>{props.description ? props.description : ""}</p>
        </div>
      </div>
    </section>
  );
}
