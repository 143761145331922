import React, { useEffect, useState } from "react";
import SellOrder from "../../Views/Seller/SellOrder";
import Graph from "../component/Graph";
import ServiceList5 from "../../Assets/Images/service_list 1.png";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import BlankOrder from "../../Views/Orders/BlankOrder";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import RecentAct from "./RecentAct";
import ProCharts from "./ProCharts";

const Overview = () => {
  const userData = reactLocalStorage.getObject("userData");
  const [order, setorder] = useState([]);
  const [totalview, settotalview] = useState(0);
  const [total_active_services, settotal_active_services] = useState(0);
  const [allservices, setallservices] = useState(0);
  const [total_inactive_Services, settotal_inactive_Services] = useState(0);
  const [revenue_this_year, setrevenue_this_year] = useState(0);
  const [totalorder, settotalorder] = useState(0);
  const [loading, setLoading] = useState(false);
  // profile part ta
  const [refund, setrefund] = useState(0);
  const [totalTips, settotalTips] = useState(0);
  const [totalEarning, settotalEarning] = useState(0);
  const [totalSettle, settotalSettle] = useState(0);
  const [pending_settle, setpending_settle] = useState(0);
  const [wallet, setwallet] = useState(0);
  const [yearlytotal, setyearlytotal] = useState(0);
  const [monthlytotal, setmonthlytotal] = useState(0);
  const [weeklytotal, setweeklytotal] = useState(0);
  const [yesterdaytotal, setyesterdaytotal] = useState(0);
  const [todaytotal, settodaytotal] = useState(0);
  const [totalorder_accept, settotalorder_accept] = useState(0);
  const [totalorder_cancel, settotalorder_cancel] = useState(0);
  const [totalorder_reject, settotalorder_reject] = useState(0);
  const [totalorder_pending, settotalorder_pending] = useState(0);
  const [processing_fee, setprocessing_fee] = useState(0);
  const [dateSelect, setdateSelect] = useState("");
  const [last_month, setlast_month] = useState(false);
  const [yesterday, setyesterday] = useState(false);
  const [today, settoday] = useState(true);
  const [to_date, setto_date] = useState("");
  const [from_date, setfrom_date] = useState("");
  const [enable, setenable] = useState(false);
  const [recentActivity, setrecentActivity] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDashboardData(true, false, false);
    getorderlist();
    fetchcount(true, false, false);
    getEarningshistory();
    getRecentActivity();
  }, []);

  const getRecentActivity = async () => {
    // setLoading(true);
    let result = await HttpClient.requestData(
      "viewAllshopservicelistreviewsRating/" + userData._id,
      "GET"
    );
    // console.log("recent activity", result);
    if (result && result.status) {
      let reviewData = [];
      result.data.map((item) => {
        if (item.rev_data.length) {
          item.rev_data.map((rev) => {
            rev.service_name = item.name;
            reviewData.push(rev);
          });
        }
      });
      // console.log("review data---",reviewData);
      setrecentActivity(reviewData);
    } else {
      setrecentActivity([]);
    }
  };

  const update = (val) => {
    if (val) {
      fetchcount(true, false, false);
      getDashboardData(true, false, false);
    }
  };

  const getDashboardData = async (today, yesterday, last_month) => {
    let data = {
      datefrom: from_date,
      dateto: to_date,
      last_month: last_month,
      yesterday: yesterday,
      today: today,
      id: userData._id,
    };
    console.log("data----", data);
    let result = await HttpClient.requestData("summary-stats", "POST", data);
    console.log("summary-stats", result);
    if (result && result.status) {
      settotalview(result.total_views);
      settotal_active_services(result.total_active_services);
      settotal_inactive_Services(result.total_inactive_Services);
      setrevenue_this_year(result.revenue_this_year);
      setallservices(
        result.total_active_services + result.total_inactive_Services
      );
      settotalorder(result.total_orders);
    } else {
    }
  };

  const getorderlist = async () => {
    setLoading(true);
    let data = {};
    let result = await HttpClient.requestData(
      "sellerbookhistory/" + userData._id,
      "GET",
      data
    );
    // console.log("order details", result);
    if (result && result.status) {
      setorder(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };

  const fetchcount = async (today, yesterday, last_month) => {
    let dataa = {
      datefrom: from_date,
      dateto: to_date,
      last_month: last_month,
      yesterday: yesterday,
      today: today,
      id: userData._id,
      currencyy: userData.currency,
    };
    console.log("data 1----", dataa);
    let result = await HttpClient.requestData(
      "getSellersettlement",
      "POST",
      dataa
    );
    console.log("getSellersettlement", result);
    if (result) {
      let totalEarning = Math.abs(
        result.total_earnings -
          (
            (result.total_earnings * result.processingfees.processing_fees) /
            100
          ).toFixed(2)
      );
      let totalPending = Math.abs(
        result.pending_settlement -
          (
            (result.pending_settlement *
              result.processingfees.processing_fees) /
            100
          ).toFixed(2)
      );
      // settotalEarning(result.total_earnings ? result.total_earnings : 0);
      settotalEarning(totalEarning ? totalEarning : 0);
      setprocessing_fee(
        result.processingfees.processing_fees
          ? result.processingfees.processing_fees
          : 0
      );
      settotalTips(result.totalTips ? result.totalTips : 0);
      // settotalSettle(result.earning_settled);
      settotalSettle(result.earning_settled ? result.earning_settled : 0);
      // setpending_settle(
      //   result.pending_settlement ? result.pending_settlement : 0
      // );
      setpending_settle(totalPending ? totalPending : 0);
      setwallet(result.claimable_earnings ? result.claimable_earnings : 0);
      setrefund(result.service_refund_amt ? result.service_refund_amt : 0);
      settotalorder_accept(result.acceptOrders ? result.acceptOrders : 0);
      settotalorder_cancel(result.cancelorders ? result.cancelorders : 0);
      settotalorder_reject(result.rejectorders ? result.rejectorders : 0);
      settotalorder_pending(result.pendingorders ? result.pendingorders : 0);
      // setuser(result.data);
      // console.log("pending", result.pending_settlement);
    } else {
    }
  };

  const getEarningshistory = async () => {
    // let userData = reactLocalStorage.getObject("userData");
    let result = await HttpClient.requestData(
      "getGraphcomission/" + userData._id,
      "GET"
    );
    console.log("getGraphcomission", result);
    if (result && result.status) {
      setyearlytotal(result.yearTotal);
      setweeklytotal(result.sevendaysTotal);
      setmonthlytotal(result.monthTotal);
      setyesterdaytotal(result.yestardayTotal);
      settodaytotal(result.todaysTotal);
    } else {
    }
  };
  const loop = [1, 2, 3, 4];
  return (
    <div className="dashboard_over seller">
      <div className="row my-4">
        <div className="col-md-12 my-4">
          <div className="_chrt_tabs border-bottom">
            <div className="d-flex">
              {/* <button className="_chrt_btns">
                Graphical representation of services booked and create
              </button> */}
              <div className="col-md-12 text-center">
                <h2>Services Graph</h2>
              </div>
              {/* <button className="_chrt_btns">
                Orders <p>2</p>
              </button>
              <button className="_chrt_btns">
                Convenience Rate <p>0%</p>
              </button>
              <button className="_chrt_btns">
                Revenue <p>$0.00</p>
              </button> */}
            </div>
          </div>
          {/* <Graph /> */}
          <ProCharts />
        </div>
        <div className="col-md-12">
          <div className="_designoverview d-flex align-items-center mb-3">
            <h5 className="mb-0">Stats overview for</h5>
            <select
              className="form-controls ml-3"
              style={{ maxWidth: "120px" }}
              // defaultValue={"yesterday"}
              onChange={(val) => {
                if (val.target.value == "yesterday") {
                  // setyesterday(true);
                  // settoday(false);
                  // setlast_month(false);
                  setdateSelect(val.target.value);
                  getDashboardData(false, true, false);
                  fetchcount(false, true, false);
                } else if (val.target.value == "last_month") {
                  // setyesterday(false);
                  // settoday(false);
                  // setlast_month(true);
                  setdateSelect(val.target.value);
                  getDashboardData(false, false, true);
                  fetchcount(false, false, true);
                } else {
                  // setyesterday(false);
                  // settoday(true);
                  // setlast_month(false);
                  setdateSelect(val.target.value);
                  getDashboardData(true, false, false);
                  fetchcount(true, false, false);
                }
              }}
              value={dateSelect}
            >
              <option value={"today"}>Today</option>
              <option value={"yesterday"}>Yesterday</option>
              <option value={"last_month"}>Last Month</option>
              <option value={"custom"}>Custom</option>
            </select>
            {dateSelect == "custom" ? (
              <>
                <div className="form-group align_grp ml-3">
                  <label htmlFor="From">From </label>
                  <input
                    type="date"
                    className="form-control ml-3"
                    id="From"
                    onChange={(val) => {
                      setfrom_date(val.target.value);
                    }}
                    value={from_date}
                  />
                </div>
                <div className="form-group align_grp ml-3">
                  <label htmlFor="To">To </label>
                  <input
                    type="date"
                    className="form-control ml-3"
                    id="To"
                    onChange={(val) => {
                      setto_date(val.target.value);
                      setenable(true);
                    }}
                    value={to_date}
                  />
                </div>
                &nbsp;
                <button
                  type="button"
                  className="btn srch_btn ml-3"
                  onClick={() => {
                    setyesterday(false);
                    settoday(false);
                    setlast_month(false);
                    getDashboardData();
                    fetchcount();
                  }}
                  disabled={!enable}
                >
                  Search
                </button>
              </>
            ) : null}
          </div>
          <div className="row no-gutters">
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <Link to="/seller-service">
                <div className="balance_card">
                  <div className="card-body text-center">
                    <h6>All services</h6>

                    <span>{allservices}</span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Views</h6>
                  <span>{totalview}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Active Services</h6>
                  <span>{total_active_services}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Inactive Services</h6>
                  <span>{total_inactive_Services}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Revenue</h6>
                  <span>{revenue_this_year}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Order</h6>
                  <span>{totalorder}</span>
                </div>
              </div>
            </div>
            {/* profile page er part ta */}

            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Earning</h6>
                  <span>
                    {totalEarning ? (totalEarning + totalTips).toFixed(2) : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Earning Settled </h6>
                  <span>{totalSettle}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Pending </h6>
                  <span>{pending_settle}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Claimable Amount</h6>
                  <span>{wallet}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Refund Amount</h6>
                  <span>{refund ? refund.toFixed(2) : 0}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Tips</h6>
                  <span>{totalTips ? totalTips.toFixed(2) : 0}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Order Accept</h6>
                  <span>{totalorder_accept ? totalorder_accept : 0}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Order Rejected</h6>
                  <span>{totalorder_reject ? totalorder_reject : 0}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Order Cancelled</h6>
                  <span>{totalorder_cancel ? totalorder_cancel : 0}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Total Order Pending</h6>
                  <span>{totalorder_pending ? totalorder_pending : 0}</span>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2 col-sm-6 col-xs-6">
              <div className="balance_card">
                <div className="card-body text-center">
                  <h6>Processing Fees</h6>
                  <span>{processing_fee ? processing_fee : 0}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12 text-left">
          <div className="d-flex align-items-center">
            <h5>Total Income History</h5>
            {/* <select
              className="form-controls ml-3"
              style={{ maxWidth: "100px" }}
            >
              <option value="1">Stats</option>
              <option value="2">Overview</option>
            </select> */}
          </div>
        </div>

        <div className="col-md-12 mx-auto mt-3">
          <Table border className="table-responsive">
            <thead>
              <tr>
                <th>Yearly Total</th>
                <th>Monthly Total</th>
                <th>Weekly Total</th>
                <th>Yesterday Total</th>
                <th>Today Total</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  {userData.currency == "INR"
                    ? "₹"
                    : userData.currency == "CAD"
                    ? "$"
                    : userData.currency == "USD"
                    ? "$"
                    : userData.currency == "	GBP"
                    ? "£"
                    : userData.currency == "EUR"
                    ? "€"
                    : "₹"}{" "}
                  {yearlytotal}
                </td>
                <td>
                  {userData.currency == "INR"
                    ? "₹"
                    : userData.currency == "CAD"
                    ? "$"
                    : userData.currency == "USD"
                    ? "$"
                    : userData.currency == "	GBP"
                    ? "£"
                    : userData.currency == "EUR"
                    ? "€"
                    : "₹"}{" "}
                  {monthlytotal}
                </td>
                <td>
                  {userData.currency == "INR"
                    ? "₹"
                    : userData.currency == "CAD"
                    ? "$"
                    : userData.currency == "USD"
                    ? "$"
                    : userData.currency == "	GBP"
                    ? "£"
                    : userData.currency == "EUR"
                    ? "€"
                    : "₹"}{" "}
                  {weeklytotal}
                </td>
                <td>
                  {userData.currency == "INR"
                    ? "₹"
                    : userData.currency == "CAD"
                    ? "$"
                    : userData.currency == "USD"
                    ? "$"
                    : userData.currency == "	GBP"
                    ? "£"
                    : userData.currency == "EUR"
                    ? "€"
                    : "₹"}{" "}
                  {yesterdaytotal}
                </td>
                <td>
                  {userData.currency == "INR"
                    ? "₹"
                    : userData.currency == "CAD"
                    ? "$"
                    : userData.currency == "USD"
                    ? "$"
                    : userData.currency == "	GBP"
                    ? "£"
                    : userData.currency == "EUR"
                    ? "€"
                    : "₹"}{" "}
                  {todaytotal}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      {/* <div className="row mt-4">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <h5>Requested Orders</h5>
            </div>
            <div className="col-md-6 text-right"></div>
          </div>
        </div>
      </div> */}
      {/* <div>
        <SellOrder update={update} />
      </div>
      */}

      <div className="_rcnt_act py-4">
        <h5 className="mb-4">Recent Activity</h5>
        {recentActivity.length ? (
          recentActivity.map((item, index) => {
            return (
              <div key={index}>
                <RecentAct data={item} />
              </div>
            );
          })
        ) : (
          <div className="col-md-12 text-center blank_product">
            <h4>
              No recent activity found <br></br>
            </h4>
          </div>
        )}
        {/* {loop.map((item, index) => {
          return (
            <div key={index}>
              <RecentAct />
            </div>
          );
        })} */}
      </div>
    </div>
  );
};
export default Overview;
