import React, { useEffect, useState } from "react";
import Pink_box from "../../Assets/Images/Rectangle 44.png";
import About_ban_img from "../../Assets/Images/about_ban_img.png";
import MoreInfo from "./MoreInfo";
import Footer from "../../Defaults/Footer";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { ReactTitle } from "react-meta-tags";
const Index = () => {
  const [content1, setcontent1] = useState("");
  const [content2, setcontent2] = useState("");
  const [image, setimage] = useState("");
  const [aboutFaq, setaboutFaq] = useState([]);
  useEffect(() => {
    window.scrollTo(0,0);
    getAboutUs();
    getAboutUsFaq();
  }, []);

  const getAboutUs = async () => {
    let result = await HttpClient.requestData(
      "aboutus",
      "GET"
    );
    console.log("result data--", result);
    if (result && result.status) {
      createNormaltext(result.data);
      setimage(result.data[0].image)
      setcontent1(createNormaltext(result.data[0].content1));
      setcontent2(createNormaltext(result.data[0].content2));
    }
  };
  const getAboutUsFaq = async () => {
    let result = await HttpClient.requestData(
      "allFaqabout",
      "POST"
    );
    console.log("allFaqabout --", result);
    if (result && result.status) {
      setaboutFaq(result.data);
    } else {
      setaboutFaq([]);
    }
  };

  function createNormaltext(html) {
    var divElement = document.createElement("div");
    divElement.innerHTML = html;
    return divElement.textContent || divElement.innerText || "";
  }
  return (
    <div>
      <div className="wrapper">
        <ReactTitle title="Astrophy | About Us" />
      </div>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center about_head mt-4">
              <h3>About Us</h3>
            </div>
          </div>
          <div className="row p-4">
            <div className="col-md-4 mx-auto about_ban_head">
              <h4>Who are we ?</h4>
              <div className="Pink_layout d-lg-block d-none">
                <img src={Pink_box} alt="img" />
              </div>
              <div className="about_ban_img">
                <img
                  src={HttpClient.IMG_URL + image}
                  alt="img"
                  style={{ width: "100%", maxWidth: "440px" }}
                />
              </div>
            </div>
            <div className="col-md-8 mt-lg-5 pt-lg-5 pt-0  mt-3 about_ban_text ">
              <p>
                {/* Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
                <br></br>
                <br></br> It was popularised in the 1960s with the release of
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum.<br></br>
                <br></br> Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries, but also
                the leap into electronic typesetting, remaining essentially
                unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum. */}
                {content1}
                { }
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid my-5">
          <div className="row p-4">
            <div className="col-md-12 about_body">
              <h4>What do we do?</h4>
              <div>
                <div className="card about_card mt-5">
                  <div className="card-body about_card_body">
                    <p>
                      {/* Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.<br></br>
                      <br></br> It was popularised in the 1960s with the release
                      of Letraset sheets containing Lorem Ipsum passages, and
                      more recently with desktop publishing software like Aldus
                      PageMaker including versions of Lorem Ipsum.<br></br>
                      <br></br>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum. */}
                      {content2}
                    </p>
                    {/* <Link to="/">
                      <div className="text-right">
                        {" "}
                        <span>Read More</span>
                      </div>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid my-5">
          <div className="row p-4">
            <div className="col-md-12 about_info answer">
              <h4>More Information</h4>
              {aboutFaq.length ? (
                aboutFaq.map((item,index) => {
                  return (
                    <MoreInfo dataFaq={item}/>
                  ) 
                })
              ) : (
                <div className="pl-lg-3">No More Information Found</div>
              )}
              
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};
export default Index;
