import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
const user = reactLocalStorage.getObject("userData");
const Subscription = () => {
  const history = useHistory();
  // const loop = [];
  const [loop, setloop] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkk, setcheckk] = useState(false);
  const userdata = reactLocalStorage.getObject("userData");
  useEffect(() => {
    getsublist();

    checksub();
  }, []);

  const getsublist = async () => {
    setLoading(true);
    let sendData = {};
    // console.log("object", sendData);
    let result = await HttpClient.requestData(
      "listSubs/" + userdata._id,
      "GET",
      sendData
    );
    console.log("result subscription", result);
    if (result && result.status) {
      let newResult = result.data.filter((item) => item.status == true);
      console.log(newResult,"newres");
      setloop(newResult);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };

  const checksub = async () => {
    setLoading(true);
    let sendData = {
      userid: userdata._id,
    };
    console.log("object", sendData);
    let result = await HttpClient.requestData("userSubs", "POST", sendData);
    console.log("userSubs", result);
    if (result && result.status) {
      if (result.data) {
        setcheckk(true);
      } else {
        setcheckk(false);
      }
    } else {
    }
  };

  const buy = async (item) => {
    console.log("item", item);
    history.push("/subscription-form");

    reactLocalStorage.setObject("subdetails", item);
  };

  const cancelsub = async (subs_id, subid) => {
    // console.log("subs_id", subs_id);
    let data = {
      sub_id: subid,
      userid: user._id,
      subs_id: subs_id,
    };
    console.log("request", data);
    let result = await HttpClient.requestData(
      "stripe/subs-cancel",
      "POST",
      data
    );
    console.log("result cancel", result);
    if (result && result.status) {
      setTimeout(() => {
        window.location.href = "/seller";
      }, 1000);
    } else {
    }
  };

  return (
    <div className="row subscription_sec">
      <ToastContainer />
      {loading ? (
        <Loader />
      ) : loop.length > 0 ? (
        loop.map((item, index) => {
          return (
            <div className="col-md-4 my-3" key={index}>
              <div className="card Subs_card">
                <div className="card-body text-center">
                  <h5 className="card-title" style={{ fontWeight: "bolder" }}>
                    {item.name}
                  </h5>
                  <h6 className="card-subtitle mb-2 ">
                    {/* ₹ */}
                    {userdata.currency == "INR"
                      ? "₹"
                      : userdata.currency == "CAD"
                      ? "$"
                      : userdata.currency == "USD"
                      ? "$"
                      : userdata.currency == "	GBP"
                      ? "£"
                      : userdata.currency == "EUR"
                      ? "€"
                      : "₹"}{" "}
                    {item.price}
                  </h6>
                  <h6 className="card-subtitle mb-2 pt-2 ">
                    {/* {item.duration} {item.type == "yearly" ? "Year" : "Month"} */}
                    {item.duration} Month
                  </h6>
                  <p className="card-text mt-3">{item.description}</p>
                </div>
                <div className="px-2">
                  {item.speakers.length > 0 && item.purchase.length > 0 && (
                    <div className="d-lg-flex text-center  justify-content-center">
                      <button className="btn buy_btn mb-3" disabled>
                        Activated
                      </button>
                      <button
                    //    className="btn buy_btn mb-3 ml-lg-3 mx-2"
                    className="btn  mb-3 ml-lg-3 mx-2"
                        onClick={() =>
                          cancelsub(
                            item.speakers[0].subs_id,
                            item.speakers[0]._id
                          )
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  )}

                  {!checkk && (
                    <div className="text-center">
                      <button
                        className="btn buy_btn mb-3"
                        onClick={() => buy(item)}
                      >
                        {" "}
                        Buy Now
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : null}
    </div>
  );
};
export default Subscription;
