import React, { useState, useEffect } from "react";
import Footer from "../../Defaults/Footer";
import ServiceOrderDetails from "./ServiceOrderDetails";
import ServiceOrderList from "./ServiceOrderList";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import Loader from "../../Component/loader";
import bgBlog from "../../Assets/Images/blogBg.jpg";
const ServiceOrder = () => {
  useEffect(() => {
    // window.scrollTo(0, 0);
    getorderlist(1);
  }, []);

  const userData = reactLocalStorage.getObject("userData");
  const [order, setorder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderdetails, setorderdetails] = useState({});
  const [count, setcount] = useState(0);
  const getorderlist = async (num) => {
    if (num == 1) {
      setLoading(true);
    }
    let data = {};
    let result = await HttpClient.requestData(
      "servicebookhistory/" + userData._id,
      "GET",
      data
    );
    console.log("result order list", result);
    if (result && result.status) {
      let arr = result.data.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          new Date(b.servicecart_data[0].booking_date) -
          new Date(a.servicecart_data[0].booking_date)
        );
      });
      // console.log("order list", result.data);
      // console.log("new arr", arr);
      setorder(arr);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    } else {
    }
  };
  const updateData = (val) => {
    getorderlist();
    // if (val) {
    //   getorderlist();
    // }
  };
  const sendata = (val, count) => {
    setcount((prevState) => prevState + count);

    setorderdetails(val);
    //after closing modal to updated the refund status
  };
  const callList = (val, num) => {
    console.log("val", val);
    //after closing modal to updated the refund status
    setorder(val);
    getorderlist(num);
  };

  const refresh = (val) => {
    if (val) {
      getorderlist();
    }
  };

  // const backcancelorder = async () => {

  //   getorderlist(2);
  // };
  const backreview = (val, num) => {
    console.log("bak revire", val, num);
    setorder(val);
    getorderlist(num);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <section>
            <div
              className="bGimg_Service_Booking"
              style={{
                background: `url(${bgBlog})`,
              }}
            >
              <div className="container my-2">
                <div className="row">
                  <div className="col-md-12 order_head my-3">
                    <h3>My Service Booking</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
            <div className="row">
              <div className="col-md-4 ">
                <ServiceOrderList
                  order={order}
                  sendata={sendata}
                  updatedList={callList}
                  count={count}
                />
              </div>
              <div className="col-md-8 my-md-0 my-4">
                {Object.keys(orderdetails).length != 0 ? (
                  <div className="order_details">
                    <ServiceOrderDetails
                      orderdetails={orderdetails}
                      refresh={refresh}
                      // backcancelorder={backcancelorder}
                      backreview={backreview}
                      updateData={updateData}
                      count={count}
                    />
                  </div>
                ) : (
                  <div className="text-center mt-5">
                    <img
                      src="https://c1.alamy.com/thumbs/trta2g/online-shopping-icon-vector-with-male-customer-person-profile-avatar-symbol-for-buying-goods-in-glyph-pictogram-illustration-trta2g.jpg"
                      alt="img"
                      style={{
                        width: "200px",
                        height: "200px",
                        borderRadius: "50%",
                      }}
                    />
                    <h4 className="pt-3">
                      Click on the orders list there, to view order status
                    </h4>
                  </div>
                )}
              </div>
            </div>
            </div>
          </section>
          <section>
            <Footer />
          </section>
        </>
      )}
    </div>
  );
};
export default ServiceOrder;
