import React, { useState, useEffect } from "react";
// import Table from "./Table";
import { MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Details from "./Details";
const CancelOrder = () => {
  const [table, setTable] = useState(false);
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [disbale, setdisbale] = useState(false);
  const userData = reactLocalStorage.getObject("userData");
  const [processing_fee, setprocessing_fee] = useState(0);
  const [dataa, setdata] = useState([]);

  useEffect(() => {
    // getcomission();
    getorderlist();
  }, []);

//   const getorderlist = async () => {
//     let data = {};
//     let result = await HttpClient.requestData(
//       "sellerbookhistory/" + userData._id,
//       "GET",
//       data
//     );
//     console.log("order details---", result);
//     if (result && result.status) {
//       let newArray = result.data.filter(
//         (item) => item.cancelstatus == "cancel"
//       );
//       console.log("new arr---", newArray);
//       setTimeout(() => {}, 1);
//     } else {
//     }
//   };

  const getorderlist = async (processing_fee) => {
    let result = await HttpClient.requestData(
      "sellerbookhistory/" + userData._id,
      "GET",
      data
    );
    console.log("result commission", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      let newArray = result.data.filter(
        (item) => item.cancelstatus == "cancel"
      );
      console.log("new arr---", newArray);
      newArray.forEach((element, index) => {
        let rows = {
          sl: i,

          order_id: element.order_id,
          service_name: element.servicename,
          image: (
            <img
              src={HttpClient.IMG_URL + element.image}
              width="80"
              height="80"
            />
          ),
        };
        i++;
        data.push(rows);
      });

      setdata(data);
      setTable(true);
    } else {
      setdata([]);
    }
  };

  const data = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        width: 150,
      },
      {
        label: "Order Id",
        field: "order_id",
        width: 270,
      },
      {
        label: "Service Name",
        field: "service_name",
        width: 270,
      },
      {
        label: "Image",
        field: "image",
        width: 270,
      },
      // {
      //   label: "Transaction Id",
      //   field: "txnid",
      //   width: 270,
      // },
    ],
    rows: dataa,
  };
  return (
    <div className="order-report">
      {/* <ToastContainer /> */}
      <div className="row my-4">
        <div className="col-md-12 text-center">
          <h1>Cancelled Order List</h1>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-6 mx-auto">
          <form>
            <div className="row">
              <div className="col-12">
                <label htmlFor="inputFromDate">From Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="From Date"
                  value={fromdate}
                  onChange={(val) => {
                    setfromdate(val.target.value);
                  }}
                />
              </div>
              <div className="col-12 my-4">
                <label htmlFor="inputToDate">To Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="To Date"
                  value={todate}
                  onChange={(val) => {
                    settodate(val.target.value);
                    setdisbale(true);
                  }}
                />
              </div>
            </div>
          </form>
          <div className="text-center">
            <button
              type="button"
              className="btn report-btn"
              onClick={submit}
              disabled={!disbale}
            >
              Submit
            </button>
          </div>
        </div>
      </div> */}

      <div className="row mt-5">
        <div className="col-md-10 mx-auto">
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};
export default CancelOrder;
