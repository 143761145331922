import React from "react";
import { Link } from "react-router-dom";
import RatingChanged from "../../Component/RatingChanged";
import Slider from "react-slick";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HttpClient from "../../utils/HttpClient";
import blankImg from "../../Assets/Images/blank.jpg";
const ListItem = (props) => {
  console.log("productDetails", props.productDetails);
  console.log("prodetail---", props.data);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  let productDetails = props.productDetails;
  const proData = props.data;
  let tax_percent = proData.product_data[0].tax.replace("%", "");
  let b =
    Number(proData.product_data[0].selling_price) * (Number(tax_percent) / 100);
  let selling_priceWithTax = (
    proData.product_data[0].selling_price + b
  ).toFixed(2);
  const userData = reactLocalStorage.getObject("userData");

  const addcart = async (val) => {
    let data = {
      prod_id: val.prod_id,
      user_id: userData._id,
      currency: userData.currency,
      productname: val.productname,
      qty: 1,
      price: selling_priceWithTax,
      image: val.image[0],
      orderFrom: "web",
    };
    console.log("data", data);

    let result = await HttpClient.requestData("add-to-cart", "POST", data);
    console.log("result", result);
    if (result && result.status) {
      toast.success(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
      // window.location.href = "/checkout";
      // props.success(result.status);
      toast.success("Product has been added the cart", {
        position: "bottom-right",
        autoClose: 3000,
      });
      setTimeout(() => {
        window.location.href = "/product-details/" + proData.prod_id;
      }, 4000);
    } else {
      toast.error(result.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const buyNow = async (val) => {
    let data = {
      prod_id: val.prod_id,
      user_id: userData._id,
      currency: userData.currency,
      productname: val.productname,
      qty: 1,
      price: selling_priceWithTax,
      image: val.image[0],
      orderFrom: "web",
    };
    let result = await HttpClient.requestData("add-to-cart", "POST", data);
    // console.log("result", result);
    if (result && result.status) {
      window.location.href = "/checkout";
    } else {
    }
  };

  const deletee = async (val) => {
    console.log("del", val._id);
    let data = {};
    let result = await HttpClient.requestData(
      "product-wishlist/" + val._id,
      "DELETE",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      props.success(result.status);
    } else {
    }
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(" ", "-");
    return strReplace;
  };

  return (
    <div className="bottomSection mt-4" key={props.key}>
      <ToastContainer />
      <div className="card wish_card">
        <div className="card-body" style={{ padding: 0 }}>
          <div
            className="web_img"
            onClick={() => {
              window.location.href =
                "/product-details/" +
                nameToSlug(proData.productname) +
                "/" +
                proData.prod_id;
            }}
          >
            <img
              src={
                props.data.image[0]
                  ? HttpClient.IMG_URL + proData.image[0]
                  : blankImg
              }
              alt="img"
              className="img-fluid"
            />
            {/* <Slider {...settings}>
              {props.data.image ? (
                props.data.image.map((item2, index2) => {
                  return (
                    <div key={index2}>
                      {" "}
                      <img
                        src={HttpClient.IMG_URL + item2}
                        alt="img"
                        className="relative service_list_img"
                      />
                    </div>
                  );
                })
              ) : (
                <img src={blankImg} alt="img" className="img-fluid" />
              )}
            </Slider> */}
          </div>

          <div
            className="web_box  my-lg-0 my-3 w-100"
            style={{ padding: "1.25rem" }}
          >
            <div className="row  ">
              <div
                className="col-md-12"
                onClick={() => {
                  window.location.href =
                    "/product-details/" +
                    nameToSlug(proData.productname) +
                    "/" +
                    proData.prod_id;
                }}
              >
                <h4>{proData.productname}</h4>
                <p className="product-para">
                  {proData.product_data.length
                    ? proData.product_data[0].description
                    : ""}
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  elementum ac diam at convallis. Nulla diam libero, maximus sed
                  justo et, convallis tempus dolor. Etiam non magna vel felis. */}
                </p>
              </div>
              {selling_priceWithTax <= proData.product_data[0].selling_price ? (
                <div class="col-md-12">
                  <div
                    className="d-flex"
                    onClick={() => {
                      window.location.href =
                        "/product-details/" + proData.prod_id;
                    }}
                  >
                    <h5>
                      {/* ₹ */}
                      {userData.currency == "INR"
                        ? "₹"
                        : userData.currency == "CAD"
                        ? "$"
                        : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "	GBP"
                        ? "£"
                        : userData.currency == "EUR"
                        ? "€"
                        : "₹"}
                      {proData.product_data[0].selling_price}
                      {/* {selling_priceWithTax} */}
                      {/* {proData.product_data.length
                      ? proData.product_data[0].selling_price
                      : ""} */}
                    </h5>
                    <p className="mx-md-3 mt-1 text-success">
                      <i className="fas fa-check-circle mr-2" />
                      In Stock
                    </p>
                  </div>
                </div>
              ) : (
                <div class="col-md-12">
                  <div
                    className="d-flex"
                    onClick={() => {
                      window.location.href =
                        "/product-details/" + proData.prod_id;
                    }}
                  >
                    <h5>
                      {/* ₹ */}
                      {userData.currency == "INR"
                        ? "₹"
                        : userData.currency == "CAD"
                        ? "$"
                        : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "	GBP"
                        ? "£"
                        : userData.currency == "EUR"
                        ? "€"
                        : "₹"}
                      {proData.product_data[0].selling_price}

                      {/* {selling_priceWithTax} */}
                      {/* {proData.product_data.length
                      ? proData.product_data[0].selling_price
                      : ""} */}

                      <del className="text-muted font-italic ml-2 small">
                        {userData.currency == "INR"
                          ? "₹"
                          : userData.currency == "CAD"
                          ? "$"
                          : userData.currency == "USD"
                          ? "$"
                          : userData.currency == "GBP"
                          ? "£"
                          : userData.currency == "EUR"
                          ? "€"
                          : "₹"}{" "}
                        {selling_priceWithTax}
                      </del>
                    </h5>
                    <p className="mx-md-3 mt-1 text-success">
                      <i className="fas fa-check-circle mr-2" />
                      In Stock
                    </p>
                  </div>
                </div>
              )}

              <div className="col-md-12 ">
                {/* <p className=" text-right">Item Added 28 Oct </p> */}
                <div
                  className="d-flex justify-content-between"
                  style={{ marginBottom: "20px" }}
                >
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => addcart(proData)}
                  >
                    Add to Cart
                  </button>
                  <button
                    className="btn btn-danger ml-3"
                    type="button"
                    onClick={() => buyNow(proData)}
                  >
                    Buy Now
                  </button>

                  <button
                    className="btn btn-secondary register_btn ml-3"
                    type="button"
                    onClick={() => deletee(proData)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div>{/* <RatingChanged /> */}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListItem;
