import React, { useEffect, useState } from "react";
import Footer from "../../Defaults/Footer";
import LeftBar from "../component/LeftBar";
import Subscription from "./Subscription";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

const Index = (props) => {
  console.log("props", props);
  const [checkk, setcheckk] = useState(false);
  const userdata = reactLocalStorage.getObject("userData");
  useEffect(() => {

    checksub();
  }, []);
  const checksub = async () => {

    let sendData = {
      userid: userdata._id,
    };
    // console.log("object", sendData);
    let result = await HttpClient.requestData("userSubs", "POST", sendData);
    console.log("userSubs", result);
    if (result && result.status) {
      if (result.data) {
        setcheckk(true);
      } else {
        setcheckk(false);
      }
    } else {
    }
  };
  return (
    <div className="subscription">
      <div>
        <div className="row my-3">
          <div className="col-md-12 text-center">
            <h1>Subscription Plan</h1>
            <br></br>
            {checkk ? null : (
              <h4>Subscribe First to Access the Seller Panel</h4>
            )}
          </div>
        </div>

        <Subscription />


      </div>
    </div>
  );
};
export default Index;
