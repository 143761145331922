import React, { useEffect, useState } from "react";
import shop1 from "../../Assets/Images/service_list 9.png";
import shop2 from "../../Assets/Images/service_list 8.png";
import shop3 from "../../Assets/Images/service_list 10.png";
import shop4 from "../../Assets/Images/service_list 14.png";
import Provider_name from "../../Assets/Images/provider_name.png";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import EmptyWishlist from "../EmptyWishlist/EmptyWishlist";

const Shop = () => {
  useEffect(() => {
    getproduct();
  }, []);
  const [loading, setLoading] = useState(false);
  const [loopp, setloopp] = useState([]);
  const userdata = reactLocalStorage.getObject("userData");

  const getproduct = async () => {
    setLoading(true);
    let sendData = {};
    // console.log("object", sendData);
    let result = await HttpClient.requestData(
      "product-wishlist/" + userdata._id + "?currency=" + userdata.currency,
      "GET",
      sendData
    );
    console.log("product-wishlist", result);
    if (result && result.status) {
      setloopp(result.data);
      setTimeout(() => {
        setLoading(false);
      }, 10);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 10);
    }
  };
  const nameToSlug = (name) => {
    let lName = name.toLowerCase();
    let strReplace = lName.replaceAll(' ', '-');
    return strReplace;
  }

  const loop = [
    {
      img: shop1,
    },
    {
      img: shop2,
    },
    {
      img: shop3,
    },
    // {
    //   img: shop4,
    // },
  ];
  return (
    <div className="row px-md-4">
      {loopp != null && loopp.length > 0 ? (
        loopp.map((item, index) => {
          let tax_percent = item.product_data[0].tax.replace("%", "");      
          let b = Number(item.product_data[0].selling_price) * (Number(tax_percent) / 100);
          let selling_priceWithTax = (item.product_data[0].selling_price + b).toFixed(2)
          return (
            <div
              className="col-6 col-lg-3 col-md-4 my-3 px-2 px-md-3"
              key={index}
            >
              <div
                className="card service_list new_product_design"
                onClick={() => {
                  window.location.href = "/product-details/" + nameToSlug(item.productname) + "/" + item.prod_id;
                }}
              >
                <a href="#">
                  <img
                    src={HttpClient.IMG_URL + item.image[0]}
                    alt="img"
                    className="relative service_list_img"
                  />
                </a>
                <div className="card-body product_list_text px-md-3 pt-2">
                  <a href="#">
                    <h6>{item.productname}</h6>
                  </a>
                  {
                      selling_priceWithTax <= item.selling_price ? (
                        <div className="d-flex justify-content-between py-md-3">
                   
                    <div className="pricing">
                      
                      <p className="font-weight-bold  mb-0">
                        {userdata.currency == "INR"
                          ? "₹"
                          : userdata.currency == "CAD"
                          ? "$"
                          : userdata.currency == "USD"
                          ? "$"
                          : userdata.currency == "	GBP"
                          ? "£"
                          : userdata.currency == "EUR"
                          ? "€"
                          : "₹"}{" "}
                          {selling_priceWithTax}
                        {/* {item.mrp} */}
                        {/* <del className="text-muted font-italic ml-2 small">
                          {item.product_data.length
                            ? item.product_data[0].selling_price
                            : ""}
                        </del> */}
                      </p>
                      {/* <h5 className="small mb-0 font-weight-bold">
                        {item.product_data.length
                          ? item.product_data[0].offer
                            ? item.product_data[0].offer + "% offer+"
                            : null
                          : ""}
                        {item.product_data.length
                          ? item.product_data[0].tax
                          : ""}
                        tax
                      </h5> */}
                    </div>
                    <div className="rating">
                      <i className="fas fa-star my-1 mr-1 mr-md-2" />
                      {item.avgRating != null ? item.avgRating.toFixed(1) : "0"}
                      /5
                    </div>
    
                  </div>
                      ) : (
                        <div className="d-flex justify-content-between py-md-3">
                   
                        <div className="pricing">
                          
                          <p className="font-weight-bold  mb-0">
                            {userdata.currency == "INR"
                              ? "₹"
                              : userdata.currency == "CAD"
                              ? "$"
                              : userdata.currency == "USD"
                              ? "$"
                              : userdata.currency == "	GBP"
                              ? "£"
                              : userdata.currency == "EUR"
                              ? "€"
                              : "₹"}{" "}
                              {selling_priceWithTax}
                              <del className="text-muted font-italic ml-2 small">
                            {userdata.currency == "INR"
                              ? "₹"
                              : userdata.currency == "CAD"
                              ? "$"
                              : userdata.currency == "USD"
                              ? "$"
                              : userdata.currency == "GBP"
                              ? "£"
                              : userdata.currency == "EUR"
                              ? "€"
                              : "₹"}{" "}
                            {selling_priceWithTax}
                          </del>
                            {/* {item.mrp} */}
                            {/* <del className="text-muted font-italic ml-2 small">
                              {item.product_data.length
                                ? item.product_data[0].selling_price
                                : ""}
                            </del> */}
                          </p>
                          {/* <h5 className="small mb-0 font-weight-bold">
                            {item.product_data.length
                              ? item.product_data[0].offer
                                ? item.product_data[0].offer + "% offer+"
                                : null
                              : ""}
                            {item.product_data.length
                              ? item.product_data[0].tax
                              : ""}
                            tax
                          </h5> */}
                        </div>
                        <div className="rating">
                          <i className="fas fa-star my-1 mr-1 mr-md-2" />
                          {item.avgRating != null ? item.avgRating.toFixed(1) : "0"}
                          /5
                        </div>
        
                      </div>
                      )
                    }
                  
                  
                  {/* <p>
                    {item.product_data.length
                      ? item.product_data[0].description
                      : ""}
                  </p> */}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyWishlist />
      )}
      {/* {loop.map((item, index) => {
        return (
          <div className="col-lg-4 col-md-6  my-3" key={index}>
            <div className="card shop">
              <img src={item.img} className="card-img-top" alt="img" />
              <div className="card-body mt-3">
                <div className="d-flex px-2 ">
                  <div>
                    <img
                      src={Provider_name}
                      alt="img"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="mx-2">
                    <Link to="/astrophy-mall">
                      <h5 className="card-title">SeraphDragonaara</h5>
                    </Link>
                    <p>
                      <i className="fas fa-star my-1 mr-1"></i> 4.6/5{" "}
                      <span>(15 Reviews)</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })} */}
    </div>
  );
};
export default Shop;
