import React, { useState, useEffect } from "react";
import BlankOrder from "../Orders/BlankOrder";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import moment from "moment";
// import RefundModal from "./refundmodal";
//import ChatWithSeller from "./chatwithseller";
import { Row, Card, CardBody, Col, Input, Button } from "reactstrap";
import {
  getDatabase,
  ref,
  push,
  set,
  onValue,
  get,
  onChildAdded,
} from "firebase/database";
const ServiceOrderList = (props) => {
  console.log("hjhj", props.order);
  const [isOpenmodal, setisopenmodal] = useState(false);
  const [isOpenmodal1, setisopenmodal1] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [sentorderdetails, setsentorderdetails] = useState({});
  const [orderid, setorderid] = useState("");
  const [servicename, setservicename] = useState("");
  const [firebaseData, setfirebaseData] = useState([]);
  const userData = reactLocalStorage.getObject("userData");

  useEffect(() => {
    // console.log("hjhj", props.order);
    checklist();
  }, []);

  const orderdertail = (item) => {
    // console.log("object", item);
    let countInr = props.count + 1;
    props.sendata(item, countInr);
  };
  const styles = {
    cursor: "pointer",
  };

  // const Callbackmsg = async (val) => {
  //   setisopenmodal(false);
  //   props.updatedList(props.order, 2);
  // };

  // const Callbackmsg1 = async (val) => {
  //   setisopenmodal1(false);
  //   // props.updatedList(props.order, 2)
  // };

  const checklist = async () => {
    const dbRef = getDatabase();
    const starCountRef = ref(dbRef, "SellerBuyerChat/");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      let arr = [];
      // console.log("data", data);
      if (snapshot.exists()) {
        snapshot.forEach((ele) => {
          // console.log("ele", ele.val());
          let dom = {
            id: ele.val(),
          };
          // console.log(dom);
          arr.push(dom);
          // console.log("arr", arr);
        });
        setfirebaseData(arr);
      }
    });
  };

  // const callrefund = async (item) => {
  //   let result = await HttpClient.requestData("refundProduct/" + reactLocalStorage.getObject("userData")._id, "PUT");
  //   console.log("result1", result);
  //   if (result && result.status) {
  //   }
  // }

  return (
    <div className="order_list">
      <div className="row">
        {props.order.length > 0 ? (
          props.order.map((item, index) => {
            return (
              <div
                className="col-md-12 "
                key={index}
                style={styles}
                onClick={() => {
                  orderdertail(item);
                  setsentorderdetails(item);
                  window.scrollTo(0,0);
                }}
              >
                <div className="border-bottom p-3 highlight">
                  <h5>
                    Order Number : {item.order_id}
                    <span></span>
                  </h5>
                  <div className="d-flex justify-content-between">
                    <p>
                      <span>
                        Order On:{" "}
                        {moment(item.servicecart_data[0].booking_date).format(
                          "D-MM-YYYY"
                        )}
                      </span>
                      <br></br>
                      {}
                    </p>
                    <p>
                      <span>Total Amount:</span>
                      <br></br>
                      {/* ₹  */}
                      {userData.currency == "INR"
                        ? "₹"
                        : userData.currency == "CAD"
                        ? "$"
                        : userData.currency == "USD"
                        ? "$"
                        : userData.currency == "	GBP"
                        ? "£"
                        : userData.currency == "EUR"
                        ? "€"
                        : "₹"}
                      {item.total.toFixed(2)}
                    </p>
                  </div>
                  {/* {item.servicecart_data[0].acceptstatus == "accept" ? (
                  <p>
                    <Button
                      type="button "
                      color={
                        item.servicecart_data.length > 0 &&
                          item.servicecart_data[0].refund_request ==
                          "refund_initiated"
                          ? "success"
                          : 
                          item.servicecart_data.length > 0 &&
                            item.servicecart_data[0].refund_request == "yes"
                            ? "warning"
                            : "info"
                      }
                      onClick={() => {
                        
                        setisopenmodal(!isOpenmodal);

                        console.log("hhj", isOpenmodal);
                        
                      }}
                    >
                      {item.servicecart_data.length > 0 &&
                        item.servicecart_data[0].refund_request ==
                        "refund_initiated"
                        ? "Refund Initiated"
                        :
                        item.servicecart_data.length > 0 &&
                          item.servicecart_data[0].refund_request == "yes"
                          ? "Refund Requested"
                          : "Refund"}
                    </Button>
                  </p>
                  ) : null}  */}
                  {/* {firebaseData.length > 0 &&
                    firebaseData.map((it, index) => {
                      console.log("firebaseid", it.id.order_id);
                      console.log("uu1", item.order_id);
                      if (it.id.order_id == item.order_id) {
                        return ( */}
                  {/* <Button
                    onClick={() => {
                      setisopenmodal1(true);
                      setorderid(
                        item.servicecart_data.length > 0 &&
                        item.servicecart_data[0].order_id
                      );
                      setservicename(
                        item.servicecart_data.length > 0 &&
                        item.servicecart_data[0].servicename
                      );

                      console.log("hhj", isOpenmodal);
                    }}
                  >
                    Chat with seller
                  </Button> */}
                  {/* );
                      }
                    })} */}
                </div>
              </div>
            );
          })
        ) : (
          <BlankOrder />
        )}
      </div>
      {/* {isOpenmodal ? (
        <RefundModal
          status={true}
          orderdetails={sentorderdetails}
          onCloseCallBack={Callbackmsg}
          image=""
        />
      ) : null} */}
      {/* {isOpenmodal1 ? (
        <ChatWithSeller
          status={true}
          orderid={orderid}
          servicename={servicename}
          onCloseCallBack1={Callbackmsg1}
          image=""
        />
      ) : null} */}
    </div>
  );
};
export default ServiceOrderList;
