import React, { useState, useEffect, useRef } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
//import ReactJsAlert from "reactjs-alert";
import "./chatlist.css";
//import Firebase from "../../firebase-messaging-sw";
import db from "../../Firebase/fbconfig";
import {
  getDatabase,
  ref,
  push,
  set,
  onValue,
  get,
  onChildAdded,
  limitToLast,
  query,
  update,
  onChildChanged,
  child,
} from "firebase/database";
import { Row, Card, CardBody, Col, Input, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import img from "./pro.jpeg";
import img1 from "./mp4.jpeg";
import moment from "moment";
import MessageBody from "../Messages/MessageList/messageBody";
import { v4 as uuidv4 } from "uuid";
import AudioModal from "../Messages/ChatBody/AudioModal";
import VideoModal from "../Messages/ChatBody/VideoModal";

const Refund = (props) => {
  const userDataDetail = reactLocalStorage.getObject("userData");
  const messagesEndRef = useRef(null);
  const { remoteUserDetails } = props;
  const [modalStatus, setmodalStatus] = useState(
    props.status ? props.status : false
  );
  const [msglist, setmsglist] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [message, setmessage] = useState("");
  const [firstFile, setfirstFile] = useState("");
  const [filetype, setfiletype] = useState("");
  const [roomId, setRoomId] = useState("");
  const [userData, setUserData] = useState({});
  const [call, setCall] = useState(false);
  const [videoModalshow, setVideoModalshow] = useState(false);
  const [remoteData, setRemoteData] = useState({});

  const dbRef = getDatabase();
  const chatRef = useRef(null);
  const chatUpdateRef = useRef(null);

  useEffect(() => {
   
    scrollToBottom();
    checkUser();
  }, []);

  const callBackcall = (val) => {
    setCall(false);
  };

  const callBackcall1 = () => {
    setVideoModalshow(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const msgvalid = (txt) => txt && txt.replace(/\s/g, "").length;

  const sendmessage = async () => {
    if (message == "" || msgvalid(message) === 0) {
      return false;
    }
    // let roomId = "123456789";
    const postListRef = ref(dbRef, "/Chat/" + roomId + "/messages/");
    const newPostRef = push(postListRef);
    set(newPostRef, {
      roomId: roomId,
      msgId: uuidv4(),
      sender_id: userData._id,
      message: message,
      send_time: moment().format(),
      name: userData.firstName + " " + userData.lastName,
      nodeId: newPostRef.key,
    });

    let postData = {
      lastMsg: message,
      lastMsgTime: moment().format(),
      name: `${remoteUserDetails.firstName} ${remoteUserDetails.lastName}`,
      displayName: `${remoteUserDetails.firstName} ${remoteUserDetails.lastName}`,
      userId: remoteUserDetails._id,
      roomId: roomId,
    };

    let postDataa = {
      lastMsg: message,
      lastMsgTime: moment().format(),
      name: userData.firstName + " " + userData.lastName,
      displayName: userData.firstName + " " + userData.lastName,
      userId: userData._id,
      roomId: roomId,
      unseen: true,
    };
    const updates = {};
    updates[`/chatList/${userData._id}/${remoteUserDetails._id}`] = postData;
    updates[`/chatList/${remoteUserDetails._id}/${userData._id}`] = postDataa;
    update(ref(dbRef), updates);
    sendnotification(message);
    setmessage("");
    // setshowemoji(false);
    // props.refresh(true);
  };

  const sendnotification = async (msg) => {
    let token = "";
    // console.log("/Chat/" + props.roomId + "/messages/");
    get(child(ref(dbRef), "/user/" + remoteUserDetails._id))
      .then((snapshot) => {
        if (snapshot.exists()) {
          sendtoken(snapshot.val().fcmToken);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sendtoken = async (token) => {
    console.log("token", token);
    const FIREBASE_API_KEY =
      "AAAAkKuhlBw:APA91bHuUxvnxQR6VxdLpxkBsgyZjapd4ekX-eFV4EhKGjR0lFoALfCbGsg_U1IW1-jOuLd3vjYTWm2EaCUi64pQNrxirTCsV23IzgcXki3a3yROBY8tFPsWJ_w1sc_zmCNc8L8N5nO2";
    // data.notiType = 'Call';
    const message = {
      registration_ids: [token],
      priority: "high",
      // data: { ...data, notiType: 'Call', callDataType: 'start' },
      notification: {
        title: userData.firstName + " " + userData.lastName,
        body: "New message arrived",
        vibrate: 1,
        sound: 1,
        show_in_foreground: true,
        priority: "high",
        content_available: true,
      },
    };

    let headers = new Headers({
      "content-type": "application/json",
      Authorization: "key=" + FIREBASE_API_KEY,
    });
    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers,
      body: JSON.stringify(message),
    });
    response = await response.json();
    console.log("response55555", response, message);
  };

  const checkUser = () => {
    let userDetails = reactLocalStorage.getObject("userData");
    setUserData(userDetails);
    console.log("userDetails", userDetails, remoteUserDetails);

    get(
      ref(dbRef, `/chatList/${userDetails._id}/${remoteUserDetails._id}`)
    ).then((snapshot) => {
      if (snapshot.exists()) {
        console.log("snapshot", snapshot.val());
        let fbData = snapshot.val();
        setRoomId(fbData.roomId);
        setRemoteData(fbData);
        getChat(fbData.roomId);
      } else {
      }
    });
  };

  const getChat = (roomIds) => {
    get(query(ref(dbRef, `/Chat/${roomIds}/messages/`)))
      .then((snapshot) => {
        if (snapshot.exists()) {
          // console.log(snapshot.val());
          // setisloading(false);
          // sethasMore(true);
          setmsglist(Object.values(snapshot.val()));
          getFirebaseListener(Object.values(snapshot.val()), roomIds);
          scrollToBottom();
        } else {
          // sethasMore(false);
          setmsglist([]);
          getFirebaseListener([], roomIds);
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getFirebaseListener = (oldVal, roomIds) => {
    console.log("roomId", roomIds, "/Chat/" + roomIds + "/messages/");
    let mssg = oldVal;

    const dbquery = query(
      ref(dbRef, "/Chat/" + roomIds + "/messages/"),
      limitToLast(1)
    );

    chatRef.current = onChildAdded(dbquery, (snapshot) => {
      if (snapshot.exists()) {
        let fbData = snapshot.val();
        let msgIndex = mssg.findIndex((it) => it.msgId == fbData.msgId);
        if (msgIndex == -1) {
          setmsglist((msgarr) => [...msgarr, fbData]);
          mssg = [...mssg, fbData];
          const updates = {};
          updates[
            `/chatList/${userData._id}/${remoteUserDetails._id}/unseen`
          ] = false;
          update(ref(dbRef), updates);
          scrollToBottom();
        }
      }
    });

    chatUpdateRef.current = onChildChanged(
      ref(dbRef, `/Chat/${roomIds}/messages/`),
      (snapshot) => {
        if (snapshot.exists()) {
          let fbData = snapshot.val();
          if (!fbData.uploading) {
            let index = mssg.findIndex((it) => it.msgId == fbData.msgId);
            if (index >= 0) {
              mssg[index] = fbData;
              setmsglist(mssg);
              //console.log("arrayyyyyyyyyyyyyyyyyyy", mssg)
            }
          }
        }
      }
    );
  };

  return (
    <div
      className={modalStatus ? "modal show fade _modal_open" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: modalStatus ? "block" : "none" }}
    >
      {
        console.log("remoteUserDetails", remoteUserDetails)
      }
      <div className="modal-dialog modal-dialog-centered replyBox modal-lg">
        <div className="modal-content chat_details">
          <Card className="mb-0">
            {/* chat head area */}
            <CardBody className="border-bottom chat_head py-2">
              <div className="user-chat-border">
                <Row>
                  <Col md={9} xs={9}>
                    <div className="d-flex">
                      <div className="my-auto mr-3">
                        {
                          remoteUserDetails.image ?
                            <img
                              src={HttpClient.IMG_URL + remoteUserDetails.image}
                              className="img-fluid rounded-circle"
                              style={{ height: "30px", width: "30px" }}
                            />
                            :
                            <img
                              src={img}
                              className="img-fluid rounded-circle"
                              style={{ height: "30px", width: "30px" }}
                            />
                        }

                      </div>
                      <h5 className="font-size-15 my-auto mr-auto">{`${remoteUserDetails.firstName} ${remoteUserDetails.lastName}`}</h5>
                    </div>
                  </Col>
                  <Col md={3}>
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => setCall(true)}
                    >
                      <i className="fas fa-phone-alt"></i>
                    </button>
                    <button
                      type="button"
                      className="bg-transparent border-0 ml-3"
                      onClick={() => setVideoModalshow(true)}
                    >
                      <i className="fas fa-video"></i>
                    </button>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={() => props.onCloseCallBack1(false)}
                    >
                      ×
                    </button>
                  </Col>
                </Row>
              </div>
            </CardBody>
            {/* chat list body */}
            <CardBody>
              <div className="chat-widget">
                <div className="chat-conversation">
                  <SimpleBar style={{ maxHeight: "250px" }}>
                    <ul className="list-unstyled mb-0 pr-3">
                      {msglist.length > 0 &&
                        msglist.map((item, index) => {
                          // console.log(item);
                          return (
                            <>
                        
                              {item?.type == "call" ? (
                                <li className="log_time">
                                  {moment(item.callTime).format("hh:mm A")}
                                  <br />
                                  <span>
                                    {item.callStatus == "Recieved"
                                      ? `Call ${item.callDuration}`
                                      : item.sender_id == userData._id
                                        ? "Not Connected"
                                        : "Missed Call"}
                                  </span>
                                  <br />
                                  <span>
                                    {moment(item.callTime).format(
                                      "DD MMM, YYYY"
                                    )}
                                  </span>
                                </li>
                              ) : (
                                <li
                                  className={
                                    item.sender_id == userData._id
                                      ? "right"
                                      : ""
                                  }
                                  key={index}
                                >
                                  {/* {item.msg != "" && item.msgimg != "" ? (
                                                                <div className="conversation-list">
                                                                    <div className="ctext-wrap">
                                                                        <div className="ctext-wrap-content py-1 px-2">
                                                                            <p className="mb-2">{item.msg}</p>

                                                                            <img
                                                                                data-dz-thumbnail
                                                                                src={item.msgimg}
                                                                                className="avatar-md rounded bg-light"
                                                                                alt="preview"
                                                                            />
                                                                            <p className="mb-2">
                                                                                {moment(item.msgtime).fromNow()}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : item.msg != "" && item.msgvideo != "" ? (
                                                                <div className="conversation-list">
                                                                    <div className="ctext-wrap">
                                                                        <div className="ctext-wrap-content py-1 px-2">
                                                                            <p className="mb-2">{item.msg}</p>

                                                                            <video
                                                                                // width="50%"
                                                                                hight={300}
                                                                                width={300}
                                                                                controls
                                                                            // className="rounded-lg"
                                                                            >
                                                                                <source
                                                                                    src={item.msgvideo}
                                                                                    type="video/mp4"
                                                                                />
                                                                            </video>
                                                                            <p className="mb-2">
                                                                                {moment(item.msgtime).fromNow()}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : item.msg == "" && item.msgimg != "" ? (
                                                                <div className="conversation-list">
                                                                    <div className="ctext-wrap">
                                                                        <div className="ctext-wrap-content py-1 px-2">
                                                                            <img
                                                                                data-dz-thumbnail
                                                                                src={item.msgimg}
                                                                                className="avatar-md rounded bg-light"
                                                                                alt="preview"
                                                                            />
                                                                            <p className="mb-2">
                                                                                {moment(item.msgtime).fromNow()}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : item.msg == "" && item.msgvideo != "" ? (
                                                                <div className="conversation-list">
                                                                    <div className="ctext-wrap">
                                                                        <div className="ctext-wrap-content py-1 px-2">
                                                                            <video
                                                                                // width="50%"
                                                                                controls
                                                                                hight={300}
                                                                                width={300}
                                                                                className="rounded-lg"
                                                                            >
                                                                                <source
                                                                                    src={item.msgvideo}
                                                                                    type="video/mp4"
                                                                                />
                                                                            </video>
                                                                            <p className="mb-2">
                                                                                {moment(item.msgtime).fromNow()}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="conversation-list">
                                                                    <div className="ctext-wrap">
                                                                        <div className="ctext-wrap-content py-1 px-2">
                                                                            <p className="mb-2">{item.msg}</p>
                                                                            <p className="mb-2">
                                                                                {moment(item.msgtime).fromNow()}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )} */}

                                  <div className="conversation-list">
                                    <div className="ctext-wrap">
                                      <div className="ctext-wrap-content py-1 px-2">
                                        {item.image ? (
                                          <img
                                            data-dz-thumbnail
                                            src={item.message}
                                            className="avatar-md rounded bg-light"
                                            alt="preview"
                                          />
                                        ) : item.offer ? (
                                          <MessageBody
                                            offername={item.offername}
                                            price={item.price}
                                            details={item.details}
                                            image={item.imagepath}
                                            serviceid={item.service_id}
                                            tosendwhom={remoteUserDetails._id}
                                            accepted={item.accepted}
                                            rejected={item.rejected}
                                            nodeId={item.nodeId}
                                            sender={
                                              item.sender_id == userData._id
                                            }
                                            roomId={props.roomId}
                                          />
                                        ) : (
                                          <p className="mb-0">{item.message}</p>
                                        )}

                                        <p className="mb-2">
                                          {moment(item.send_time).fromNow()}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </>
                          );
                        })}

                      <li ref={messagesEndRef}></li>
                    </ul>
                  </SimpleBar>
                </div>
              </div>
            </CardBody>
            {/* chat write area */}
            <div className="px-3 py-2 chat-input-section border-top">
              <Row>
                {/* upload image here */}
                <Col md={12}>
                  <div className="mb-2">
                    {selectedFiles.length > 0 &&
                      selectedFiles.map((item, index) => {
                        return (
                          <div className="upld_img">
                            {item.type == "image/jpeg" ? (
                              <img
                                data-dz-thumbnail
                                src={item.preview}
                                className="avatar-sm rounded bg-light"
                                alt="preview"
                              />
                            ) : (
                              <img
                                data-dz-thumbnail
                                src={img1}
                                className="avatar-sm rounded bg-light"
                                alt="preview"
                              />
                            )}

                            <button
                              className="close border-0 bg-transparent p-0 img_remove"
                              onClick={() => {
                                this.setState({
                                  firstFile: "",
                                  selectedFiles: [],
                                });
                              }}
                            >
                              <i className="far fa-times-circle" />
                            </button>
                          </div>
                        );
                      })}
                    {/* {this.state.videoloading?
                        <img
                        src="https://tenor.com/view/loading-waiting-please-wait-gif-14579561"
                        />:null} */}
                  </div>
                </Col>
                {/* choose image */}
                {/* <Col md={1}>
                                    <label htmlFor="fileUpload" className="my_fileUpload">
                                        <i className="far fa-file-image"></i>
                                        <input
                                            type="file"
                                            id="fileUpload"
                                            className="fileUpload"
                                            onChange={handlefilePick}
                                        />
                                    </label>
                                </Col> */}
                {/* write message */}

                <Col md={9} className="px-lg-0">
                  <div>
                    <Input
                      type="text"
                      className="form-control rounded chat-input pl-3"
                      placeholder="Enter Message..."
                      onChange={(val) => setmessage(val.target.value)}
                      value={message}
                    />
                  </div>
                </Col>
                {message != "" && firstFile != "" ? (
                  <Col md={2} xs={{ size: "auto" }}>
                    <Button
                      color="primary"
                      type="button"
                      // onClick={uploadFile}
                      className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                    >
                      <span className="d-none d-sm-inline-block mr-1">
                        Send
                      </span>{" "}
                      <i className="fas fa-paper-plane"></i>
                    </Button>
                  </Col>
                ) : message == "" && firstFile != "" ? (
                  <Col md={2} xs={{ size: "auto" }}>
                    <Button
                      color="primary"
                      type="button"
                      // onClick={uploadFile}
                      className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                    >
                      <span className="d-none d-sm-inline-block mr-1">
                        Send
                      </span>{" "}
                      <i className="fas fa-paper-plane"></i>
                    </Button>
                  </Col>
                ) : message != "" && firstFile == "" ? (
                  <Col md={2} xs={{ size: "auto" }}>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => sendmessage()}
                      // onClick={() =>
                      //     // SendMsg("")
                      // }
                      className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                    >
                      <span className="d-none d-sm-inline-block mr-1">
                        Send
                      </span>{" "}
                      <i className="fas fa-paper-plane"></i>
                    </Button>
                  </Col>
                ) : (
                  <Col md={2} xs={{ size: "auto" }}>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        alert("please enter message");
                      }}
                      className="chat-send waves-effect waves-light font-size-12 w-100 btn-sm px-0"
                    >
                      <span className="d-none d-sm-inline-block mr-1">
                        Send
                      </span>{" "}
                      <i className="fas fa-paper-plane"></i>
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </Card>
        </div>
      </div>

      {call ? (
        <div
          className={"modal fade loginmodal show"}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <AudioModal
            remoteData={remoteData}
            roomId={remoteData.roomId}
            type="Outgoing"
            callBackcall1={callBackcall}
          />
        </div>
      ) : null}

      {videoModalshow ? (
        <div
          className="modal fade single_modal show videoModal"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <VideoModal
            chatRoomId={remoteData.roomId}
            remoteData={remoteData}
            callBackcall1={callBackcall1}
          />
        </div>
      ) : null}

    </div>
  );
};
export default Refund;
