// we comment checksubscription  due urgent

import React, { useEffect, useState } from "react";
import Footer from "../Defaults/Footer";
import LeftBar from "./component/LeftBar";
import Subscription from "./Subscription/Index";
import MyOrder from "./MyOrders/Index";
import Message from "./Message/Index";
import Withdraw from "./Withdraw/Index";
import WithdrawHistory from "./WithdrawHistory/index";
import MyBankDetails from "./MyBankDetails/index";
import CompleteOrder from "./MyOrders/CompleteOrder";
import Contact from "./Contact/Contact";
import MyProfile from "./MyProfile/Index";
import MyShop from "./MyShop/Index";
import ServiceAdd from "./ServiceAdd/Index";
import Overview from "./Dashboard/Overview";
import Comission from "./Comission/index";
import CancelOrder from "./CancelOrder/CancelOrder";
import Refund from "./Refund/Refund";
import Active from "./Dashboard/Active";
import OrderReport from "./MyOrders/OrderReport";
import HttpClient from "../../src/utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { useHistory, useParams } from "react-router";
import { Redirect } from "react-router-dom";
import MyTicket from "../Seller/MyTicket";
import MyServices from "../Seller/MyServices/Index";
import ServiceList from "./ServicesList/serviceList";
import OurAssociated from "./OurAssociated/index";
import ServiceRequest from "./ServiceRequest/index"

const user = reactLocalStorage.getObject("userData");

const Index = () => {
  const history = useHistory()
  const { page } = useParams();
  const [loading, setLoading] = useState(false);
  const [checkk, setcheckk] = useState(false);
  const userdata = reactLocalStorage.getObject("userData");
  const [tab, setTab] = useState(page ? page : "subscription");

  useEffect(async () => {
    window.scrollTo(0, 0);
    // checksub();
    // const intervalid = setInterval(() => {
    //   checksub();
    // }, 3000);
    // return () => clearInterval(intervalid);
    setTab(page);
 
  }, [page]);

  const handleChangeTab = (val) => {
    setTab(val);
      // window.location.href = "/seller/" + val;
      history.push(`/seller/${val}`)
    if (checkk) {
      // setTab(val);
      // window.location.href = "/seller/" + val;
      // history.push(`/seller/${val}`)
    } else{
      // window.location.href = "/seller/subscription";
      // history.push(`/seller/subscription`)
    }
    
  };

  const checksub = async () => {
    setLoading(true);
    let sendData = {
      userid: userdata._id,
    };
    // console.log("object", sendData);
    let result = await HttpClient.requestData("userSubs", "POST", sendData);
    console.log("userSubs", result);
    if (result && result.status) {
      if (result.data != null) {
        setcheckk(result.data.status);
        console.log(result.data.status);
      } else {
        setcheckk(false);
        console.log(checkk);
      }
    } else {
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3" style={{background:"aliceblue"}}>
            <LeftBar handleClickTab={handleChangeTab} tab={tab} />
          </div>
          {/* {checkk ? ( */}
            <div className="col-md-9" style={{background:"aliceblue"}}>
              <div>{tab === "subscription" ? <Subscription /> : null}</div>
              <div>{tab === "openOrder" ? <MyOrder /> : null}</div>
              <div>{tab === "orderReport" ? <OrderReport /> : null}</div>
              <div>{tab === "completeOrder" ? <CompleteOrder /> : null}</div>
              <div>{tab === "message" ? <Message /> : null}</div>
              <div>{tab === "commission" ? <Comission /> : null}</div>
              <div>{tab === "cancelorder" ? <CancelOrder /> : null}</div>
              <div>{tab === "refund" ? <Refund /> : null}</div>
              <div>{tab === "withdraw" ? <Withdraw /> : null}</div>
              <div>
                {tab === "WithdrawHistory" ? <WithdrawHistory /> : null}
              </div>
              <div>{tab === "MyBankDetails" ? <MyBankDetails /> : null}</div>
              <div>{tab === "contact" ? <Contact /> : null}</div>
              <div>{tab === "profile" ? <MyProfile /> : null}</div>
              <div>{tab === "shop" ? <MyShop /> : null}</div>
              <div>{tab === "myticket" ? <MyTicket /> : null}</div>
              <div>{tab === "services" ? <ServiceAdd /> : null}</div>
              <div>{tab === "seller-service" ? <MyServices /> : null}</div>
              <div>{tab === "service-list" ? <ServiceList /> : null}</div>
              <div>{tab === "overview" ? <Overview /> : null}</div>
              <div>{tab === "activity" ? <Active /> : null}</div>
              <div>{tab === "service-request" ? <ServiceRequest /> : null}</div>

              {/* <div>{tab === "associated" ? <OurAssociated /> : null}</div> */}
            </div>
          {/* ) : ( */}
            <div className="col-md-9">
              {tab === "subscription" ? (
                <Subscription subStatus={checkk} />
              ) : null}
            </div>
          {/* )} */}
        </div>
      </div>
      <section className="mt-3">
        {/* <Footer /> */}
      </section>
    </div>
  );
};

export default Index;
